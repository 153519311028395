<template>
  <div>
    <div class="row mt-5 mb-4">
      <div class="col col-lg-auto ms-auto d-flex">
        <div>
          <button @click="action('cancel')" class="btn btn-secondary">
            Cancelar
          </button>
        </div>

        <div v-if="disabled">
          <tooltip :width="250" boxAlign="left" arrowAlign="right">
            <template #tooltip-container>
              <button disabled class="btn btn-primary ms-3">Salvar</button>
            </template>
            <template #tooltip-text>
              Não há funcionalidades habilitadas para o grupo de acesso nesta
              aba. <br />
              Habilite alguma permissão ou desligue o acesso para salvar.
            </template>
          </tooltip>
        </div>

        <div v-else>
          <button
            v-if="$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
            @click="action('save')"
            class="btn btn-primary ms-3"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tooltip from "@/components/Tooltip.vue";
export default {
  props: {
    align: { type: String, default: "right" },
    save: { type: String, required: false },
    cancel: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    showTooltip: { type: Boolean, required: false, default: false },
  },
  methods: {
    action(type) {
      switch (type) {
        case "save":
          this.$emit("save");
          break;
        case "cancel":
          this.$emit("cancel");
          break;
        default:
          break;
      }
    },
  },
  components: {
    Tooltip,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
