export default class DadosBancariosModel {
  constructor(
    {
      id,
      tipo,
      numero,
      agencia,
      wbcBanco,
      nomeBanco,
      codigoBanco,
      contaPrincipal,
    } = new Object()
  ) {
    this._id = id;
    this._tipo = tipo;
    this._numero = numero;
    this._agencia = agencia;
    this._wbcBanco = wbcBanco;
    this._nomeBanco = nomeBanco;
    this._codigoBanco = codigoBanco;
    this._contaPrincipal = contaPrincipal;
  }

  set wbcBanco(wbcBanco = null) {
    this._wbcBanco = wbcBanco;
  }

  get wbcBanco() {
    return this._wbcBanco || null;
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id ?? null;
  }

  set codigoBanco(codigoBanco) {
    this._codigoBanco = codigoBanco;
  }

  get codigoBanco() {
    return this._codigoBanco;
  }

  set nomeBanco(nomeBanco) {
    this._nomeBanco = nomeBanco;
  }

  get nomeBanco() {
    return this._nomeBanco;
  }

  set contaPrincipal(contaPrincipal) {
    this._contaPrincipal = contaPrincipal;
  }

  get contaPrincipal() {
    return this._contaPrincipal ?? false;
  }

  set agencia(agencia) {
    this._agencia = agencia;
  }

  get agencia() {
    return this._agencia;
  }

  set numero(numero) {
    this._numero = numero;
  }

  get numero() {
    return this._numero?.replace(/[^\d]/g, "");
  }

  set tipo(tipo) {
    this._tipo = tipo;
  }

  get tipo() {
    return this._tipo;
  }

  getData() {
    const {
      id,
      tipo,
      numero,
      agencia,
      wbcBanco,
      nomeBanco,
      codigoBanco,
      contaPrincipal,
    } = this;
    return {
      id,
      tipo,
      numero,
      agencia,
      wbcBanco,
      nomeBanco,
      codigoBanco,
      contaPrincipal,
    };
  }
}
