import ServicesPortal from "../ServicesPortal";
import TipoContatoENUM from "@/enums/TipoContatoENUM";
import ContatoModel from "@/models/empresa/ContatoModel";
import RecebeNotaFiscalENUM from "@/enums/RecebeNotaFiscalENUM";

class ContatoService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  getByEmpresa(request) {
    this._url = `/${request.empresaId}/contatos`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data.length > 0) {
          response.data = response.data.map((contato) => {
            contato.comprador = contato.comprador
              ? TipoContatoENUM.CONTATO_TIPO_COMPRADOR
              : null;
            contato.representanteLegal = contato.representanteLegal
              ? TipoContatoENUM.CONTATO_TIPO_REPRESENTANTE_LEGAL
              : null;
            contato.permissoes.recebeNotaFiscal = contato.permissoes
              .recebeNotaFiscal
              ? RecebeNotaFiscalENUM.CONTATO_NF_RECEBE
              : null;
            return new ContatoModel(contato);
          });
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  create(request, empresaId) {
    this._url = `/${empresaId}/contatos`;
    return this.post(request);
  }

  remove(request, empresaId) {
    this._url = `/${empresaId}/contatos/${request.id}`;
    return this.delete(request);
  }

  update(request, empresaId) {
    this._url = `/${empresaId}/contatos/${request.id}`;
    return this.put(request);
  }

  createEstadoCivil(request) {
    this._url = "/contatos/estado-civil";
    return this.post(request);
  }

  removeEstadoCivil(request) {
    this._url = `/contatos/estado-civil/${request.id}`;
    return this.delete(request);
  }

  listEstadoCivil(request) {
    this._url = "/contatos/estado-civil";
    return this.get(request);
  }

  updateEstadoCivil(request) {
    this._url = `/contatos/estado-civil/${request.id}`;
    return this.put(request);
  }

  createTipoDocumento(request) {
    this._url = "/contatos/documentos/tipos";
    return this.post(request);
  }

  removeTipoDocumento(request) {
    this._url = `/contatos/documentos/tipos/${request.id}`;
    return this.delete(request);
  }

  listTipoDocumento(request) {
    this._url = "/contatos/documentos/tipos";
    return this.get(request);
  }

  updateTipoDocumento(request) {
    this._url = `/contatos/documentos/tipos/${request.id}`;
    return this.put(request);
  }

  listDepartamentosAtivos(request) {
    this._url = "/contatos/departamentos/ativos";

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  listDepartamento(request) {
    this._url = "/contatos/departamentos";

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  createDepartamento(request) {
    this._url = "/contatos/departamentos";
    return this.post(request);
  }

  updateDepartamento(request) {
    const { id } = request;
    this._url = `/contatos/departamentos/${id}/ativar-desativar`;
    return this.put(request);
  }

  changeGestor(request) {
    this._url = `/contatos/departamentos`;
    return this.put(request);
  }

  removeDepartamento(request) {
    this._url = `/contatos/departamentos/${request.id}`;
    return this.delete(request);
  }

  getEmpresasAssociadasByContato(request) {
    this._url = `associadas/${request.id}`;
    return this.get(request);
  }
}

export default new ContatoService("empresas");
