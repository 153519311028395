<template>
  <div style="relative" v-element_loading="loading">
    <nome-grupo-field
      class="mt-5"
      ref="nome-grupo"
      :modelProp="model"
      :disabled="!$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />
    <div class="mt-5" style="font-size: 14px; font-weight: bold">
      Usuários neste grupo
    </div>
    <div class="mb-3" style="font-size: 12.8px">
      Para adicionar um usuário ao grupo, edite o usuário no menu “Usuários” e
      selecione este grupo em “Grupo de Acesso”.
    </div>
    <grupo-acesso-usuarios-table :config="tableConfig" />
    <footer class="d-flex align-items-center justify-content-end">
      <button
        class="btn btn-secondary"
        @click="$router.push({ name: 'BuscaGrupoAcesso' })"
      >
        Cancelar
      </button>
      <button
        @click="save"
        class="btn btn-primary ms-3"
        v-if="isCreating && $can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
      >
        Salvar
      </button>
      <button
        @click="update"
        class="btn btn-primary ms-3"
        v-else-if="$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
      >
        Atualizar
      </button>
    </footer>
  </div>
</template>
<script>
import { formIsValid } from "@/utils/validators";
import { HTTP_RESPONSE } from "@/constants/strings";
import { setFieldsData } from "@/utils/functionUtils";
import RolesServices from "@/services/portal/RolesService";
import NomeGrupoField from "../components/NomeGrupoField.vue";
import GrupoAcessoModel from "@/models/grupo-acesso/GrupoAcessoModel";
import GrupoAcessoUsuariosTable from "../components/GrupoAcessoUsuariosTable.vue";
export default {
  data() {
    return {
      loading: undefined,
      model: GrupoAcessoModel,
      isCreating: false,
      tableConfig: {
        pagination: undefined,
        conteudo: new Array(),
      },
    };
  },
  mounted() {
    this.isCreating = !this.$route.params.id;
    if (this.isCreating) {
      this.model.resetData();
    } else {
      this.getRole();
    }
  },
  destroyed() {
    this.setHeaderText("");
  },
  methods: {
    getRole() {
      this.loading = RolesServices.getRoleById(this.$route.params.id)
        .then(({ data }) => {
          this.model.resetData();
          this.model.setData(data);
          this.tableConfig.conteudo = this.model.getData().usuarios;
          !this.isCreating ? this.setHeaderText(data.nome) : null;
          setFieldsData(this, data);
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    save() {
      if (formIsValid(this)) {
        this.mxResetErrorPendencies();
        this.loading = RolesServices.createRoles({
          ...this.model.getData(),
          permissoes: null,
        })
          .then(({ data }) => {
            const { id } = data;
            this.mxToggleToast(HTTP_RESPONSE.GRUPO_ACESSO.SUCCESS.CREATE);
            this.$route.params.id = id;
            this.getRole();
            this.$emit("isCreating", false);
            this.isCreating = false;
          })
          .catch((error) => this.mxHandleRequestError(error, "GRUPO_ACESSO"));
      }
    },
    update() {
      if (formIsValid(this)) {
        this.mxResetErrorPendencies();
        this.loading = RolesServices.update(this.model.getData())
          .then(({ data }) => {
            this.setHeaderText(data.nome);
            this.mxToggleToast(HTTP_RESPONSE.GRUPO_ACESSO.SUCCESS.UPDATE);
          })
          .catch((error) => {
            this.mxHandleRequestError(error, "NOME_GRUPO_ACESSO");
          });
      }
    },
  },
  components: {
    NomeGrupoField,
    GrupoAcessoUsuariosTable,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
