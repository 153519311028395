<template>
  <div>
    <div class="mt-4 wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Razão Social</th>
            <th scope="col">CNPJ</th>
            <th scope="col">Apelido</th>
            <th scope="col">Tipo</th>
            <th scope="col">Grupo</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody v-if="config.conteudo && config.conteudo.length > 0">
          <tr
            @click="nextPageEmpresa(empresas.id)"
            v-for="(empresas, empresasIndex) in config.conteudo"
            :key="`empresa_${empresasIndex}`"
          >
            <td>{{ empresas.razaoSocial }}</td>
            <td>{{ empresas.cnpj }}</td>
            <td>{{ empresas.apelido }}</td>
            <td>{{ empresas.tipo }}</td>
            <td>{{ empresas.grupo }}</td>
            <td :class="empresas.status.id">{{ empresas.status.nome }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="6">Nenhum empresa encontrada</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination";
export default {
  props: { config: Object },
  components: {
    Pagination,
  },
  methods: {
    nextPageEmpresa(value) {
      this.$emit("nextPage", value);
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
td {
  cursor: pointer;
}

.APROVADO_INCOMPLETO {
  color: $status--red;
}
</style>
