<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="idCargaField">
        ID Carga
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          maxlength="9"
          :disabled="disabled"
          @input="isNumberOrDot()"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="idCargaField"
          class="form-control"
          v-model.trim="$v.model.content.$model"
        />
      </label>
    </div>
    <div class="form-text">Preencha o campo.</div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "idCarga",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("ID Carga inválido");
        return true;
      }
      return false;
    },
    isNumberOrDot() {
      const newValueIdCarga = this.$v.model.content.$model.replace(
        /[^\d\.]/gim,
        ""
      );
      this.$v.model.content.$model = newValueIdCarga;
    },
  },
  validations: {
    model: {
      content: {},
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}
</style>
