<template>
  <div class="container-fluid" v-async="getParametrosPageLoading">
    <page-tabs :tabs="tabs" />
    <!-- <dados-gerais v-if="activeTab === 'DADOS_GERAIS'" /> -->
    <contatos v-if="activeTab === 'CONTATOS'" />
    <dados-bancarios v-else-if="activeTab === 'DADOS_BANCARIOS'" />
    <anexos v-else-if="activeTab === 'ANEXOS'" />
    <pos-vendas v-else-if="activeTab === 'POS-VENDAS'" />
  </div>
</template>

<script>
import Anexos from "./forms/Anexos.vue";
import Contatos from "./forms/Contatos.vue";
import PosVendas from "./forms/PosVendas.vue";
import DadosGerais from "./forms/DadosGerais.vue";
import DadosBancarios from "./forms/DadosBancarios.vue";
import PageTabs from "@/components/navigation/PageTabs.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeTab: "CONTATOS",
      tabs: new Array(
        // { title: "Dados Gerais", key: "DADOS_GERAIS", active: true },
        { title: "Contatos", key: "CONTATOS", active: true },
        { title: "Dados Bancários", key: "DADOS_BANCARIOS", active: true },
        { title: "Anexos", key: "ANEXOS", active: true },
        { title: "Pós-vendas", key: "POS-VENDAS", active: true }
      ),
    };
  },
  computed: {
    ...mapGetters(["getParametrosPageLoading"]),
  },
  mounted() {
    this.setBreadcrumbsParam("Cadastro");
  },
  methods: {
    changeTab(key) {
      this.activeTab = key;
    },
  },
  components: {
    Anexos,
    Contatos,
    PageTabs,
    PosVendas,
    DadosGerais,
    DadosBancarios,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.container-fluid {
  padding: 12px !important;
  height: 100%;
}
</style>
