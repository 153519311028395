<template>
  <div class="wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th
            v-for="(titleColuna, titleColunaColunaIndex) in config.colunas"
            :key="`titleColunaColunaIndex${titleColunaColunaIndex}`"
          >
            {{ titleColuna.description }}
          </th>
          <th>Selecionar</th>
        </tr>
      </thead>
      <tbody v-if="config.conteudo.length > 0">
        <tr
          @click="click(linha)"
          v-for="(linha, linhaIndex) in config.conteudo"
          :key="`linhaIndex${linhaIndex}`"
        >
          <td
            v-for="(coluna, indexColuna) in config.colunas"
            :key="`indexColuna${indexColuna}`"
          >
            <span>{{ getData(linha, coluna.name) }}</span>
          </td>
          <td>
            <div class="input-group mt-3">
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                </label>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" :colspan="config.colunas.length">
            Nenhum registro encontrado
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {};
  },
  methods: {
    click(value) {
      this.$emit("click", value);
    },
    getData(linha, coluna) {
      return linha[coluna];
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  cursor: pointer;
}
</style>
