import store from "@/store";
import { Buffer } from "buffer";

const usuario = {
  state: {
    token: null,
    permissoes: null,
    usuario: new Object(),
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getUsuario(state) {
      if (state.usuario && Object.keys(state.usuario).length > 0) {
        const decodedUser = JSON.parse(
          Buffer.from(state.usuario, "base64").toString("utf8")
        );
        return decodedUser;
      }
      return state.usuario;
    },
    getPermissoes(state) {
      if (state.permissoes) {
        const decodedPermissions = JSON.parse(
          Buffer.from(state.permissoes, "base64").toString("utf8")
        );

        return decodedPermissions;
      }
      return state.permissoes;
    },
  },
  mutations: {
    setToken(state, newstate) {
      state.token = newstate;
    },
    setUsuario(state, data) {
      if (state.token && data) {
        const base64User = Buffer.from(JSON.stringify(data)).toString("base64");
        state.usuario = base64User;
        window.sessionStorage.setItem("u-keys", base64User);
      } else {
        state.usuario = null;
        window.sessionStorage.removeItem("u-keys");
      }
    },
    setPermissoes(state, data) {
      if (state.token && data) {
        const base64Permissions = Buffer.from(JSON.stringify(data)).toString(
          "base64"
        );
        state.permissoes = base64Permissions;
        window.sessionStorage.setItem("p-keys", base64Permissions);
      } else {
        state.permissoes = null;
        window.sessionStorage.removeItem("p-keys");
      }
    },
  },
  actions: {
    ActionSetToken(context, value) {
      context.commit("setToken", value);
      window.sessionStorage.token = value;
      if (!value) {
        window.sessionStorage.removeItem("vuex");
        window.sessionStorage.removeItem("token");
      }
    },
    ActionSetUsuario(context, value) {
      if (!value) {
        window.sessionStorage.removeItem("vuex");
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("u-keys");
      } else {
        context.commit("setUsuario", value);
      }
    },
    ActionSetPermissoes(context, value) {
      context.commit("setPermissoes", value);
      if (!store.state.usuario.token || !value) {
        window.sessionStorage.removeItem("p-keys");
      }
    },
  },
};
export { usuario };
