<template>
  <div>
    <div class="mt-4 wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">E-mail</th>
            <th scope="col" class="text-center">Demonstrativo</th>
            <th scope="col" class="text-center">Selecionar</th>
          </tr>
        </thead>
        <tbody v-if="agentePosVendaList.length > 0">
          <tr
            :class="
              agentePosVenda &&
              agentePosVendaSelected &&
              agentePosVenda.id !== agentePosVendaSelected.id
                ? 'text-secondary'
                : null
            "
            v-for="(agentePosVenda, agentePosVendaIndex) in agentePosVendaList"
            :key="`agentePosVenda_${agentePosVendaIndex}`"
          >
            <td>{{ agentePosVenda.nome }}</td>
            <td>{{ agentePosVenda.email }}</td>
            <td class="text-center">
              <input
                name="#"
                type="checkbox"
                :disabled="
                  !agentePosVendaSelected ||
                  agentePosVenda.id !== agentePosVendaSelected.id
                "
                :checked="agentePosVenda.habilitadoDemonstrativo"
                :class="isAssociate ? 'text-secondary' : null"
                @click="checkDemonstrativoAgentePosVenda(agentePosVenda)"
              />
            </td>
            <td class="text-center">
              <input
                name="#"
                type="radio"
                :disabled="isAssociate || disabled"
                :class="isAssociate ? 'text-secondary' : null"
                :checked="
                  agentePosVendaSelected &&
                  agentePosVenda.id === agentePosVendaSelected.id
                "
                @click="selectAgentePosVenda(agentePosVenda)"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="5">
              Nenhum Agente Pós-Venda encontrado
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    isAssociate: { type: Boolean },
    agentePosVendaList: { type: Array },
    agentePosVendaSelected: { type: Object },
  },
  methods: {
    selectAgentePosVenda(agentePosVenda) {
      this.agentePosVendaList.forEach((currAgentePosVenda) => {
        currAgentePosVenda.habilitadoDemonstrativo = false;
      });
      this.$emit("update:agentePosVendaSelected", agentePosVenda);
    },
    checkDemonstrativoAgentePosVenda(agentePosVenda) {
      agentePosVenda.habilitadoDemonstrativo =
        !agentePosVenda.habilitadoDemonstrativo;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
th {
  font-weight: 500;
}
</style>
