<template>
  <div class="col col-lg-2">
    <div class="input-group">
      <label for="CNAEField">
        CNAE
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          :class="{
            'is-invalid': $v.model.content.$error && !invalidYellow,
            'is-invalid-yellow': invalidYellow,
            'recent-updated': recentUpdatedField,
          }"
          id="CNAEField"
          :disabled="disabled"
          v-mask="'9999-9/99'"
          class="form-control"
          v-model.trim="$v.model.content.$model"
        />
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { isCnae } from "@/filters/assertions";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "cnae",
        content: "",
      },
      isCnaeValidado: true,
    };
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        let errorCount = 0;
        if (!this.isCnaeValidado) {
          this.mxToggleErrorMessageAlert(
            "Campo CNAE inválido: Não encontrado na base de dados da Receita Federal."
          );
          errorCount++;
        }
        if (this.model.content.length !== 9) {
          this.mxToggleErrorMessageAlert(
            "Campo CNAE inválido: deve possuir 7 caracteres"
          );
          errorCount++;
        }
        if (errorCount > 0) {
          return true;
        }
      }
      return false;
    },
  },
  directives: {
    mask: AwesomeMask,
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
    "model.content": {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal) {
          if (oldVal.replace(/[^\d]/g, "") != newVal.replace(/[^\d]/g, "")) {
            this.modelProp[this.model.name] = this.model.content;
          }
        } else {
          this.modelProp[this.model.name] = this.model.content;
        }
        if (this.model.content?.length === 9) {
          this.$parent
            .getCnaeValido()
            .then((res) => (this.isCnaeValidado = res));
        }
      },
    },
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
  },
  validations: {
    model: {
      content: {
        isCnae,
        isCnaeValidado: function () {
          return this.isCnaeValidado;
        },
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
