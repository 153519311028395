<template>
  <div v-element_loading="loading">
    <switch-acessar-field
      title="Acessar Gerador de Propostas"
      :disabled="!$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />
    <roles-manager
      :clearPermissoes="clearPermissoes"
      :type="'GERACAO_PROPOSTAS'"
      :title="'Gestão do Cadastro'"
      :disabled="!acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />
    <footer>
      <cc-barra-salvar-cancelar
        :disabled="!grupoAcessoHasPermissions"
        @save="save"
        @cancel="$router.push({ name: 'BuscaGrupoAcesso' })"
      />
    </footer>
  </div>
</template>
<script>
import { HTTP_RESPONSE } from "@/constants/strings";
import RolesManager from "../components/RolesManager.vue";
import RolesServices from "@/services/portal/RolesService";
import GrupoAcessoModel from "@/models/grupo-acesso/GrupoAcessoModel";
import SwitchAcessarField from "../components/SwitchAcessarField.vue";
export default {
  data() {
    return {
      acessar: false,
      mounted: false,
      loading: undefined,
      clearPermissoes: false,
      model: GrupoAcessoModel,
    };
  },
  destroyed() {
    this.setHeaderText("");
  },
  mounted() {
    this.getRole();
    this.acessar = this.grupoAcessoHasPermissions;
    this.mounted = true;
  },
  methods: {
    getRole() {
      this.loading = RolesServices.getRoleById(this.$route.params.id)
        .then(({ data }) => {
          this.model.setData(data);
          !this.isCreated ? this.setHeaderText(data.nome) : null;
          this.$forceUpdate();
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    save() {
      this.loading = RolesServices.updatePermissions(this.model.getData())
        .then(() => {
          this.mxToggleToast(HTTP_RESPONSE.GRUPO_ACESSO.SUCCESS.UPDATE);
          this.getRole();
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  computed: {
    grupoAcessoHasPermissions() {
      if (this.mounted) {
        if (!this.acessar) return true;
      }

      return (
        this.model.options["GERACAO_PROPOSTAS"] &&
        this.model.options["GERACAO_PROPOSTAS"].filter(
          (option) =>
            option.enabled && option.permissao != "ACESSAR_GERACAO_DESABILITADO"
        ).length > 0
      );
    },
  },
  watch: {
    acessar() {
      if (!this.acessar) {
        this.model.clearPermissoes("GERACAO_PROPOSTAS");
        this.clearPermissoes = true;
      }
    },
  },
  components: {
    RolesManager,
    SwitchAcessarField,
  },
};
</script>
<style lang="scss" scoped></style>
