export default class RegistroHistoricoGestorModel {
    constructor({ id, agentePosVenda, dataInicio, dataFim, atual } = new Object()) {
        this._id = id;
        this._agentePosVenda = agentePosVenda;
        this._dataInicio = dataInicio;
        this._dataFim = dataFim;
        this._atual = atual;
    }

    set id(id = null) {
        this._id = id;
    }

    get id() {
        return this._id;
    }

    set agentePosVenda(agentePosVenda = null) {
        this._agentePosVenda = agentePosVenda;
    }

    get agentePosVenda() {
        return this._agentePosVenda;
    }

    set dataInicio(dataInicio = null) {
        this._dataInicio = dataInicio;
    }

    get dataInicio() {
        return this._dataInicio;
    }

    set dataFim(dataFim = null) {
        this._dataFim = dataFim;
    }

    get dataFim() {
        return this._dataFim;
    }

    set atual(atual = null) {
        this._atual = atual;
    }

    get atual() {
        return !this._dataFim;
    }

}