<template>
  <div class="mt-1 container" :key="`alert_${pendencieIndex}`">
    <div class="alert-primary alert alert-dismissible fade show col col-12" :class="config.type">
      <div v-html="config.message"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
    pendencieIndex: Number,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors";
.alert {
  margin-bottom: 0 !important;
  margin-top: 12px !important;
}
</style>
