export default (fonts) => {
  let fontFaces = "";

  for (let i = 0; i < Object.keys(fonts).length; i++) {
    const font = fonts[Object.keys(fonts)[i]];

    for (let j = 0; j < font.fontVersions.length; j++) {
      const fontVersion = font.fontVersions[j];
      const url =
        (process.env.VUE_APP_BASE_URL || "") +
        font.mainUrl +
        fontVersion.weight;

      let fontFace = "@font-face {";

      if (!font.fontFamily || !font.fontStyle || !fontVersion.weight) continue;
      fontFace += ` font-family: ${font.fontFamily}; font-style: ${font.fontStyle}; font-weight: ${fontVersion.weight};`;

      if (font.mainFile) {
        fontFace += ` src: url("${url + font.mainFile}");`;
      }

      if (fontVersion.local.length || font.files.length) {
        fontFace += " src:";
        const srcItems = [];
        for (let k = 0; k < fontVersion.local.length; k++) {
          srcItems.push(` local("${fontVersion.local[k]}")`);
        }
        for (let k = 0; k < font.files.length; k++) {
          srcItems.push(
            ` url("${url + font.files[k].ext}") format("${
              font.files[k].format
            }")`
          );
        }
        fontFace += srcItems.join(",") + ";";
      }
      fontFace += " }\r\n";

      fontFaces += fontFace;
    }
  }

  return fontFaces;
};
