var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"mt-5"},[_c('div',{staticClass:"row"},[_c('form-row-header',{attrs:{"text":"Cadastrar Ponto de Medição"}})],1),_c('div',{staticClass:"row mt-3"},[_c('switch-ativo-field',{ref:"switchField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('ativo'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('distribuidora-field',{ref:"distribuidoraField",attrs:{"list":_vm.distribuidoraList,"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('distribuidora'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('codigo-unidade-consumidora-field',{ref:"codigoUnidadeConsumidoraField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('codigoUnidadeConsumidora'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}})],1),_c('div',{staticClass:"row mt-3"},[_c('subgrupo-field',{ref:"subgrupoField",attrs:{"list":_vm.subgrupoList,"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('subGrupo'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('modalidade-field',{ref:"modalidadeField",attrs:{"modelProp":_vm.model,"list":_vm.modalidadeList,"recentUpdatedField":_vm.recentUpdatedField('modalidadeTarifaria'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}})],1),_c('div',{staticClass:"row mt-3"},[_c('demanda-contratada-ponta-field',{ref:"demandaContratadaPontaField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('demandaContratadaPonta'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('demanda-contratada-fora-ponta-field',{ref:"demandaContratadaForaPontaField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('demandaContratadaForaPonta'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}})],1),_c('div',{staticClass:"row mt-3"},[_c('ponto-medicao-field',{ref:"pontosDeMedicaoField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('pontoMedicao'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('data-migracao-field',{ref:"dataMigracaoField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('dataMigracao'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('id-carga-field',{ref:"idCargaField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('idCarga'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('id-carga-CCEE',{ref:"idCargaCCEE",attrs:{"modelProp":_vm.model,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('identificacaoCargaCCEE')}})],1),_c('div',{staticClass:"row"},[(_vm.model)?_c('switch-ponto-retaguarda-field',{ref:"switchPontoRetaguardaField",attrs:{"modelProp":_vm.model,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}):_vm._e(),(_vm.model)?_c('ponto-retaguarda-field',{ref:"pontoRetaguardaField",attrs:{"modelProp":_vm.model,"pontoMedicao":_vm.model.pontoMedicao,"tipoRetaguardaAtivo":_vm.model.pontoRetaguardaAtivo,"recentUpdatedField":_vm.recentUpdatedField('pontoRetaguarda'),"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}):_vm._e()],1),_c('div',{staticClass:"row"},[_c('form-row-header',{attrs:{"text":"Endereço"}})],1),_c('div',{staticClass:"row mt-4"},[_c('endereco-unidades-consumidoras-fields',{ref:"enderecoFields",attrs:{"modelProp":_vm.model,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')},on:{"updateEndereco":_vm.updateEndereco,"setCidadesToLoadedState":_vm.setCidadesToLoadedState}})],1),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col col-lg-auto ms-auto"},[(_vm.editing)?_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.resetFields}},[_vm._v(" Cancelar ")]):_vm._e(),(_vm.editing && _vm.CAN_SHOW_BUTTON)?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.add}},[_vm._v(" Atualizar ")]):(_vm.CAN_SHOW_BUTTON)?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.add}},[_vm._v(" Adicionar ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('form-row-header',{staticClass:"ms-2 mt-2",attrs:{"text":"Pontos de Medição Cadastrados"}})],1),_c('table-medicao-cadastrada',{attrs:{"distribuidoraList":_vm.distribuidoraList,"pontosDeMedicaoList":_vm.list,"recentUpdatedFields":_vm.recentUpdatedFields,"recentCreatedPontosMedicao":_vm.recentCreatedPontosMedicao,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
      !this.$can('CENTRAL_CADASTROS_APROVAR') &&
      !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')},on:{"editPontoMedicao":_vm.edit,"deletePontoMedicao":_vm.remove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }