import ServicesPortal from "./ServicesPortal";
class Usuario extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Gets an Usuario entity
   * @param {Object} request
   * @endpoint /api/usuarios
   */
  getUser(request) {
    this._url = `/${request.id}`;
    return this.get(request);
  }

  /**
   * @summary Gets the Usuario
   * @param {Object} request
   * @endpoint /api/usuarios
   */
  getUserInfo(request) {
    this._url = "/info";
    return this.get(request, true);
  }

  getPermissoes(request) {
    this._url = "/permissoes";
    return this.post(request, true);
  }

  /**
   * @summary Gets an Usuario entity
   * @param {Object} request
   * @endpoint /api/usuarios
   */
  searchUser(request) {
    this._url = `/${request.id}/buscar`;
    return this.get(request);
  }

  /**
   * @summary Validates the username and password to access the system
   * @param {Object} request
   * @endpoint /api/usuarios/login
   */
  auth(request) {
    this._url = "/login";
    return this.post(request);
  }

  /**
   * @summary Creates an Usuario entity
   * @param {Object} request
   * @endpoint /api/usuarios
   */
  register(request) {
    this._url = "/";
    return this.post(request);
  }

  /**
   * @summary Updates an Usuario entity
   * @param {Object} request
   * @endpoint /api/usuarios/${usuarioId}
   */
  update(request) {
    this._url = `/`;
    return this.put(request);
  }

  /**
   * @summary Deleting/Disable an user
   * @param {Object} request
   * @endpoint /api/usuarios/${usuarioId}
   */
  disableUser(request) {
    this._url = `/${request.id}`;
    return this.delete(request);
  }

  /**
   * @summary List of user roles
   * @param {Object} request
   * @endpoint /api/usuarios/roles
   */
  getRoles(request) {
    this._url = `/roles`;
    return this.get(request);
  }

  /**
   * @summary User listing
   * @param {Object} request
   * @endpoint /api/usuarios
   */
  list(request) {
    this._url = `/`;
    return this.get(request, true);
  }

  changePassword(request) {
    this._url = "/atualizar-senha";
    return this.patch(request);
  }

  /**
   * @summary Gera um token temporário para recuperação de senha do usuário a partir do email
   * @param {Object} request
   * @endpoint /api/usuarios/recuperar-senha
   */
  recuperarSenha(request) {
    this._url = "/recuperar-senha";
    return this.post(request);
  }

  /**
   * @summary Troca a senha após requisição de Recuperação de Senha
   * @param {Object} request
   * @endpoint /api/usuarios/resetar-senha
   */
  resetarSenha(request, token, emailEncoded) {
    this._url = `/resetar-senha?emailEncoded=${emailEncoded}&token=${token}`;
    return this.post(request);
  }
}

export default new Usuario("usuarios");
