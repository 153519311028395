<style lang="scss" scoped>
.modalSpinner {
  pointer-events: none;
}
</style>
<template>
  <div class="modalSpinner" :style="styles.mainDiv || {}">
    <div class="loadingSpinner" :style="styles.spinnerWrapper || {}">
      <img :src="imageSpinner" :style="styles.spinner || {}" />
    </div>
  </div>
</template>
<script>
import imageSpinner from "@/assets/svg/spinner.svg";

export default {
  computed: {
    imageSpinner() {
      return imageSpinner;
    },
  },
  props: {
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
