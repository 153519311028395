export default {
  PROSPECT: {
    id: "PROSPECT",
    nome: "Prospect",
  },
  RASCUNHO: {
    id: "RASCUNHO",
    nome: "Rascunho",
  },
  PRE_CADASTRO: {
    id: "PRE_CADASTRO",
    nome: "Pré Cadastro",
  },
  EM_INTEGRACAO: {
    id: "EM_INTEGRACAO",
    nome: "Em Integração",
  },
  INFORMACOES_PENDENTES: {
    id: "INFORMACOES_PENDENTES",
    nome: "Informações Pendentes",
  },
  PENDENTE_APROVACAO: {
    id: "PENDENTE_APROVACAO",
    nome: "Pendente de Aprovação",
  },
  EM_APROVACAO: {
    id: "EM_APROVACAO",
    nome: "Em Aprovação",
  },
  APROVADO: {
    id: "APROVADOS",
    nome: "Cadastro Aprovado",
  },
  APROVADO_INCOMPLETO: {
    id: "APROVADO_INCOMPLETO",
    nome: "Cadastro Aprovado Incompleto",
  },
  APROVADO_COMPLETO: {
    id: "APROVADO_COMPLETO",
    nome: "Cadastro Aprovado Completo",
  },
  FALHA_INTEGRACAO_WBC: {
    id: "FALHA_INTEGRACAO_WBC",
    nome: "Falha na Integração WBC",
  },
  EXPIRADO: {
    id: "EXPIRADO",
    nome: "Expirado",
  },
};
