<template>
  <div class="col col-lg-3">
    <label for="idDataValidade">
      Prazo de Validade
      <input
        id="idDataValidade"
        type="date"
        class="form-select form-date"
        :class="{
          'is-invalid': $v.model.content.$error,
        }"
        v-model="model.content"
        ref="idDataValidade"
      />
    </label>
    <div class="form-text">Escolha uma data.</div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";

export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
  },
  data() {
    return {
      model: {
        name: "dataValidade",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      const { $touch, $invalid } = this.$v;
      $touch();
      if ($invalid)
        this.mxToggleErrorMessageAlert(
          "Data de validade possui um valor inválido"
        );
      return $invalid;
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          return this.$refs.idDataValidade.validity.badInput;
        }),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
<style scoped>
.form-date {
  padding-right: 0.75rem !important;
  background-image: none !important;
  background-repeat: initial !important;
  background-position: 0% !important;
  background-size: auto !important;
}
</style>
