<template>
  <div class="col col-lg-5">
    <div class="input-group">
      <label for="enderecoLogradouroField">
        Logradouro
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="200"
          :disabled="disabled"
          class="form-control"
          id="enderecoLogradouroField"
          v-model.trim="model.content"
          :class="{
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField,
          }"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "logradouro",
        content: "",
      },
    };
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (this.invalidYellow) {
            return true;
          }
          return false;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
