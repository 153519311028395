<template>
  <div class="container-fluid">
    <page-tabs :tabs="tabs" />
    <portal-gestor v-if="activeTab === 'PORTAL_GESTOR'" />
    <portal-cliente v-else />
  </div>
</template>

<script>
import PortalGestor from "../acesso-externo/PortalGestor.vue";
import PortalCliente from "../acesso-externo/PortalCliente.vue";
import PageTabs from "@/components/navigation/PageTabs.vue";

export default {
  data() {
    return {
      activeTab: "PORTAL_GESTOR",
      tabs: new Array(
        { title: "Portal Gestor", key: "PORTAL_GESTOR", active: true },
        { title: "Portal Cliente", key: "PORTAL_CLIENTE", active: true }
      ),
    };
  },
  mounted() {
    this.setBreadcrumbsParam("Clientes e Gestores");
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
  components: {
    PageTabs,
    PortalGestor,
    PortalCliente,
  },
};
</script>

<style scoped lang="scss">
.container-fluid {
  padding: 12px !important;
  height: 100%;
}
</style>
