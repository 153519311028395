import "./async.scss";
import $ from "jquery";

export default {
  inserted(el) {
    const overlay = $(
      "<div class='async-overlay'><div class='fundo'></div><div class='spinner'></div></div>"
    );
    $(el).append(overlay).data("overlay-div", overlay);
  },
  update(el, binding) {
    if (binding.value === binding.oldValue) return;
    const $el = $(el);
    const overlay = $el.data("overlay-div");
    if (binding.modifiers.opacity) overlay.addClass("opacity-loading");
    else overlay.removeClass("opacity-loading");

    overlay.width($el.width());
    overlay.height($el.height());
    if (binding.value === false) {
      overlay.hide();
    } else {
      overlay.show();
      binding.value.then(() => overlay.hide()).catch(() => overlay.hide());
    }
  },
};
