import EmpresasLoteEmpresaModel from "./EmpresasLoteEmpresaModel";

export default class EmpresasLoteModel {
  constructor(
    {
      id,
      dataInicio,
      dataFim,
      totalRegistros,
      totalRegistrosProcessados,
      totalRegistrosProcessadosComSucesso,
      processando,
      finalizado,
      finalizadoComErro,
      empresas,
    } = new Object()
  ) {
    this._id = id;
    this._dataInicio = dataInicio;
    this._dataFim = dataFim;
    this._totalRegistros = totalRegistros;
    this._totalRegistrosProcessados = totalRegistrosProcessados;
    this._totalRegistrosProcessadosComSucesso =
      totalRegistrosProcessadosComSucesso;
    this._processando = processando;
    this._finalizado = finalizado;
    this._finalizadoComErro = finalizadoComErro;
    this._empresas = empresas;
  }

  get id() {
    return this._id;
  }

  set id(id = null) {
    this._id = id;
  }

  get dataInicio() {
    return this._dataInicio;
  }

  set dataInicio(dataInicio = null) {
    this._dataInicio = dataInicio;
  }

  get dataFim() {
    return this._dataFim;
  }

  set dataFim(dataFim = null) {
    this._dataFim = dataFim;
  }

  get totalRegistros() {
    return this._totalRegistros;
  }

  set totalRegistros(totalRegistros = null) {
    this._totalRegistros = totalRegistros;
  }

  get totalRegistrosProcessados() {
    return this._totalRegistrosProcessados;
  }

  set totalRegistrosProcessados(totalRegistrosProcessados = null) {
    this._totalRegistrosProcessados = totalRegistrosProcessados;
  }

  get totalRegistrosProcessadosComSucesso() {
    return this._totalRegistrosProcessadosComSucesso;
  }

  set totalRegistrosProcessadosComSucesso(
    totalRegistrosProcessadosComSucesso = null
  ) {
    this._totalRegistrosProcessadosComSucesso =
      totalRegistrosProcessadosComSucesso;
  }

  get processando() {
    return this._processando;
  }

  set processando(processando = null) {
    this._processando = processando;
  }

  get finalizado() {
    return this._finalizado;
  }

  set finalizado(finalizado = null) {
    this._finalizado = finalizado;
  }

  get finalizadoComErro() {
    return this._finalizadoComErro;
  }

  set finalizadoComErro(finalizadoComErro = null) {
    this._finalizadoComErro = finalizadoComErro;
  }

  get empresas() {
    return this._empresas || [];
  }

  set empresas(empresas = null) {
    if (!Array.isArray(empresas)) {
      empresas = [];
    }
    if (
      empresas.length > 0 &&
      !(empresas[0] instanceof EmpresasLoteEmpresaModel)
    ) {
      empresas = empresas.map(
        (empresa) => new EmpresasLoteEmpresaModel(empresa)
      );
    }
    this._empresas = empresas;
  }
}
