<template>
  <div>
    <div class="mt-4 wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">E-mail</th>
            <th scope="col" class="text-center">Demonstrativo</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-if="agentePosVendaAssociated && agentePosVendaAssociated.id">
          <tr :key="`agentePosVendaAssociated`">
            <td>{{ agentePosVendaAssociated.nome }}</td>
            <td>{{ agentePosVendaAssociated.email }}</td>
            <td class="text-center">
              <i class="icon-check icon small" v-if="habilitadoDemonstrativo" />
              <i class="icon-xis icon small" v-else />
            </td>
            <td>
              <i
                v-if="!disabled"
                class="
                  d-flex
                  justify-content-end
                  align-items-center
                  icon-lixeira icon
                  ms-3
                  cursop
                "
                @click="$emit('dissociateAgente')"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="5">
              Nenhum Agente Pós-Venda associado
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    isAssociate: { type: Boolean },
    habilitadoDemonstrativo: { type: Boolean },
    agentePosVendaAssociated: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
th {
  font-weight: 500;
}
</style>
