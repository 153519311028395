<template>
  <div
    class="hint_bubble"
    v-if="on"
    :style="{
      marginTop: modalY - height - 10 + 'px',
      marginLeft: modalX - width/2 + 'px'
    }"
    ref="modalBubbleHint"
  >
    <div>{{ msg }}</div>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: 0,
      height: 0,
    };
  },
  props: {
    on: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "",
    },
    modalX: {
      type: Number,
      required: true,
    },
    modalY: {
      type: Number,
      required: true,
    },
  },
  watch: {
    on() {
      if (
        this.$refs &&
        this.$refs.modalBubbleHint &&
        this.$refs.modalBubbleHint.offsetWidth
      ) {
        this.width = this.$refs.modalBubbleHint.offsetWidth
      }
      if (
        this.$refs &&
        this.$refs.modalBubbleHint &&
        this.$refs.modalBubbleHint.offsetHeight
      ) {
        this.height = this.$refs.modalBubbleHint.offsetHeight
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/style/style.scss";
.hint_bubble
  top: 0
  left: 0
  z-index: 10
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  & div:nth-child(1)
    max-width: 400px
    color: #fff
    background-color: $primary--black
    padding: 15px
    border-radius: 2px
  & div:nth-child(2)
    border-style: solid
    border-width: 12.5px 6.25px 0 6.25px
    border-color: $primary--black transparent transparent transparent
</style>
