class ContatoDepartamentoModel {
  constructor(data = new Object()) {
    this._id = data.id;
    this._nome = data.nome;
    this._ativo = data.ativo;
    this._gestor = data.gestor;
  }

  get id() {
    return this._id || null;
  }

  set id(id = null) {
    this._id = id;
  }

  get nome() {
    return this._nome || null;
  }

  set nome(nome = null) {
    this._nome = nome;
  }

  get ativo() {
    return this._ativo || false;
  }

  set ativo(ativo = false) {
    this._ativo = ativo;
  }

  get gestor() {
    return this._gestor || false;
  }

  set gestor(gestor = false) {
    this._gestor = gestor;
  }

  getData() {
    const { id, nome, ativo, gestor } = this;

    return {
      id,
      nome,
      ativo,
      gestor,
    };
  }
}

export default ContatoDepartamentoModel;
