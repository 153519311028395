<template>
  <div v-async="loading">
    <div class="row d-flex align-items-center justify-content-between">
      <div class="col-md-6 col-lg-6">
        <div>
          <search-bar
            ref="searchBar"
            @doSearch="doSearch"
            placeholder="Busque por Nome"
            title="Buscar Grupo Econômico"
            :cols="10"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-3 d-flex justify-content-end">
        <button
          v-if="
            $can('CENTRAL_CADASTROS_EDITAR') ||
            $can('CENTRAL_CADASTROS_APROVAR') ||
            $can('CENTRAL_CADASTROS_PARAMETRIZAR')
          "
          @click="nextPage"
          class="btn btn-primary-blue"
        >
          + Adicionar Grupo
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <data-table
          @click="nextPage"
          @setPage="setPage"
          class="mt-4"
          :config="tableConfig"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";
import DataTable from "@/components/tables/Table.vue";
import Pagination from "@/components/pagination/pagination";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import GrupoEconomicoService from "@/services/portal/empresa/GrupoEconomicoService";
export default {
  data() {
    return {
      loading: undefined,
      dataTableKey: 0,
      tableConfig: {
        colunas: [
          { name: "nome", description: "Nome" },
          { name: "empresasSize", description: "Quantidade de Empresas" },
        ],
        conteudo: new Array(),
        pagination: undefined,
      },
      filter: {
        size: 10,
        page: 0,
      },
    };
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    nextPage({ id }) {
      this.$router.push({
        name: "GrupoEconomico",
        params: { id },
      });
    },
    setPage(page) {
      this.filter.page = page;
      this.doSearch();
    },
    doSearch(search = this.$refs.searchBar.content, resetPage = false) {
      const { size, page } = this.filter;
      this.loading = GrupoEconomicoService.list({
        filtro: search,
        size,
        page: resetPage ? 0 : page,
      })
        .then((response) => {
          this.tableConfig.conteudo = response.data.content;
          this.tableConfig.pagination = response.data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  components: {
    SearchBar,
    DataTable,
    Pagination,
    FormRowHeader,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.text {
  @include phablet {
    margin-top: 10px;
  }
}
</style>
