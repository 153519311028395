<template>
  <div v-element_loading="loading" class="container-fluid">
    <div class="ga-header">
      <div>
        <page-tabs :tabs="tabs" />
      </div>
      <div style="text-align: right">
        <button
          @click="toggleAtivar"
          v-if="!model.getData().ativo"
          :disabled="isCreated"
          class="btn btn-primary-green"
        >
          Ativar Grupo
        </button>
        <button
          @click="toggleAtivar"
          v-if="model.getData().ativo && !model.usuarios.length"
          :disabled="model.getData().id == null"
          class="btn btn-primary-red"
        >
          Inativar Grupo
        </button>
      </div>
    </div>
    <div style="position: relative">
      <div :class="{ 'modal-inativo': !model.getData().ativo }"></div>
      <configuracoes
        @isCreating="isCreating"
        v-if="activeTab === 'CONFIGURACOES'"
      />
      <central-cadastros v-else-if="activeTab === 'CENTRAL_CADASTROS'" />
      <geracao-propostas v-else-if="activeTab === 'GERACAO_PROPOSTAS'" />
      <billing v-else-if="activeTab === 'BILLING'" />
    </div>
  </div>
</template>
<script>
import Billing from "./sections/Billing.vue";
import { HTTP_RESPONSE } from "@/constants/strings";
import Configuracoes from "./sections/Configuracoes.vue";
import RolesServices from "@/services/portal/RolesService";
import PageTabs from "@/components/navigation/PageTabs.vue";
import CentralCadastros from "./sections/CentralCadastro.vue";
import GeracaoPropostas from "./sections/GeracaoProposta.vue";
import GrupoAcessoModel from "@/models/grupo-acesso/GrupoAcessoModel";
export default {
  data() {
    return {
      isCreated: false,
      loading: undefined,
      model: GrupoAcessoModel,
      activeTab: "CONFIGURACOES",
      tabs: new Array(
        {
          title: "Configurações",
          key: "CONFIGURACOES",
          active: true,
        },
        {
          title: "Central de Cadastros",
          key: "CENTRAL_CADASTROS",
          active: true,
        },
        {
          title: "Enviador de Propostas",
          key: "GERACAO_PROPOSTAS",
          active: true,
        },
        {
          title: "Billing",
          key: "BILLING",
          active: true,
        }
      ),
    };
  },
  methods: {
    changeTab(key) {
      this.activeTab = key;
    },
    isCreating(isCreate) {
      this.tabs.map((tab) => {
        tab.active = !(tab.key != "CONFIGURACOES" && isCreate);
        return tab;
      });
    },
    toggleAtivar() {
      this.model.ativo = !this.model.ativo;
      this.loading = RolesServices.update(this.model.getData())
        .then(() => {
          this.mxToggleToast(HTTP_RESPONSE.GRUPO_ACESSO.SUCCESS.UPDATE);
          this.isCreating(!this.$route.params.id);
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  watch: {
    activeTab: {
      immediate: true,
      handler() {
        const title = this.tabs.find((tab) => tab.key === this.activeTab).title;
        this.setBreadcrumbsParam(title);
      },
    },
  },
  mounted() {
    this.isCreating(!this.$route.params.id);
  },
  components: {
    Billing,
    PageTabs,
    Configuracoes,
    CentralCadastros,
    GeracaoPropostas,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
.container-fluid {
  height: 100%;
  padding: 12px !important;
}

.ga-header {
  display: flex;
  justify-content: space-between;
}

.modal-inativo {
  width: 100%;
  height: 100%;
  z-index: 200;
  position: absolute;
  background: rgba($color: #fff, $alpha: 0.8);
}
</style>
