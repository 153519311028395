<template>
  <div class="col col-lg-6">
    <span>Tipo de Inscrição Estadual</span>
    <div class="input-group mt-3">
      <div class="d-flex">
        <div
          v-for="(inscricao, index) in list"
          :key="`inscricao_type_${index}`"
        >
          <div class="me-4">
            <label class="p-1" :for="`inscricao_type_${inscricao.id}`">
              <input
                :value="inscricao"
                :disabled="disabled"
                v-model="model.content"
                name="tipo_inscricao"
                type="radio"
                :id="`inscricao_type_${inscricao.id}`"
              />
              {{ inscricao.nome }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InscricaoEstadualTypeENUM from "@/enums/InscricaoEstadualTypeENUM";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
  },
  data() {
    return {
      list: new Array(),
      model: {
        name: "inscricaoEstadualTipo",
        content: "",
      },
    };
  },
  mounted() {
    const newList = Object.keys(InscricaoEstadualTypeENUM).map(
      (type) => InscricaoEstadualTypeENUM[type]
    );
    this.list = [newList[1], newList[0], ...newList.splice(2)]
  },
  watch: {
    modelProp: {
      deep: true,
      immediate: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
  },
};
</script>

<style scoped lang="scss">
div.form-check:first-child {
  justify-content: space-around;
}

div.form-check {
  width: 50%;
  float: left;
  display: flex;
  margin-right: 0;
  padding-left: 0;
  align-items: center;
  justify-content: space-evenly;
}

div.form-check,
div.form-check input,
div.form-check label {
  cursor: pointer;
}
</style>
