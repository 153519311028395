<template>
  <div class="wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Acesso</th>
        </tr>
      </thead>
      <tbody v-if="config.conteudo.length > 0">
        <tr
          v-for="(
            grupoAcessoUsuario, grupoAcessoUsuarioIndex
          ) in config.conteudo"
          :key="`grupo_acesso_${grupoAcessoUsuarioIndex}`"
        >
          <td>{{ grupoAcessoUsuario.nome }}</td>
          <td>
            <i class="me-2 icon-padunlocked"></i>
            {{ grupoAcessoUsuario.ativo ? "Ativo" : "Inativo" }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="2">
            Nenhum usuário cadastrado no grupo
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { DATE_ptBR } from "@/filters/formatters";
export default {
  props: {
    config: { type: Object, required: true },
  },
  filters: {
    DATE_ptBR,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.table {
  cursor: pointer;
}
</style>
