<template>
  <div class="toast-container">
    <div
      class="toast"
      id="toast-component"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <div class="success-dot"></div>
        <strong class="me-auto">{{ getToast.title }}</strong>
        <small class="text-muted">{{ getActualDateTime() }}</small>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body">
        {{ getToast.message }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getToast"]),
  },
  methods: {
    getActualDateTime() {
      return new Date().toLocaleTimeString("pt-BR");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
.toast-container {
  right: 10px;
  bottom: 20px;
  z-index: 999;
  position: fixed;
}

.success-dot {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 2px;
  background-color: $status--green;
}
</style>
