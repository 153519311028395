import ParametrosCadastros from "@/pages/parametros/ParametrosCadastros.vue";
import ParametrosStatusCadastros from "@/pages/parametros/ParametrosStatusCadastros.vue";
const routes = [
  {
    path: "/parametros-cadastro",
    name: "ParametrosCadastro",
    component: ParametrosCadastros,
    meta: {
      auth: true,
      breadcrumb: new Array("Parâmetros"),
      permissions: new Array("CENTRAL_CADASTROS_PARAMETRIZAR"),
    },
  },
  {
    path: "/parametros-status",
    name: "ParametrosStatus",
    component: ParametrosStatusCadastros,
    meta: {
      auth: true,
      breadcrumb: new Array("Parâmetros"),
      permissions: new Array("CENTRAL_CADASTROS_PARAMETRIZAR"),
    },
  },
];

export default routes;
