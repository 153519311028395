<template>
  <div class="mt-4 wrapper-table">
    <table class="table table-hover">
      <thead v-if="title">
        <tr>
          <th>
            {{ title }}
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(linha, linhaIndex) in options"
          :key="`linhaIndex${linhaIndex}`"
          @click="selectRole(linha)"
        >
          <td style="width: 40%" v-if="linha.tipo == type && !linha.invisible">
            <span>{{ linha.nome }}</span>
          </td>
          <td style="width: 40%" v-if="linha.tipo == type && !linha.invisible">
            <small>{{ linha.alertText }}</small>
          </td>
          <td v-if="linha.tipo == type && !linha.invisible">
            <div class="d-flex justify-content-end">
              <input
                :disabled="disabled"
                type="radio"
                :name="`${type}${subtype}`"
                :value="linha"
                v-model="selectedRole"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import GrupoAcessoModel from "@/models/grupo-acesso/GrupoAcessoModel";
export default {
  props: {
    type: String,
    title: String,
    subtype: String,
    clearPermissoes: Boolean,
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      options: new Array(),
      selectedRole: undefined,
      model: GrupoAcessoModel,
    };
  },
  mounted() {
    this.buildOptions();
    this.buildRoles();
  },
  methods: {
    selectRole(role) {
      if (!this.disabled) this.selectedRole = role;
    },
    buildOptions() {
      let options = this.model.getOptions();
      if (this.type === "BILLING" || this.type === "CENTRAL_CADASTROS") {
        options = options[this.type][this.subtype];
      } else {
        options = options[this.type];
      }
      this.options = options;
    },
    buildRoles() {
      if (
        this.$parent.model.permissoes &&
        this.$parent.model.permissoes.length > 0 &&
        this.$parent.model.permissoes.findIndex(
          (permissao) => permissao.tipo === this.type
        ) > -1
      ) {
        this.selectedRole =
          this.options &&
          this.options.find((option) => {
            let hasFound = false;
            this.$parent.model.permissoes.forEach((permissao) => {
              if (permissao.permissao === option.permissao) hasFound = true;
            });
            return hasFound;
          });
      }
    },
    resetRoles() {
      this.selectedRole =
        this.options &&
        this.options.find((option) => {
          return (
            option.nome.toUpperCase() === "NÃO HABILITAR FUNCIONALIDADE" ||
            option.permissao == "VISUALIZAR_FATURAMENTO"
          );
        });
    },
  },
  watch: {
    clearPermissoes: {
      deep: true,
      handler() {
        if (this.clearPermissoes) {
          this.resetRoles();
          if (
            (this.type === "CENTRAL_CADASTROS" &&
              this.subtype === "GRUPOS_ACESSO") ||
            this.type === "GERACAO_PROPOSTAS" ||
            (this.type === "BILLING" && this.subtype === "BUSCAR_APURACOES")
          ) {
            this.$parent.clearPermissoes = false;
          }
        }
      },
    },
    selectedRole: {
      deep: true,
      handler() {
        if (this.selectedRole) {
          this.options.forEach((option) => {
            if (option.permissao === this.selectedRole.permissao) {
              option.enabled = true;
            } else {
              option.enabled = false;
            }
          });
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
@import "@/assets/style/style.scss";
small {
  color: #dc3545;
  font-size: 12px;
}
.icons {
  color: $primary--orange;
}
.table {
  cursor: pointer;
  margin-bottom: 0px !important;
}

.switch-button-control {
  .switch-button {
    $switch-is-rounded: true;
    $switch-button-height: 1em;
    background: $primary--gray;
    margin-top: 10px !important;
    $switch-button-color: transparent;
    $switch-button-border-thickness: 2px;
    $switch-transition: all 0.3s ease-in-out;

    height: $switch-button-height;
    width: calc(#{$switch-button-height} * 2);
    border: $switch-button-border-thickness solid $switch-button-color;
    box-shadow: inset 0px 0px $switch-button-border-thickness 0px
      rgba(0, 0, 0, 0.33);
    border-radius: if($switch-is-rounded, $switch-button-height, 0);

    transition: $switch-transition;

    $button-side-length: calc(
      #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
    );

    cursor: pointer;

    .button {
      background: white;
      width: $button-side-length;
      height: $button-side-length;
      transition: $switch-transition;
      box-shadow: 0.5px 0.5px 2px $black-overlay;
      border-radius: if($switch-is-rounded, $button-side-length, 0);
      border: $switch-button-border-thickness solid $switch-button-color;
    }

    &.enabled {
      box-shadow: none;
      background-color: $primary--orange;

      .button {
        background: white;
        transform: translateX(
          calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
        );
      }
    }

    &.disabled {
      box-shadow: none;
      background-color: $primary--gray !important;

      .button {
        background: white;
        transform: translateX(
          calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
        );
      }
    }
  }
}
</style>
