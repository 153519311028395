import ServicesPortal from "../ServicesPortal";

class TarefaService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  listTarefas(request) {
    this._url = `/email/`;
    return this.get(request);
  }

  configureTarefa(request) {
    this._url = `/email/configuracao`;
    return this.post(request);
  }

  deleteTarefa(request) {
    this._url = `/email/${request.id}`;
    return this.delete(request);
  }

}

export default new TarefaService("tarefas");
