import { helpers } from "vuelidate/lib/validators";

/**
 * @sumary - Checks whether the value is a valid Brazilian CNPJ number
 * @pattern - XX.XXX.XXX/XXXX-XX
 * @optional - The dots and the hyphen
 */
export const isCnpj = helpers.regex(
  "isCnpj",
  /[0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2}/
);

/**
 * @sumary - Checks whether the value is a valid Brazilian CPF number
 * @pattern - XXX.XXX.XXX-XX
 * @optional - The dots and the hyphen
 */
export const isCpf = helpers.regex(
  "isCpf",
  /[0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2}/
);

/**
 * @sumary - Checks whether the value is a valid Brazilian state registration number
 * @pattern - XXX.XXX.XXX
 * @optional - The dots
 */
export const isStateRegistration = helpers.regex(
  "isStateRegistration",
  /[0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}|[0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}/
);

/**
 * @sumary - Checks whether the value is a valid Brazilian CEP number
 * @pattern - XXXXX-XX
 * @optional - The hyphen
 */
export const isCep = helpers.regex("isCep", /[0-9]{5}[\-]?[0-9]{3}/);

/**
 * @sumary - Checks whether the value is a valid phone number
 * @pattern - (XX) XXXX-XXXX
 * @optional - Both parentheses, the blank space and the hyphen
 */
export const isPhone = helpers.regex(
  "isPhone",
  /[\(]?[0-9]{2}[\)]?[\ ]?[0-9]{4}[\-]?[0-9]{4}/
);

/**
 * @sumary - Checks whether the value is a valid cell phone number
 * @pattern - (XX) XXXXX-XXXX
 * @optional - Both parentheses, the "9" digit, the blank space and the hyphen
 */
export const isCelPhone = helpers.regex(
  "isCelPhone",
  /[\(]?[0-9]{2}[\)]?[\ ]?[\0-9]{1}?[0-9]{4}[\-]?[0-9]{4}/
);

/**
 * @sumary - Checks whether the value is a valid acronym for Brazilian states
 * @pattern - AA (ignores case)
 */
export const isValidBrazilianUF = helpers.regex(
  "isValidBrazilianUF",
  /(AC|AL|AP|AM|BA|CE|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO|DF)/i
);

/**
 * @sumary - Checks whether the value is a valid Brazilian RG number
 * @pattern - XX.XXX.XXX-X
 * @optional - Both dots between numbers and the hyphen
 */
export const isRG = helpers.regex(
  "isRG",
  /[0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\-]?[0-9]{1}/
);

/**
 * @sumary - Checks whether the value is a valid Brazilian CNH number
 * @pattern - XXXXXXXXXXX
 */
export const isCNH = helpers.regex("isCNH", /[0-9]{11}/);

/**
 * @sumary - Checks whether the value is a valid Brazilian Passport number
 * @pattern - AAXXXXXX (ignores case)
 */
export const isValidBrazilianPassport = helpers.regex(
  "isValidBrazilianPassport",
  /[a-z]{2}[0-9]{6}/i
);

/**
 * @sumary - Checks whether the hour value is valid and if its in the 24 hour format
 * @pattern - XX:XX
 * @optional - Double dots
 */
export const isValid24HourFormat = helpers.regex(
  "isValid24HourFormat",
  /([0-1]{1}[0-9]{1}[\:]?[0-5]{1}[0-9]{1})|([0-2]{1}[0-3]{1}[\:]?[0-5]{1}[0-9]{1})/
);

/**
 * @sumary - Checks whether the product Data Base is valid
 * @pattern - XX/XXXX
 * @optional - Slash
 */
export const isDataBaseValidFormat = helpers.regex(
  "isDataBaseValidFormat",
  /(([1]{1}[0-2]{1})|([0]{1}[1-9]{1}))[\/]?[2]{1}[0]{1}[0-4]{1}[0-9]{1}/
);

/**
 * @summary - Checks whether the email is valid
 * @pattern - NNNNN@NNNNN.COM (ignores case)
 */
export const isValidEmail = helpers.regex(
  "isValidEmail",
  /^[A-Za-z0-9_.-]+@[A-Za-z0-9_.-]+\.[A-z]+(\.([A-z]+))?$/
);

/**
 * @summary - Checks whether the value contains only number without special caracters
 */
export const numberWOSpecialCaracter = helpers.regex("numberNoSpace", /^\d+$/);

/**
 * @summary - Checks whether the value contains only text without special caracters
 */
export const textWOSpecialCaracter = helpers.regex(
  "noSpecialCaracters",
  /^[a-zA-Z]+$/
);

/**
 * @summary - Checks whether the value contains only alphanumeric without special caracters
 */
export const alphanumericWOSPecialCaracter = helpers.regex(
  "alphanumericWOSPecialCaracter",
  /^[a-zA-Z0-9]+$/
);

/**
 * @summary - Checks whether the value contains only alphanumeric without special caracters
 */
export const alphanumericSpacedWOSPecialCaracter = helpers.regex(
  "alphanumericWOSPecialCaracter",
  /^[-_ a-zA-Z0-9]+$/
);

/**
 * @summary - Checks whether the value is a valid CNAE number
 * @pattern - XXXX-X/XX
 * @optional - The hyphen and slash
 */
export const isCnae = helpers.regex(
  "isCnae",
  /[0-9]{4}[\-]?[0-9][\/]?[0-9]{2}/
);
