var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"mt-3"},[_c('form',[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"actualPassword"}},[_vm._v(" Senha atual ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.model.senhaAntiga.$model),expression:"$v.model.senhaAntiga.$model"}],staticClass:"form-control",class:{
              'is-invalid': _vm.$v.model.senhaAntiga.$error,
            },attrs:{"autocomplete":"current-password","id":"actualPassword","type":"password"},domProps:{"value":(_vm.$v.model.senhaAntiga.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.model.senhaAntiga, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-text"},[_vm._v(" Caso você tenha esquecido sua senha, você pode pedir outra. "),_c('span',{staticClass:"clickme",on:{"click":_vm.resendPassword}},[_vm._v("clicando aqui")]),_vm._v(". ")])])]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"newPassword"}},[_vm._v(" Nova senha "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.model.senhaNova.$model),expression:"$v.model.senhaNova.$model"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.model.senhaNova.$error,
              },attrs:{"autocomplete":"new-password","id":"newPassword","type":"password","maxLength":"10"},domProps:{"value":(_vm.$v.model.senhaNova.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.model.senhaNova, "$model", $event.target.value)}}}),_c('div',{staticClass:"form-text"},[(
                  !_vm.$v.model.senhaNova.minLength && _vm.$v.model.senhaNova != ''
                )?_c('span',{staticClass:"error",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.INVALID_FIELD_MSG.PASSWORD_MIN_LEN)+" ")]):_vm._e(),(!_vm.$v.model.senhaNova.$model)?_c('span',[_vm._v(" Preencha com sua nova senha. ")]):_vm._e(),(
                  _vm.$v.model.senhaNova.minLength && _vm.$v.model.senhaNova.$model
                )?_c('span',[_c('i',{staticClass:"icon-check ms-1"})]):_vm._e()])])])])]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"input-group"},[_c('label',{attrs:{"for":"confirmPassword"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._v("Confirme a senha")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.model.confirmation.$model),expression:"$v.model.confirmation.$model"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.model.confirmation.$error,
              },attrs:{"autocomplete":"new-password","id":"confirmPassword","type":"password","maxLength":"10"},domProps:{"value":(_vm.$v.model.confirmation.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.model.confirmation, "$model", $event.target.value)}}}),_c('div',{staticClass:"form-text"},[(!_vm.$v.model.confirmation.$model)?_c('span',[_vm._v(" Repita aqui a nova senha. ")]):_vm._e(),(
                  _vm.$v.model.confirmation.$model &&
                  _vm.$v.model.confirmation.$model === _vm.$v.model.senhaNova.$model
                )?_c('span',[_c('i',{staticClass:"icon-check ms-1"})]):_vm._e()])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-lg-auto ms-auto"},[_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.changePassword}},[_vm._v(" Trocar senha ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }