<template>
  <div
    @click.self="rejectOnCloseModal ? resultReject() : null"
    class="modal"
    id="warningModal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100 text-center">
            {{ title }}
          </h5>
          <button
            @click="rejectOnCloseModal ? resultReject() : null"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="
            modal-body
            text-break
            d-flex
            flex-column
            align-items-center
            justify-content-center
          "
          v-html="message"
        >
        </div>
        <div class="modal-footer d-flex align-items-center justify-content-end">
          <button
            @click="resultResolve()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            {{ btnTrue }}
          </button>
          <button
            @click="resultReject()"
            type="button"
            v-if="showBtnFalse"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ btnFalse }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
const store = {
  dialog: {},
};
export default {
  data() {
    return {
      title: "Atenção",
      message: "Ocorreu um erro ao realizar esta operação",
      btnTrue: "Sim",
      btnFalse: "Não",
      showBtnFalse: true,
      rejectOnCloseModal: false,
      resultResolve: undefined,
      resultReject: undefined,
    };
  },
  mounted() {
    store.dialog = this;
  },

  show({ title, message, btnTrue, btnFalse, showBtnFalse, rejectOnCloseModal }) {
    return new Promise((resolve, reject) => {
      store.dialog.title = title;
      store.dialog.message = message;
      store.dialog.btnTrue = btnTrue;
      store.dialog.btnFalse = btnFalse;
      store.dialog.showBtnFalse = showBtnFalse;
      store.dialog.rejectOnCloseModal = rejectOnCloseModal;
      store.dialog.resultResolve = resolve;
      store.dialog.resultReject = reject;
      const modal = new Modal(document.getElementById("warningModal"), {});
      modal.show();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
</style>