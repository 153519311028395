<template>
  <app-container>
    <div slot="main-content" class="main-content notFound-children-scroll">
      <div class="container-about">
        <div class="row">
          <div class="mt-5">
            <h1>404</h1>
            <h2>Página não encontrada</h2>
          </div>
        </div>
        <div class="container-image">
          <img src="@/assets/svg/heliceFundo.svg" alt="Imagem de um parque" />
        </div>
      </div>
    </div>
  </app-container>
</template>

<script>
import AppContainer from "@/components/AppContainer";
export default {
  mounted() {
    this.setHeaderText("");
  },
  components: {
    AppContainer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";

.notFound-page {
  @include fullScreen;
  display: flex;
  padding: 0 !important;
  background-color: $primary--gray;
}

.notFound-content {
  width: 100%;
}

.notFound-children-scroll {
  text-align: center;
  padding: 0 !important;
  height: calc(100vh - 63px - 1.5rem);
}

h1 {
  font-weight: 400;
}

.main-content {
  background-color: $primary--white;
}

.container-about {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  justify-content: space-between;

  .container-image {
    width: 100%;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
