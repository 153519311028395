<template>
  <div class="mt-3" v-async="loading">
    <div class="row mt-5 d-flex align-items-center">
      <switch-gestor-field
        :modelProp="model"
        ref="switchGestorField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <div class="col col-lg-auto ms-auto">
        <button
          v-if="empresa.gestor && CAN_SHOW_BUTTON"
          class="btn btn-primary"
          @click.prevent="() => resetGestorView(CONFIRMATION.GESTOR.UPDATE)"
        >
          Trocar Gestor
        </button>
      </div>
    </div>
    <div class="row" v-if="empresa.gestor">
      <form-row-header class="mt-3 mb-3" :text="empresa.gestor.razaoSocial" />
    </div>
    <div class="row" v-if="!empresa.gestor">
      <search-bar
        :cols="5"
        ref="searchBar"
        @doSearch="doSearch"
        title="Buscar Gestor"
        placeholder="Busque por Nome, CNPJ..."
        :disabled="!model.clienteGestor || !CAN_SHOW_BUTTON"
      />
    </div>
    <div class="row" v-if="!empresa.gestor">
      <form-row-header class="mt-5" text="Gestores encontrados" />
    </div>
    <table-gestores
      v-if="!empresa.gestor"
      :isAssociate="isAssociate"
      :config="gestorListConfig"
      :disabled="!CAN_SHOW_BUTTON"
    />
    <div class="row mt-5" v-if="!empresa.gestor">
      <div class="col col-lg-auto ms-auto">
        <button
          v-if="CAN_SHOW_BUTTON"
          @click="associateGestor"
          :disabled="!GESTOR_IS_SELECTED"
          :class="['btn', isAssociate ? 'btn-secondary' : 'btn-primary']"
        >
          {{ isAssociate ? "Editar Gestor" : "Associar Gestor" }}
        </button>
      </div>
    </div>
    <div v-if="empresa.gestor">
      <form-row-header class="mt-3" text="Contatos:" />
    </div>
    <div class="row" v-else>
      <form-row-header class="mt-3" text="Contatos da Empresa Gestora:" />
    </div>
    <table-contatos-gestor
      @sortBy="sortBy"
      :contatosList="model.contatos"
      :sortedBy="contatosGestorConfig.filter.sort"
    />
    <div class="row mt-5">
      <div class="col col-lg-auto ms-auto">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'BuscarEmpresas' })"
        >
          Cancelar
        </button>
        <button
          @click="save"
          v-if="CAN_SHOW_BUTTON"
          class="btn btn-primary ms-3"
          :disabled="model.contatos.length < 1"
        >
          Salvar
        </button>
      </div>
    </div>
    <div class="row">
      <form-row-header
        class="mt-3"
        text="Histórico de Envio de Demonstrativo"
      />
    </div>
    <table-historico-relacionamento :config="historicoRelacionamentoConfig" />
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import SwitchGestorField from "@/components/forms/fields/empresa/SwitchGestorField.vue";
import TableGestores from "@/components/tables/TableGestores.vue";
import TableHistoricoRelacionamento from "@/components/tables/TableHistoricoRelacionamento.vue";
import { CONFIRMATION, HTTP_RESPONSE } from "@/constants/strings";
import EmpresaModel from "@/models/empresa/EmpresaModel";
import GestorModel from "@/models/empresa/GestorModel";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import GestorService from "@/services/portal/empresa/GestorService";
import { setFieldsData } from "@/utils/functionUtils";
import TableContatosGestor from "../tables/TableContatosGestor.vue";
export default {      
  data() {
    return {
      isAssociate: false,
      loading: undefined,
      gestorList: new Array(),
      model: new GestorModel(),
      empresa: new EmpresaModel(),
      gestorSelected: new Object(),
      historicoRelacionamentoConfig: {
        conteudo: new Array(),
        pagination: undefined,
        filter: {
          page: 0,
          size: 10,
        },
      },
      gestorListConfig: {
        conteudo: new Array(),
        pagination: undefined,
        filter: {
          page: 0,
          size: 10,
        },
      },
      contatosGestorConfig: {
        filter: {
          sort: "contato.email",
        },
      },
    };
  },
  computed: {
    CONFIRMATION: () => CONFIRMATION,
    CAN_SHOW_BUTTON() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
    GESTOR_IS_SELECTED() {
      return !!this.gestorSelected.id;
    },
  },
  destroyed() {
    this.setHeaderText("");
  },
  mounted() {
    this.loading = this.mountEmpresa();
  },
  methods: {
    sortBy(emitted) {
      this.contatosGestorConfig.filter.sort = emitted;
      this.loading = this.mountEmpresa();
    },
    mountEmpresa() {
      return new Promise((resolve, reject) => {
        const { empresaId } = this.$parent;
        EmpresaService.getById({ empresaId })
          .then(async ({ data }) => {
            this.empresa = new EmpresaModel(data);

            if (this.empresa.gestor) {
              const { data: contatos } =
                await GestorService.getContatosByGestor({
                  empresaId: this.empresa.id,
                  filter: this.contatosGestorConfig.filter,
                });

              this.model = new GestorModel({
                ...this.empresa.gestor,
                clienteGestor: true,
                contatos,
              });

              setFieldsData(this, this.model);
            }

            this.getHistoricoByGestor();
            this.setHeaderText(this.empresa.razaoSocial);
            this.setBreadcrumbsParam(this.empresa.tipo.nome);
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error, "empresa");
            reject();
          });
      });
    },
    resetGestorView(message, sendRequest = false) {
      this.mxToggleWarningModal(
        message,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
        .then(() => {
          this.empresa.gestor = null;

          if (sendRequest) {
            this.isAssociate = false;
            this.gestorSelected = new Object();
            this.gestorList = new Array();
            this.model = new GestorModel({ clienteGestor: false });
            this.save(false);
          } else {
            this.isAssociate = false;
            this.doSearch("", true);
            this.model = new GestorModel({ clienteGestor: true });
          }
        })
        .catch(() => (this.model.clienteGestor = true));
    },
    associateGestor() {
      if (!this.isAssociate) {
        this.loading = GestorService.associateGestor({
          empresaId: this.empresa.id,
          gestorId: this.gestorSelected.getData().id,
        })
          .then(() => {
            GestorService.getContatosByGestor({
              empresaId: this.empresa.id,
              filter: this.contatosGestorConfig.filter,
            })
              .then(({ data: contatos }) => {
                this.model = new GestorModel({
                  ...this.gestorSelected.getData(),
                  clienteGestor: true,
                  contatos,
                });

                this.isAssociate = true;
                this.getHistoricoByGestor();
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      } else {
        this.isAssociate = false;
      }
    },
    getHistoricoByGestor() {
      const { id } = this.empresa;
      const { page, size } = this.historicoRelacionamentoConfig.filter;
      this.loading = GestorService.getHistoricoByGestor({ id, page, size })
        .then(({ data }) => {
          this.historicoRelacionamentoConfig.conteudo = data.content;
          this.historicoRelacionamentoConfig.pagination = data;
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "gestor");
        });
    },
    doSearch(search = this.$refs.searchBar.content, resetPage = false) {
      const { size, page } = this.gestorListConfig.filter;
      this.loading = GestorService.list({
        filtro: search,
        size,
        page: resetPage ? 0 : page,
      })
        .then((response) => {
          this.gestorListConfig.conteudo = response.data.content;
          this.gestorListConfig.pagination = response.data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    updateAssociation(contatos = new Array()) {
      const { id } = this.empresa;
      this.loading = GestorService.update(contatos, id)
        .then(() => {
          this.mxToggleToast(HTTP_RESPONSE.GESTOR.SUCCESS.UPDATE);
          this.loading = this.mountEmpresa();
        })
        .catch((error) => this.mxHandleRequestError(error, "gestor"));
    },
    save(warningModal = true) {
      if (this.model.contatos.length) {
        const contatos = this.model.contatos.map((gestorContato) =>
          gestorContato.getData()
        );
        this.updateAssociation(contatos);
      } else {
        if (warningModal) {
          this.mxToggleWarningModal(
            CONFIRMATION.GESTOR.SAVE_WO_ASSOCIATION,
            undefined,
            undefined,
            undefined,
            undefined,
            true
          ).then(() => this.updateAssociation());
        } else {
          this.updateAssociation();
        }
      }
    },
  },
  components: {
    SearchBar,
    FormRowHeader,
    TableGestores,
    SwitchGestorField,
    TableContatosGestor,
    TableHistoricoRelacionamento,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
</style>
