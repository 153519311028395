export default class EmpresasLoteEmpresaModel {
  constructor(
    {
      id,
      empresaId,
      nome,
      cnpj,
      responsavel,
      dataCadastroFila,
      processando,
      finalizado,
      finalizadoComErro,
      cadastroRealizado,
      integradoWbc,
      dataFinalizacao,
      detalheProcessamento,
    } = new Object()
  ) {
    this._id = id;
    this._empresaId = empresaId;
    this._nome = nome;
    this._cnpj = cnpj;
    this._responsavel = responsavel;
    this._dataCadastroFila = dataCadastroFila;
    this._processando = processando;
    this._finalizado = finalizado;
    this._finalizadoComErro = finalizadoComErro;
    this._cadastroRealizado = cadastroRealizado;
    this._integradoWbc = integradoWbc;
    this._dataFinalizacao = dataFinalizacao;
    this._detalheProcessamento = detalheProcessamento;
  }

  get id() {
    return this._id;
  }

  set id(id = null) {
    this._id = id;
  }

  get empresaId() {
    return this._empresaId;
  }

  set empresaId(empresaId = null) {
    this._empresaId = empresaId;
  }

  get nome() {
    return this._nome;
  }

  set nome(nome = null) {
    this._nome = nome;
  }

  get cnpj() {
    return this._cnpj;
  }

  set cnpj(cnpj = null) {
    this._cnpj = cnpj;
  }

  get responsavel() {
    return this._responsavel;
  }

  set responsavel(responsavel = null) {
    this._responsavel = responsavel;
  }

  get dataCadastroFila() {
    return this._dataCadastroFila;
  }

  set dataCadastroFila(dataCadastroFila = null) {
    this._dataCadastroFila = dataCadastroFila;
  }

  get processando() {
    return this._processando;
  }

  set processando(processando = null) {
    this._processando = processando;
  }

  get finalizado() {
    return this._finalizado;
  }

  set finalizado(finalizado = null) {
    this._finalizado = finalizado;
  }

  get finalizadoComErro() {
    return this._finalizadoComErro;
  }

  set finalizadoComErro(finalizadoComErro = null) {
    this._finalizadoComErro = finalizadoComErro;
  }

  get cadastroRealizado() {
    return this._cadastroRealizado;
  }

  set cadastroRealizado(cadastroRealizado = null) {
    this._cadastroRealizado = cadastroRealizado;
  }

  get integradoWbc() {
    return this._integradoWbc;
  }

  set integradoWbc(integradoWbc = null) {
    this._integradoWbc = integradoWbc;
  }

  get dataFinalizacao() {
    return this._dataFinalizacao;
  }

  set dataFinalizacao(dataFinalizacao = null) {
    this._dataFinalizacao = dataFinalizacao;
  }

  get detalheProcessamento() {
    return this._detalheProcessamento;
  }

  set detalheProcessamento(detalheProcessamento = null) {
    this._detalheProcessamento = detalheProcessamento;
  }
}
