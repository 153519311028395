import { render, staticRenderFns } from "./RazaoSocialField.vue?vue&type=template&id=25894ee2&scoped=true&"
import script from "./RazaoSocialField.vue?vue&type=script&lang=js&"
export * from "./RazaoSocialField.vue?vue&type=script&lang=js&"
import style0 from "./RazaoSocialField.vue?vue&type=style&index=0&id=25894ee2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25894ee2",
  null
  
)

export default component.exports