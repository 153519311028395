<template>
  <div class="col col-lg-12">
    <span>{{ parametro.title }}</span>
    <label>
      <div class="input-group">
        <input
          class="form-control"
          :maxlength="inputMaxLength"
          v-model.trim="model.nome"
        />
        <div class="input-group-append">
          <button
            @click="createParametro"
            :disabled="!model.nome"
            class="btn btn-primary rounded-0 rounded-end"
            type="button"
          >
            Adicionar
          </button>
        </div>
      </div>
      <div class="form-text">
        Preencha com o novo valor a ser adicionado para esta opção.
      </div>
    </label>
    <transition-group name="list" tag="ul" mode="in-out" class="list-params">
      <li
        v-for="(parametro, indexParametro) in list"
        :key="`indexParametro${indexParametro}`"
      >
        <div
          v-if="(parametro.nome != 'FAKE' && aba == 'CCEE') || aba != 'CCEE'"
        >
          <span
            class="pe-3 small text-break"
            :class="{ 'text-secondary': !parametro.ativo }"
          >
            {{ parametro.nome }}
          </span>

          <div style="min-width: 200px" v-if="config.selectable">
            <label>
              <input
                type="checkbox"
                v-model="parametro.gestor"
                @click="(e) => config.executable(parametro, e)"
              />
              <span>
                {{ config.text }}
              </span>
            </label>
          </div>
          <div
            style="width: 8%"
            class="d-flex align-items-center justify-content-between"
          >
            <i
              v-if="parametro.ativo"
              @click="switchParameterActuation(parametro)"
              style="font-size: 0.8em"
              class="icon-eye cursop"
            ></i>
            <i
              v-else
              @click="switchParameterActuation(parametro)"
              class="icon-eye-closed cursop"
            ></i>
            <!--
              <i
                @click="updateParameter(parametro)"
                class="icon-lapis cursop"
              ></i>
            -->
            <i
              @click="removeParametro(parametro)"
              class="icon-lixeira cursop"
            ></i>
          </div>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
import ParametroTemplate from "./ParametroTemplate.vue";
export default {
  extends: ParametroTemplate,
  data() {
    return {
      model: {
        id: null,
        nome: "",
        ativo: true,
      },
      list: new Array(),
    };
  },
  props: {
    aba: String,
    parametro: {
      type: Object,
      required: false,
      default() {
        return new Object();
      },
    },
    config: {
      type: Object,
      required: false,
      default() {
        return new Object();
      },
    },
  },
  mounted() {
    this.listParametro();
  },
  watch: {
    list: {
      deep: true,
      handler() {
        this.mxResetErrorPendencies();
      },
    },
  },
  computed: {
    inputMaxLength() {
      if (
        this.parametro &&
        this.parametro.title &&
        ["Estado Civil"].includes(this.parametro.title)
      ) {
        return 50;
      }
      return 100;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}

.list-params {
  padding: 0px;
  margin-top: 16px;
  li {
    display: flex;
    justify-content: space-between;
    div {
      &:first-child {
        padding: 8px 0px 8px 0px;
        border-bottom: 1px solid #e8e8e8;
        width: 100%;
        display: flex;
      }
      span {
        &:first-child {
          width: 100%;
        }
      }
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
