<template>
  <div class="wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th
            v-for="(titleColuna, titleColunaColunaIndex) in config.colunas"
            :key="`titleColunaColunaIndex${titleColunaColunaIndex}`"
          >
            {{ titleColuna.description }}
          </th>
        </tr>
      </thead>
      <tbody v-if="config.conteudo.length > 0">
        <tr
          @click="click(linha)"
          v-for="(linha, linhaIndex) in config.conteudo"
          :key="`linhaIndex${linhaIndex}`"
        >
          <td
            v-for="(coluna, indexColuna) in config.colunas"
            :key="`indexColuna${indexColuna}`"
          >
            <i 
            v-if="getData(linha, coluna.name) == 'Desbloquear' || 
                  getData(linha, coluna.name) == 'Bloquear'" 
                  style="margin-right:5px" 
                  class="icon-padlock icon">
            </i> 
            <i  style="margin-right:5px"  v-if="getData(linha, coluna.name) == 'Criar'" class="icon-lapis icon"></i>
            <i  style="margin-right:5px"  v-if="getData(linha, coluna.name) == 'Reenviar'" class="icon-engrenagem icon" ></i>
            <span>{{ getData(linha, coluna.name) | empty }}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" :colspan="config.colunas.length">
            Nenhum registro encontrado
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {};
  },
  methods: {
    click(value) {
      this.$emit("click", value);
    },
    getData(linha, coluna) {
      return linha[coluna];
    },
  },
  filters: {
  empty: function (value) {
    if (!value) return '-'
    return value
  }
}
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon {
  color: $primary--orange !important;
}

.table {
//   font-size: 12.8px;
//   line-height: 19px;
//   letter-spacing: 0.01em;
//   color: #505050;
  cursor: pointer;
//   thead {
//     border-bottom: 1px solid #222222 !important;
//   }
//   tbody {
//     tr {
//       border-bottom: 1px solid #e8e8e8;
//     }
//   }
}
</style>
