import { render, staticRenderFns } from "./Contatos.vue?vue&type=template&id=7f265a43&scoped=true&"
import script from "./Contatos.vue?vue&type=script&lang=js&"
export * from "./Contatos.vue?vue&type=script&lang=js&"
import style0 from "./Contatos.vue?vue&type=style&index=0&id=7f265a43&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f265a43",
  null
  
)

export default component.exports