<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="nomeField">
        Nome <span class="required-markup">*</span>
        <input
          :disabled="disabled"
          maxlength="255"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
          }"
          id="nomeField"
          v-model.trim="$v.model.content.$model"
        />
        <div class="form-text">Preencha com o nome do grupo.</div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import { required } from "vuelidate/lib/validators";
export default {
  extends: FieldTemplate,
  props: {
    modelProp: Object,
    disabled: Boolean,
  },
  data() {
    return {
      model: {
        name: "nome",
        content: "",
      },
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Nome do grupo inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
