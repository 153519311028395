import { render, staticRenderFns } from "./GrupoDeAcessoField.vue?vue&type=template&id=dbe47a10&scoped=true&"
import script from "./GrupoDeAcessoField.vue?vue&type=script&lang=js&"
export * from "./GrupoDeAcessoField.vue?vue&type=script&lang=js&"
import style0 from "./GrupoDeAcessoField.vue?vue&type=style&index=0&id=dbe47a10&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbe47a10",
  null
  
)

export default component.exports