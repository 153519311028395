<script>
export default {
  watch: {
    modelProp: {
      deep: true,
      handler() {
        if (typeof this.modelProp[this.model.name] === "string") {
          this.model.content = this.modelProp[this.model.name].toUpperCase();
        } else {
          this.model.content = this.modelProp[this.model.name];
        }
      },
    },
    "model.content": {
      deep: true,
      handler() {
        if (typeof this.model.content === "string") {
          this.modelProp[this.model.name] = this.model.content.toUpperCase();
        } else {
          this.modelProp[this.model.name] = this.model.content;
        }
      },
    },
  },
};
</script>
