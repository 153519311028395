<template>
  <div v-element_loading="loading" class="container-fluid">
    <div class="ga-header">
      <div>
        <page-tabs :tabs="tabs" />
      </div>
    </div>
    <div class="row">
      <search-bar
        :cols="5"
        ref="searchBar"
        @doSearch="doSearch"
        title="Buscar Empresa"
      />
    </div>
    <status-table
      class="mt-5"
      @setPage="setPage"
      @click="goToEmpresa"
      :config="tableConfig"
      :activeTab="activeTab"
      @changeStatus="changeStatus"
    />
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";
import PageTabs from "@/components/navigation/PageTabs.vue";
import StatusTable from "./components/StatusCadastrosTable.vue";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
export default {
  data() {
    return {
      loading: undefined,
      activeTab: "PRE_CADASTRO",
      tableConfig: {
        colunas: [
          { name: "razaoSocial", description: "Razão Social" },
          { name: "cnpj", description: "CNPJ" },
          { name: "dataStatus", description: "Tempo neste Status" },
          { name: "status", description: "Status" },
        ],
        conteudo: [],
        pagination: undefined,
      },
      filter: {
        content: "",
        page: 0,
        status: "PRE_CADASTRO",
      },
      tabs: new Array(
        {
          title: "Pré-cadastro",
          key: "PRE_CADASTRO",
          active: true,
        },
        {
          title: "Informações pendentes",
          key: "INFORMACOES_PENDENTES",
          active: true,
        },
        {
          title: "Pendente de Aprovação",
          key: "PENDENTE_APROVACAO",
          active: true,
        },
        {
          title: "Aprovado",
          key: "APROVADOS",
          active: true,
        },
        {
          title: "Expirado",
          key: "EXPIRADO",
          active: true,
        },
        {
          title: "Falha de Integração",
          key: "FALHA_INTEGRACAO_WBC",
          active: true,
        }
      ),
    };
  },
  mounted() {
    const { status } = this.filter;
    this.list(status);
  },
  methods: {
    changeTab(nextTab) {
      this.activeTab = nextTab;
      this.filter.status = (nextTab !== "APROVADOS" && nextTab) || [
        "APROVADO_COMPLETO",
        "APROVADO_INCOMPLETO",
      ];
      const { status } = this.filter;
      this.list(status);
    },
    doSearch(search) {
      this.filter.content = search;
      const { status, content } = this.filter;
      this.list(status, 0, content);
    },
    goToEmpresa({ id }) {
      this.$router.push({
        name: "CadastroEmpresas",
        params: { id },
      });
    },
    list(status, page, filtro = this.$refs.searchBar.content) {
      const payload = {
        page,
        size: 10,
        orderBy: "dataStatus",
        desc: false,
        status,
        filtro,
      };
      this.loading = EmpresaService.list(payload)
        .then(({ data }) => {
          this.tableConfig.conteudo = data.content;
          this.tableConfig.pagination = data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    setPage(nextPage) {
      this.filter.page = nextPage;
      const { status, page } = this.filter;
      this.list(status, page);
    },
    changeStatus(status) {
      const params = {
        aprovado: ["APROVADO_COMPLETO", "APROVADO_INCOMPLETO"],
        completo: "APROVADO_COMPLETO",
        incompleto: "APROVADO_INCOMPLETO",
      };
      this.filter.status = params[status];
      return this.list(params[status]);
    },
  },
  components: {
    PageTabs,
    SearchBar,
    StatusTable,
  },
};
</script>
