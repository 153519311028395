import PermissoesENUM from "@/enums/PermissoesENUM";
import { AbilityBuilder, Ability } from "@casl/ability";

export const defineAbilityFor = (userPermissions) => {
  const { can, build, rules } = new AbilityBuilder(Ability);
  if (userPermissions) {
    userPermissions.map((USUARIO_PERMISSAO, index) => {
      for (const ACTION in PermissoesENUM) {
        if (PermissoesENUM[ACTION] === USUARIO_PERMISSAO) {
          can(USUARIO_PERMISSAO);
        }
      }
    });

    return rules;
  }
  return build();
};

export const userHasPermissions = (userPermissions, permissionsToCheck) => {
  if (!userPermissions) return false;

  return permissionsToCheck.some((permission) =>
    userPermissions.some((userPermission) => userPermission === permission)
  );
};
