import Usuario from "@/pages/acessos/usuario/Usuario.vue";
import AcessoPortal from "@/pages/acessos/acesso-portal/AcessoPortal.vue";
import GruposAcesso from "@/pages/grupos-acesso/GruposAcesso.vue";
import BuscarUsuario from "@/pages/acessos/usuario/BuscarUsuario.vue";
import BuscaGruposAcesso from "@/pages/grupos-acesso/GruposAcessoBusca.vue";

const routes = [
  {
    path: "/buscar-grupos-de-acesso",
    name: "BuscaGrupoAcesso",
    component: BuscaGruposAcesso,
    meta: {
      auth: true,
      breadcrumb: new Array("Acessos"),
      permissions: new Array(
        "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR",
        "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR"
      ),
    },
  },
  {
    path: "/grupos-de-acesso/:id",
    name: "GruposAcesso",
    component: GruposAcesso,
    meta: {
      auth: true,
      breadcrumb: new Array("Acessos", "Grupos Acesso"),
      permissions: new Array(
        "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR",
        "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR"
      ),
    },
  },
  {
    path: "/adicionar-novo-grupo-de-acesso",
    name: "AdicionarGrupoAcesso",
    component: GruposAcesso,
    meta: {
      auth: true,
      breadcrumb: new Array("Acessos", "Grupos Acesso"),
      permissions: new Array(
        "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR",
        "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR"
      ),
    },
  },
  {
    path: "/usuarios/:id",
    name: "Usuario",
    component: Usuario,
    meta: {
      auth: true,
      breadcrumb: new Array("Acessos"),
      permissions: new Array(
        "CENTRAL_CADASTROS_USUARIO_VISUALIZAR",
        "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR"
      ),
    },
  },
  {
    path: "/usuarios/cadastrar",
    name: "UsuarioCadastrar",
    component: Usuario,
    meta: {
      auth: true,
      breadcrumb: new Array("Acessos"),
      permissions: new Array("CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR"),
    },
  },
  {
    path: "/buscar-usuarios",
    name: "BuscarUsuario",
    component: BuscarUsuario,
    meta: {
      auth: true,
      breadcrumb: new Array("Acessos"),
      permissions: new Array(
        "CENTRAL_CADASTROS_USUARIO_VISUALIZAR",
        "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR"
      ),
    },
  },
];

export default routes;
