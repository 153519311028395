<template>
  <div class="mt-4 wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr v-if="isGestor">
          <th @click="$emit('sortBy', 'nome')" class="cursop">Nome</th>
          <th @click="$emit('sortBy', 'email')" class="cursop" scope="col">
            E-mail
          </th>
          <th scope="col">Departamento</th>
          <th class="text-center" scope="col">Nota Fiscal</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-else>
          <th class="text-center" scope="col"></th>
          <th @click="$emit('sortBy', 'nome')" class="cursop" scope="col">
            Nome
          </th>
          <th @click="$emit('sortBy', 'email')" class="cursop" scope="col">
            E-mail
          </th>
          <th scope="col">Departamento</th>
          <th class="text-center" scope="col">Rep. Legal</th>
          <th class="text-center" scope="col">Comprador</th>
          <th class="text-center" scope="col">Nota Fiscal</th>
          <th class="text-center" scope="col">Aprova Dem.</th>
          <th class="text-center" scope="col">Notificado Dem.</th>
          <th class="text-center" scope="col"></th>
          <th class="text-center" scope="col"></th>
        </tr>
      </thead>
      <tbody v-if="!contatosList || contatosList.length === 0">
        <tr>
          <td class="text-center" colspan="8">Nenhum contato adicionado</td>
        </tr>
      </tbody>
      <transition-group
        tag="tbody"
        name="list"
        mode="in-out"
        v-else-if="isGestor"
      >
        <tr
          v-for="(contato, contatoIndex) in contatosList"
          :key="`contato_${contatoIndex}`"
          :class="{
            'recent-updated-row': recentUpdatedField(contato.id),
          }"
        >
          <td>{{ contato.nome }}</td>
          <td>{{ contato.email }}</td>
          <td>{{ contato.departamento ? contato.departamento.nome : "-" }}</td>
          <td class="text-center">
            <i
              v-if="contato.permissoes && contato.permissoes.recebeNotaFiscal"
              class="icon-check icon small"
            />
          </td>
          <td class="text-end">
            <i
              class="icon-lapis icon cursop"
              @click="$emit('editContato', contato)"
            />
          </td>
          <td>
            <i
              class="icon-lixeira icon ms-3 cursop"
              @click="$emit('deleteContato', contato)"
            />
          </td>
        </tr>
      </transition-group>
      <transition-group name="list" mode="in-out" tag="tbody" v-else>
        <tr
          v-for="(contato, contatoIndex) in contatosList"
          :key="`contato_${contatoIndex}`"
          :class="{
            'recent-updated-row': recentUpdatedField(contato.id),
          }"
        >
          <td>
            <div class="pt-1 d-flex align-items-center justify-content-center">
              <tooltip
                v-if="recentUpdatedField(contato.id)"
                :width="450"
                boxAlign="right"
                arrowAlign="right"
                icon="icon-warning"
              >
                <template #tooltip-text>
                  Possui informações alteradas recentemente, pendente de
                  aprovação
                </template>
              </tooltip>
            </div>
          </td>
          <td>{{ contato.nome }}</td>
          <td>{{ contato.email }}</td>
          <td>{{ contato.departamento ? contato.departamento.nome : "-" }}</td>
          <td class="text-center">
            <i
              v-if="contato.representanteLegal"
              class="icon-check icon small"
            />
          </td>
          <td class="text-center">
            <i v-if="contato.comprador" class="icon-check icon small" />
          </td>
          <td class="text-center">
            <i
              v-if="contato.permissoes && contato.permissoes.recebeNotaFiscal"
              class="icon-check icon small"
            />
          </td>
          <td class="text-center">
            <i
              v-if="
                contato.permissoes.demonstrativo &&
                contato.permissoes.demonstrativo.id &&
                contato.permissoes.demonstrativo.id === 'CONTATO_DF_APROVA'
              "
              class="icon-check icon small"
            />
          </td>
          <td class="text-center">
            <i
              v-if="
                contato.permissoes.demonstrativo &&
                contato.permissoes.demonstrativo.id &&
                contato.permissoes.demonstrativo.id === 'CONTATO_DF_NOTIFICADO'
              "
              class="icon-check icon small"
            />
          </td>
          <td>
            <i
              v-if="!disabled"
              class="icon-lapis icon cursop"
              @click="$emit('editContato', contato)"
            />
          </td>
          <td>
            <i
              v-if="!disabled"
              class="icon-lixeira icon ms-3 cursop"
              @click="$emit('deleteContato', contato)"
            />
          </td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";
export default {
  props: {
    disabled: Boolean,
    isGestor: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    contatosList: {
      type: Array,
      required: false,
      default() {
        return new Array();
      },
    },
    recentUpdatedFields: {
      type: Array,
      required: false,
      default() {
        return new Array();
      },
    },
  },
  methods: {
    recentUpdatedField(contatoId) {
      return this.recentUpdatedFields.find(
        (contatoAlterado) => contatoId === contatoAlterado.id
      );
    },
  },
  components: {
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon {
  color: $primary--orange !important;
}

th {
  font-weight: 500;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
