<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="enderecoComplemento">
        Complemento
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="100"
          :disabled="disabled"
          class="form-control"
          id="enderecoComplemento"
          v-model.trim="model.content"
          :class="{
            'is-invalid': $v.model.content.$error,
            'is-invalid-yellow': invalidYellow && !disabled,
            'recent-updated': recentUpdatedField,
          }"
          @mousemove="useDynamicTooltip($event, true)"
          @mouseleave="useDynamicTooltip($event, false)"
        />
      </label>
    </div>
    <dynamic-tooltip
      :on="bubbleHint.on"
      msg="Limite de 50 caracteres excedido"
      :modalX="bubbleHint.x"
      :modalY="bubbleHint.y"
    />
  </div>
</template>

<script>
import { maxLength } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
import DynamicTooltip from "@/components/DynamicTooltip.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "complemento",
        content: "",
      },
      bubbleHint: {
        on: false,
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    useDynamicTooltip(e, entering) {
      if (
        (this.model.content && this.model.content.length == 0) === "" ||
        !this.$v.model.content.$error
      )
        return;
      if (
        !entering ||
        !(e.offsetX > e.target.clientWidth - e.target.clientHeight)
      ) {
        this.bubbleHint.on = false;
        return;
      }
      this.bubbleHint.x = e.clientX;
      this.bubbleHint.y = e.clientY;
      this.bubbleHint.on = true;
    },
    checkSpecialCharacters() {
      this.$v.model.content.$model = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
    },
    isInvalid() {
      const { $touch, $invalid } = this.$v;
      $touch();
      if ($invalid)
        this.mxToggleErrorMessageAlert(
          this.model?.content?.length > 50
            ? "Complemento deve possuir até 50 caracteres"
            : "Campo complemento inválido"
        );
      return $invalid;
    },
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        maxLength: maxLength(50),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
    DynamicTooltip,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
