<template>
  <div class="mt-3" v-async="loading">
    <div class="row d-flex" :class="empresaValidStyle">
      <tipo-contato-card
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        v-if="!isEmpresaGestor"
        ref="tipoContatoCard"
      />
      <recebe-nota-fiscal-card
        v-if="isValidEmpresaForNF"
        ref="recebeNotaFiscalCard"
        :style="
          isEmpresaGestor
            ? 'margin-left: 0'
            : isValidEmpresaForNF && !isValidEmpresaForDemonstrativo
            ? 'margin-left: 4%'
            : null
        "
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <demonstrativo-faturamento-card
        ref="demonstrativoCard"
        v-if="model && isValidEmpresaForDemonstrativo"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-3">
      <form-row-header text="Dados do Contato" />
    </div>
    <div class="row mt-5">
      <nome-field
        ref="nomeField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('nome')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <email-field
        ref="emailField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('email')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-5">
      <telefone-fixo-field
        :modelProp="model"
        ref="telefoneFixoField"
        :recentUpdatedField="recentUpdatedField('telefoneFixo')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <telefone-celular-field
        :modelProp="model"
        ref="telefoneCelularField"
        :recentUpdatedField="recentUpdatedField('telefoneCelular')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <departamento-field
        ref="cargoField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('departamento')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <cargo-field
        ref="cargoField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('cargo')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-5">
      <cpf-field
        ref="cpfField"
        v-if="this.model"
        :modelProp="model"
        :isRequired="!!this.model.representanteLegal"
        :recentUpdatedField="recentUpdatedField('cpf')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <estado-civil-field
        v-if="this.model"
        :modelProp="model"
        ref="estadoCivilField"
        :isRequired="!!this.model.representanteLegal"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('estadoCivil')"
      />
      <tipo-documento-field
        :modelProp="model"
        ref="tipoDocumentoField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('documento')"
      />
      <numero-documento-field
        :modelProp="model"
        ref="numeroDocumentoField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('numeroDocumento')"
      />
    </div>
    <div class="row mt-5">
      <div class="col col-lg-auto ms-auto">
        <button v-if="editing" @click="resetFields" class="btn btn-secondary">
          Cancelar
        </button>
        <button
          class="btn btn-primary ms-3"
          @click="addContato"
          v-if="CAN_SHOW_BUTTON"
        >
          {{ editing ? "Atualizar" : "Adicionar Contato" }}
        </button>
      </div>
    </div>
    <div class="row mt-5">
      <form-row-header text="Contatos Adicionados" />
      <table-contatos-adicionados
        @sortBy="sortBy"
        @editContato="edit"
        :contatosList="list"
        @deleteContato="remove"
        :isGestor="isEmpresaGestor"
        :recentUpdatedFields="recentUpdatedFields"
        :recentCreatedContatos="recentCreatedContatos"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
  </div>
</template>

<script>
import { formIsValid } from "@/utils/validators";
import CRUDTemplate from "@/components/CRUDTemplate";
import { ERROR, PENDENCIES } from "@/constants/strings";
import ContatoModel from "@/models/empresa/ContatoModel";
import EmpresaModel from "@/models/empresa/EmpresaModel";
import { checkDFAprovaPendencie } from "@/utils/functionUtils";
import TipoContatoCard from "../fields/contatos/TipoContatoCard";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import { CONFIRMATION, HTTP_RESPONSE } from "@/constants/strings";
import { cleanUpFields, makeDeepCopy } from "@/utils/functionUtils";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import ContatoService from "@/services/portal/empresa/ContatoService";
import CpfField from "@/components/forms/fields/empresa/CpfField.vue";
import CargoField from "@/components/forms/fields/empresa/CargoField.vue";
import RecebeNotaFiscalCard from "../fields/contatos/RecebeNotaFiscalCard";
import NomeField from "@/components/forms/fields/empresa/NomeUpperCaseField.vue";
import EmailField from "@/components/forms/fields/empresa/EmailUpperCaseField.vue";
import EstadoCivilField from "@/components/forms/fields/empresa/EstadoCivilField.vue";
import TableContatosAdicionados from "@/components/tables/TableContatosAdicionados.vue";
import TelefoneFixoField from "@/components/forms/fields/empresa/TelefoneFixoField.vue";
import DepartamentoField from "@/components/forms/fields/empresa/DepartamentoField.vue";
import TipoDocumentoField from "@/components/forms/fields/empresa/TipoDocumentoField.vue";
import DemonstrativoFaturamentoCard from "../fields/contatos/DemonstrativoFaturamentoCard";
import NumeroDocumentoField from "@/components/forms/fields/empresa/NumeroDocumentoField.vue";
import TelefoneCelularField from "@/components/forms/fields/empresa/TelefoneCelularField.vue";

export default {
  extends: CRUDTemplate,
  data() {
    return {
      recentUpdatedFields: new Array(),
      recentCreatedContatos: new Array(),
      filter: {
        sort: "email",
      },
    };
  },
  computed: {
    CAN_SHOW_BUTTON() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
    isValidEmpresaForDemonstrativo() {
      return (
        this.empresa &&
        this.empresa.tipo &&
        ["CONSUMIDOR"].includes(this.empresa.tipo.id)
      );
    },
    isValidEmpresaForNF() {
      return (
        this.empresa &&
        this.empresa.tipo &&
        ["CONSUMIDOR", "TRADING", "GESTOR", "GERADOR"].includes(
          this.empresa.tipo.id
        )
      );
    },
    isEmpresaGestor() {
      return (
        this.empresa && this.empresa.tipo && this.empresa.tipo.id === "GESTOR"
      );
    },
    empresaValidStyle() {
      if (this.isValidEmpresaForDemonstrativo && this.isValidEmpresaForNF) {
        return "justify-content-between";
      } else if (this.isValidEmpresaForNF) {
        return "justify-content-start";
      } else {
        return "justify-content-between";
      }
    },
  },
  mounted() {
    const { empresaId } = this.$parent;
    this.loading = EmpresaService.getById({ empresaId })
      .then(({ data }) => {
        this.service = ContatoService;
        this.requestEntity = "contato";
        this.model = new ContatoModel();
        this.empresa = new EmpresaModel(data);
        this.setHeaderText(this.empresa.razaoSocial);
        this.setBreadcrumbsParam(this.empresa.tipo.nome);
        this.getByEmpresa();
        this.mountRecentUpdatedFields(empresaId);
      })
      .catch((error) => this.mxHandleRequestError(error, "contato"));
  },
  methods: {
    sortBy(emitted) {
      this.filter.sort = emitted;
      this.getByEmpresa();
    },
    getByEmpresa() {
      const { empresaId } = this.$parent;
      const { sort } = this.filter;
      this.loading = this.service
        .getByEmpresa({
          empresaId,
          sort,
        })
        .then(({ data }) => {
          this.list = data || new Array();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, this.requestEntity);
        });
    },
    edit(contato) {
      this.editing = true;
      this.model = new ContatoModel(contato);
      this.setFieldsData(contato);
    },
    remove({ id }) {
      this.loading = this.service
        .getEmpresasAssociadasByContato({ id })
        .then(({ data }) => {
          if (data.length > 0) {
            let empresasList = data.map((empresa) => `${empresa.razaoSocial}`);
            let empresasListText = "";
            empresasList.forEach((empresaAssociada) => {
              empresasListText += `<li>${empresaAssociada}</li>`;
            });
            this.mxToggleWarningModal(
              `
                <div>
                  Este contato está vinculado com a(s) empresa(s): <br/><br/>
                  <ul> ${empresasListText} </ul>
                  Deseja realmente deletar este contato?
                </div>
              `
            )
              .then(() => {
                const { empresaId } = this.$parent;
                this.loading = this.service
                  .remove({ id }, empresaId)
                  .then(({ data }) => {
                    this.mxToggleToast(
                      HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS
                        .DELETE
                    );
                    this.resetFields();
                    this.getByEmpresa(data);
                  })
                  .catch((error) =>
                    this.mxHandleRequestError(error, this.requestEntity)
                  );
              })
              .catch(() => {});
          } else {
            this.mxToggleWarningModal(
              CONFIRMATION[this.requestEntity.toUpperCase()].DELETE
            )
              .then(() => {
                const { empresaId } = this.$parent;
                this.loading = this.service
                  .remove({ id }, empresaId)
                  .then(({ data }) => {
                    this.mxToggleToast(
                      HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS
                        .DELETE
                    );
                    this.resetFields();
                    this.getByEmpresa(data);
                  })
                  .catch((error) =>
                    this.mxHandleRequestError(error, this.requestEntity)
                  );
              })
              .catch(() => {});
          }
        })
        .catch((error) => this.mxHandleRequestError(error, this.requestEntity));
    },
    mountRecentUpdatedFields(empresaId) {
      this.loading = EmpresaService.getEmpresaContatosRecentUpdates({
        empresaId,
      })
        .then(({ data }) => {
          this.recentUpdatedFields = data;
          this.recentCreatedContatos = this.recentUpdatedFields
            .map((contatoAlterado) => {
              if (contatoAlterado.campos.length == 0) {
                return contatoAlterado.id;
              }
            })
            .filter((contatoAlterado) => contatoAlterado != undefined);
          if (this.recentUpdatedFields.length > 0) {
            let camposAlterados = `Campos alterados: na aba Contatos`;
            this.mxToggleWarningMessageAlert(camposAlterados, true, true);
          } else {
            this.mxClearYellowPendency(true);
          }
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    setFieldsData(contato) {
      Object.keys(this.$refs).forEach((ref) => {
        if (!this.$refs[ref]) return;
        if (ref === "tipoContatoCard") {
          Object.keys(this.$refs[ref].$refs).forEach((refRef) => {
            if (this.$refs[ref].$refs[refRef].name) {
              const refName = this.$refs[ref].$refs[refRef].name;
              this.model[refName] = makeDeepCopy(contato[refName]);
            }
          });
        }
        if (ref === "recebeNotaFiscalCard" || ref === "demonstrativoCard") {
          this.model.permissoes = makeDeepCopy(contato.permissoes);
        }
        if (
          this.$refs[ref].model &&
          this.$refs[ref].model.name &&
          contato[this.$refs[ref].model.name]
        ) {
          this.$refs[ref].model.content = contato[this.$refs[ref].model.name];
          if (this.$refs[ref].$v) {
            this.$refs[ref].$v.$reset();
          }
        }
        if (!this.isValidEmpresaForDemonstrativo) {
          this.model.permissoes.demonstrativo = null;
        }
        if (!this.isValidEmpresaForNF) {
          this.model.permissoes.recebeNotaFiscal = null;
        }
        if (this.isEmpresaGestor) {
          this.model.comprador = null;
          this.model.representanteLegal = null;
        }
      });
    },
    resetFields() {
      this.editing = false;
      this.model.id = null;
      cleanUpFields(this);
      if (this.isValidEmpresaForNF) {
        this.$refs.recebeNotaFiscalCard.cleanUpFields();
        if (!this.isEmpresaGestor) {
          this.$refs.tipoContatoCard.cleanUpFields();
        }
      }
      this.model = new ContatoModel();
      this.mxResetErrorPendencies();
    },
    updatePermissoes() {
      this.model.cleanPermissoes();
      Object.keys(this.$refs.tipoContatoCard.model.content).map((permissao) =>
        this.model.addPermissao(
          this.$refs.tipoContatoCard.model.content[permissao]
        )
      );
      this.model.addPermissao(this.$refs.recebeNotaFiscalCard.model.content);
      this.model.addPermissao(
        this.$refs.demonstrativoFaturamentoCard.model.content
      );
    },
    listHasContatoWithDuplicated(attribute, value, id) {
      if (!value || this.list.length === 0) return false;
      return this.list.some((contato) => {
        if (contato.id === id) return false;
        return contato[attribute] === value;
      });
    },
    addContato() {
      if (formIsValid(this)) {
        const { id, email, cpf } = this.model.getData();
        if (this.listHasContatoWithDuplicated("email", email, id)) {
          this.mxToggleErrorMessageAlert(ERROR.CONTATO_DUPLICATED_EMAIL, true);
        } else if (this.listHasContatoWithDuplicated("cpf", cpf, id)) {
          this.mxToggleErrorMessageAlert(ERROR.CONTATO_DUPLICATED_CPF, true);
        } else {
          this.mxResetWarningPendencies();
          let query = null;
          this.editing ? (query = this.update()) : (query = this.save());
          query
            .then(async () => {
              if (this.empresa.tipo.id !== "GESTOR") {
                const { id: empresaId } = this.empresa;
                const { data: empresa } = await EmpresaService.getById({
                  empresaId,
                });
                if (checkDFAprovaPendencie(empresa)) {
                  this.mxToggleWarningMessageAlert(
                    PENDENCIES.SEND_DEMONSTRATIVOS_FATURAMENTO_OFF,
                    false,
                    true
                  );
                } else {
                  this.mxResetWarningPendencies(true);
                }
              }
              const { empresaId } = this.$parent;
              this.mountRecentUpdatedFields(empresaId);
            })
            .catch((error) => console.log(error));
        }
      }
    },
    recentUpdatedField(field) {
      const updatedFieldsFiltered =
        this.recentUpdatedFields.find(
          (contatoAlterado) => this.model.id === contatoAlterado.id
        ) ?? false;
      if (updatedFieldsFiltered) {
        return (
          updatedFieldsFiltered.campos &&
          updatedFieldsFiltered.campos.includes(field)
        );
      }
      return false;
    },
  },
  components: {
    CpfField,
    NomeField,
    EmailField,
    CargoField,
    FormRowHeader,
    TipoContatoCard,
    EstadoCivilField,
    TelefoneFixoField,
    DepartamentoField,
    TipoDocumentoField,
    RecebeNotaFiscalCard,
    NumeroDocumentoField,
    TelefoneCelularField,
    TableContatosAdicionados,
    DemonstrativoFaturamentoCard,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
