<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="numeroDocumentoField">
        Número do Documento
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          maxlength="30"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          :disabled="disabled"
          id="numeroDocumentoField"
          v-model.trim="$v.model.content.$model"
        />
        <div class="form-text">
          Preencha com o número do doc.
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "numeroDocumento",
        content: "",
      },
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Número do Documento inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {},
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
