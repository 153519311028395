var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 order-2 order-md-1"},[_c('form-row-header',{attrs:{"text":"Dados Pessoais"}})],1),_c('div',{staticClass:"col-md-6 order-1 order-md-2 d-flex d-flex justify-content-end"},[(
          !_vm.isUserLoggedIn &&
          _vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
        )?_c('button',{class:['btn', _vm.model.ativo ? 'btn-bloq' : 'btn-desbloq'],attrs:{"disabled":_vm.model.id == null},on:{"click":_vm.toggleLockUser}},[_vm._v(" "+_vm._s(_vm.model.ativo ? "Bloquear" : "Desbloquear")+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('nome-field',{ref:"nomeField",attrs:{"disabled":(_vm.isCreated && !_vm.isUserLoggedIn) ||
        !_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'),"modelProp":_vm.model}}),_c('email-field',{ref:"emailField",attrs:{"disabled":_vm.isCreated || !_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'),"modelProp":_vm.model}})],1),_c('div',{staticClass:"row mt-3"},[_c('telefone-fixo-field',{ref:"telefoneFixoField",attrs:{"disabled":!this.model.ativo ||
        !_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'),"modelProp":_vm.model}}),_c('telefone-celular-field',{ref:"telefoneCelularField",attrs:{"disabled":!this.model.ativo ||
        !_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'),"modelProp":_vm.model}}),_c('cargo-field',{ref:"cargoField",attrs:{"disabled":!this.model.ativo ||
        !_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'),"colLG":6,"modelProp":_vm.model}})],1),_c('div',{staticClass:"row mt-3"},[_c('grupo-de-acesso-field',{ref:"acessoField",attrs:{"disabled":!_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'),"modelProp":_vm.model}})],1),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12"},[_vm._v("Dashboards")]),_vm._l((_vm.powerBiDashboards),function(dashboard,index){return _c('div',{key:("dashboard_" + index),staticClass:"col-12"},[_c('label',{staticClass:"w-auto mt-2"},[_c('input',{attrs:{"disabled":!_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'),"type":"checkbox"},domProps:{"checked":_vm.model.dashboardsPermitidos.includes(dashboard.id)},on:{"click":function($event){$event.stopPropagation();return _vm.checkDashboard(dashboard, index)}}}),_c('span',{staticClass:"ms-2"},[_vm._v(_vm._s(dashboard.reportName))])])])})],2),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col col-lg-4 ms-auto d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.$router.push({ name: 'BuscarUsuario' })}}},[_vm._v(" Cancelar ")]),(
          _vm.isCreated && _vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
        )?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.update}},[_vm._v(" Atualizar ")]):(_vm.$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR'))?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.save}},[_vm._v(" Salvar ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }