<template>
  <div v-if="isShown" v-resize="checkMedia" id="app">
    <router-view />
    <error-modal />
    <warning-modal />
  </div>
  <div v-else class="container">
    <page-not-screen />
  </div>
</template>

<script>
import fonts from "@/assets/style/fonts";
import ErrorModal from "@/components/modais/ErrorModal.vue";
import WarningModal from "@/components/modais/WarningModal.vue";
import PageNotScreen from "@/pages/PageNotScreen.vue";
import fontIncorporate from "@/utils/fontIncorporate";

export default {
  components: {
    ErrorModal,
    WarningModal,
    PageNotScreen,
  },
  mounted() {
    this.checkMedia();
  },
  data() {
    return {
      isShown: true,
    };
  },
  methods: {
    checkMedia() {
      if (process.env.NODE_ENV === "development") {
        this.isShown = true;
        return;
      }
      const larguraBrowser =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.isShown = larguraBrowser >= 1280;
    },
  },
  beforeCreate() {
    const fontFaces = fontIncorporate(fonts);
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    styleEl.innerHTML = fontFaces;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/mixins.scss";
#app {
  @include fullScreen;
  overflow: hidden;
}
</style>
