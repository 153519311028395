var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"mt-3"},[_c('div',{staticClass:"row d-flex",class:_vm.empresaValidStyle},[(!_vm.isEmpresaGestor)?_c('tipo-contato-card',{ref:"tipoContatoCard",attrs:{"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}):_vm._e(),(_vm.isValidEmpresaForNF)?_c('recebe-nota-fiscal-card',{ref:"recebeNotaFiscalCard",style:(_vm.isEmpresaGestor
          ? 'margin-left: 0'
          : _vm.isValidEmpresaForNF && !_vm.isValidEmpresaForDemonstrativo
          ? 'margin-left: 4%'
          : null),attrs:{"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}):_vm._e(),(_vm.model && _vm.isValidEmpresaForDemonstrativo)?_c('demonstrativo-faturamento-card',{ref:"demonstrativoCard",attrs:{"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}):_vm._e()],1),_c('div',{staticClass:"row mt-3"},[_c('form-row-header',{attrs:{"text":"Dados do Contato"}})],1),_c('div',{staticClass:"row mt-5"},[_c('nome-field',{ref:"nomeField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('nome'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('email-field',{ref:"emailField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('email'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}})],1),_c('div',{staticClass:"row mt-5"},[_c('telefone-fixo-field',{ref:"telefoneFixoField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('telefoneFixo'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('telefone-celular-field',{ref:"telefoneCelularField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('telefoneCelular'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('departamento-field',{ref:"cargoField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('departamento'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('cargo-field',{ref:"cargoField",attrs:{"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('cargo'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}})],1),_c('div',{staticClass:"row mt-5"},[(this.model)?_c('cpf-field',{ref:"cpfField",attrs:{"modelProp":_vm.model,"isRequired":!!this.model.representanteLegal,"recentUpdatedField":_vm.recentUpdatedField('cpf'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}):_vm._e(),(this.model)?_c('estado-civil-field',{ref:"estadoCivilField",attrs:{"modelProp":_vm.model,"isRequired":!!this.model.representanteLegal,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('estadoCivil')}}):_vm._e(),_c('tipo-documento-field',{ref:"tipoDocumentoField",attrs:{"modelProp":_vm.model,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('documento')}}),_c('numero-documento-field',{ref:"numeroDocumentoField",attrs:{"modelProp":_vm.model,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('numeroDocumento')}})],1),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col col-lg-auto ms-auto"},[(_vm.editing)?_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.resetFields}},[_vm._v(" Cancelar ")]):_vm._e(),(_vm.CAN_SHOW_BUTTON)?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.addContato}},[_vm._v(" "+_vm._s(_vm.editing ? "Atualizar" : "Adicionar Contato")+" ")]):_vm._e()])]),_c('div',{staticClass:"row mt-5"},[_c('form-row-header',{attrs:{"text":"Contatos Adicionados"}}),_c('table-contatos-adicionados',{attrs:{"contatosList":_vm.list,"isGestor":_vm.isEmpresaGestor,"recentUpdatedFields":_vm.recentUpdatedFields,"recentCreatedContatos":_vm.recentCreatedContatos,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')},on:{"sortBy":_vm.sortBy,"editContato":_vm.edit,"deleteContato":_vm.remove}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }