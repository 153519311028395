<template>
  <div class="col col-lg-3">
    <label for="siglaPerfilAgenteField">
      Sigla Perfil do Agente <span class="required-markup">*</span>
      <span class="recent-updated-label" v-if="recentUpdatedField">
        <tooltip-informacao-alterada />
      </span>
      <div class="input-group">
        <input
          type="text"
          maxlength="255"
          class="form-control"
          :disabled="disabled || modelProp.isProvisorio"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="siglaPerfilAgenteField"
          v-model.trim="$v.model.content.$model"
        />
      </div>
      <div class="form-text">
        Preencha com a sigla para este tipo de energia.
      </div>
    </label>
  </div>
</template>

<script>
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import { maxLength, requiredIf } from "vuelidate/lib/validators";
export default {
  props: {
    modelProp: Object,
    disabled: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "siglaPerfilAgente",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert(
          "Campo Sigla do Perfil do Agente inválido"
        );
        return true;
      }
      return false;
    },
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
    "modelProp.isProvisorio": {
      deep: true,
      handler() {
        if (this.modelProp.isProvisorio) {
          this.model.content = null;
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (this.modelProp.isProvisorio) {
            return false;
          }
          return true;
        }),
        maxLegth: maxLength(255),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/colors.scss";
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.red--primary {
  color: $red--primary-error;
}
</style>
