<template>
  <div>
    <div class="wrapper-table table-responsive">
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th
              v-for="(titleColuna, titleColunaColunaIndex) in config.colunas"
              :key="`titleColunaColunaIndex${titleColunaColunaIndex}`"
              class="coluna"
            >
              <select
                v-if="titleColuna.name == 'status' && activeTab == 'APROVADOS'"
                class="select-approve"
                v-model="statusSelected"
                @change="changeStatus"
              >
                <option value="aprovado">
                  {{ titleColuna.description }}
                </option>
                <option value="completo">Completo</option>
                <option value="incompleto">Incompleto</option>
              </select>
              <span v-else>
                {{ titleColuna.description }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody v-if="config.conteudo && config.conteudo.length > 0">
          <tr
            v-for="(linha, linhaIndex) in config.conteudo"
            :key="`linhaIndex${linhaIndex}`"
          >
            <td
              @click="click(linha)"
              v-for="(coluna, indexColuna) in config.colunas"
              :key="`indexColuna${indexColuna}`"
              class="coluna"
            >
              <span>{{ getData(linha, coluna.name) }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" :colspan="config.colunas.length">
              Nenhum registro encontrado
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import Pagination from "@/components/pagination/pagination";
export default {
  components: { Pagination },
  props: {
    config: Object,
    activeTab: String,
  },
  data() {
    return {
      statusSelected: "aprovado",
    };
  },
  methods: {
    timeStatus(value) {
      const end = DateTime.now();
      const start = DateTime.fromMillis(value);
      const diffInDays = end.diff(start, ["days", "hours", "minutes"]);
      if (diffInDays.values.days == 1) {
        return `${parseInt(diffInDays.values.days)} dia`;
      }
      return `${parseInt(diffInDays.values.days)} dias`;
    },
    click(value) {
      this.$emit("click", value);
    },
    getData(linha, coluna) {
      let value = linha[coluna];
      if (value === "" || value === null) {
        return "-";
      }
      if (coluna === "dataStatus") {
        return this.timeStatus(value);
      }
      if (coluna === "status") {
        return value.nome;
      }
      return value;
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
    changeStatus() {
      const { statusSelected } = this;
      this.$emit("changeStatus", statusSelected);
    },
  },
  modelProp: {
    deep: true,
    handler() {
      this.model.content = this.modelProp[this.model.nome];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon {
  color: $primary--orange !important;
}

.table {
  cursor: pointer;
}
.coluna {
  width: 25% !important;
}

.select-approve {
  width: 104px;
  height: 24px;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
  border: #ced4da 1px solid;
  border-radius: 5px;
  position: relative;
  background-color: #e8e8e8;
  padding-left: 0.2rem;
}
</style>
