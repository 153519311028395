export default {
  "model?.classeAgente?.id": {
    id: "classeAgente",
    nome: "Classe do Agente (tipo)",
    values: {
        "COMERCIALIZACAO": [
            "PONTOS_DE_MEDICAO"
        ],
        "HOLDING": [
            "PONTOS_DE_MEDICAO"
        ],
        "TRANSMISSAO": [
            "PONTOS_DE_MEDICAO"
        ],
        "IMPORTADOR_EXPORTADOR": [
            "PONTOS_DE_MEDICAO"
        ],
        "CONSUMIDOR_CATIVO": [
            "PONTOS_DE_MEDICAO"
        ],
    }
  },
};
