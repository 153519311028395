 <template>
  <div class="col col-lg-5">
    <div>
      <label for="distribuidoraField">
        Distribuidora
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          :disabled="disabled"
          id="distribuidoraField"
          v-model="model.content"
          class="form-select"
          :class="{
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField,
          }"
        >
          <option
            v-for="(distribuidora, distribuidoraIndex) in list"
            :key="`uf_option_${distribuidoraIndex}`"
            :value="distribuidora.codigo_distribuidora.toString()"
          >
            {{ distribuidora.nome_distribuidora }}
          </option>
        </select>
      </label>
      <div
        v-if="loading"
        style="margin-left: -50px"
        class="spinner-border spinner-border-sm"
      ></div>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    list: Array,
    loading: Boolean,
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "codigoDistribuidoraAneel",
        content: "",
      },
    };
  },
  watch: {
    "modelProp.codigoDistribuidoraAneel": {
      deep: true,
      handler() {
        this.model.content = this.modelProp.codigoDistribuidoraAneel;
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp.codigoDistribuidoraAneel = this.model.content;
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (this.invalidYellow) {
            return true;
          }
          return false;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

.align-spinner {
  display: flex;
  align-items: center;
}
</style>
