<template>
  <div class="cc-param-contatos" v-async="loading">
    <section class="cc-param-contatos__content">
      <div class="row mt-5">
        <div class="col-lg-7">
          <crud-parametros :parametro="ParametrosENUM.ESTADO_CIVIL" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-7">
          <crud-parametros :parametro="ParametrosENUM.TIPO_DOCUMENTO" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-7">
          <crud-parametros
            ref="paramDepartamento"
            :parametro="ParametrosENUM.DEPARTAMENTO"
            :config="{
              selectable: true,
              text: `Gestão de Clientes`,
              executable: changeGestor,
            }"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CrudParametros from "../CRUDParametros";
import { HTTP_RESPONSE } from "@/constants/strings";
import ParametrosENUM from "@/enums/ParametrosENUM";
import ContatoDepartamentoModel from "@/models/empresa/ContatoDepartamentoModel";
export default {
  data() {
    return {
      loading: undefined,
    };
  },
  computed: {
    ParametrosENUM: () => ParametrosENUM,
  },
  components: {
    CrudParametros,
  },
  mounted() {
    this.$refs.paramDepartamento.listParametro = this.listParametro;
    this.$refs.paramDepartamento.createParametro = this.createParametro;
    this.$refs.paramDepartamento.removeParametro = this.removeParametro;
    this.$refs.paramDepartamento.switchParameterActuation =
      this.switchParameterActuation;
    this.$refs.paramDepartamento.model = new ContatoDepartamentoModel({
      ativo: true,
    });
  },
  methods: {
    ...mapActions(["ActionToggleParametrosPageIsLoading"]),
    listParametro() {
      const request = this.ParametrosENUM.DEPARTAMENTO.list()
        .then((response) => (this.$refs.paramDepartamento.list = response.data))
        .catch((error) => this.mxHandleRequestError(error, "PARAMETRO"));
      this.ActionToggleParametrosPageIsLoading(request);
    },
    createParametro() {
      this.mxToggleWarningModal(
        `Deseja criar o parâmetro ${this.$refs.paramDepartamento.model.nome} ?`
      ).then(() => {
        this.loading = this.ParametrosENUM.DEPARTAMENTO.create(
          this.$refs.paramDepartamento.model.getData()
        )
          .then(() => {
            this.$refs.paramDepartamento.model = new ContatoDepartamentoModel({
              ativo: true,
            });
            this.mxToggleToast(HTTP_RESPONSE.PARAMETRO.SUCCESS.CREATE);
            this.listParametro();
          })
          .catch((error) => this.mxHandleRequestError(error, "PARAMETRO"));
      });
    },
    removeParametro(parametro) {
      this.mxToggleWarningModal(
        `Deseja deletar o parâmetro ${parametro.nome} ?`
      ).then(() => {
        this.loading = this.ParametrosENUM.DEPARTAMENTO.remove(parametro)
          .then(() => {
            this.mxToggleToast(HTTP_RESPONSE.PARAMETRO.SUCCESS.DELETE);
            this.listParametro();
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              return this.mxToggleErrorMessageAlert(
                "Não foi possível remover o parâmetro de departamento selecionado, pois há um contato vinculado a ele"
              );
            }
            this.mxHandleRequestError(error, "PARAMETRO");
          });
      });
    },
    switchParameterActuation(parametro) {
      this.mxToggleWarningModal(
        `Deseja ${parametro.ativo ? "inativar" : "ativar"} o parâmetro ${
          parametro.nome
        } ?`
      ).then(() => {
        this.loading = this.ParametrosENUM.DEPARTAMENTO.update({
          ...parametro,
          ativo: !parametro.ativo,
        })
          .then(() => {
            this.mxToggleToast(HTTP_RESPONSE.PARAMETRO.SUCCESS.UPDATE);
            this.listParametro();
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              return this.mxToggleErrorMessageAlert(
                "Não foi possível inativar o parâmetro de departamento selecionado, pois há um contato vinculado a ele"
              );
            }
            this.mxHandleRequestError(error, "PARAMETRO");
          });
      });
    },
    changeGestor(entity) {
      const departamento = new ContatoDepartamentoModel({
        ...entity,
        gestor: !entity.gestor,
      });

      this.loading = new Promise(async (resolve, reject) => {
        this.ParametrosENUM.DEPARTAMENTO.changeGestor(departamento.getData())
          .then(() => {
            this.mxToggleToast(
              `Gestão de Clientes do departamento "${entity.nome}" atualizado com sucesso!`
            );
            resolve();
          })
          .catch((error) => reject(error));
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cc-param-contatos {
  display: flex;
  flex-direction: column;
  .cc-param-contatos__content {
    flex: 1;
  }
  height: calc(100% - 30px);
}
.section-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
</style>
