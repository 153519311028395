<template>
  <div class="col col-lg-2">
    <div class="input-group">
      <label for="enderecoCepField">
        CEP
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <div class="form-group align-spinner is-loading">
          <input
            class="form-control"
            :disabled="disabled"
            v-mask="'99999-999'"
            :class="{
              'is-invalid': $v.model.content.$error && !invalidYellow,
              'is-invalid-yellow': invalidYellow,
              'recent-updated': recentUpdatedField,
            }"
            id="enderecoCepField"
            v-model.trim="$v.model.content.$model"
          />
          <div
            v-if="loading"
            style="margin-left: -30px"
            class="spinner-border spinner-border-sm"
          ></div>
        </div>
        <div class="form-text">Código postal.</div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { isCep } from "@/filters/assertions";
import { requiredIf } from "vuelidate/lib/validators";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: {
    isFieldRequired: {
      type:Boolean,
      default: true,
    },
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      loading: false,
      model: {
        name: "cep",
        content: "",
      },
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal == null) {
          this.modelProp[this.model.name] = null;
          return;
        }
        if (oldVal) {
          if (oldVal.replace(/[^\d]/g, "") != newVal.replace(/[^\d]/g, "")) {
            this.modelProp[this.model.name] = this.model.content;
            if (this.model.content && this.model.content.length === 9) {
              this.searchByCEP();
            }
          }
        } else {
          this.modelProp[this.model.name] = this.model.content;
          if (this.model.content && this.model.content.length === 9) {
            this.searchByCEP();
          }
        }
      },
    },
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  methods: {
    async searchByCEP() {
      try {
        this.loading = true;
        const response = await EmpresaService.getEmpresaEnderecoFromCorreios({
          cep: this.model.content.replace(/[^\d]/g, ""),
        });
        this.$emit("updateEnderecoFields", response.data);
      } catch (error) {
        this.mxHandleRequestError(error, "CEP");
      } finally {
        this.loading = false;
      }
    },
  },
  validations: {
    model: {
      content: {
        isCep,
        required: requiredIf(function () {
          if (this.isFieldRequired) {
            return true;
          }
          return false;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

.align-spinner {
  display: flex;
  align-items: center;
}
</style>
