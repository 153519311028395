var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comp-help"},[_c('div',[_vm._t("tooltip-container")],2),(_vm.text || _vm.icon)?_c('div',{staticClass:"comp-help__icon",class:("" + _vm.icon)},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('span',{staticClass:"subText"},[_vm._v(_vm._s(_vm.subtext))])]):_vm._e(),(_vm.renderMessage)?_c('div',[_c('div',{class:[
        { 'mt-3': _vm.hasTooltipContainerSlot },
        { 'comp-help-arrow-left': _vm.arrowAlign === 'left' },
        { 'comp-help-arrow-right': _vm.arrowAlign === 'right' },
        'comp-help-arrow' ]}),_c('div',{class:[
        { 'mt-3': _vm.hasTooltipContainerSlot },
        { 'comp-help__box-message--left': _vm.boxAlign === 'left' },
        { 'comp-help__box-message--right': _vm.boxAlign === 'right' },
        'comp-help__box-message' ],style:(("width:" + _vm.width + "px;"))},[_c('span',{staticClass:"comp-help__message"},[_vm._t("tooltip-text")],2)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }