import ArquivoModel from "@/models/empresa/ArquivoModel";
import ServicesPortal from "@/services/portal/ServicesPortal";
import store from "@/store/index";
import axios from "axios";

class ArquivoService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint, false);
  }

  create(request, empresaId) {
    const URL = `${process.env.VUE_APP_ROOT_API_PORTAL}empresas/${empresaId}/arquivos`;
    const OPTIONS = {
      transformRequest: [
        (data, header) => {
          let vuexUser = store.state.usuario;
          let localStore = JSON.parse(sessionStorage.getItem("vuex"));
          let token = undefined;
          if (localStore) {
            token = localStore.usuario.token && vuexUser.token;
          }
          if (token) {
            header["Authorization"] = `Bearer ${token}`;
          }
          return data;
        },
      ],
    };
    return axios.post(URL, request, OPTIONS);
  }

  remove(request, empresaId) {
    this._url = `${empresaId}/arquivos/${request.id}`;
    return this.delete(request);
  }

  getByEmpresa(request) {
    this._url = `${request.empresaId}/arquivos`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data.length > 0) {
          response.data = response.data.map(
            (arquivo) =>
              new ArquivoModel({
                ...arquivo,
                arquivo: arquivo.nome,
                tipo: arquivo.tipoAnexo,
                validadeId: arquivo.validadeArquivo,
              })
          );
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  downloadArquivo(request, empresaId) {
    this._url = `${empresaId}/arquivos/${request.id}`;
    return this.getAttachment(request);
  }

  createTipoArquivo(request) {
    this._url = `/arquivos/tipos`;
    return this.post(request);
  }

  removeTipoArquivo(request) {
    this._url = `/arquivos/tipos/${request.id}`;
    return this.delete(request);
  }

  listTipoArquivo(request) {
    this._url = `/arquivos/tipos`;
    return this.get(request);
  }

  updateTipoArquivo(request) {
    this._url = `/arquivos/tipos/${request.id}`;
    return this.put(request);
  }
}

export default new ArquivoService("empresas");
