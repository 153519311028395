export default class RegistroHistoricoGestorModel {
    constructor({ id, gestor, nomeGestor, dataInicio, dataFim, atual } = new Object()) {
        this._id = id;
        this._gestor = gestor;
        this._nomeGestor = nomeGestor;
        this._dataInicio = dataInicio;
        this._dataFim = dataFim;
        this._atual = atual;
    }

    set id(id = null) {
        this._id = id;
    }

    get id() {
        return this._id;
    }

    set gestor(gestor = null) {
        this._gestor = gestor;
    }

    get gestor() {
        return this._gestor;
    }

    set nomeGestor(nomeGestor = null) {
        this._nomeGestor = nomeGestor;
    }

    get nomeGestor() {
        return this._nomeGestor;
    }

    set dataInicio(dataInicio = null) {
        this._dataInicio = dataInicio;
    }

    get dataInicio() {
        return this._dataInicio;
    }

    set dataFim(dataFim = null) {
        this._dataFim = dataFim;
    }

    get dataFim() {
        return this._dataFim;
    }

    set atual(atual = null) {
        this._atual = atual;
    }

    get atual() {
        return !this._dataFim;
    }

}