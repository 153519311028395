<template>
  <div class="col col-lg-4 tipo-contato-container shadow p-4 mb-5 rounded">
    <span class="fw-bold mb-3">Tipo de Contato</span>
    <div class="row">
      <tipo-contato-check
        size="7"
        :disabled="disabled"
        name="representanteLegal"
        fieldTitle="Representante legal"
        ref="checkboxRepresentanteLegal"
        @addRepresentante="addRepresentante"
        @removeRepresentante="removeRepresentante"
        :parentModel="TipoContatoENUM.CONTATO_TIPO_REPRESENTANTE_LEGAL"
      />
      <tipo-contato-check
        size="5"
        name="comprador"
        :disabled="disabled"
        fieldTitle="Comprador"
        ref="checkboxComprador"
        @addComprador="addComprador"
        @removeComprador="removeComprador"
        :parentModel="TipoContatoENUM.CONTATO_TIPO_COMPRADOR"
      />
    </div>
  </div>
</template>

<script>
import TipoContatoCheck from "./TipoContatoCheck";
import TipoContatoENUM from "@/enums/TipoContatoENUM";
export default {
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      model: {},
    };
  },
  components: {
    TipoContatoCheck,
  },
  methods: {
    addRepresentante(representante) {
      if (representante === TipoContatoENUM.CONTATO_TIPO_REPRESENTANTE_LEGAL) {
        this.$parent.model.representanteLegal = representante;
      }
    },
    removeRepresentante(representante) {
      if (representante === TipoContatoENUM.CONTATO_TIPO_REPRESENTANTE_LEGAL) {
        this.$parent.model.representanteLegal = null;
      }
    },
    addComprador(comprador) {
      if (comprador === TipoContatoENUM.CONTATO_TIPO_COMPRADOR) {
        this.$parent.model.comprador = comprador;
      }
    },
    removeComprador(comprador) {
      if (comprador === TipoContatoENUM.CONTATO_TIPO_COMPRADOR) {
        this.$parent.model.comprador = null;
      }
    },
    cleanUpFields() {
      this.$refs.checkboxRepresentanteLegal.selected = false;
      this.$refs.checkboxComprador.selected = false;
    },
  },
  watch: {
    "$parent.model.representanteLegal"() {
      if (
        this.$parent.model.representanteLegal &&
        this.$parent.model.representanteLegal.id ==
          TipoContatoENUM.CONTATO_TIPO_REPRESENTANTE_LEGAL.id
      ) {
        this.$refs.checkboxRepresentanteLegal.selected = true;
      } else {
        this.$refs.checkboxRepresentanteLegal.selected = false;
      }
    },
    "$parent.model.comprador"() {
      if (
        this.$parent.model.comprador &&
        this.$parent.model.comprador.id ==
          TipoContatoENUM.CONTATO_TIPO_COMPRADOR.id
      ) {
        this.$refs.checkboxComprador.selected = true;
      } else {
        this.$refs.checkboxComprador.selected = false;
      }
    },
  },
  computed: {
    TipoContatoENUM: () => TipoContatoENUM,
  },
  validations: {
    model: {
      content: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.tipo-contato-container {
  display: flex;
  flex-direction: column;
}

input,
label {
  cursor: pointer !important;
}
</style>
