import EnderecoModel from "../../../models/empresa/EnderecoModel";
import PontoDeMedicaoModel from "../../../models/empresa/PontoDeMedicaoModel";
import ServicesPortal from "../ServicesPortal";
class PontoMedicaoService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  create(request, empresaId) {
    this._url = `/${empresaId}/pontos`;
    return this.post(request);
  }

  update(request, empresaId) {
    this._url = `/${empresaId}/pontos`;
    return this.put(request);
  }

  getByEmpresa(request) {
    this._url = `/${request.empresaId}/pontos`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        if (response.data.length) {
          response.data = response.data.map((pontoMedicao) => {
            return new PontoDeMedicaoModel({
              ...pontoMedicao,
              endereco: pontoMedicao.endereco
                ? new EnderecoModel(pontoMedicao.endereco)
                : new EnderecoModel(),
              pontoRetaguardaAtivo: !!pontoMedicao.pontoRetaguarda,
            });
          });
        }
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  }

  remove(request, empresaId) {
    this._url = `/${empresaId}/pontos/${request.id}`;
    return this.delete(request);
  }
}

export default new PontoMedicaoService("empresas");
