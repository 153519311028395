import ServicesPortal from "./ServicesPortal";
class Roles extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Get All Usuario Roles
   * @param {Object} request
   * @endpoint /api/usuarios/roles
   */
  getRoles(request) {
    this._url = ``;
    return this.get(request, true);
  }

  getRolesList(request) {
    this._url = `/names`;
    return this.get(request);
  }

  
  getRoleById(request) {
    this._url = `/${request}`;
    return this.get(request);
  }

  /**
   * @summary Create a Usuario Role
   * @param {Object} request
   * @endpoint /api/usuarios/roles
   */
  createRoles(request) {
    this._url = "";
    return this.post(request);
  }

  /**
   * @summary Update a Usuario Role
   * @param {Object} request
   * @endpoint /api/usuarios/Roles
   */
  update(request) {
    this._url = ``;
    return this.put(request);
  }

  /**
   * @summary Update a Usuario Role
   * @param {Object} request
   * @endpoint /api/usuarios/Roles
   */
  updatePermissions(request) {
    this._url = `/permissions`;
    return this.put(request);
  }

  /**
 * @summary Obter todos as permissões do Billing
 * @param {Object} request
 * @endpoint /api/usuarios/roles/billing/permissoes
 */
  getRolesBilling(request) {
    this._url = `/billing/permissoes`;
    return this.get(request);
  }

}

export default new Roles("usuarios/roles");