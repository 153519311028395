var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"mt-3"},[_c('div',{staticClass:"row p-2"},[_c('tipo-empresa-card',{ref:"tipoEmpresaField",attrs:{"maintainValue":_vm.maintainValue,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"modelProp":_vm.model}})],1),_c('div',{staticClass:"row"},[_c('cnpj-field',{ref:"cnpjField",attrs:{"editing":_vm.editing,"modelProp":_vm.model,"recentUpdatedField":_vm.recentUpdatedField('cnpj'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('razao-social-field',{ref:"razaoSocialField",attrs:{"modelProp":_vm.model,"isEmpresaMatriz":_vm.IS_EMPRESA_MATRIZ,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('razaoSocial')}})],1),_c('div',{staticClass:"row mt-4"},[_c('form-row-header',{attrs:{"text":"Dados complementares"}})],1),_c('div',{staticClass:"row mt-4"},[_c('nome-fantasia-field',{ref:"nomeFantasiaField",attrs:{"modelProp":_vm.model,"invalidYellow":_vm.invalidYellow(_vm.model.nomeFantasia),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('nomeFantasia')}}),_c('apelido-field',{ref:"apelidoField",attrs:{"modelProp":_vm.model,"invalidYellow":_vm.invalidYellow(_vm.model.apelido),"recentUpdatedField":_vm.recentUpdatedField('apelido'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}})],1),_c('div',{staticClass:"row mt-4"},[_c('inscricao-estadual-type-field',{ref:"inscricaoEstadualTypeField",attrs:{"modelProp":_vm.model,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"invalidYellow":_vm.invalidYellow(_vm.model.inscricaoEstadualTipo)}}),_c('regime-especial-field',{ref:"registroEspecialField",attrs:{"modelProp":_vm.model,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"recentUpdatedField":_vm.recentUpdatedField('regimeEspecial')}})],1),_c('div',{staticClass:"row mt-4"},[_c('inscricao-estadual-estado-field',{ref:"inscricaoEstadualEstadoField",attrs:{"modelProp":_vm.model,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"invalidYellow":_vm.invalidYellow(_vm.model.inscricaoEstadualEstado),"recentUpdatedField":_vm.recentUpdatedField('inscricaoEstadualEstado')}}),_c('inscricao-estadual-field',{ref:"inscricaoEstadualField",attrs:{"modelProp":_vm.model,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"invalidYellow":_vm.invalidYellow(_vm.model.inscricaoEstadual),"recentUpdatedField":_vm.recentUpdatedField('inscricaoEstadual')}}),_c('CNAE-field',{ref:"cnaeField",attrs:{"modelProp":_vm.model,"invalidYellow":_vm.invalidYellow(_vm.model.cnae),"recentUpdatedField":_vm.recentUpdatedField('cnae'),"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('CNAE-descricao-field',{ref:"descricaoCnaeField",attrs:{"modelProp":_vm.model,"disabled":true}})],1),_c('div',{staticClass:"row mt-5"},[_c('form-row-header',{attrs:{"text":"Endereço"}})],1),_c('div',{staticClass:"row mt-4"},[_c('endereco-fields',{ref:"enderecoFields",attrs:{"modelProp":_vm.model,"recentUpdatedFields":_vm.recentUpdatedFields,"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
        !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
        !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')},on:{"updateEndereco":_vm.updateEndereco,"setCidadesToLoadedState":_vm.setCidadesToLoadedState}})],1),_c('div',{staticClass:"row mt-5 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col col-lg-6 w-50 col-md-8"},[(_vm.CAN_APROVAR_CADASTRO && _vm.CAN_APROVAR_PARAMETRIZAR)?_c('button',{staticClass:"btn btn-primary-blue",on:{"click":_vm.approveEmpresa}},[_c('b',[_vm._v("Aprovar")]),_vm._v(" Cadastro ")]):_vm._e(),(
          _vm.CAN_INTEGRAR_WBC &&
          _vm.IS_PRE_CADASTRO_OR_FALHA_INTEGRACAO_WBC &&
          _vm.CAN_APROVAR_PARAMETRIZAR
        )?_c('button',{staticClass:"btn btn-primary-blue",class:{ 'ms-3': _vm.IS_PENDENTE_APROVACAO },on:{"click":function($event){return _vm.updateBeforeIntegracaoWBC(true)}}},[_c('b',[_vm._v("Validar")]),_vm._v(" cadastro ")]):_vm._e(),(
          _vm.CAN_INTEGRAR_WBC &&
          !_vm.IS_PRE_CADASTRO_OR_FALHA_INTEGRACAO_WBC &&
          _vm.IS_NOT_INTEGRADA_WBC &&
          _vm.CAN_APROVAR_PARAMETRIZAR
        )?_c('button',{staticClass:"btn btn-primary-blue",class:{ 'ms-3': _vm.IS_PENDENTE_APROVACAO },on:{"click":function($event){return _vm.integrarWBC()}}},[_c('b',[_vm._v("Integrar")]),_vm._v(" com "),_c('b',[_vm._v("WBC")])]):_vm._e()]),_c('div',{staticClass:"col col-lg-4 w-50 d-flex align-items-center justify-content-end"},[(_vm.editing && _vm.CAN_EDITAR_APROVAR_PARAMETRIZAR)?_c('button',{staticClass:"btn btn-secondary-blue ms-3",on:{"click":_vm.getEmpresaDataByCNPJ}},[_c('strong',[_vm._v("Importar")]),_vm._v(" dados da "),_c('strong',[_vm._v("Receita")])]):_vm._e(),_c('button',{staticClass:"btn btn-secondary",class:{ 'ms-3': _vm.editing },on:{"click":function($event){return _vm.$router.push({ name: 'BuscarEmpresas' })}}},[_vm._v(" Cancelar ")]),(_vm.editing && _vm.CAN_EDITAR_APROVAR_PARAMETRIZAR)?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.update}},[_vm._v(" Atualizar ")]):(_vm.CAN_EDITAR_APROVAR_PARAMETRIZAR)?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.save}},[_vm._v(" Salvar ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }