export default class GrupoEconomicoModel {
    constructor({ id, nome, empresas, selectedForGroup } = new Object()) {
      this._id = id;
      this._nome = nome;
      this._empresas = empresas;
      this._selectedForGroup = selectedForGroup;
    }
  
    set id(id = null) {
      this._id = id;
    }
  
    get id() {
      return this._id || null;
    }
  
    set selectedForGroup(selectedForGroup = null) {
      this._selectedForGroup = selectedForGroup;
    }
  
    get selectedForGroup() {
      return this._selectedForGroup || false;
    }
  
    set nome(nome = null) {
      this._nome = nome;
    }
  
    get nome() {
      return this._nome || null;
    }
  
    set empresas(empresas = new Array()) {
      this._empresas = empresas;
    }
  
    get empresas() {
      return this._empresas ?? new Array();
    }
  
    addEmpresa(empresa) {
      if (!this._empresas) this._empresas = new Array();
      this._empresas.push(empresa);
    }
  
    getData() {
      const { id, nome, empresas } = this;
      return {
        id,
        nome,
        empresas:
          empresas.length === 0
            ? null
            : empresas.map(({ id }) => {
                return { id };
              }),
      };
    }
  }