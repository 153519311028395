import ServicesPortal from "../ServicesPortal";

class EmpresaPosVendaService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  listAgentes(request) {
    this._url = `/pos-venda`;
    return this.get(request, true);
  }

  createAgente(request) {
    this._url = `/pos-venda`;
    return this.get(request);
  }

  dissociateAgente(request) {
    this._url = `/pos-venda/desassociar`;
    return this.patch(request, true);
  }

  associateAgente(request) {
    this._url = `/pos-venda/associar`;
    return this.patch(request, true);
  }

  updateConfiguracoes(request, empresaId) {
    this._url = `/${empresaId}/configuracoes`;
    return this.patch(request);
  }
}

export default new EmpresaPosVendaService("empresas");
