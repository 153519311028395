<template>
  <div v-async="loading" class="col col-lg-3">
    <div class="input-group">
      <label for="representanteField">
        Representante <span v-if="!IS_DISABLED" class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          autocomplete="off"
          list="representantes"
          :disabled="IS_DISABLED"
          class="form-control w-100"
          @keyup="(event) => findRepresentantes(event)"
          :class="{
            'is-invalid': $v.model.content.$error,
            'is-invalid-yellow':
              $v.search.$error && invalidYellow && !IS_DISABLED,
            'recent-updated': recentUpdatedField && !IS_DISABLED,
          }"
          id="representanteField"
          v-model="$v.search.$model"
        />
        <datalist ref="representanteEmpresaList" id="representantes">
          <option
            :key="`option_${optionIndex}`"
            v-for="(option, optionIndex) in options"
          >
            {{ option.cnpj | CNPJ }} - {{ option.razaoSocial }}
          </option>
        </datalist>
        <div class="form-text" style="height: 21px"></div>
      </label>
    </div>
  </div>
</template>

<script>
import { CNPJ } from "@/filters/formatters.js";
import { requiredIf, minLength } from "vuelidate/lib/validators";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      search: "",
      isFocused: false,
      isMounted: false,
      loading: undefined,
      options: new Array(),
      model: {
        name: "representante",
        content: null,
      },
    };
  },
  mounted() {
    this.isMounted = true;
  },
  filters: {
    CNPJ,
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Representante é obrigatório");
        return true;
      }
      return false;
    },
    mountSearch() {
      if (
        this.model.content &&
        this.model.content.id &&
        this.model.content.cnpj &&
        this.model.content.razaoSocial
      ) {
        this.search = `${this.$options.filters.CNPJ(
          this.model.content.cnpj
        )} - ${this.model.content.razaoSocial}`;
      }
    },
    resetRepresentanteSelection() {
      this.search = "";
      this.model.content = "";
      setTimeout(() => {
        this.clearOptionsList();
      }, 750);
    },
    clearOptionsList() {
      this.model.content = "";
      this.options = new Array();
    },
    findRepresentantes(event) {
      const blockedKeys = [
        "F2",
        "F4",
        "F6",
        "F8",
        "F9",
        "Alt",
        "End",
        "Home",
        "Enter",
        "Pause",
        "Shift",
        "Insert",
        "Escape",
        "Control",
        "AltGraph",
        "ArrowLeft",
        "ArrowRight",
        "ScrollLock",
      ];
      if (
        !(
          blockedKeys.includes(event.key) || blockedKeys.includes(event.code)
        ) &&
        !!event.key
      ) {
        try {
          if (this.timer != null && !!this.timer) {
            clearTimeout(this.timer);
          }
          if (this.search.length >= 3) {
            this.timer = setTimeout(async () => {
              this.loading = EmpresaService.listRepresentantes({
                filtro: this.search,
              })
                .then(({ data }) => {
                  this.options = data;
                  this.showWarning(() => {
                    return this.options.length === 0;
                  });
                })
                .catch((error) => this.mxHandleRequestError(error));
            }, 750);
          } else {
            this.clearOptionsList();
          }
        } catch (error) {
          this.mxHandleRequestError(error);
        }
      }
    },
    showWarning(callbackFn) {
      if (this.options.length === 0) {
        this.mxToggleWarningMessageAlert(
          "Não foi encontrado nenhum representante para essa procura, busque novamente."
        );
        this.resetRepresentanteSelection();
      } else {
        this.mxResetWarningPendencies();
      }
    },
  },
  computed: {
    IS_DISABLED() {
      const { dadosGeraisModel: parentModel } = this.$parent;
      if (!parentModel) return false;
      return (
        parentModel.tipo.restrictions.fields.includes(this.model.name) ||
        this.disabled
      );
    },
  },
  watch: {
    search: {
      handler() {
        if (this.search.includes(" - ")) {
          const cnpj = this.search
            .split(" - ")[0]
            .replaceAll("-", "")
            .replaceAll("/", "")
            .replaceAll(".", "");
          if (cnpj && cnpj.length > 0) {
            const option = this.options.find((option) => option.cnpj === cnpj);
            if (option) {
              this.model.content = option;
            }
          } else {
            this.resetRepresentanteSelection();
          }
        }
      },
    },
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
        this.mountSearch();
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
        this.mountSearch();
      },
    },
    "modelProp.agenteCcee": {
      immediate: true,
      handler() {
        if ((this.search || this.model.content) && this.modelProp.agenteCcee) {
          this.resetRepresentanteSelection();
        }
      },
    },
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    search: {
      required: requiredIf(function () {
        return this.invalidYellow && this.modelProp.empresaRepresentada;
      }),
    },
    model: {
      content: {
        required: requiredIf(function () {
          return !!this.modelProp.empresaRepresentada
        }),
        minLength: minLength(1),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
.listed-input-container {
  width: 100%;
  position: relative;
}
.list-container {
  left: 0;
  top: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 9999;
  overflow: auto;
  max-height: 50vh;
  list-style: none;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #222222;
  li {
    padding: 2px 10px;
    &:hover {
      color: #ffffff;
      background-color: #097eff;
    }
  }
}
.active {
  color: #ffffff;
  background-color: #097eff !important;
}
</style>
