<template>
  <div class="__empresas-lote">
    <h2 class="__empresas-lote__status">
      Status do processamento:
      <span class="status-running" v-if="is('running')">
        Em Andamento: ({{ model.totalRegistrosProcessados }}/{{
          model.totalRegistros
        }})
      </span>
      <span class="status-finished" v-else-if="is('finished')">Finalizado</span>
      <span class="status-error" v-else-if="is('error')">
        Finalizado com Erro
      </span>
      <span class="status-idle" v-else>Carregando...</span>
    </h2>
    <div v-if="USER_HAS_CC_PERMISSION">
      <overlay-loader v-if="loading" />
      <input-upload-field
        :modelProp="fieldsModel"
        ref="inputUploadField"
        :disabled="is('running') || loading"
        :fileType="['.xls', '.xlsx']"
        :getLayoutFile="getLayoutFile"
      />
      <div class="col col-lg-auto ms-auto">
        <button
          class="btn btn-secondary"
          @click="cleanFields"
          :disabled="is('running') || loading"
        >
          Limpar
        </button>
        <button
          class="btn btn-primary ms-3"
          @click="startProcessamento"
          :disabled="is('running') || !IS_FILE_ATTACHED || loading"
        >
          Iniciar Processamento
        </button>
      </div>
    </div>
    <h2 class="__empresas-lote__status">
      <template v-if="is('running')">
        Empresas do Processamento Atual
      </template>
      <template v-else-if="is('finished', 'error')">
        Empresas do Último Processamento ({{
          is("error") ? "Finalizado com Erro" : "Finalizado"
        }}
        às {{ HORARIO_FIM }})
      </template>
      <template v-else>Sem processamentos anteriores encontrados</template>
    </h2>
    <table-empresas-lote :config="{ list: model.empresas }" />
  </div>
</template>

<script>
import InputUploadField from "@/components/forms/fields/empresa/InputUploadField.vue";
import TableEmpresasLote from "@/components/tables/TableEmpresasLote.vue";
import { HTTP_RESPONSE } from "@/constants/strings";
import EmpresasLoteModel from "@/models/empresa/EmpresasLoteModel";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { formIsValid } from "@/utils/validators";

export default {
  data() {
    return {
      fieldsModel: { arquivo: null },
      model: new EmpresasLoteModel(),
      status: null,
      intervalo: null,
      loading: false,
    };
  },
  mounted() {
    this.getStatusCadastroLote();
  },
  methods: {
    async getStatusCadastroLote() {
      try {
        const { data } = await EmpresaService.getStatusCadastroLote();
        this.model = new EmpresasLoteModel(data);
      } catch (error) {
        this.mxHandleRequestError(error);
        return;
      }
      if (this.model.finalizadoComErro) this.status = "error";
      else if (this.model.finalizado) this.status = "finished";
      else if (this.model.empresas.length === 0) this.status = "idle";
      else this.status = "running";
    },
    async startProcessamento() {
      if (this.loading) return;
      if (!formIsValid(this)) return;

      this.loading = true;

      await this.getStatusCadastroLote();
      if (this.is("running")) {
        this.mxToggleErrorMessageAlert(
          "Já existe um processamento em andamento"
        );
        return;
      }

      const formData = new FormData();
      formData.append(
        "file",
        this.fieldsModel.arquivo,
        this.fieldsModel.arquivo.name
      );
      try {
        await EmpresaService.cadastrarEmpresasLote(formData);
        this.mxToggleToast(HTTP_RESPONSE.ARQUIVO.SUCCESS.CREATE);
        this.fieldsModel.arquivo = null;
        this.getStatusCadastroLote();
      } catch (error) {
        if (error?.response?.data?.messages?.length > 1) {
          this.mxToggleErrorModalAlerts(error.response.data.messages);
        } else {
          this.mxHandleRequestError(error);
        }
      }
      this.loading = false;
    },
    cleanFields() {
      this.fieldsModel.arquivo = null;
    },
    is(...status) {
      return status.includes(this.status);
    },
    async getLayoutFile() {
      try {
        const { data } = await EmpresaService.getTemplateExcel();
        return data;
      } catch (error) {
        this.mxHandleRequestError(error);
      }
    },
  },
  computed: {
    IS_FILE_ATTACHED() {
      return this.fieldsModel.arquivo !== null;
    },
    USER_HAS_CC_PERMISSION() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
    HORARIO_FIM() {
      if (!this.model.dataFim) return "HH:mm de DD/MM/AAAA";
      const date = new Date(this.model.dataFim);
      return `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")} de ${date.toLocaleDateString()}`;
    },
  },
  watch: {
    status() {
      if (this.is("running")) {
        if (!this.intervalo) {
          this.intervalo = setInterval(this.getStatusCadastroLote, 5000);
        }
      } else {
        if (this.intervalo) clearInterval(this.intervalo);
        this.intervalo = null;
      }
    },
  },
  components: {
    InputUploadField,
    TableEmpresasLote,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.__empresas-lote {
  padding: 24px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__status {
    font-size: 1rem;
    margin: 0;
    font-weight: normal;
    > span {
      font-weight: 500;
      font-size: 1.2rem;
      &.status-running {
        color: $status--yellow;
      }
      &.status-finished {
        color: $status--green;
      }
      &.status-error {
        color: $status--red;
      }
    }
  }
  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
