<template>
  <div class="mt-3" v-async="loading">
    <form>
      <div class="row mt-5">
        <div class="col-md-6">
          <div class="input-group">
            <label for="actualPassword"> Senha atual </label>
            <input
              v-model="$v.model.senhaAntiga.$model"
              :class="{
                'is-invalid': $v.model.senhaAntiga.$error,
              }"
              autocomplete="current-password"
              id="actualPassword"
              type="password"
              class="form-control"
            />
          </div>
          <div class="form-text">
            Caso você tenha esquecido sua senha, você pode pedir outra.
            <span class="clickme" @click="resendPassword">clicando aqui</span>.
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="input-group">
            <label for="newPassword">
              Nova senha
              <input
                :class="{
                  'is-invalid': $v.model.senhaNova.$error,
                }"
                v-model="$v.model.senhaNova.$model"
                autocomplete="new-password"
                id="newPassword"
                type="password"
                class="form-control"
                maxLength="10"
              />
              <div class="form-text">
                <span
                  style="color: red"
                  class="error"
                  v-if="
                    !$v.model.senhaNova.minLength && $v.model.senhaNova != ''
                  "
                  >{{ INVALID_FIELD_MSG.PASSWORD_MIN_LEN }}
                </span>

                <span v-if="!$v.model.senhaNova.$model">
                  Preencha com sua nova senha.
                </span>

                <span
                  v-if="
                    $v.model.senhaNova.minLength && $v.model.senhaNova.$model
                  "
                >
                  <i class="icon-check ms-1"></i>
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="input-group">
            <label for="confirmPassword">
              <div class="d-flex justify-content-between">Confirme a senha</div>
              <input
                :class="{
                  'is-invalid': $v.model.confirmation.$error,
                }"
                v-model="$v.model.confirmation.$model"
                autocomplete="new-password"
                id="confirmPassword"
                type="password"
                class="form-control"
                maxLength="10"
              />

              <div class="form-text">
                <span v-if="!$v.model.confirmation.$model">
                  Repita aqui a nova senha.
                </span>
                <span
                  v-if="
                    $v.model.confirmation.$model &&
                    $v.model.confirmation.$model === $v.model.senhaNova.$model
                  "
                >
                  <i class="icon-check ms-1"></i>
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col col-lg-auto ms-auto">
        <button class="btn btn-primary ms-3" @click="changePassword">
          Trocar senha
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MxUserUtils from "@/mixins/mxUserUtils";
import { HTTP_RESPONSE } from "@/constants/strings";
import UsuarioService from "@/services/portal/UsuarioService";
import { INVALID_FIELD_MSG } from "@/constants/form.constants";
import {
  required,
  sameAs,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      loading: undefined,
      INVALID_FIELD_MSG,
      model: {
        senhaAntiga: null,
        senhaNova: null,
        confirmation: null,
      },
    };
  },
  mixins: [MxUserUtils],
  computed: {
    ...mapGetters(["getUsuario"]),
  },
  methods: {
    ...mapActions(["ActionToggleMessageAlert"]),
    changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert(
          HTTP_RESPONSE.CHANGE_NEW_PASSWORD.FAILURE
        );
        return;
      }
      const { senhaAntiga, senhaNova } = this.model;
      this.loading = UsuarioService.changePassword({ senhaAntiga, senhaNova })
        .then(async () => {
          this.mxToggleToast(HTTP_RESPONSE.USUARIO.SUCCESS.UPDATE);
          const credentials = {
            username: this.getUsuario.email,
            password: senhaNova,
          };
          const userId = await this.mxTryLogin(credentials);
          await this.mxGetUserDetails(userId, false);
          this.mxResetErrorPendencies();
        })
        .catch((error) =>
          this.mxHandleRequestError(error, "CHANGE_NEW_PASSWORD")
        );
    },
    resendPassword() {
      const { email } = this.getUsuario;
      this.loading = UsuarioService.recuperarSenha({
        email,
      })
        .then(() => {
          this.mxToggleToast(
            "Um e-mail com as instruções para redefinição de senha foi enviado"
          );
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  validations: {
    model: {
      senhaAntiga: { required },
      senhaNova: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(6),
      },
      confirmation: {
        samePasswords: sameAs("senhaNova"),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100%;
}

.clickme {
  cursor: pointer;
  font-weight: bold;
  color: $primary--orange;
}

.icon-check:before {
  color: #2ed47a !important;
}
</style>
