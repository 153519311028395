import ServicesPortal from "./ServicesPortal";

class ModalidadeTarifariaService extends ServicesPortal {
    constructor(endpoint) {
        super(endpoint);
    }

    getModalidades(request) {
        this._url = "/listar";
        return this.get(request);
    }
}

export default new ModalidadeTarifariaService("modalidade");