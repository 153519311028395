import EmpresaConfiguracaoDemonstrativoEnum from "@/enums/EmpresaConfiguracaoDemonstrativoEnum";
class EmpresaConfiguracoesModel {
  constructor(
    {
      id,
      curtoPrazo,
      hasAgentePosVendaAssociated = false,
      reducaoDeMontantes,
      tipoEnvioDemonstrativo,
    } = new Object()
  ) {
    this._id = id;
    this._curtoPrazo = curtoPrazo;
    this._hasAgentePosVendaAssociated = hasAgentePosVendaAssociated;
    this._reducaoDeMontantes = reducaoDeMontantes;
    this._tipoEnvioDemonstrativo = tipoEnvioDemonstrativo;
  }

  get hasAgentePosVendaAssociated() {
    return this._hasAgentePosVendaAssociated || false;
  }

  set hasAgentePosVendaAssociated(hasAgentePosVendaAssociated = null) {
    this._hasAgentePosVendaAssociated = hasAgentePosVendaAssociated;
  }

  get curtoPrazo() {
    return this._curtoPrazo || false;
  }

  set curtoPrazo(curtoPrazo = null) {
    this._curtoPrazo = curtoPrazo;
  }

  get id() {
    return this._id || null;
  }

  set id(id = null) {
    this._id = id;
  }

  get reducaoDeMontantes() {
    return this._reducaoDeMontantes || false;
  }

  set reducaoDeMontantes(reducaoDeMontantes = null) {
    this._reducaoDeMontantes = reducaoDeMontantes;
  }

  get tipoEnvioDemonstrativo() {
    return (
      this._tipoEnvioDemonstrativo ||
      EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE
    );
  }

  set tipoEnvioDemonstrativo(tipoEnvioDemonstrativo = null) {
    this._tipoEnvioDemonstrativo = tipoEnvioDemonstrativo;
  }

  getData() {
    const { curtoPrazo, reducaoDeMontantes, tipoEnvioDemonstrativo } = this;
    return {
      curtoPrazo,
      id: null,
      reducaoDeMontantes,
      tipoEnvioDemonstrativo,
    };
  }
}

export default EmpresaConfiguracoesModel;
