<template>
  <div class="default-error-modal" :class="{ closingAnim: runClosingAnim }">
    <div class="default-error-modal-bg" @click="delayedClosing" />
    <div class="default-error-modal-content">
      <button @click="delayedClosing" type="button" class="btn-close" />
      <h2>Erros no envio do arquivo:</h2>
      <div class="custom-table-template">
        <div class="table__row" v-for="(mensagem, i) in messages" :key="i">
          <div class="coll">{{ mensagem.message }}</div>
          <!-- O nível do erro sempre está vindo igual, mas isso pode mudar em uma task futura, e nesse caso colocaremos uma imagem como indicador; Temporariamente desabilitado -->
          <div v-if="false" class="coll"><img :src="icon" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      runClosingAnim: false,
    };
  },
  methods: {
    ...mapActions(["ActionResetAlertsModal"]),
    delayedClosing() {
      this.runClosingAnim = true;
      setTimeout(() => {
        this.ActionResetAlertsModal();
        this.runClosingAnim = false;
      }, 300);
    },
  },
  computed: {
    messages() {
      return this.$store.getters.getAlertsModal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";
.default-error-modal {
  width: 100%;
  height: 100vh;
  z-index: 900;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.closingAnim {
    .default-error-modal-content {
      animation: modalShrink 300ms forwards;
    }
    .default-error-modal-bg {
      animation: modalBgFadeOut 500ms forwards;
    }
  }
  &-bg {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1.5px);
    animation: modalBgFadeIn 300ms;
    width: 100%;
    height: 100%;
  }
  &-content {
    position: absolute;
    background-color: white;
    animation: modalBump 300ms;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    > h2 {
      font-size: 22px;
      color: #dc3545;
    }
    > button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.custom-table-template {
  overflow-y: scroll;
  height: 100%;
  background-color: #f8f8f8;
  > div.table__row {
    background-color: white;
    display: flex;
    justify-content: center;
    transition: background-color 100ms;
    &:hover {
      background-color: #f8f8f8;
    }
    .coll {
      border-bottom: 1px solid #b6b6b6;
      padding: 10px;
      font-weight: 400;
      color: #2a2a2a;
      &:nth-child(1) {
        flex-grow: 1;
      }
      &:nth-child(3) {
        width: 10%;
        width: 21px;
        padding: 0;
      }
    }
  }
}
</style>
