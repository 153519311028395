<template>
  <div class="col col-lg-1">
    <div class="input-group">
      <label for="enderecoEstadoField">
        U.F.
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          class="form-select"
          :disabled="disabled"
          v-model="model.content"
          id="enderecoEstadoField"
          @change="$emit('estadoUpdated', model.content.id)"
          :class="{
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField,
          }"
        >
          <option
            v-for="(estado, estadoIndex) in list"
            :key="`uf_option_${estadoIndex}`"
            :value="estado"
          >
            {{ estado.id }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      list: new Array(),
      loadList: new Array(),
      model: {
        name: "estado",
        content: "",
      },
    };
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler() {
        if (this.model.content.id) {
          this.$emit("estadoUpdated", model.content.id);
        }
      },
    },
  },
  mounted() {
    this.loadList = EmpresaService.listEstados()
      .then(({ data }) => (this.list = data))
      .catch((error) => console.log(error));
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (this.invalidYellow) {
            return true;
          }
          return false;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
