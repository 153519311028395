<template>
  <div class="col col-lg-4">
    <div class="input-group">
      <label for="codigoPerfilAgenteField">
        Código do Perfil do Agente <span class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          :disabled="disabled || modelProp.isProvisorio"
          class="form-control"
          id="codigoPerfilAgenteField"
          v-model.trim="$v.model.content.$model"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
        />
        <div v-if="model.content == -1" class="form-text">
          "-1" é o valor padrão para quando não há Pefil do Agente cadastrado no
          WBC.
        </div>
        <div v-else class="form-text">
          Insira o código para este tipo de energia.
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import { maxLength, requiredIf } from "vuelidate/lib/validators";

export default {
  props: {
    modelProp: Object,
    disabled: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "codigoPerfilAgente",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert(
          "Campo Código do Perfil do Agente inválido"
        );
        return true;
      }
      return false;
    },
    checkSpecialCharacters() {
      this.$v.model.content.$model = this.$v.model.content.$model.replace(
        /[^0-9]/g,
        ""
      );
    },
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
    "modelProp.isProvisorio": {
      deep: true,
      handler() {
        if (this.modelProp.isProvisorio) {
          this.model.content = null;
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        maxLength: maxLength(255),
        required: requiredIf(function () {
          if (this.modelProp.isProvisorio) {
            return false;
          } else {
            return true;
          }
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
