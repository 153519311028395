import CCEEService from "@/services/portal/empresa/CCEEService";
import ContatoService from "@/services/portal/empresa/ContatoService";
import ArquivoService from "@/services/portal/empresa/ArquivoService";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import DadosBancariosService from "@/services/portal/empresa/DadosBancariosService";
export default {
  SETOR: {
    title: "Setor",
    list: () => EmpresaService.listSetor(),
    remove: (parametro) => EmpresaService.removeSetor(parametro),
    update: (parametro) => EmpresaService.updateSetor(parametro),
    create: (parametro) => EmpresaService.createSetor(parametro),
  },
  SEGMENTO: {
    title: "Segmento",
    list: () => EmpresaService.listSegmento(),
    remove: (parametro) => EmpresaService.removeSegmento(parametro),
    update: (parametro) => EmpresaService.updateSegmento(parametro),
    create: (parametro) => EmpresaService.createSegmento(parametro),
  },
  ESTADO_CIVIL: {
    title: "Estado Civil",
    list: () => ContatoService.listEstadoCivil(),
    remove: (parametro) => ContatoService.removeEstadoCivil(parametro),
    update: (parametro) => ContatoService.updateEstadoCivil(parametro),
    create: (parametro) => ContatoService.createEstadoCivil(parametro),
  },
  TIPO_DOCUMENTO: {
    title: "Tipo de Documento",
    list: () => ContatoService.listTipoDocumento(),
    remove: (parametro) => ContatoService.removeTipoDocumento(parametro),
    update: (parametro) => ContatoService.updateTipoDocumento(parametro),
    create: (parametro) => ContatoService.createTipoDocumento(parametro),
  },
  DEPARTAMENTO: {
    title: "Departamento",
    list: () => ContatoService.listDepartamento(),
    remove: (parametro) => ContatoService.removeDepartamento(parametro),
    update: (parametro) => ContatoService.updateDepartamento(parametro),
    create: (parametro) => ContatoService.createDepartamento(parametro),
    changeGestor: (parametro) => ContatoService.changeGestor(parametro),
  },
  TIPO_CONTA: {
    title: "Tipo de Conta",
    list: () => DadosBancariosService.listTipoConta(),
    remove: (parametro) => DadosBancariosService.removeTipoConta(parametro),
    update: (parametro) => DadosBancariosService.updateTipoConta(parametro),
    create: (parametro) => DadosBancariosService.createTipoConta(parametro),
  },
  TIPO_ENERGIA: {
    title: "Tipo de Energia",
    list: () => CCEEService.listTipoEnergia(),
    remove: (parametro) => CCEEService.removeTipoEnergia(parametro),
    update: (parametro) => CCEEService.updateTipoEnergia(parametro),
    create: (parametro) => CCEEService.createTipoEnergia(parametro),
  },
  TIPO_ANEXO: {
    title: "Tipo de Anexo",
    list: () => ArquivoService.listTipoArquivo(),
    remove: (parametro) => ArquivoService.removeTipoArquivo(parametro),
    update: (parametro) => ArquivoService.updateTipoArquivo(parametro),
    create: (parametro) => ArquivoService.createTipoArquivo(parametro),
  },
  POS_VENDAS: {
    title: "Pós Vendas",
    list: () => EmpresaService.findAllPosVenda(),
    remove: (parametro) => EmpresaService.removePosVenda(parametro),
    update: (parametro) => EmpresaService.updatePosVenda(parametro),
    create: (parametro) => EmpresaService.savePosVenda(parametro),
  },
};
