<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="enderecoPaisField">
        País
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          disabled
          class="form-select"
          id="enderecoPaisField"
          v-model="model.content"
          :class="{
            'recent-updated': recentUpdatedField,
          }"
        >
          <option
            :value="pais"
            v-for="(pais, paisIndex) in list"
            :key="`pais_${paisIndex}`"
          >
            {{ pais.nome }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      list: new Array(),
      model: {
        name: "pais",
        content: new Object(),
      },
    };
  },
  mounted() {
    this.list = new Array({ id: "BR", nome: "Brasil" });
    this.model.content = this.list[0];
    // EmpresaService.getPaisList()
    // .then(({ data }) => (this.list = data))
    //   .catch((error) => console.log(error));
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
