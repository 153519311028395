<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="nomeField">
        Nome <span class="required-markup">*</span> 
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          maxlength="100"
          :disabled="disabled"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="nomeField"
          v-model.trim="$v.model.content.$model"
        />
        <div class="form-text">Preencha com o nome completo.</div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { required } from "vuelidate/lib/validators";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "nome",
        content: "",
      },
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Nome inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },  
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
