import App from "@/App.vue";
import "@/assets/font-icons/style.css";
import "@/assets/style/modal_spinner.scss";
import "@/components/globalizedComponents";
import Diretivas from "@/directives/index";
import "@/directives/resize-screen/screen";
import Spinner from "@/directives/spiner-loading/spiner";
import "@/mixins/mxBreadcrumbs";
import MixinHandleRequestError from "@/mixins/mxHandleRequestError";
import "@/mixins/mxHeaderBoxInfo";
import MixinMountUrls from "@/mixins/mxMountUrls";
import MixinToggleMessageAlert from "@/mixins/mxToggleMessageAlert";
import MixinToggleToast from "@/mixins/mxToggleToast";
import MixinToggleWarningModal from "@/mixins/mxToggleWarningModal";
import "@/plugins/casl";
import "@/plugins/vuelidate";
import "@/plugins/vuescroll";
import router from "@/router";
import store from "@/store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";

Vue.use(Diretivas);
Vue.mixin(MixinToggleToast);
Vue.config.productionTip = false;
Vue.mixin(MixinToggleMessageAlert);
Vue.mixin(MixinToggleWarningModal);
Vue.mixin(MixinHandleRequestError);
Vue.mixin(MixinMountUrls);
Vue.directive("element_loading", Spinner);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
