<template>
  <div
    class="
      col col-lg-12
      tipo-empresa-container
      shadow
      p-4
      mb-5
      rounded
      card-shadow-border
    "
    :class="{ 'is-invalid': $v.model.content.$error }"
  >
    <span class="fw-bold mb-3">Tipo de Empresa</span>
    <div class="btn-group">
      <div
        v-for="(empresaTipo, empresaTipoIndex) in list"
        :key="`empresa_check_${empresaTipoIndex}_${empresaTipo.id}`"
      >
        <label :for="`empresa_check_${empresaTipo.id}`">
          <input
            type="radio"
            name="tipo_empresa"
            :disabled="disabled"
            :value="empresaTipo"
            v-model="model.content"
            :id="`empresa_check_${empresaTipo.id}`"
          />
          {{ empresaTipo.nome }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import EmpresaTipoENUM from "@/enums/EmpresaTipoENUM";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    maintainValue: Boolean,
  },
  data() {
    return {
      model: {
        name: "tipo",
        content: EmpresaTipoENUM.CONSUMIDOR,
      },
      list: new Array(),
    };
  },
  mounted() {
    this.list = Object.keys(EmpresaTipoENUM).map((key) => EmpresaTipoENUM[key]);
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Selecione o Tipo de Empresa");
        return true;
      }
      return false;
    },
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
        if (!this.maintainValue) {
          this.$emit("changeTipoEmpresa");
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.tipo-empresa-container {
  display: flex;
  flex-direction: column;
}

.btn-group {
  justify-content: space-between;
}

input,
label {
  cursor: pointer !important;
}

.is-invalid {
  border: 1px solid #dc3545 !important;
}
</style>
