<template>
  <div class="wrapper-table table-responsive">
    <table class="table table-hover">
      <thead class="col-4">
        <tr>
          <th>Tipo</th>
          <th>CNPJ</th>
          <th>Razão Social</th>
          <th>Apelido</th>
          <th>Nome Fantasia</th>
        </tr>
      </thead>
      <tbody v-if="empresas && empresas.length > 0">
        <tr
          :class="`${empresa.matrizCnpj ? 'empresa-filial' : null}`"
          @click="emitEmpresaId(empresa.id)"
          v-for="(empresa, index) in empresas"
          :key="`filial_${index}`"
        >
          <td>{{ empresa.matriz ? 'Matriz' : 'Filial' }}</td>
          <td>{{ empresa.cnpj | CNPJ }}</td>
          <td>{{ empresa.razaoSocial | EMPTY }}</td>
          <td>{{ empresa.apelido | EMPTY }}</td>
          <td>{{ empresa.nomeFantasia | EMPTY }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="5">Nenhuma filial encontrada.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { CNPJ, EMPTY } from "@/filters/formatters";
export default {
  props: {
    empresas: {
      type: Array,
      default: () => new Array(),
    },
  },
  data() {
    return {
      loading: undefined,
    };
  },
  methods: {
    emitEmpresaId(empresaId) {
      this.$emit("emitEmpresaId", empresaId);
    },
  },
  filters: {
    CNPJ,
    EMPTY,
  },
};
</script>
<style scoped>
.title-filial {
  font-size: 16px;
  font-weight: 600;
}

.margin-filial {
  margin-top: 32px;
}

.empresa-filial {
  color: #505050;

  opacity: 0.5 !important;
}

thead {
  font-size: 16px;
}

tbody {
  color: #505050;
  font-size: 12.8px;
  cursor: pointer;
}
</style>
