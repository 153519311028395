<script>
export default {
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
  },
};
</script>
