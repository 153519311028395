<template>
  <div class="container" v-async="loading">
    <div class="row d-flex align-items-center justify-content-between">
      <div class="col-md-6 col-lg-6">
        <div>
          <search-bar
            ref="searchBar"
            @doSearch="getPropostasByFiltro"
            title="Buscar Empresa"
            :cols="10"
          />
          <div class="form-text">Pesquise por Razão Social ou CNPJ</div>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button
          type="button"
          @click="generateReport"
          class="col-auto btn btn-primary-blue rounded"
          :disabled="tableConfig.conteudo.length === 0"
        >
          Exportar XLS
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 wrapper-table table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Razão Social</th>
              <th>CNPJ</th>
              <th>Número da Proposta</th>
            </tr>
          </thead>
          <tbody v-if="tableConfig.conteudo.length > 0">
            <tr
              v-for="(linha, linhaIndex) in tableConfig.conteudo"
              :key="`linhaIndex${linhaIndex}`"
            >
              <td>{{ linha.razaoSocial }}</td>
              <td>{{ linha.cnpj | CNPJ }}</td>
              <td>
                {{ linha.identificadorProposta | EMPTY }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3" class="text-center">
                Nenhum registro encontrado
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mt-4">
        <div class="col col-lg-12">
          <pagination
            @setPage="setPage"
            :info="tableConfig.pagination"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/pagination/pagination";
import { CNPJ, EMPTY } from "@/filters/formatters";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { downloadFileFromResponseObject } from "@/utils/functionUtils";
export default {
  data() {
    return {
      fields: new Array(),
      loading: undefined,
      selectedField: null,
      selectedFields: new Array(),
      tableConfig: {
        conteudo: new Array(),
        pagination: undefined,
      },
      filters: {
        size: 10,
        page: 0,
        filtro: null,
      },
    };
  },
  filters: {
    CNPJ,
    EMPTY,
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    generateReport() {
      this.loading = EmpresaService.getEmpresaExcel("status", ["PROSPECT"])
        .then(({ data }) => {
          downloadFileFromResponseObject(
            data,
            `Empresas Status Prospect - ${new Date().toLocaleDateString()}.xlsx`
          );
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    setPage(page) {
      this.filters.page = page;
      this.doSearch();
    },
    getPropostasByFiltro(filtro) {
      const RESET_PAGE = true;
      this.doSearch(RESET_PAGE, filtro);
    },
    doSearch(resetPage = false, filtro) {
      this.filters.filtro = filtro;
      const { size, page } = this.filters;
      this.loading = EmpresaService.listProspects({
        filtro,
        size,
        page: resetPage ? 0 : page,
      })
        .then((response) => {
          this.tableConfig.conteudo = response.data.content;
          this.tableConfig.pagination = response.data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  components: {
    SearchBar,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
