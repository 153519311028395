import ServicesPortal from "../ServicesPortal";

class CCEEService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  getByEmpresa(request) {
    this._url = `/${request.empresaId}/ccee/perfis`;
    return this.get(request, true);
  }

  sincronizarCCEEComWBC(request) {
    this._url = `/${request.empresaId}/ccee/perfis/integrar`;
    return this.get(request);
  }

  create(request, empresaId) {
    this._url = `/${empresaId}/ccee/perfis/`;
    return this.post(request);
  }

  remove(request, empresaId) {
    this._url = `/${empresaId}/ccee/perfis/${request.id}`;
    return this.delete(request);
  }

  update(request, empresaId) {
    this._url = `/${empresaId}/ccee/perfis/${request.id}`;
    return this.put(request);
  }

  updateCodigoAgente(request, empresaId) {
    this._url = `/${empresaId}/ccee/codigo`;
    return this.put(request);
  }

  createTipoEnergia(request) {
    this._url = "/ccee/tipos-energia";
    return this.post(request);
  }

  removeTipoEnergia(request) {
    this._url = `/ccee/tipos-energia/${request.id}`;
    return this.delete(request);
  }

  listTipoEnergia(request) {
    this._url = "/ccee/tipos-energia";
    return this.get(request);
  }

  updateTipoEnergia(request) {
    this._url = "/ccee/tipos-energia";
    return this.put(request);
  }
}

export default new CCEEService("empresas");
