import ServicesPortal from "./ServicesPortal";

class PowerBiService extends ServicesPortal {
    constructor(endpoint) {
        super(endpoint);
    }

    getDashboards(request) {
        return this.get(request);
    }
}

export default new PowerBiService("power-bi");