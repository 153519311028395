<template>
  <div>
    <div class="comp-help">
      <div class="comp-help__icon">{{ text }}</div>
      <div class="comp-help-arrow"></div>
      <div
        :style="`width:${width}px`"
        :class="[
          { 'comp-help__box-message--left': boxAlign === 'left' },
          { 'comp-help__box-message--right': boxAlign === 'right' },
          'comp-help__box-message',
        ]"
      >
        <span class="comp-help__message">
          <slot></slot>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 250,
    },
    textAlign: {
      type: String,
      required: false,
      default: "left",
    },
    boxAlign: {
      type: String,
      required: false,
      default: "left",
    },
    text: {
      type: String,
      required: false,
      default: "?",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.comp-help {
  width: 20px;
  height: 20px;
  z-index: 400;
  display: flex;
  cursor: pointer;
  color: #ffffff;
  position: relative;
  align-items: center;
  border-radius: 20px;
  background: #c4c4c4;
  justify-content: center;
  transform: rotate(-1deg);
  padding-left: 1px;
  &:hover .comp-help__box-message {
    display: block;
  }
  &:hover .comp-help-arrow {
    display: block;
  }
}

.comp-help__box-message {
  top: 35px;
  padding: 10px;
  z-index: 999;
  display: none;
  text-align: left;
  position: absolute;
  border-radius: 2px;
  background: rgba($color: #909090, $alpha: 0.9);
}

.comp-help__message {
  color: #fff;
  font-size: 12px;
  line-height: 22px;
}

.comp-help-arrow {
  top: 25px;
  display: none;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba($color: #909090, $alpha: 0.9);
}

.comp-help__box-message--left {
  right: -10px;
}

.comp-help__box-message--right {
  left: -10px;
}
</style>
