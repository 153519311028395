export default class ContatoGestorModel {
    constructor({ contato, id, permissoes } = new Object()) {
        this._id = id;
        this._contato = contato;
        this._permissoes = permissoes;
    }

    get id() {
        return this._id || null;
    }

    set id(id = null) {
        this._id = id;
    }

    get contato() {
        return this._contato || null;
    }

    set contato(contato = null) {
        this._contato = contato;
    }

    get permissoes() {
        return this._permissoes;
    }

    set permissoes(permissoes = null) {
        this._permissoes = permissoes;
    }

    getData() {
        const { id, contato, permissoes } = this;
        return {
            id,
            contato: {
                ...contato,
                permissoes: {
                    demonstrativo: null,
                    recebeNotaFiscal: null,
                }
            },
            permissoes,
        };
    }
}