<script>
import { mapActions } from "vuex";
import { HTTP_RESPONSE } from "@/constants/strings";
export default {
  methods: {
    ...mapActions(["ActionToggleParametrosPageIsLoading"]),
    removeParametro(parametro) {
      this.mxToggleWarningModal(
        `Deseja deletar o parâmetro ${parametro.nome} ?`
      ).then(() => {
        this.loading = this.parametro
          .remove(parametro)
          .then(() => {
            this.mxToggleToast(HTTP_RESPONSE.PARAMETRO.SUCCESS.DELETE);
            this.listParametro();
          })
          .catch((error) => this.mxHandleRequestError(error, "PARAMETRO"));
      });
    },
    switchParameterActuation(parametro) {
      this.mxToggleWarningModal(
        `Deseja ${parametro.ativo ? "inativar" : "ativar"} o parâmetro ${
          parametro.nome
        } ?`
      ).then(() => {
        this.loading = this.parametro
          .update({ ...parametro, ativo: !parametro.ativo })
          .then(() => {
            this.mxToggleToast(HTTP_RESPONSE.PARAMETRO.SUCCESS.UPDATE);
            this.listParametro();
          })
          .catch((error) => this.mxHandleRequestError(error, "PARAMETRO"));
      });
    },
    createParametro() {
      this.mxToggleWarningModal(
        `Deseja criar o parâmetro ${this.model.nome} ?`
      ).then(() => {
        this.loading = this.parametro
          .create(this.model)
          .then(() => {
            this.model = {
              id: null,
              nome: "",
              ativo: true,
            };
            this.mxToggleToast(HTTP_RESPONSE.PARAMETRO.SUCCESS.CREATE);
            this.listParametro();
          })
          .catch((error) => this.mxHandleRequestError(error, "PARAMETRO"));
      });
    },
    listParametro() {
      const request = this.parametro
        .list()
        .then((response) => (this.list = response.data))
        .catch((error) => this.mxHandleRequestError(error, "PARAMETRO"));
      this.ActionToggleParametrosPageIsLoading(request);
    },
  },
};
</script>
