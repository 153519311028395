import store from "@/store/index";
import axios from "axios";

class Services {
  constructor(baseURL) {
    this._url = "";
    this._api = axios.create({
      baseURL,
      timeout: 100000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      validateStatus: function (status) {
        return status >= 200 && status <= 299;
      },
    });

    this._api.interceptors.request.use(
      async (request) => {
        const token =
          window.sessionStorage.getItem(process.env.VUE_APP_TOKEN_KEY) || null;
        if (token) {
          request.headers.Authorization = `Bearer ${token}`;
        }

        store.dispatch("ActionResetAllPendencies");

        return request;
      },
      (error) => {
        if (error.response.status === 401) {
          loginRedirect();
        }
        return error;
      }
    );
  }

  static getInstanceAxios(options) {
    return axios.create({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("b-token")}`,
      },
      ...options,
    });
  }

  createQueryArrayTypeParams(paramKey, params) {
    this._url += "?";
    let count = 0;
    params.forEach((param) => {
      if (param == null || param === undefined || param === "undefined") {
        return;
      }
      this._url += `${count > 0 ? "&" : ""}${paramKey}=${param}`;
      count++;
    });
  }

  createQueryParams(request) {
    if (!request) return;

    let keys = Object.keys(request);
    this._url += "?";
    let count = 0;
    for (let key of keys) {
      if (
        request[key] == null ||
        request[key] === undefined ||
        request[key] === "undefined"
      ) {
        continue;
      }

      if (Array.isArray(request[key])) {
        this._url +=
          (count > 0 ? "&" : "") +
          request[key].map((e) => `${key}=${e}`).join("&");
        count++;
        continue;
      }

      this._url += `${count > 0 ? "&" : ""}${key}=${request[key]}`;
      count++;
    }
  }

  replaceParameters(request) {
    let keys = Object.keys(request);
    for (let key of keys) {
      this._url = this._url.replace(`\{${key}\}`, request[key]);
    }
  }

  hasParameters() {
    return /(\{[a-zA-Z]\})+/.test(this._url);
  }

  get(request = null, queryParam = false) {
    if (this.hasParameters() && !queryParam) {
      this.replaceParameters(request);
    } else if (queryParam) {
      this.createQueryParams(request);
    }

    return this._api.get(this._url, { data: null });
  }

  getExcel(paramKey, request) {
    const params = this.createQueryArrayTypeParams(paramKey, request);
    return this._api.get(this._url, {
      responseType: "arraybuffer",
      params,
    });
  }

  getAttachment() {
    return this._api.get(this._url, { responseType: "blob" });
  }

  postParam(request = null, queryParam = false) {
    if (this.hasParameters() && !queryParam) {
      this.replaceParameters(request);
    } else if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.post(this._url);
  }

  post(request, queryParam = false, config) {
    if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.post(this._url, request, config);
  }

  put(request) {
    return this._api.put(this._url, request);
  }

  // patch(request) {
  //   return this._api.patch(this._url, request);
  // }

  patch(request, queryParam = false) {
    if (this.hasParameters() && !queryParam) {
      this.replaceParameters(request);
    } else if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.patch(this._url, request);
  }

  putParam(request = null, queryParam = false) {
    if (this.hasParameters() && !queryParam) {
      this.replaceParameters(request);
    } else if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.put(this._url);
  }

  delete(request) {
    this.replaceParameters(request);
    let body = null;
    if (request) {
      body = { data: request };
    }
    return this._api.delete(this._url, body);
  }
}

export default Services;
