import BarraSalvarCancelar from "@/components/BarraSalvarCancelar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Help from "@/components/HelpDefault.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import Vue from "vue";
import vSelect from "vue-select";

const components = [
  ["cc-barra-salvar-cancelar", BarraSalvarCancelar],
  ["breadcrumbs", Breadcrumbs],
  ["v-select", vSelect],
  ["help", Help],
  ["overlay-loader", OverlayLoader],
];

components.map((component) => {
  Vue.component(component[0], component[1]);
});
