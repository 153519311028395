import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions([
      "ActionToggleWarningAlert",
      "ActionToggleDangerAlert",
      "ActionToggleAlertsModal",
      "ActionResetAllPendencies",
      "ActionResetErrorPendencies",
      "ActionSetSingleErrorMessage",
      "ActionResetWarningPendencies",
      "ActionResetSingleErrorMessage",
      "ActionResetAlertsModal",
      "ActionClearCamposAlteradosPendencies",
      "ActionClearCamposPendentesPendencies",
    ]),
    mxToggleWarningMessageAlert(
      message,
      showButton = false,
      immutable = false
    ) {
      this.ActionToggleWarningAlert({ message, showButton, immutable });
    },
    mxToggleErrorMessageAlert(message, showButton = true) {
      this.ActionToggleDangerAlert({ message, showButton });
    },
    mxToggleErrorSingleMessageAlert(message) {
      this.ActionSetSingleErrorMessage(message);
    },
    mxToggleErrorModalAlerts(message) {
      this.ActionToggleAlertsModal(message);
    },
    mxResetSingleMessageAlert() {
      this.ActionResetSingleErrorMessage();
    },
    mxResetErrorPendencies() {
      this.ActionResetErrorPendencies();
    },
    mxResetWarningPendencies(resetImmutable = false) {
      this.ActionResetWarningPendencies(resetImmutable);
    },
    mxClearYellowPendency(camposAlterados = false, camposPendentes = false) {
      if (camposAlterados) {
        this.ActionClearCamposAlteradosPendencies();
      }
      if (camposPendentes) {
        this.ActionClearCamposPendentesPendencies();
      }
    },
    mxResetAllPendencies() {
      this.ActionResetAllPendencies();
    },
    mxResetAlertsModal() {
      this.ActionResetAlertsModal();
    },
  },
};
