<template>
  <div class="col col-lg-6">
    <span>Regime Especial</span>
    <span class="recent-updated-label" v-if="recentUpdatedField">
      <tooltip-informacao-alterada />
    </span>
    <div class="input-group mt-3">
      <div v-for="(regime, index) in list" :key="`regime_type_${index}`">
        <div class="me-4 label-regime">
          <label class="p-1" :for="`regime_type_${regime.id}`">
            <input
              type="radio"
              :value="regime"
              :disabled="disabled"
              name="aplica_regime"
              v-model="model.content"
              :id="`regime_type_${regime.id}`"
            />
            {{ regime.nome }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegimeEspecialENUM from "@/enums/RegimeEspecialENUM";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      list: new Array(),
      model: {
        name: "regimeEspecial",
        content: RegimeEspecialENUM.NAO_SE_APLICA,
      },
    };
  },
  mounted() {
    this.list = Object.keys(RegimeEspecialENUM).map(
      (type) => RegimeEspecialENUM[type]
    );
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>
