<template>
  <div class="container-fluid">
    <page-tabs :tabs="tabs" />
    <component
      v-for="(tab, i) in tabs"
      v-if="tab.key === activeTab"
      :key="tab.key"
      :ref="tab.key"
      :is="tab.key.toLowerCase().split('_').join('-')"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CCEE from "./components/forms/CCEE";
import Gestor from "./components/forms/Gestor";
import Anexos from "./components/forms/Anexos";
import Contatos from "./components/forms/Contatos";
import { HTTP_RESPONSE } from "@/constants/strings";
import PosVendas from "./components/forms/PosVendas";
import PageTabs from "@/components/navigation/PageTabs";
import DadosGerais from "./components/forms/DadosGerais";
import PontosMedicao from "./components/forms/PontosMedicao";
import DadosBancarios from "./components/forms/DadosBancarios";
import EstruturaRateioWbc from "./components/forms/EstruturaRateioWbc";
import InactiveTabsByFieldsENUM from "@/enums/InactiveTabsByFieldsENUM";
export default {
  data() {
    return {
      empresaId: null,
      activeTab: "DADOS_GERAIS",
      tabs: new Array(
        { title: "Dados Gerais", key: "DADOS_GERAIS", active: true },
        { title: "Contatos", key: "CONTATOS", active: true },
        { title: "Dados Bancários", key: "DADOS_BANCARIOS", active: true },
        { title: "Pós-Vendas", key: "POS_VENDAS", active: true },
        { title: "Gestor", key: "GESTOR", active: true },
        { title: "CCEE", key: "CCEE", active: true },
        {
          title: "Unidades Consumidoras",
          key: "PONTOS_DE_MEDICAO",
          active: true,
        },
        {
          title: "Estr. Rateio WBC",
          key: "ESTRUTURA_RATEIO_WBC",
          active: true,
        },
        { title: "Anexos", key: "ANEXOS", active: true }
      ),
    };
  },
  destroyed() {
    this.ActionResetEmpresaEditing();
  },
  created() {
    this.empresaId = this.$router.history.current.params.id;
  },
  methods: {
    ...mapActions(["ActionResetEmpresaEditing"]),
    async changeTab(nextTab, empresaId = null) {
      if (empresaId) {
        this.empresaId = empresaId;
      }

      if (nextTab != this.activeTab) {
        if (this.activeTab === "DADOS_GERAIS") {
          try {
            if (
              this.$can("CENTRAL_CADASTROS_EDITAR") ||
              this.$can("CENTRAL_CADASTROS_APROVAR") ||
              this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
            ) {
              await this.$refs.DADOS_GERAIS[0][
                this.empresaId ? "update" : "save"
              ](true);
            }
            this.activeTab = nextTab;
            this.mxResetErrorPendencies();
          } catch (error) {
            if (error) {
              let status = 500;
              if (error.response) {
                status = error.response.status;
              }
              this.mxToggleErrorMessageAlert(HTTP_RESPONSE.GENERIC[status]);
            }
          }
        } else {
          this.activeTab = nextTab;
          this.mxResetErrorPendencies();
        }
      }
    },
    inactiveTabs(model) {
      const empresasInativas = new Set();

      Object.keys(InactiveTabsByFieldsENUM).forEach((v) => {
        if (!eval(v)) return;
        if (InactiveTabsByFieldsENUM[v].values[eval(v)]) {
          InactiveTabsByFieldsENUM[v].values[eval(v)].forEach((v2) =>
            empresasInativas.add(v2)
          );
        }
      });
      this.tabs.forEach((tab) => {
        tab.active =
          !model.tipo.restrictions.tabs.includes(tab.key) &&
          !empresasInativas.has(tab.key);
      });
    },
  },
  components: {
    ccee: CCEE,
    Anexos,
    Gestor,
    PageTabs,
    Contatos,
    PosVendas,
    DadosGerais,
    PontosDeMedicao: PontosMedicao,
    DadosBancarios,
    EstruturaRateioWbc,
  },
};
</script>
