<template>
  <div v-async="loading">
    <div class="row">
      <div class="col-md-6 order-2 order-md-1">
        <form-row-header text="Dados Pessoais" />
      </div>
      <div
        class="col-md-6 order-1 order-md-2 d-flex d-flex justify-content-end"
      >
        <button
          v-if="
            !isUserLoggedIn &&
            $can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
          "
          @click="toggleLockUser"
          :class="['btn', model.ativo ? 'btn-bloq' : 'btn-desbloq']"
          :disabled="model.id == null"
        >
          {{ model.ativo ? "Bloquear" : "Desbloquear" }}
        </button>
      </div>
    </div>
    <div class="row">
      <nome-field
        :disabled="
          (isCreated && !isUserLoggedIn) ||
          !$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
        "
        :modelProp="model"
        ref="nomeField"
      />
      <email-field
        :disabled="
          isCreated || !$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
        "
        :modelProp="model"
        ref="emailField"
      />
    </div>
    <div class="row mt-3">
      <telefone-fixo-field
        :disabled="
          !this.model.ativo ||
          !$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
        "
        :modelProp="model"
        ref="telefoneFixoField"
      />
      <telefone-celular-field
        :disabled="
          !this.model.ativo ||
          !$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
        "
        :modelProp="model"
        ref="telefoneCelularField"
      />
      <cargo-field
        :disabled="
          !this.model.ativo ||
          !$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
        "
        :colLG="6"
        :modelProp="model"
        ref="cargoField"
      />
    </div>
    <div class="row mt-3">
      <grupo-de-acesso-field
        :disabled="!$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')"
        :modelProp="model"
        ref="acessoField"
      />
    </div>

    <div class="row mt-3">
      <div class="col-12">Dashboards</div>
      <div
        class="col-12"
        :key="`dashboard_${index}`"
        v-for="(dashboard, index) in powerBiDashboards"
      >
        <label class="w-auto mt-2">
          <input
            :disabled="!$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')"
            type="checkbox"
            :checked="model.dashboardsPermitidos.includes(dashboard.id)"
            @click.stop="checkDashboard(dashboard, index)"
          />
          <span class="ms-2">{{ dashboard.reportName }}</span>
        </label>
      </div>
    </div>

    <div class="row mt-5">
      <div
        class="col col-lg-4 ms-auto d-flex align-items-center justify-content-end"
      >
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'BuscarUsuario' })"
        >
          Cancelar
        </button>
        <button
          v-if="
            isCreated && $can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')
          "
          class="btn btn-primary ms-3"
          @click="update"
        >
          Atualizar
        </button>
        <button
          v-else-if="$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')"
          class="btn btn-primary ms-3"
          @click="save"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MxUserUtils from "@/mixins/mxUserUtils";
import { formIsValid } from "@/utils/validators";
import { HTTP_RESPONSE } from "@/constants/strings";
import { setFieldsData } from "@/utils/functionUtils";
import UsuarioService from "@/services/portal/UsuarioService.js";
import PowerBiService from "@/services/portal/PowerBiService.js";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import DadosPessoaisModel from "@/models/perfil/DadosPessoaisModel";
import NomeField from "@/components/forms/fields/empresa/NomeField.vue";
import CnpjField from "@/components/forms/fields/empresa/CnpjField.vue";
import EmailField from "@/components/forms/fields/empresa/EmailField.vue";
import CargoField from "@/components/forms/fields/empresa/CargoField.vue";
import TelefoneFixoField from "@/components/forms/fields/empresa/TelefoneFixoField.vue";
import GrupoDeAcessoField from "@/components/forms/fields/empresa/GrupoDeAcessoField.vue";
import TelefoneCelularField from "@/components/forms/fields/empresa/TelefoneCelularField.vue";
export default {
  data() {
    return {
      loading: undefined,
      isCreated: false,
      selectedDashboards: new Array(),
      powerBiDashboards: new Array(),
      model: new DadosPessoaisModel(),
    };
  },
  mounted() {
    const { id } = this.$router.history.current.params;

    if (id) this.isCreated = true;

    const queries = new Array(
      this.mountPowerBI(),
      !!id && UsuarioService.getUser({ id })
    );
    this.loading = Promise.all(queries)
      .then((response) => {
        if (response[0]) {
          const data = response[0];
          this.powerBiDashboards = data;
        }
        if (response[1]) {
          const { data } = response[1];
          if (data.ativo) {
            this.model = new DadosPessoaisModel({
              ...data,
              role: {
                id: data.role.id,
                ativo: data.role.ativo,
                nome: data.role.nome,
              },
            });
            if (this.model.dashboardsPermitidos.length > 0) {
              this.selectedDashboards = new Array(
                ...this.model.dashboardsPermitidos
              );
            }
          } else {
            this.model = new DadosPessoaisModel(data);
          }
          this.setBreadcrumbsParam("Usuários");
          setFieldsData(this, this.model);
        }
      })
      .catch((error) => this.mxHandleRequestError(error, "USUARIO"));
  },
  computed: {
    ...mapGetters(["getUsuario"]),
    isUserLoggedIn() {
      return this.model.email === this.getUsuario.email;
    },
  },
  mixins: [MxUserUtils],
  methods: {
    ...mapActions(["ActionToggleMessageAlert"]),
    mountPowerBI() {
      return new Promise((resolve, reject) => {
        PowerBiService.getDashboards()
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject();
          });
      });
    },
    checkDashboard(dashboard) {
      if (this.selectedDashboards.includes(dashboard.id)) {
        this.selectedDashboards = this.selectedDashboards.filter(
          (dash) => dash != dashboard.id
        );
      } else {
        this.selectedDashboards.push(dashboard.id);
      }
      if (this.model.dashboardsPermitidos.includes(dashboard.id)) {
        this.model.dashboardsPermitidos =
          this.model.dashboardsPermitidos.filter(
            (dash) => dash != dashboard.id
          );
      } else {
        this.model.dashboardsPermitidos.push(dashboard.id);
      }
    },
    save() {
      if (formIsValid(this)) {
        this.mxResetErrorPendencies();
        this.loading = UsuarioService.register(this.model.getData())
          .then(() => {
            this.mxToggleToast(HTTP_RESPONSE.USUARIO.SUCCESS.CREATE);
            this.$router.push({ name: "BuscarUsuario" });
          })
          .catch((error) => this.mxHandleRequestError(error, "USUARIO"));
      }
    },
    toggleLockUser() {
      if (!this.model.ativo) {
        if (!formIsValid(this)) return;
      }
      this.model.ativo = !this.model.ativo;
      this.loading = UsuarioService.update(this.model.getData())
        .then(() => {
          this.mxToggleToast(HTTP_RESPONSE.USUARIO.SUCCESS.UPDATE);
          this.$router.push({ name: "BuscarUsuario" });
        })
        .catch((error) => this.mxHandleRequestError(error, "USUARIO"));
    },
    update() {
      const { id } = this.$router.history.current.params;
      if (formIsValid(this)) {
        this.loading = UsuarioService.update(this.model.getData())
          .then(async () => {
            if (id === this.getUsuario.id) {
              await this.mxGetUserDetails(id, false);
            }
            this.mxToggleToast(HTTP_RESPONSE.USUARIO.SUCCESS.UPDATE);
            this.$router.push({ name: "BuscarUsuario" });
          })
          .catch((error) => this.mxHandleRequestError(error, "USUARIO"));
      }
    },
  },
  components: {
    CnpjField,
    NomeField,
    EmailField,
    CargoField,
    FormRowHeader,
    UsuarioService,
    TelefoneFixoField,
    GrupoDeAcessoField,
    TelefoneCelularField,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.btn-bloq {
  color: white;
  background: $status--red !important;
  &:hover {
    color: white;
  }
}

.btn-desbloq {
  color: white;
  background: $status--green !important;
  &:hover {
    color: white;
  }
}
</style>
