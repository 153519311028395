<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="setorField">
        Setor
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          id="setorField"
          v-model.trim="model.content"
          class="form-select"
          :class="{
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField,
          }"
        >
          <option
            :value="setor"
            v-for="(setor, setorIndex) in list"
            :key="`setor_option_${setorIndex}`"
          >
            {{ setor.nome }}
          </option>
        </select>
        <div class="form-text text-setor">Setor econômico.</div>
      </label>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "setor",
        content: "",
      },
      loadList: undefined,
      list: new Array(),
    };
  },
  mounted() {
    this.loadList = EmpresaService.listSetor()
      .then(
        ({ data }) => (this.list = data.filter((parametro) => parametro.ativo))
      )
      .catch((error) => console.log(error));
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (this.invalidYellow) {
            return true;
          }
          return false;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

.text-setor {
  width: 210px;
}
</style>
