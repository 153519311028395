<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="cnpjField">
        CNPJ <span class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          :disabled="disabled"
          class="form-control"
          v-mask="'99.999.999/9999-99'"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="cnpjField"
          v-model.trim="$v.model.content.$model"
        />
        <div class="form-text">Somente números.</div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { isCnpj } from "@/filters/assertions";
import { required } from "vuelidate/lib/validators";
import { validateCNPJ } from "@/utils/functionUtils";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: {
    editing: Boolean,
    modelProp: Object,
    disabled: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "cnpj",
        content: "",
      },
    };
  },
  watch: {
    "model.content": {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal) {
          if (oldVal.replace(/[^\d]/g, "") != newVal.replace(/[^\d]/g, "")) {
            this.modelProp[this.model.name] = this.model.content;
            if (
              !this.editing &&
              ![undefined, "", null].includes(this.model.content) &&
              this.model.content.replace(/[^\d]/g, "").length === 14
            ) {
              if (!this.$v.$invalid) {
                this.$parent.getEmpresaDataByCNPJ();
              }
            }
          }
        } else {
          this.modelProp[this.model.name] = this.model.content;
          if (
            !this.editing &&
            ![undefined, "", null].includes(this.model.content) &&
            this.model.content.replace(/[^\d]/g, "").length === 14
          ) {
            if (!this.$v.$invalid) {
              this.$parent.getEmpresaDataByCNPJ();
            }
          }
        }
      },
    },
    modelProp: {
      deep: true,
      immediate: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
  },
  directives: {
    mask: AwesomeMask,
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo CNPJ inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        isCnpj,
        required,
        validateCNPJ,
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
