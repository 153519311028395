<template>
  <div v-element_loading="loading">
    <switch-acessar-field
      title="Acessar Central de Cadastro"
      :disabled="!$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />

    <roles-manager
      subtype="PERMISSOES_GERAIS"
      type="CENTRAL_CADASTROS"
      key="role-manager-PERMISSOES_GERAIS"
      title="Gestão de Cadastros"
      :clearPermissoes="clearPermissoes"
      :disabled="!acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />
    <roles-manager
      subtype="USUARIOS"
      type="CENTRAL_CADASTROS"
      key="role-manager-USUARIOS"
      title="Gestão de Usuários"
      :clearPermissoes="clearPermissoes"
      :disabled="!acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />
    <roles-manager
      subtype="GRUPOS_ACESSO"
      type="CENTRAL_CADASTROS"
      key="role-manager-GRUPOS_ACESSO"
      title="Gestão de Grupos de Acesso"
      :clearPermissoes="clearPermissoes"
      :disabled="!acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />

    <footer>
      <cc-barra-salvar-cancelar
        @save="save"
        @cancel="$router.push({ name: 'BuscaGrupoAcesso' })"
      />
    </footer>
  </div>
</template>
<script>
import { HTTP_RESPONSE } from "@/constants/strings";
import RolesManager from "../components/RolesManager.vue";
import RolesServices from "@/services/portal/RolesService";
import ComboRolesManager from "../components/RolesComboManager.vue";
import GrupoAcessoModel from "@/models/grupo-acesso/GrupoAcessoModel";
import SwitchAcessarField from "../components/SwitchAcessarField.vue";
export default {
  data() {
    return {
      acessar: false,
      firstCheck: false,
      loading: undefined,
      clearPermissoes: false,
      model: GrupoAcessoModel,
    };
  },
  mounted() {
    this.getRole();
  },
  methods: {
    getRole() {
      this.loading = RolesServices.getRoleById(this.$route.params.id)
        .then(({ data }) => {
          this.model.setData(data);
          !this.isCreated ? this.setHeaderText(data.nome) : null;
          this.acessar = this.model.havePermissaoAcessar("CENTRAL_CADASTROS");
          this.firstCheck =
            !this.model.havePermissaoAcessar("CENTRAL_CADASTROS");
          this.$forceUpdate();
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    save() {
      this.loading = RolesServices.updatePermissions(this.model.getData())
        .then(() => {
          this.mxToggleToast(HTTP_RESPONSE.GRUPO_ACESSO.SUCCESS.UPDATE);
          this.getRole();
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  watch: {
    acessar() {
      if (!this.acessar) {
        this.model.changePermissaoAcesso("CENTRAL_CADASTROS", false);
        this.model.clearPermissoes("CENTRAL_CADASTROS");
        this.clearPermissoes = true;
      } else {
        if (this.firstCheck) {
          this.model.clearPermissoes("CENTRAL_CADASTROS");
          this.clearPermissoes = true;
          this.firstCheck = false;
        }
        this.model.changePermissaoAcesso("CENTRAL_CADASTROS", true);
      }
    },
  },
  components: {
    RolesManager,
    ComboRolesManager,
    SwitchAcessarField,
  },
};
</script>
<style lang="scss" scoped></style>
