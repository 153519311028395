var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pre-input-group"},[_c('div',{staticClass:"input-group",class:{
      'is-invalid': _vm.$v.model.content.$error,
      'file-attached': _vm.IS_FILE_ATTACHED && !_vm.$v.model.content.$error,
      'is-dragging': _vm.isDragging,
      disabled: _vm.disabled,
    }},[_c('span',[(_vm.model.content)?[_c('i',{staticClass:"icon-file"}),_vm._v(" "+_vm._s(_vm.model.content.name)+" ")]:[_c('i',{staticClass:"icon-download"}),_vm._v(" Arraste e solte arquivos aqui ou clique para enviar ")]],2),_c('input',{ref:"inputUploadField",attrs:{"type":"file","disabled":_vm.disabled},on:{"change":_vm.changeFile,"dragenter":function($event){_vm.isDragging = true},"dragleave":function($event){_vm.isDragging = false},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.drop.apply(null, arguments)}}})]),(_vm.getLayoutFile !== null)?_c('button',{class:{ disabled: _vm.loadingLayout },attrs:{"disabled":_vm.loadingLayout},on:{"click":_vm.downloadLayoutFile}},[_c('div',[(_vm.loadingLayout)?_c('overlay-loader',{attrs:{"styles":{
          spinnerWrapper: {
            width: '30px',
            height: '30px',
          },
        }}}):_vm._e(),_c('i',{staticClass:"icon-descarregar small"}),_c('span',[_vm._v("Baixar template de Excel")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }