<template>
  <div :class="['col', `col-lg-${cols}`, 'mt-3']">
    <label for="searchBar">
      <b>{{ title }}</b>
      <div class="input-group">
        <input
          :disabled="disabled"
          @keyup.enter="$emit('doSearch', content, true)"
          class="form-control"
          v-model="content"
          :placeholder="placeholder"
          id="searchBar"
        />
        <div>
          <button
            :disabled="disabled"
            @click="$emit('doSearch', content, true)"
            class="btn btn-primary rounded-0 rounded-end"
            type="button"
          >
            Buscar
          </button>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    cols: {
      type: Number,
      required: true,
      default: 5,
    },
    title: {
      type: String,
      required: true,
      default: "Busca",
    },
  },
  data() {
    return {
      content: "",
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}
</style>
