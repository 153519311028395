<template>
  <div v-async="loading">
    <div class="row">
      <div class="col-md-6">
        <div>
          <search-bar
            ref="searchBar"
            title="Buscar Usuário"
            @doSearch="doSearch"
            :cols="10"
          />
          <div class="form-text">Pesquise por Nome</div>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-center justify-content-end">
        <button
          v-if="$can('CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR')"
          @click="addNewUser"
          class="btn btn-primary-blue"
        >
          + Adicionar Usuário
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table-usuario-cadastrados
          @selectUser="selectUser"
          @setPage="setPage"
          :config="tableConfig"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";
import DataTable from "@/components/tables/Table.vue";
import UsuarioService from "@/services/portal/UsuarioService.js";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import TableUsuarioCadastrados from "@/components/tables/TableUsuarioCadastrados.vue";

export default {
  data() {
    return {
      loading: undefined,
      ativo: false,
      tableConfig: {
        conteudo: new Array(),
        pagination: undefined,
      },
      filter: {
        page: 0,
        size: 10,
      },
    };
  },
  mounted() {
    this.setBreadcrumbsParam("Usuários");
    this.doSearch();
  },
  methods: {
    addNewUser() {
      this.$router.push({ name: "UsuarioCadastrar" });
    },
    selectUser(id) {
      this.$router.push({ name: "Usuario", params: { id } });
    },
    setPage(page) {
      this.filter.page = page;
      this.doSearch();
    },
    doSearch(search = this.$refs.searchBar.content, resetPage = false) {
      const { size, page } = this.filter;
      this.loading = UsuarioService.list({
        filtro: search,
        size,
        page: resetPage ? 0 : page,
      })
        .then((response) => {
          this.tableConfig.conteudo = response.data.content;
          this.tableConfig.pagination = response.data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  components: {
    DataTable,
    SearchBar,
    FormRowHeader,
    TableUsuarioCadastrados,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.text {
  @include phablet {
    margin-top: 10px;
  }
}
</style>
