<template>
  <div>
    <section>
      <div class="row mt-5 d-flex justify-content-start">
        <div class="col-4">
          <label for="pos-vendas-nome">
            Nome:
            <div class="input-group">
              <input
                maxlength="64"
                id="pos-vendas-nome"
                class="form-control"
                v-model.trim="$v.model.nome.$model"
              />
            </div>
          </label>
        </div>
        <div class="col-4">
          <label for="pos-vendas-email">
            Email:
            <div class="input-group">
              <input
                maxlength="64"
                class="form-control"
                id="pos-vendas-email"
                v-model.trim="$v.model.email.$model"
              />
            </div>
          </label>
        </div>
        <div class="col-2 align-self-end">
          <button
            type="button"
            class="btn btn-primary"
            @click="createParametro"
            :disabled="$v.model.nome.$invalid || $v.model.email.$invalid"
          >
            Adicionar
          </button>
        </div>
      </div>
      <transition-group name="list" tag="ul" mode="in-out" class="list-params">
        <li
          v-for="(parametro, indexParametro) in list"
          :key="`indexParametro${indexParametro}`"
        >
          <div>
            <span
              class="pe-3 small text-break"
              :class="{ 'text-secondary': !parametro.ativo }"
            >
              {{ parametro.nome }}
            </span>
            <span
              style="width: 47%"
              class="pe-3 small text-break"
              :class="{ 'text-secondary': !parametro.ativo }"
            >
              {{ parametro.email }}
            </span>

            <div
              style="width: 6%"
              class="d-flex align-items-center justify-content-between"
            >
              <i
                v-if="parametro.ativo"
                style="font-size: 0.8em"
                class="icon-eye cursop me-1"
                @click="switchParameterActuation(parametro)"
              ></i>
              <i
                v-else
                class="icon-eye-closed cursop"
                @click="switchParameterActuation(parametro)"
              ></i>
              <!-- <i
                class="icon-lapis cursop ms-2"
                @click="updateParametro(parametro)"
              ></i> -->
              <i
                class="icon-lixeira cursop ms-2"
                @click="removeParametro(parametro)"
              ></i>
            </div>
          </div>
        </li>
      </transition-group>
    </section>
  </div>
</template>
<script>
import { isValidEmail } from "@/filters/assertions";
import { required } from "vuelidate/lib/validators";
import ParametrosENUM from "@/enums/ParametrosENUM";
import ParametroTemplate from "../ParametroTemplate.vue";
export default {
  extends: ParametroTemplate,
  data() {
    return {
      parametro: ParametrosENUM.POS_VENDAS,
      model: {
        id: null,
        ativo: true,
        nome: "",
        email: "",
      },
      list: new Array(),
    };
  },
  computed: {
    ParametrosENUM: () => ParametrosENUM,
  },
  mounted() {
    this.listParametro();
  },
  validations: {
    model: {
      nome: {
        required,
      },
      email: {
        required,
        isValidEmail,
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}

.list-params {
  width: 75%;
  padding: 0px;
  margin-top: 16px;
  li {
    display: flex;
    justify-content: space-between;
    div {
      &:first-child {
        padding: 8px 0px 8px 0px;
        border-bottom: 1px solid #e8e8e8;
        width: 100%;
        display: flex;
      }
      span {
        &:first-child {
          width: 45%;
        }
      }
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
