export default {
    ISENTO: {
        id: "ISENTO",
        nome: "Isento",
    },
    CONTRIBUINTE: {
        id: "CONTRIBUINTE",
        nome: "Contribuinte",
    },
    VERIFICAR: {
        id: "VERIFICAR",
        nome: "Verificar",
    },
    NAO_LOCALIZADO: {
        id: "NAO_LOCALIZADO",
        nome: "Não Localizado",
    }
}