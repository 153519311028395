<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="estadoCivilField">
        Estado Civil 
        <!-- <span v-if="isRequired" class="required-markup">*</span> -->
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          class="form-select"
          :disabled="disabled"
          id="estadoCivilField"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          v-model.trim="$v.model.content.$model"
        >
          <option
            v-for="(estadoCivil, estadoCivilIndex) in list"
            :key="`estado_civil_${estadoCivilIndex}`"
            :value="estadoCivil"
          >
            {{ estadoCivil.nome }}
          </option>
        </select>
        <div class="form-text">Escolha uma das opções.</div>
      </label>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import ContatoService from "@/services/portal/empresa/ContatoService";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
    isRequired: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      loadList: undefined,
      list: new Array(),
      model: {
        name: "estadoCivil",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Estado Civil inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        // required: requiredIf(function () {
        //   return this.isRequired;
        // }),
      },
    },
  },
  mounted() {
    this.loadList = ContatoService.listEstadoCivil()
      .then(
        ({ data }) =>
          (this.list = data.filter((estadoCivil) => estadoCivil.ativo))
      )
      .catch((error) => console.log(error));
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
