<template>
  <div>
    <div class="row">
      <endereco-cep-field
        :disabled="disabled"
        ref="enderecoCep"
        @updateEnderecoFields="updateEnderecoFields"
        :modelProp="model.content"
        :invalidYellow="invalidYellow(model.content.cep)"
        :recentUpdatedField="recentUpdatedField('cep')"
      />
      <endereco-logradouro-field
        :disabled="disabled"
        ref="enderecoLogradouro"
        :modelProp="model.content"
        :invalidYellow="invalidYellow(model.content.logradouro)"
        :recentUpdatedField="recentUpdatedField('logradouro')"
      />
      <endereco-numero-field
        :disabled="disabled"
        ref="enderecoNumero"
        :modelProp="model.content"
        :invalidYellow="invalidYellow(model.content.numero)"
        :recentUpdatedField="recentUpdatedField('numero')"
      />
      <endereco-complemento-field
        :disabled="disabled"
        ref="enderecoComplemento"
        :modelProp="model.content"
        :invalidYellow="invalidYellow(model.content.complemento)"
        :recentUpdatedField="recentUpdatedField('complemento')"
      />
    </div>
    <div class="row mt-3">
      <endereco-bairro-field
        :disabled="disabled"
        ref="enderecoBairro"
        :modelProp="model.content"
        :invalidYellow="invalidYellow(model.content.bairro)"
        :recentUpdatedField="recentUpdatedField('bairro')"
      />
      <endereco-cidade-field
        :list="cidadesList"
        ref="enderecoCidade"
        :modelProp="model.content"
        :loading="cidadeFieldLoading"
        :disabled="disabled"
        :recentUpdatedField="recentUpdatedField('cidade')"
        :invalidYellow="invalidYellow(model.content.cidade)"
      />
      <endereco-estado-field
        :disabled="disabled"
        ref="enderecoEstado"
        :modelProp="model.content"
        @estadoUpdated="findCidadesFromEstado"
        :recentUpdatedField="recentUpdatedField('estado')"
        :invalidYellow="invalidYellow(model.content.estado)"
      />
      <endereco-pais-field
        ref="enderecoPais"
        :disabled="disabled"
        :modelProp="model.content"
        :recentUpdatedField="recentUpdatedField('pais')"
        :invalidYellow="invalidYellow(model.content.pais)"
      />
    </div>
    <div
      style="
        right: 0px;
        bottom: 50px;
        position: absolute;
        z-index: 999 !important;
      "
    ></div>
  </div>
</template>

<script>
import { formIsValid } from "@/utils/validators";
import EnderecoModel from "@/models/empresa/EnderecoModel";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import EnderecoCepField from "@/components/forms/fields/empresa/endereco/EnderecoCepField.vue";
import EnderecoPaisField from "@/components/forms/fields/empresa/endereco/EnderecoPaisField.vue";
import EnderecoNumeroField from "@/components/forms/fields/empresa/endereco/EnderecoNumeroField.vue";
import EnderecoBairroField from "@/components/forms/fields/empresa/endereco/EnderecoBairroField.vue";
import EnderecoCidadeField from "@/components/forms/fields/empresa/endereco/EnderecoCidadeField.vue";
import EnderecoEstadoField from "@/components/forms/fields/empresa/endereco/EnderecoEstadoField.vue";
import EnderecoLogradouroField from "@/components/forms/fields/empresa/endereco/EnderecoLogradouroField.vue";
import EnderecoComplementoField from "@/components/forms/fields/empresa/endereco/EnderecoComplementoField.vue";
export default {
  data() {
    return {
      cidadeFieldDisabled: true,
      cidadeFieldLoading: false,
      cidadesList: new Array(),
      model: {
        name: "endereco",
        content: new EnderecoModel(),
      },
    };
  },
  props: {
    disabled: Boolean,
    modelProp: Object,
    validateYellow: Boolean,
    recentUpdatedFields: Object,
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (this.modelProp.mounting == true) return;
        this.modelProp[this.model.name] = this.model.content;
        if (this.model.content.estado == null) this.disableLoading();
      },
    },
    "modelProp.endereco.estado": {
      deep: true,
      immediate: true,
      handler() {
        if (this.modelProp?.endereco?.estado?.id) {
          this.cidadeFieldDisabled = false;
          this.findCidadesFromEstado(this.modelProp.endereco.estado.id);
        }
      },
    },
    modelProp: {
      deep: true,
      handler() {
        if (this.modelProp.mounting) return;
        this.model.content = this.modelProp[this.model.name];
      },
    },
  },
  methods: {
    disableLoading() {
      this.$emit("setCidadesToLoadedState");
    },
    async findCidadesFromEstado(estado) {
      try {
        this.cidadeFieldLoading = true;
        const { data } = await EmpresaService.listCidades({ estado });
        this.cidadesList = data;
        await this.$nextTick();
        if (this.$refs.enderecoCidade) {
          this.$refs.enderecoCidade.setContent();
        }
      } catch (error) {
        this.mxHandleRequestError(error);
      } finally {
        this.cidadeFieldLoading = false;
        this.$emit("setCidadesToLoadedState");
      }
    },
    invalidYellow(field) {
      return [null, undefined, "", []].includes(field) && !!this.modelProp.id;
    },
    recentUpdatedField(field) {
      return this.recentUpdatedFields.campos
        ? this.recentUpdatedFields.campos
            .map((item) => {
              if (item.includes("endereco")) {
                return item.split("endereco")[1].toLowerCase();
              }
              return item;
            })
            .includes(field) && !!this.modelProp.id
        : false;
    },
    updateEnderecoFields(Endereco) {
      this.$emit("updateEndereco", Endereco);
    },
    isInvalid() {
      return !formIsValid(this);
    },
  },
  components: {
    EnderecoCepField,
    EnderecoPaisField,
    EnderecoNumeroField,
    EnderecoBairroField,
    EnderecoCidadeField,
    EnderecoEstadoField,
    EnderecoLogradouroField,
    EnderecoComplementoField,
  },
};
</script>


<style></style>

