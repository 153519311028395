import MeuPerfil from "@/pages/perfil/Perfil.vue";
const routes = [
    {
        path: "/perfil",
        name: "Perfil",
        component: MeuPerfil,
        meta: {
            auth: true,
            breadcrumb: new Array("Meu Perfil"),
            permissions: new Array(),
        }
    },
];

export default routes;