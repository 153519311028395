import ServicesPortal from "../ServicesPortal";
import RegistroHistoricoPosVendaModel from "@/models/empresa/RegistroHistoricoPosVendaModel";

class EmpresaPosVendaHistoricoService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  getHistorico(request) {
    this._url = `/pos-venda/historico`;
    return this.get(request);
  }

  getHistoricoByEmpresa(request) {
    this._url = `/pos-venda/historico/${request.empresaId}`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data.content && response.data.content.length > 0) {
          response.data.content = response.data.content.map(
            (registroHistoricoPosVenda) => {
              return new RegistroHistoricoPosVendaModel({
                id: registroHistoricoPosVenda.id,
                dataInicio: registroHistoricoPosVenda.inicioRelacionamento,
                dataFim: registroHistoricoPosVenda.terminoRelacionamento,
                agentePosVenda: registroHistoricoPosVenda.nomePosVenda,
                atual: false,
              });
            }
          );
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new EmpresaPosVendaHistoricoService("empresas");
