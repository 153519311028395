<template>
  <div class="comp-help">
    <div>
      <slot name="tooltip-container" />
    </div>
    <div v-if="text || icon" class="comp-help__icon" :class="`${icon}`">
      {{ text }} <span class="subText">{{ subtext }}</span>
    </div>
    <div v-if="renderMessage">
      <div
        :class="[
          { 'mt-3': hasTooltipContainerSlot },
          { 'comp-help-arrow-left': arrowAlign === 'left' },
          { 'comp-help-arrow-right': arrowAlign === 'right' },
          'comp-help-arrow',
        ]"
      ></div>
      <div
        :style="`width:${width}px;`"
        :class="[
          { 'mt-3': hasTooltipContainerSlot },
          { 'comp-help__box-message--left': boxAlign === 'left' },
          { 'comp-help__box-message--right': boxAlign === 'right' },
          'comp-help__box-message',
        ]"
      >
        <span class="comp-help__message">
          <slot name="tooltip-text" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 250,
    },
    textAlign: {
      type: String,
      required: false,
      default: "left",
    },
    boxAlign: {
      type: String,
      required: false,
      default: "left",
    },
    arrowAlign: {
      type: String,
      required: false,
      default: "left",
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    subtext: {
      type: String,
      required: false,
      default: "",
    },
    renderMessage: {
      type: Boolean,
      default: true,
    },
    icon: {
      default: "",
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasTooltipContainerSlot() {
      return !!this.$slots["tooltip-container"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

.comp-help {
  position: relative;
  &:hover .comp-help__box-message {
    display: block;
  }
  &:hover .comp-help-arrow {
    display: block;
  }
}

.comp-help__box-message {
  top: 35px;
  z-index: 999;
  display: none;
  text-align: left;
  padding-top: 15px;
  position: absolute;
  border-radius: 2px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  word-wrap: break-word;
  background-color: $primary--black;
}

.comp-help__message {
  color: #fff;
  font-size: 12px;
  line-height: 22px;
}

.comp-help-arrow {
  top: 1.563rem;
  right: 10rem;
  display: none;
  z-index: 999;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba($color: $primary--black, $alpha: 1);
}

.comp-help-arrow-left {
  right: 10rem;
}

.comp-help-arrow-right {
  left: 3rem;
}

.comp-help__box-message--left {
  right: -10px;
}

.comp-help__box-message--right {
  left: -10px;
}

.subText {
  font-size: 0.7rem;
  color: $primary--orange;
}
</style>
