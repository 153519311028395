<template>
  <div class="mt-4 wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th
            scope="col"
            class="cursop"
            @click="$emit('sortBy', 'nome')"
          >
            Nome do Arquivo
          </th>
          <th
            scope="col"
            class="cursop"
            @click="$emit('sortBy', 'empresaArquivoTipo.nome')"
          >
            Tipo do Anexo
          </th>
          <th scope="col">Data da inclusão</th>
          <th scope="col">Validade</th>
          <th scope="col">Descrição ou Comentário</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <transition-group
        name="list"
        mode="in-out"
        tag="tbody"
        v-if="anexosList.length > 0"
      >
        <tr
          v-for="(arquivo, arquivoIndex) in anexosList"
          :key="`anexo_${arquivoIndex}`"
        >
          <td>
            <i class="icon-file"></i>
            {{ arquivo.getFileName() }}
          </td>
          <td>{{ arquivo.tipo.nome }}</td>
          <td>{{ arquivo.dataCriacao | DATE_ptBR }}</td>
          <td>{{ arquivo.dataValidade | DATE_ptBR }}</td>
          <td class="text-break w-50">{{ arquivo.descricao | EMPTY }}</td>
          <td class="pointer" @click.stop="$emit('downloadArquivo', arquivo)">
            <i class="icon-descarregar" />
          </td>
          <td
            v-if="!disabled"
            class="pointer"
            @click.stop="$emit('deleteArquivo', arquivo)"
          >
            <i class="icon-lixeira" />
          </td>
        </tr>
      </transition-group>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="5">Nenhum arquivo adicionado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { EMPTY, DATE_ptBR } from "@/filters/formatters";
export default {
  filters: {
    EMPTY,
    DATE_ptBR,
  },
  props: {
    disabled: Boolean,
    anexosList: {
      type: Array,
      required: true,
      default() {
        return new Array();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon {
  cursor: pointer;
  color: $primary--orange !important;
}

th {
  font-weight: 500;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}
</style>
