<template>
  <div class="col col-lg-5">
    <div class="input-group">
      <label for="classeAgenteField">
        Classe do Agente (tipo)
        <span
          class="recent-updated-label"
          v-if="recentUpdatedField && !isDisabled"
        >
          <tooltip-informacao-alterada />
        </span>
        <select
          :disabled="isDisabled"
          id="classeAgenteField"
          v-model.trim="model.content"
          class="form-select"
          :class="{
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField && !isDisabled,
          }"
        >
          <option
            v-for="(classeAgente, classeAgenteIndex) in list"
            :key="`classe_agente_${classeAgenteIndex}`"
            :value="classeAgente"
          >
            {{ classeAgente.nome }}
          </option>
        </select>
        <div class="form-text">Escolha na lista.</div>
      </label>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import ClasseAgenteEmpresaENUM from "@/enums/ClasseAgenteEmpresaENUM";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      list: new Array(),
      model: {
        name: "classeAgente",
        content: "",
      },
    };
  },
  mounted() {
    this.list = Object.keys(ClasseAgenteEmpresaENUM).map(
      (type) => ClasseAgenteEmpresaENUM[type]
    );
  },
  computed: {
    isDisabled() {
      if (!this.$parent.model.tipo) return false;
      if (
        this.$parent.model.tipo.restrictions.fields.includes(this.model.name)
      ) {
        this.model.content = null;
        return true;
      }
      if (this.disabled) return true;
      return false;
    },
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (this.invalidYellow) {
            return true;
          }
          return false;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
