import ClasseAgenteEmpresaENUM from "@/enums/ClasseAgenteEmpresaENUM";
import DemonstrativoFaturamentoENUM from "@/enums/DemonstrativoFaturamentoENUM";
import EmpresaConfiguracaoDemonstrativoENUM from "@/enums/EmpresaConfiguracaoDemonstrativoEnum";
import EmpresaTipoENUM from "@/enums/EmpresaTipoENUM";
import InscricaoEstadualTypeENUM from "@/enums/InscricaoEstadualTypeENUM";
import RecebeNotaFiscalENUM from "@/enums/RecebeNotaFiscalENUM";
import TipoContatoENUM from "@/enums/TipoContatoENUM";
import ContatoModel from "@/models/empresa/ContatoModel";
import { DateTime } from "luxon";

/**
 * @description - Make a deep copy of an Object to a new instance with the same values;
 * @param {Object} value - Object to be copied;
 * @returns {Object} - A new instance of the same object;
 */
export const makeDeepCopy = (value = new Object()) => {
  try {
    return JSON.parse(JSON.stringify(value));
  } catch (error) {
    return new Object();
  }
};

/**
 * @description - Receives a Vue Component context to search by all its refs and clean up its models;
 * @param {Object} context - Vue Componente context;
 * @returns {void};
 */
export const cleanUpFields = (context) => {
  Object.keys(context.$refs).forEach((ref) => {
    if (!context.$refs[ref]) return;
    if (ref != "codigoAgenteField" && ref != "dadosProvisoriosField") {
      if (context.$refs[ref].model && context.$refs[ref].model.content) {
        context.$refs[ref].model.content = "";
      } else if (context.$refs[ref].selected) {
        context.$refs[ref].selected = false;
      }
      if (context.$refs[ref].$v) {
        context.$refs[ref].$v.$reset();
      }
    }
  });
};

/**
 * @description - Receives a Vue Component context to set the model of its refs;
 * @param {Object} context - Vue Componente context;
 * @param {Object} data - Data to be setted on the model;
 * @returns {void};
 */
export const setFieldsData = (context, data) => {
  Object.keys(context.$refs).forEach((ref) => {
    if (!context.$refs[ref] || !context.$refs[ref].model) return;
    const modelValue = data[context.$refs[ref].model.name];
    if (
      context.$refs[ref].model &&
      context.$refs[ref].model.name &&
      modelValue
    ) {
      if (Array.isArray(modelValue) || typeof modelValue !== "object") {
        context.$refs[ref].model.content = modelValue;
      } else {
        const optionSetting = modelValue.id;
        const toSet = context.$refs[ref].list.find(
          (item) => item.id == optionSetting
        );
        context.$refs[ref].model.content = toSet;
      }
      if (context.$refs[ref].$v) {
        context.$refs[ref].$v.$reset();
      }
    }
  });
};

/**
 * @description - Function for validation in cpf input;
 * @param {String} cpf - cpf value;
 * @param {String} referenceField - Reference field to know when there is an error;
 * @param {String} referenceMessage - Reference message to know is an error;
 * @return {Boolean} - Reference message to see if it's an error;
 */

export const validateCPF = (cpf = "") => {
  if (process.env.NODE_ENV === "development") return true;
  if (!cpf || cpf.length == 0) return true;
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split("");
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el);
  const toValidate = (pop) =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
};

/**
 * @description - Function for validation in cnpj input;
 * @param {String} cnpj - Cnpj value;
 * @param {String} referenceField - Reference field to know when there is an error;
 * @param {String} referenceMessage - Reference message to know is an error;
 * @return {Boolean} - Reference message to see if it's an error;
 */

export const validateCNPJ = (cnpj = "") => {
  if (process.env.NODE_ENV === "development") return true;
  if (!cnpj) return true;
  const _cnpj = cnpj.replace(/[^\d]+/g, "");
  const cnpjGerador = _cnpj.substring(0, _cnpj.length - 2).split("");
  const cnpjVerificador = _cnpj.substring(_cnpj.length - 2).split("");

  let valid = true;
  for (let index = 0; index < cnpjVerificador.length; index++) {
    let numbersValidation = new Array(5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
    if (index === 1) {
      numbersValidation = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
    }

    let total = 0;
    for (let i = 0; i < cnpjGerador.length; i++) {
      total += cnpjGerador[i] * numbersValidation[i];
    }

    const verificador = total % 11 >= 2 ? 11 - (total % 11) : 0;

    if (verificador == cnpjVerificador[index]) {
      cnpjGerador.push(verificador.toString());
    } else {
      valid = false;
    }
  }
  return valid;
};

export const downloadFileFromResponseObject = (file = "", fileName = "") => {
  const link = document.createElement("a");
  const url = window.URL.createObjectURL(new Blob([file]));
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const mountFormDataForRequest = (entries = new Object()) => {
  const formData = new FormData();
  Object.keys(entries).forEach((key) => {
    if (Array.isArray(entries[key])) {
      formData.append(key, entries[key][0], entries[key][1]);
    } else {
      formData.append(key, entries[key]);
    }
  });
  return formData;
};

/**
 * @description - Transform the Date object in a type date input value;
 * @param {Number} value - Miliseconds value that represents a date;
 * @returns {String} - A type date input value in the pattern YYYY-MM-DD;
 */
export const formatMilliDateToISODate = (value = null) => {
  try {
    return DateTime.fromMillis(value).toISODate();
  } catch (error) {
    console.error(error);
    throw 'Ocorreu um erro ao formatar o campo "Data"';
  }
};

/**
 * @description - Gets the hour of the Date miliseconds;
 * @param {Number} value - Miliseconds value that represents a date;
 * @returns {String} - A hour value in the pattern NN:NN;
 */
export const formatMilliDateToISOHour = (value = null) => {
  try {
    return DateTime.fromMillis(value).toLocaleString(DateTime.TIME_24_SIMPLE);
  } catch (error) {
    throw 'Ocorreu um erro ao formatar o campo "Hora"';
  }
};

/**
 * @description - Convert a Date value in Miliseconds to be persisted on the database;
 * @param {String} date - Date value;
 * @param {String} hour - The hour value (only on the Proposta creation);
 * @returns {Number} - The Date value in miliseconds;
 */
export const convertDateToTimestamp = (date = undefined, time = "0000") => {
  try {
    if (!date || (date && !date.toString().includes("-"))) {
      throw 'Valor inválido para o campo "Data"';
    }

    if (time && time.includes(":")) {
      time = time.replace(":", "");
    } else {
      throw 'Valor inválido para o campo "Hora"';
    }

    const hour = Number(time[0] + time[1]);
    const minute = Number(time[2] + time[3]);
    return DateTime.fromISO(date).set({ hour, minute }).toMillis();
  } catch (error) {
    return null;
  }
};

export const checkDFAprovaPendencie = (empresa) => {
  const hasContatoDFAprovador = empresa.contatos.some(
    (contato) =>
      contato.permissoes.demonstrativo &&
      contato.permissoes.demonstrativo.id ===
        DemonstrativoFaturamentoENUM.CONTATO_DF_APROVA.id
  );

  if (empresa.configuracao) {
    const empresaSendsDF =
      empresa.configuracao.tipoEnvioDemonstrativo.id ===
      EmpresaConfiguracaoDemonstrativoENUM.NAO_RECEBE.id;
    return hasContatoDFAprovador && empresaSendsDF;
  }
  return false;
};

export const formatEmpresaFromResponseObject = (response) => {
  const { data } = response;
  data.tipo = EmpresaTipoENUM[data.tipo.id];
  if (data.classeAgente) {
    data.classeAgente = ClasseAgenteEmpresaENUM[data.classeAgente.id];
  }
  if (data.inscricaoEstadualTipo.id === "CONTRIBUINTE") {
    data.inscricaoEstadualTipo = InscricaoEstadualTypeENUM.CONTRIBUINTE;
  }
  if (data.inscricaoEstadualTipo.id === "ISENTO") {
    data.inscricaoEstadualTipo = InscricaoEstadualTypeENUM.ISENTO;
  }
  if (data.inscricaoEstadualTipo.id === "VERIFICAR") {
    data.inscricaoEstadualTipo = InscricaoEstadualTypeENUM.VERIFICAR;
  }
  if (data.contatos.length > 0) {
    data.contatos = data.contatos.map((contato) => {
      contato.comprador = contato.comprador
        ? TipoContatoENUM.CONTATO_TIPO_COMPRADOR
        : null;
      contato.representanteLegal = contato.representanteLegal
        ? TipoContatoENUM.CONTATO_TIPO_REPRESENTANTE_LEGAL
        : null;
      contato.permissoes.recebeNotaFiscal = contato.permissoes.recebeNotaFiscal
        ? RecebeNotaFiscalENUM.CONTATO_NF_RECEBE
        : null;
      return new ContatoModel(contato);
    });
  }
  return { ...response, data };
};

/**
 * @description - Use to generate random numbers and string
 */

export const decTwoHex = (dec) => {
  return ("0" + dec.toString(16)).substring(-2);
};

export const generateRandomString = () => {
  return new Promise((resolve, reject) => {
    try {
      const array = new Uint32Array(56 / 2);
      window.crypto.getRandomValues(array);
      resolve(Array.from(array, decTwoHex).join(""));
    } catch (error) {
      reject(error);
    }
  });
};

function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }

  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

export async function challengeFromVerifier(v) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}

export function getDecimalSeparatorIndex(text) {
  if (!text) return null;
  if (typeof text !== "string") {
    text = text.toString();
  }
  return text.includes(".")
    ? text.indexOf(".")
    : text.includes(",")
    ? text.indexOf(",")
    : -1;
}

export function stringNormalization(
  string,
  config = {
    upper: true,
    lower: false,
    replaceAccents: false,
    removeSpecialChars: false,
    removeSpaces: true,
  }
) {
  let newString = string || "";
  if (config.upper) newString = newString.toUpperCase();
  if (config.lower) newString = newString.toLowerCase();
  if (config.replaceAccents)
    newString = newString
      .replace(/[ÀÁÂÃÄ]/g, "A")
      .replace(/[ÈÉÊẼË]/g, "E")
      .replace(/[ÌÍÎĨÏ]/g, "I")
      .replace(/[ÒÓÔÕÖ]/g, "O")
      .replace(/[ÙÚÛŨÜ]/g, "U")
      .replace(/[Ç]/g, "C")
      .replace(/[Ñ]/g, "N")
      .replace(/[àáâãä]/g, "a")
      .replace(/[èéêẽë]/g, "e")
      .replace(/[ìíîĩï]/g, "i")
      .replace(/[òóôõö]/g, "o")
      .replace(/[ùúûũü]/g, "u")
      .replace(/[ç]/g, "c")
      .replace(/[ñ]/g, "n");
  if (config.removeSpecialChars) newString = newString.replace(/[^\w\s]/gi, "");
  if (config.removeSpaces) newString = newString.replace(/\s/g, "");
  return newString;
}

/**
 * @description - Transform the number array in a type date input value;
 * @param {number[]} date - Numbers that represents a date;
 * @returns {String} - A type date input value in the pattern YYYY-MM-DD;
 */
export function formatArrayDateToISODate(date) {
  if (!date) return null;
  let [year, month, day] = date;
  month = String(month).length === 1 ? `0${month}` : month;
  day = String(day).length === 1 ? `0${day}` : day;
  return `${year}-${month}-${day}`;
}

export function getAppBaseUrl() {
  return process.env.VUE_APP_BASE_URL || "";
}
export function mountUrl(uri) {
  return window.location.origin + getAppBaseUrl() + uri;
}
export function mountImg(uri) {
  return getAppBaseUrl() + uri;
}
