import Ajuda from "@/pages/Ajuda.vue";
const routes = [
    {
        path: "/ajuda",
        name: "SobreMatrix",
        component: Ajuda,
        meta: {
            auth: true,
            breadcrumb: new Array("Ajuda"),
            permissions: new Array(),
        }
    },
];

export default routes;