<template>
  <div class="row" :class="{ 'justify-content-between': tabs.length > 3 }">
    <span
      v-for="(tab, tabIndex) in tabs"
      @click="changeTab(tab)"
      :class="[
        { 'selected-tab': $parent.activeTab === tab.key },
        { 'me-3': tabs.length <= 3 },
        tab.active ? 'tab-active' : 'tab-inactive',
        'tab',
        'mb-3',
        'col',
        'col-auto',
      ]"
      :key="`tab_${tabIndex}`"
    >
      {{ tab.title }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    changeTab(tab) {
      if (tab.active) {
        this.$parent.changeTab(tab.key);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/colors.scss";
.selected-tab {
  color: black;
  font-weight: bold;
  border-bottom: 3px solid $secondary--blue;
  padding: 0 24px 0 24px;
}

.row {
  margin-top: 10px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.tab-active {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
  &:hover {
    color: black;
  }
}

.tab-inactive {
  color: #d9d9d9;
}
</style>
