export default {
    AUTOPRODUTOR: {
        id: "AUTOPRODUTOR",
        nome: "Autoprodutor",
    },
    COMERCIALIZACAO: {
        id: "COMERCIALIZACAO",
        nome: "Comercialização"
    },
    CONSUMIDOR_CATIVO: {
        id: "CONSUMIDOR_CATIVO",
        nome: "Consumidor Cativo",
    },
    CONSUMIDOR_ESPECIAL: {
        id: "CONSUMIDOR_ESPECIAL",
        nome: "Consumidor Especial",
    },
    CONSUMIDOR_LIVRE: {
        id: "CONSUMIDOR_LIVRE",
        nome: "Consumidor Livre",
    },
    CONSUMIDOR_PARCIALMENTE_LIVRE: {
        id: "CONSUMIDOR_PARCIALMENTE_LIVRE",
        nome: "Consumidor Parcialmente Livre",
    },
    GERACAO: {
        id: "GERACAO",
        nome: "Geração",
    },
    GERACAO_TRANSMISSAO: {
        id: "GERACAO_TRANSMISSAO",
        nome: "Geração e Transmissão",
    },
    HOLDING: {
        id: "HOLDING",
        nome: "Holding",
    },
    IMPORTADOR_EXPORTADOR: {
        id: "IMPORTADOR_EXPORTADOR",
        nome: "Importador / Exportador",
    },
    NAO_AGENTE: {
        id: "NAO_AGENTE",
        nome: "Não é Agente",
    },
    PRODUTOR_INDEPENDENTE: {
        id: "PRODUTOR_INDEPENDENTE",
        nome: "Produtor Independente",
    },
    REPRESENTADO_VAREJISTA: {
        id: "REPRESENTADO_VAREJISTA",
        nome: "Representado Varejista",
    },
    TRANSMISSAO: {
        id: "TRANSMISSAO",
        nome: "Transmissão",
    },
}