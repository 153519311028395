<template>
  <td class="d-flex justify-content-around" :style="{ height: size.height }">
    <span
      class="
        text-center
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
      :style="{ width: size.aprovaWidth }"
    >
      <input type="radio" value="CONTATO_DF_APROVA" v-model="model.content" />
    </span>
    <span
      class="
        text-center
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
      :style="{ width: size.notificadoWidth }"
    >
      <input
        type="radio"
        value="CONTATO_DF_NOTIFICADO"
        v-model="model.content"
      />
    </span>
    <span
      class="
        text-center
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
      :style="{ width: size.nenhumaacaoWidth }"
    >
      <input type="radio" value="CONTATO_DF_NA" v-model="model.content" />
    </span>
  </td>
</template>

<script>
import DemonstrativoFaturamentoENUM from "@/enums/DemonstrativoFaturamentoENUM";
export default {
  name: "RadioDemonstrativoField",
  props: {
    contato: Object,
    size: {
      type: Object,
      required: false,
      default() {
        return {
          aprovaWidth: "auto",
          notificadoWidth: "auto",
          nenhumaacaoWidth: "auto",
          height: "auto",
        };
      },
    },
    modelProp: Object,
  },
  watch: {
    "model.content": {
      immediate: true,
      handler() {
        if (this.model.content) {
          this.contato.permissoes.demonstrativo = DemonstrativoFaturamentoENUM[this.model.content]
        }
      },
    },
    modelProp: {
      immediate: true,
      handler() {
        this.model.content = this.modelProp.id;
      },
    },
  },
  data() {
    return {
      model: {
        name: "demonstrativo",
        content: {
          id: "CONTATO_DF_NA",
          nome: "Não se Aplica",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
@import "@/assets/style/style.scss";
</style>
