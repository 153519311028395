<template>
  <div
    class="col col-lg-4 tipo-demonstrativo-container shadow p-4 mb-5 rounded"
    :class="{ 'is-invalid': $v.model.content.$error }"
  >
    <span class="fw-bold mb-3"> Demonstrativo de Faturamento </span>
    <div class="btn-group">
      <div
        v-for="(demonstrativo, index) in list"
        :key="`demonstrativo_check_${index}`"
      >
        <label :for="`demonstrativo_check_${demonstrativo.id}`">
          <input
            :disabled="disabled"
            :value="demonstrativo"
            v-model="model.content"
            name="tipo_demonstrativo"
            type="radio"
            :id="`demonstrativo_check_${demonstrativo.id}`"
          />
          {{ demonstrativo.nome }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import DemonstrativoFaturamentoENUM from "@/enums/DemonstrativoFaturamentoENUM";
export default {
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      model: {
        name: "demonstrativo",
        content: DemonstrativoFaturamentoENUM.CONTATO_DF_NA,
      },
      list: new Array(),
    };
  },
  mounted() {
    this.list = Object.keys(DemonstrativoFaturamentoENUM)
      .filter((key) => {
        return !["CONTATO_ASSOCIADO", "CONTATO_NAO_ASSOCIADO"].includes(key);
      })
      .map((keySanitized) => DemonstrativoFaturamentoENUM[keySanitized]);
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert(
          "Selecione o tipo de Demonstrativo de Faturamento"
        );
        return true;
      }
      return false;
    },
  },
  watch: {
    "$parent.model.permissoes": {
      deep: true,
      handler() {
        if (this.$parent.model.permissoes[this.model.name]) {
          this.model.content = this.$parent.model.permissoes[this.model.name];
        } else {
          this.model.content = DemonstrativoFaturamentoENUM.CONTATO_DF_NA;
        }
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.$parent.model.permissoes[this.model.name] = this.model.content;
      },
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
.tipo-demonstrativo-container {
  display: flex;
  flex-direction: column;
}

.btn-group {
  justify-content: space-between;
}

input,
label {
  cursor: pointer !important;
}

.is-invalid {
  border: 1px solid $red--primary-error;
}
</style>
