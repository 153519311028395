var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"mt-5"},[_c('div',{staticClass:"row mb-2"},[_c('form-row-header',{attrs:{"text":"Upload de Arquivos"}})],1),_c('input-upload-field',{ref:"inputUploadField",attrs:{"modelProp":_vm.model,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
      !this.$can('CENTRAL_CADASTROS_APROVAR') &&
      !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('div',{staticClass:"row mt-3"},[_c('tipo-anexo-field',{ref:"tipoAnexoField",attrs:{"modelProp":_vm.model,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),_c('prazo-validade-anexo-field',{ref:"prazoValidadeAnexoField",attrs:{"modelProp":_vm.model,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}}),(_vm.model)?_c('descricao-field',{ref:"descricaoAnexoField",attrs:{"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"modelProp":_vm.model,"isRequired":this.model.tipo && this.model.tipo.nome.toLowerCase() === 'outros'}}):_vm._e()],1),_c('div',{staticClass:"row mt-5 mb-2"},[_c('div',{staticClass:"col col-lg-auto ms-auto"},[(
          this.$can('CENTRAL_CADASTROS_EDITAR') ||
          this.$can('CENTRAL_CADASTROS_APROVAR') ||
          this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        )?_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.resetFields}},[_vm._v(" Limpar ")]):_vm._e(),(
          this.$can('CENTRAL_CADASTROS_EDITAR') ||
          this.$can('CENTRAL_CADASTROS_APROVAR') ||
          this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        )?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.save}},[_vm._v(" Adicionar ")]):_vm._e()])]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('form-row-header',{attrs:{"text":"Arquivos Enviados"}}),_c('help',{staticClass:"ms-2",attrs:{"boxAlign":"right"}},[_vm._v(" Clique na linha para baixar o arquivo ")])],1),_c('table-arquivos-enviados',{attrs:{"anexosList":_vm.list,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
      !this.$can('CENTRAL_CADASTROS_APROVAR') &&
      !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')},on:{"sortBy":_vm.sortBy,"deleteArquivo":_vm.remove,"downloadArquivo":_vm.downloadArquivo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }