import ServicesPortal from "../ServicesPortal";

class GrupoEconomicoService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  /**
   * @summary Gets an group entity
   * @param {Object} request
   * @endpoint /api/grupos
   */
  list(request) {
    this._url = `/`;
    return this.get(request, true);
  }

  /**
   * @summary Post an group entity
   * @param {Object} request
   * @endpoint /api/grupos
   */
  create(request) {
    this._url = `/`;
    return this.post(request);
  }

  /**
   * @summary delete a group by id
   * @param {Object} request
   * @endpoint /api/grupos
   */
  remove(request) {
    this._url = `/${request.id}`;
    return this.delete(request);
  }

  /**
   * @summary disassociate a group from a company
   * @param {Object} request
   * @endpoint  /api/grupos/{grupoId}/desassociar
   */
  removeEmpresaFromGrupo(request, grupoId) {
    this._url = `${grupoId}/desassociar`;
    return this.delete(request);
  }

  /**
   * @summary Get a group entity by id
   * @param {Object} request
   * @endpoint /api/grupos/{grupoId}
   */
  getById(request) {
    this._url = `/${request.id}`;
    return this.get(request);
  }

  /**
   * @summary Put a group entity by id
   * @param {Object} request
   * @endpoint /api/grupos/{grupoId}
   */
  update(request) {
    this._url = `/${request.id}`;
    return this.put(request);
  }
}
export default new GrupoEconomicoService("grupos");
