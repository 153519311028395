<template>
  <div>
    <div class="mt-4 wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Empresa Gestora</th>
            <th scope="col">Nome do Gestor</th>
            <th class="text-center" scope="col">Início Relacionamento</th>
            <th class="text-center" scope="col">Término Relacionamento</th>
          </tr>
        </thead>
        <transition-group
          name="list"
          mode="in-out"
          tag="tbody"
          v-if="config.conteudo.length > 0"
        >
          <tr
            :class="{ 'text-secondary': !registro.atual }"
            v-for="(registro, registroIndex) in config.conteudo"
            :key="`registro_${registroIndex}`"
          >
            <td>{{ registro.gestor }}</td>
            <td>{{ registro.nomeGestor }}</td>
            <td class="text-center">{{ registro.dataInicio | DATE_ptBR }}</td>
            <td v-if="registro.atual" class="text-center">Gestor Atual</td>
            <td v-else class="text-center">
              {{ registro.dataFim | DATE_ptBR }}
            </td>
          </tr>
        </transition-group>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="4">Nenhum registro encontrado</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { DATE_ptBR } from "@/filters/formatters";
import Pagination from "@/components/pagination/pagination";
export default {
  props: { config: Object },
  filters: {
    DATE_ptBR,
  },
  components: {
    Pagination,
  },
  methods: {
    setPage(page) {
      this.config.filter.page = page;
      this.$parent.getHistoricoByGestor();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

th {
  font-weight: 500;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
