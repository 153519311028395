<template>
  <div class="col col-lg-4">
    <div class="input-group">
      <label for="descricaoCnaeField">
        Descrição CNAE
        <textarea
          :disabled="disabled"
          id="descricaoCnaeField"
          class="form-control"
          v-model.trim="model.content"
        />
      </label>
    </div>
  </div>
</template>

<script>
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
  },
  data() {
    return {
      model: {
        name: "descricaoCnae",
        content: "",
      },
    };
  },
};
</script>
