<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="emailField">
        E-mail <span class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="80"
          class="form-control"
          :disabled="disabled"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="emailField"
          v-model.trim="$v.model.content.$model"
        />
        <div class="form-text">Insira o e-mail.</div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { isValidEmail } from "@/filters/assertions";
import { required } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "email",
        content: "",
      },
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^\w\s@.-]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo E-mail inválido");
        return true;
      }
      return false;
    },
  },
  computed: {
    splittedLocalPartFromEmail() {
      if (this.model.content) {
        if (this.model.content.includes("@")) {
          return this.model.content.split("@")[0];
        }
        return this.model.content;
      }
      return "";
    },
  },
  validations: {
    model: {
      content: {
        required,
        isValidEmail,
        localPartContainsLessThan64Char() {
          if (this.disabled) return true;
          if (this.splittedLocalPartFromEmail.length > 64) {
            return false;
          }
          return true;
        },
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
