export default class GestorModel {
  constructor(
    {
      id,
      clienteGestor,
      cnpj,
      razaoSocial,
      apelido,
      nomeFantasia,
      contatos,
    } = new Object()
  ) {
    this._id = id;
    this._clienteGestor = clienteGestor;
    this._cnpj = cnpj;
    this._razaoSocial = razaoSocial;
    this._apelido = apelido;
    this._nomeFantasia = nomeFantasia;
    this._contatos = contatos;
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id || null;
  }

  set clienteGestor(clienteGestor = false) {
    this._clienteGestor = clienteGestor;
  }

  get clienteGestor() {
    return this._clienteGestor || false;
  }

  set cnpj(cnpj = null) {
    this._cnpj = cnpj;
  }

  get cnpj() {
    return this._cnpj || null;
  }

  set razaoSocial(razaoSocial = null) {
    this._razaoSocial = razaoSocial;
  }

  get razaoSocial() {
    return this._razaoSocial || null;
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get apelido() {
    return this._apelido || null;
  }

  set nomeFantasia(nomeFantasia = null) {
    this._nomeFantasia = nomeFantasia;
  }

  get nomeFantasia() {
    return this._nomeFantasia || null;
  }

  set contatos(contatos = new Array()) {
    this._contatos = contatos;
  }

  get contatos() {
    return this._contatos || new Array();
  }

  getData() {
    const {
      id,
      clienteGestor,
      cnpj,
      razaoSocial,
      apelido,
      nomeFantasia,
      contatos,
    } = this;
    return {
      id,
      clienteGestor,
      cnpj,
      razaoSocial,
      apelido,
      nomeFantasia,
      contatos: contatos && contatos.length > 0 ? contatos : null,
    };
  }
}
