import ServicesPortal from "../ServicesPortal";
class DadosBancariosService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  getBancosList(request) {
    this._url = `/dados-bancarios/bancos`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        if (response.data.length > 0) {
          response.data = response.data.map((banco) => {
            return {
              codigoBanco: banco.codigo,
              nomeBanco: banco.nome,
            };
          });
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  getByEmpresa(request) {
    this._url = `/${request.empresaId}/dados-bancarios`;
    return this.get(request);
  }

  create(request, empresaId) {
    this._url = `/${empresaId}/dados-bancarios`;
    return this.post(request);
  }

  remove(request, empresaId) {
    this._url = `/${empresaId}/dados-bancarios/${request.id}`;
    return this.delete(request);
  }

  update(request, empresaId) {
    this._url = `/${empresaId}/dados-bancarios/${request.id}`;
    return this.put(request);
  }

  createTipoConta(request) {
    this._url = "/dados-bancarios/tipos";
    return this.post(request);
  }

  removeTipoConta(request) {
    this._url = `/dados-bancarios/tipos/${request.id}`;
    return this.delete(request);
  }

  listTipoConta(request) {
    this._url = "/dados-bancarios/tipos";
    return this.get(request);
  }

  updateTipoConta(request) {
    this._url = "/dados-bancarios/tipos";
    return this.put(request);
  }

  sincronizarDadosBancariosComWBC(request) {
    this._url = `/${request.empresaId}/dados-bancarios/integrar`;
    return this.get();
  }
}

export default new DadosBancariosService("empresas");
