<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="idCargaCCEE">
        Identificação de Carga CCEE
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="255"
          :disabled="disabled"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          class="form-control"
          id="idCargaCCEE"
          v-model.trim="$v.model.content.$model"
        />
      </label>
    </div>
    <div class="form-text ms">Preencha o campo.</div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "identificacaoCargaCCEE",
        content: "",
      },
    };
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Identificação de Carga CCEE inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {},
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}
</style>
