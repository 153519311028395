<template>
  <div class="mt-4 wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th
            @click="$emit('sortBy', 'contato.nome')"
            class="cursop"
            scope="col"
          >
            Nome
          </th>
          <th
            @click="$emit('sortBy', 'contato.email')"
            class="cursop"
            scope="col"
          >
            E-mail
          </th>
          <th scope="col">Departamento</th>
          <th scope="col" class="text-center">Recebe Nota Fiscal</th>
          <th class="text-center d-flex flex-column" scope="col">
            <div class="text-center d-flex flex-column">
              Demonstrativo de faturamento
            </div>
            <div class="d-flex justify-content-around">
              <span
                class="text-center d-flex flex-column text-secondary"
                ref="aprova"
              >
                Aprova
              </span>
              <span
                class="text-center d-flex flex-column text-secondary"
                ref="notificado"
              >
                Notificado
              </span>
              <span
                class="text-center d-flex flex-column text-secondary"
                ref="nenhumaacao"
              >
                Nenhuma ação
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <transition-group
        name="list"
        mode="in-out"
        tag="tbody"
        v-if="contatosList.length > 0"
      >
        <tr
          v-for="(contato, contatoIndex) in contatosList"
          class=""
          :key="`contato_gestor_${contatoIndex}`"
        >
          <td ref="nome">{{ contato.contato.nome }}</td>
          <td>{{ contato.contato.email }}</td>
          <td>
            {{
              contato.contato.departamento
                ? contato.contato.departamento.nome
                : "-"
            }}
          </td>
          <td class="text-center">
            <switch-demonstrativo-field
              :contato="contato"
              :disabled="disabled"
              :modelProp="contato._permissoes.recebeNotaFiscal"
              ref="demonstrativoField"
            />
          </td>
          <radio-demonstrativo-field
            :contato="contato"
            :size="{
              aprovaWidth,
              notificadoWidth,
              nenhumaacaoWidth,
              height,
            }"
            :modelProp="contato._permissoes.demonstrativo"
          />
        </tr>
      </transition-group>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="6">Nenhum contato encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SwitchDemonstrativoField from "../fields/gestor/SwitchDemonstrativoField";
import RadioDemonstrativoField from "../fields/gestor/RadioDemonstrativoField";
export default {
  data() {
    return {
      aprovaWidth: "auto",
      notificadoWidth: "auto",
      nenhumaacaoWidth: "auto",
      height: "auto",
    };
  },
  props: {
    disabled: Boolean,
    contatosList: {
      type: Array,
      required: true,
      default() {
        return new Array();
      },
    },
  },
  watch: {
    contatosList: {
      deep: true,
      handler: "windowResize",
    },
  },
  methods: {
    windowResize() {
      if (this.$refs.aprova)
        this.aprovaWidth = getComputedStyle(this.$refs.aprova).width;
      if (this.$refs.notificado)
        this.notificadoWidth = getComputedStyle(this.$refs.notificado).width;
      if (this.$refs.nenhumaacao)
        this.nenhumaacaoWidth = getComputedStyle(this.$refs.nenhumaacao).width;
      this.height =
        this.$refs.nome && this.$refs.nome.length > 0
          ? getComputedStyle(this.$refs.nome[0]).height
          : "auto";
    },
  },
  components: {
    SwitchDemonstrativoField,
    RadioDemonstrativoField,
  },
  mounted() {
    window.addEventListener("resize", this.windowResize);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

th {
  font-weight: 500;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
