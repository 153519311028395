<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="tipoEnergiaField">
        Tipo de Energia <span class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          :disabled="disabled || modelProp.isProvisorio"
          id="tipoEnergiaField"
          v-model="model.content"
          class="form-select"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
        >
          <option
            v-for="(tipoEnergia, tipoEnergiaIndex) in list"
            :key="`tipo_energia_${tipoEnergiaIndex}`"
            :value="tipoEnergia"
          >
            {{ tipoEnergia.nome }}
          </option>
        </select>
        <div class="form-text">Escolha uma das opções.</div>
      </label>
    </div>
  </div>
</template>

<script>
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import CCEEService from "@/services/portal/empresa/CCEEService";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  props: {
    modelProp: Object,
    disabled: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      list: new Array(),
      loadList: new Array(),
      model: {
        name: "tipoEnergia",
        content: "",
      },
    };
  },
  mounted() {
    this.loadList = CCEEService.listTipoEnergia()
      .then(({ data }) => {
        this.list = data
          .filter((tipoEnergia) => tipoEnergia.ativo)
          .sort(({ nome: a }, { nome: b }) => {
            const nomeSemNumeroA = a.replace(/\d+%$/, "");
            const nomeSemNumeroB = b.replace(/\d+%$/, "");

            return nomeSemNumeroA === nomeSemNumeroB
              ? parseInt(a.match(/\d+/)?.[0] || 0) -
                  parseInt(b.match(/\d+/)?.[0] || 0)
              : nomeSemNumeroA.localeCompare(nomeSemNumeroB);
          });
      })
      .catch((error) => console.log(error));
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Tipo de Energia inválido");
        return true;
      }
      return false;
    },
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
    "modelProp.isProvisorio": {
      deep: true,
      handler() {
        if (this.modelProp.isProvisorio) {
          this.model.content = null;
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          return !this.modelProp.isProvisorio;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
