<template>
  <div class="col" :class="isEmpresaMatriz ? 'col-lg-5' : 'col-lg-6'">
    <div class="input-group">
      <label for="razaoSocialField">
        Razão Social <span class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="150"
          :disabled="disabled"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="razaoSocialField"
          v-model.trim="$v.model.content.$model"
          @mousemove="useDynamicTooltip($event, true)"
          @mouseleave="useDynamicTooltip($event, false)"
        />
      </label>
    </div>
    <dynamic-tooltip
      :on="bubbleHint.on"
      msg="Limite de 100 caracteres excedido"
      :modalX="bubbleHint.x"
      :modalY="bubbleHint.y"
    />
  </div>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
import DynamicTooltip from "@/components/DynamicTooltip.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    isEmpresaMatriz: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "razaoSocial",
        content: "",
      },
      bubbleHint: {
        on: false,
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    useDynamicTooltip(e, entering) {
      if (
        (this.model.content && this.model.content.length == 0) === "" ||
        !this.$v.model.content.$error
      ) {
        return;
      }
      if (
        !entering ||
        !(e.offsetX > e.target.clientWidth - e.target.clientHeight)
      ) {
        this.bubbleHint.on = false;
        return;
      }
      this.bubbleHint.x = e.clientX;
      this.bubbleHint.y = e.clientY;
      this.bubbleHint.on = true;
    },
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      const invalid = this.$v.$invalid;
      if (invalid) {
        this.mxToggleErrorMessageAlert(
          this.model.content.length > 100
            ? "Razão Social deve possuir até 100 caracteres"
            : "Campo Razão Social inválido"
        );
      }
      return invalid;
    },
  },
  validations: {
    model: {
      content: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(100),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
    DynamicTooltip,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
