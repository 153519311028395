<template id="switch-button">
  <div class="switch-button-control">
    <div class="input-group justify-content-center">
      <input
        v-model="model.content"
        @click="!disabled && (model.content = !model.content)"
        type="checkbox"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contato: Object,
    disabled: Boolean,
    modelProp: Boolean,
  },
  watch: {
    "model.content": {
      immediate: true,
      handler() {
        this.contato.permissoes.recebeNotaFiscal = this.model.content
      },
    },
    modelProp: {
      immediate: true,
      handler() {
        this.model.content = this.modelProp;
      },
    },
  },
  data() {
    return {
      model: {
        name: "recebeDemonstrativo",
        content: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
@import "@/assets/style/style.scss";
</style>
