import store from "@/store";
import { userHasPermissions } from "@/utils/permissions";
import { loginRedirect } from "../utils/authentication";

export default function beforeach(router) {
  router.beforeEach((to, from, next) => {
    const tokenLocalStorage = window.sessionStorage.getItem(
      process.env.VUE_APP_TOKEN_KEY
    );

    if (to.path === "/") {
      if (tokenLocalStorage) {
        return next({ path: "/cadastros/buscar-empresas" });
      }
    }

    if (to.matched.some((route) => route.meta.auth == true)) {
      if (!tokenLocalStorage) {
        store.dispatch("ActionSetUsuario");
        return loginRedirect();
      }
    }

    if (!to.meta.auth || !to.meta.permissions.length) {
      return next();
    }

    const userPermissions = store.getters.getPermissoes;
    if (userHasPermissions(userPermissions, to.meta.permissions)) {
      return next();
    } else {
      if (
        to.path !== "/login/callback" &&
        tokenLocalStorage &&
        userHasPermissions(userPermissions, [
          "CENTRAL_CADASTROS_CONSULTAR",
          "CENTRAL_CADASTROS_EDITAR",
          "CENTRAL_CADASTROS_APROVAR",
          "CENTRAL_CADASTROS_PARAMETRIZAR",
        ])
      ) {
        return next({ path: "cadastros/buscar-empresas" });
      }
    }

    return next();
  });
}
