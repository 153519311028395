<template>
  <div class="mt-3" v-async="loading">
    <div class="row p-2">
      <tipo-empresa-card
        :maintainValue="maintainValue"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        ref="tipoEmpresaField"
        :modelProp="model"
      />
    </div>
    <div class="row">
      <cnpj-field
        ref="cnpjField"
        :editing="editing"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('cnpj')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <razao-social-field
        :modelProp="model"
        ref="razaoSocialField"
        :isEmpresaMatriz="IS_EMPRESA_MATRIZ"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('razaoSocial')"
      />
    </div>
    <div class="row mt-4">
      <form-row-header text="Dados complementares" />
    </div>
    <div class="row mt-4">
      <nome-fantasia-field
        :modelProp="model"
        ref="nomeFantasiaField"
        :invalidYellow="invalidYellow(model.nomeFantasia)"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('nomeFantasia')"
      />
      <apelido-field
        ref="apelidoField"
        :modelProp="model"
        :invalidYellow="invalidYellow(model.apelido)"
        :recentUpdatedField="recentUpdatedField('apelido')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-4">
      <inscricao-estadual-type-field
        :modelProp="model"
        ref="inscricaoEstadualTypeField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :invalidYellow="invalidYellow(model.inscricaoEstadualTipo)"
      />
      <regime-especial-field
        :modelProp="model"
        ref="registroEspecialField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('regimeEspecial')"
      />
    </div>
    <div class="row mt-4">
      <inscricao-estadual-estado-field
        :modelProp="model"
        ref="inscricaoEstadualEstadoField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :invalidYellow="invalidYellow(model.inscricaoEstadualEstado)"
        :recentUpdatedField="recentUpdatedField('inscricaoEstadualEstado')"
      />
      <inscricao-estadual-field
        :modelProp="model"
        ref="inscricaoEstadualField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :invalidYellow="invalidYellow(model.inscricaoEstadual)"
        :recentUpdatedField="recentUpdatedField('inscricaoEstadual')"
      />
      <CNAE-field
        ref="cnaeField"
        :modelProp="model"
        :invalidYellow="invalidYellow(model.cnae)"
        :recentUpdatedField="recentUpdatedField('cnae')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <CNAE-descricao-field
        ref="descricaoCnaeField"
        :modelProp="model"
        :disabled="true"
      />
      <!-- <setor-field
        ref="setorField"
        :modelProp="model"
        :invalidYellow="invalidYellow(model.setor)"
        :recentUpdatedField="recentUpdatedField('setor')"
      /> -->
      <!-- <segmento-empresa-field
        ref="segmentoField"
        :modelProp="model"
        :invalidYellow="
          invalidYellow(model.segmento) && notRestrictedField('segmento')
        "
        :recentUpdatedField="recentUpdatedField('segmento')"
      /> -->
    </div>
    <div class="row mt-5">
      <form-row-header text="Endereço" />
    </div>
    <div class="row mt-4">
      <endereco-fields
        :modelProp="model"
        ref="enderecoFields"
        @updateEndereco="updateEndereco"
        :recentUpdatedFields="recentUpdatedFields"
        @setCidadesToLoadedState="setCidadesToLoadedState"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-5 d-flex align-items-center justify-content-between">
      <div class="col col-lg-6 w-50 col-md-8">
        <button
          class="btn btn-primary-blue"
          @click="approveEmpresa"
          v-if="CAN_APROVAR_CADASTRO && CAN_APROVAR_PARAMETRIZAR"
        >
          <b>Aprovar</b> Cadastro
        </button>
        <button
          :class="{ 'ms-3': IS_PENDENTE_APROVACAO }"
          class="btn btn-primary-blue"
          @click="updateBeforeIntegracaoWBC(true)"
          v-if="
            CAN_INTEGRAR_WBC &&
            IS_PRE_CADASTRO_OR_FALHA_INTEGRACAO_WBC &&
            CAN_APROVAR_PARAMETRIZAR
          "
        >
          <b>Validar</b> cadastro
        </button>
        <button
          :class="{ 'ms-3': IS_PENDENTE_APROVACAO }"
          class="btn btn-primary-blue"
          @click="integrarWBC()"
          v-if="
            CAN_INTEGRAR_WBC &&
            !IS_PRE_CADASTRO_OR_FALHA_INTEGRACAO_WBC &&
            IS_NOT_INTEGRADA_WBC &&
            CAN_APROVAR_PARAMETRIZAR
          "
        >
          <b>Integrar</b> com <b>WBC</b>
        </button>
      </div>
      <div
        class="col col-lg-4 w-50 d-flex align-items-center justify-content-end"
      >
        <button
          v-if="editing && CAN_EDITAR_APROVAR_PARAMETRIZAR"
          class="btn btn-secondary-blue ms-3"
          @click="getEmpresaDataByCNPJ"
        >
          <strong>Importar</strong> dados da <strong>Receita</strong>
        </button>
        <button
          class="btn btn-secondary"
          :class="{ 'ms-3': editing }"
          @click="$router.push({ name: 'BuscarEmpresas' })"
        >
          Cancelar
        </button>
        <button
          v-if="editing && CAN_EDITAR_APROVAR_PARAMETRIZAR"
          @click="update"
          class="btn btn-primary ms-3"
        >
          Atualizar
        </button>
        <button
          @click="save"
          v-else-if="CAN_EDITAR_APROVAR_PARAMETRIZAR"
          class="btn btn-primary ms-3"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PENDENCIES,
  HTTP_RESPONSE,
  CONFIRMATION,
  TOOLTIP_MESSAGES,
} from "@/constants/strings";
import Tooltip from "@/components/Tooltip.vue";
import { formIsValid } from "@/utils/validators";
import { userHasPermissions } from "@/utils/permissions";
import CRUDTemplate from "@/components/CRUDTemplate.vue";
import EmpresaStatusENUM from "@/enums/EmpresaStatusENUM";
import EnderecoFields from "../fields/EnderecoFields.vue";
import EnderecoModel from "@/models/empresa/EnderecoModel";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import DadosGeraisModel from "@/models/empresa/DadosGeraisModel";
import TipoEmpresaCard from "../fields/dados-gerais/TipoEmpresaCard";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { formatEmpresaFromResponseObject } from "@/utils/functionUtils";
import CNAEField from "@/components/forms/fields/empresa/CNAEField.vue";
import CnpjField from "@/components/forms/fields/empresa/CnpjField.vue";
import SetorField from "@/components/forms/fields/empresa/SetorField.vue";
import ApelidoField from "@/components/forms/fields/empresa/ApelidoField.vue";
import RazaoSocialField from "@/components/forms/fields/empresa/RazaoSocialField.vue";
import NomeFantasiaField from "@/components/forms/fields/empresa/NomeFantasiaField.vue";
import CNAEDescricaoField from "@/components/forms/fields/empresa/CNAEDescricaoField.vue";
import RegimeEspecialField from "@/components/forms/fields/empresa/RegimeEspecialField.vue";
import SegmentoEmpresaField from "@/components/forms/fields/empresa/SegmentoEmpresaField.vue";
import InscricaoEstadualField from "@/components/forms/fields/empresa/InscricaoEstadualField.vue";
import InscricaoEstadualTypeField from "@/components/forms/fields/empresa/InscricaoEstadualTypeField.vue";
import InscricaoEstadualEstadoField from "@/components/forms/fields/empresa/InscricaoEstadualEstadoField.vue";

export default {
  extends: CRUDTemplate,
  data() {
    return {
      maintainValue: true,
      cidadesIsLoaded: false,
      invalidFields: new Array(),
      model: new DadosGeraisModel({ mounting: true }),
      recentUpdatedFields: new Object(),
    };
  },
  destroyed() {
    this.setHeaderStatusText({ text: "" });
    this.mxResetWarningPendencies(true);
    this.mxResetSingleMessageAlert();
  },
  async mounted() {
    const { empresaId } = this.$parent;
    this.service = EmpresaService;
    this.requestEntity = "empresa";

    if (empresaId) {
      this.editing = true;
      this.mountEmpresa();
    } else {
      this.maintainValue = false;
      this.setVendedorCompradorCategory();
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (this.model.id) {
          this.mountInvalidFields();
        }
        if (this.model.status) {
          this.setHeaderStatusText({
            text: this.model.status.nome,
            isIntegrated: this.model.integrado,
            integrationDate: this.model.dataIntegrado,
            isIntegradoSap: this.model.isIntegradoSap,
          });
        }
      },
    },
    "model.tipo": {
      immediate: true,
      handler() {
        if (this.model.tipo) {
          this.setBreadcrumbsParam(this.model.tipo.nome);
          this.$parent.inactiveTabs(this.model);
        }
      },
    },
    cidadesIsLoaded: {
      handler() {
        if (this.cidadesIsLoaded == true) {
          this.toggleLoading(false);
        }
      },
    },
  },
  computed: {
    EmpresaStatusENUM: () => EmpresaStatusENUM,
    CAN_APROVAR_PARAMETRIZAR() {
      return (
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
    CAN_EDITAR_APROVAR_PARAMETRIZAR() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
    EMPRESA_FILIAIS_MESSAGE: () => TOOLTIP_MESSAGES.EMPRESA_HAS_FILIAIS,
    IS_EMPRESA_MATRIZ() {
      return this.model && this.model.filiais && this.model.filiais.length > 0;
    },
    IS_PENDENTE_APROVACAO() {
      return (
        this.model.id &&
        this.model.status &&
        this.model.status.id === "PENDENTE_APROVACAO"
      );
    },
    IS_NOT_PARCEIRO_COMISSIONADO_NOR_OUTROS_FORNECEDORES() {
      return (
        this.model.id &&
        this.model.tipo &&
        !["PARCEIRO_COMISSIONADO", "OUTROS_FORNECEDORES"].includes(
          this.model.tipo.id
        )
      );
    },
    IS_PRE_CADASTRO_OR_FALHA_INTEGRACAO_WBC() {
      return (
        this.IS_NOT_PARCEIRO_COMISSIONADO_NOR_OUTROS_FORNECEDORES &&
        this.model.status &&
        ["PRE_CADASTRO", "FALHA_INTEGRACAO_WBC"].includes(this.model.status.id)
      );
    },
    CAN_APROVAR_CADASTRO() {
      return (
        this.IS_PENDENTE_APROVACAO &&
        (this.$can("CENTRAL_CADASTROS_APROVAR") ||
          this.$can("CENTRAL_CADASTROS_PARAMETRIZAR"))
      );
    },
    CAN_INTEGRAR_WBC() {
      return (
        this.IS_NOT_PARCEIRO_COMISSIONADO_NOR_OUTROS_FORNECEDORES &&
        this.model.status &&
        [
          "PRE_CADASTRO",
          "INFORMACOES_PENDENTES",
          "FALHA_INTEGRACAO_WBC",
          "APROVADO_COMPLETO",
          "APROVADO_INCOMPLETO",
        ].includes(this.model.status.id)
      );
    },
    IS_NOT_INTEGRADA_WBC() {
      return !(this.model && this.model.integrado && this.model.dataIntegrado);
    },
  },
  methods: {
    toggleLoading(show, forceHide) {
      if (forceHide) {
        this.loading = false;
        return;
      }
      if (typeof this.loading === "object") {
        if (show) return;
        else {
          if (this.cidadesIsLoaded) {
            this.loading = false;
          }
        }
      } else {
        if (show) {
          this.loading = new Promise((res, rej) => {});
        }
      }
    },
    setCidadesToLoadedState() {
      this.cidadesIsLoaded = true;
    },
    updateEndereco(endereco) {
      this.model = new DadosGeraisModel({
        ...this.model.getData(),
        endereco: new EnderecoModel(endereco),
      });
    },
    mountEmpresa() {
      const { empresaId } = this.$parent;
      if (empresaId) {
        this.toggleLoading(true);
        this.service
          .getById({ empresaId })
          .then(({ data: empresa }) => {
            this.model = new DadosGeraisModel({
              ...empresa,
              endereco: new EnderecoModel(empresa.endereco),
            });
            this.model.filiais = empresa.filiais;
            this.mountRecentUpdatedFields(empresaId);
          })
          .catch(this.mxHandleRequestError);
      }
    },
    notRestrictedField(field) {
      return !this.model.tipo.restrictions.fields.includes(field);
    },
    invalidYellow(field) {
      return [null, undefined, "", []].includes(field) && !!this.model.id;
    },
    recentUpdatedField(field) {
      return (
        this.recentUpdatedFields.campos &&
        this.recentUpdatedFields.campos.includes(field)
      );
    },
    mountRecentUpdatedFields(empresaId, integratingWBC = false) {
      this.toggleLoading(true);
      this.service
        .getEmpresaRecentUpdates({ empresaId })
        .then(({ data }) => {
          this.recentUpdatedFields = data;

          if (this.recentUpdatedFields.campos.length > 0) {
            const fieldsHint = {
              tipo: "Tipo de Empresa",
              cnpj: "CNPJ",
              razaoSocial: "Razão Social",
              matriz: "Boleta Mãe",
              matrizCnpj: "CNPJ da Empresa Mãe",
              nomeFantasia: "Nome Fantasia",
              apelido: "Apelido",
              inscricaoEstadualTipo: "Tipo de Inscrição Estadual",
              inscricaoEstadualEstado: "U.F I.E",
              inscricaoEstadual: "Inscrição Estadual",
              cnae: "CNAE",
              descricaoCnae: "Descrição de CNAE",
              setor: "Setor",
              segmento: "Segmento",
              regimeEspecial: "Regime Especial",
              classeAgente: "Classe do Agente (tipo)",
              representante: "Representante",
              categorias: "Tipo de Empresa (WBC)",
              enderecoCep: "CEP",
              enderecoLogradouro: "Logradouro",
              enderecoNumero: "Número",
              enderecoComplemento: "Complemento",
              enderecoBairro: "Bairro",
              enderecoCidade: "Cidade",
              enderecoEstado: "U.F.",
              enderecoPais: "País",
            };

            const updatedFields = Object.keys(fieldsHint)
              .map((atributo) => {
                if (this.recentUpdatedFields.campos.includes(atributo)) {
                  return fieldsHint[atributo];
                }
              })
              .filter((contatoAlterado) => contatoAlterado != undefined);

            let updatedFieldsMessage = "";
            if (updatedFields.length > 0) {
              updatedFieldsMessage = `Campos alterados: ${updatedFields.reduce(
                (previous, next) => {
                  return previous + ", " + next;
                }
              )}`;
            }

            if (updatedFieldsMessage.trim() != "") {
              this.mxToggleWarningMessageAlert(
                updatedFieldsMessage,
                true,
                true
              );
            } else {
              this.mxClearYellowPendency(true, false);
            }
          } else {
            this.mxClearYellowPendency(true, false);
          }
          this.maintainValue = false;
        })
        .catch(this.mxHandleRequestError)
        .finally(() => {
          if (integratingWBC == false && this.cidadesIsLoaded == true) {
            this.toggleLoading(false);
          }
        });
    },
    mountInvalidFields() {
      let enderecoList;
      let list = Object.keys(this.model.getData()).filter((key) => {
        if (
          ["id", "razaoSocial", "cnpj", "matriz", "categorias"].includes(key)
        ) {
          return;
        }

        if (key == "inscricaoEstadualEstado" || key == "inscricaoEstadual") {
          if (
            this.model.inscricaoEstadualTipo &&
            this.model.inscricaoEstadualTipo.id != "CONTRIBUINTE"
          ) {
            return;
          }
        }

        if (key == "matrizCnpj") {
          if (this.model.matriz == true) {
            return;
          }
        }
        if (key == "representante") {
          if (
            !this.model.classeAgente ||
            this.model.classeAgente.id != "NAO_AGENTE"
          ) {
            return;
          }
        }

        if (key == "endereco") {
          enderecoList = Object.keys(this.model.endereco.getData()).filter(
            (key) => {
              if (key == "pais") {
                return;
              }

              if (!this.model.endereco[key]) {
                return key;
              }
            }
          );
        }

        if (!this.model[key]) {
          return key;
        }
      });

      if (list.length > 0 || enderecoList.length > 0) {
        this.mxToggleWarningMessageAlert(
          PENDENCIES.IS_INVALID_YELLOW,
          false,
          true
        );
      } else {
        this.mxClearYellowPendency(false, true);
      }

      this.invalidFields = list;
    },
    update(throwError = false, integratingWBC = false) {
      return new Promise((resolve, reject) => {
        if (!formIsValid(this)) {
          throwError && reject();
        } else {
          this.toggleLoading(true);
          const { id: empresaId } = this.model;
          this.$parent.empresaId = empresaId;
          this.service
            .update(this.model.getData(), empresaId)
            .then(() => {
              this.mxToggleToast(
                HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.UPDATE
              );
              this.service
                .getById({ empresaId })
                .then(({ data: empresa }) => {
                  if (!integratingWBC) {
                    this.toggleLoading(false);
                  }
                  this.model = new DadosGeraisModel({
                    ...empresa,
                    endereco: new EnderecoModel(empresa.endereco),
                  });
                  if (this.model.id) {
                    this.mountRecentUpdatedFields(
                      this.model.id,
                      integratingWBC
                    );
                  }
                  resolve();
                })
                .catch((error) => {
                  if (!integratingWBC) {
                    this.toggleLoading(false);
                  }
                  this.mxHandleRequestError(error, this.requestEntity);
                });
            })
            .catch((error) => {
              this.toggleLoading(false);
              this.mxHandleRequestError(error, this.requestEntity);
            });
        }
      });
    },
    save(throwError = false) {
      return new Promise((resolve, reject) => {
        if (!formIsValid(this)) {
          throwError && reject();
        } else {
          this.toggleLoading(true);
          this.service
            .create(this.model.getData())
            .then(({ data }) => {
              this.mxToggleToast(
                HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.CREATE
              );
              const { id: empresaId } = data;
              this.$parent.empresaId = empresaId;
              this.service
                .getById({ empresaId })
                .then(({ data: empresa }) => {
                  this.mxResetErrorPendencies();
                  this.mxResetSingleMessageAlert();
                  this.model = new DadosGeraisModel({
                    ...empresa,
                    endereco: new EnderecoModel(empresa.endereco),
                  });
                  this.editing = true;
                  resolve();
                })
                .catch((error) => {
                  this.mxHandleRequestError(error, this.requestEntity);
                })
                .finally(() => this.toggleLoading(false));
            })
            .catch((error) => {
              this.toggleLoading(false);
              this.mxHandleRequestError(error, this.requestEntity);
            });
        }
      });
    },
    updateBeforeIntegracaoWBC(validate = false) {
      if (this.editing) {
        this.update(false, true)
          .then(() => {
            if (validate) {
              this.validarCadastro();
            } else {
              this.integrarWBC();
            }
          })
          .catch(this.mxHandleRequestError);
      }
    },
    validarCadastro() {
      const { id: empresaId } = this.model;
      this.toggleLoading(true);
      this.service
        .validarCadastro({ empresaId })
        .then((response) => {
          const { data: empresa } = formatEmpresaFromResponseObject(response);
          this.model = new DadosGeraisModel({
            ...empresa,
            endereco: new EnderecoModel(empresa.endereco),
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.mxToggleErrorSingleMessageAlert(
              error.response.data && error.response.data.message
                ? `${error.response.data.message}`
                : "Falha na integração com WBC."
            );
            this.mountEmpresa();
          } else {
            this.mxHandleRequestError(error);
          }
        })
        .finally(() => this.toggleLoading(false));
    },
    integrarWBC() {
      const { id: empresaId } = this.model;
      this.toggleLoading(true);
      this.service
        .integrarWBC({ empresaId })
        .then(() => {
          this.toggleLoading(false);
          this.mxToggleToast(
            "A Integração com o WBC foi realizada com sucesso!"
          );
          this.mountEmpresa();
        })
        .catch((error) => {
          this.toggleLoading(false);
          if (error.response && error.response.status === 422) {
            this.mxToggleErrorSingleMessageAlert(
              error.response.data && error.response.data.message
                ? `${error.response.data.message}`
                : "Falha na integração com WBC."
            );
            this.mountEmpresa();
          } else {
            this.mxHandleRequestError(error);
          }
        });
    },
    resetFields() {
      this.mxResetErrorPendencies();
      this.mxResetSingleMessageAlert();
    },
    buscarPermissoes() {
      let permissions = window.sessionStorage.getItem("p-keys");
      if (permissions) {
        permissions = JSON.parse(
          Buffer.from(permissions, "base64").toString("utf8")
        );
      }
      return permissions;
    },
    setVendedorCompradorCategory() {
      let list = new Array();

      this.service
        .listCategorias()
        .then(({ data }) => {
          this.model.categorias = data.filter(
            (item) =>
              item.nome.toLowerCase() === "comprador" ||
              item.nome.toLowerCase() === "vendedor"
          );
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    approveEmpresa() {
      const { id: empresaId } = this.model;
      this.mxToggleWarningModal(CONFIRMATION.EMPRESA.UPDATE, "Sim")
        .then(() => {
          this.toggleLoading(true);
          this.service
            .approveEmpresa({ empresaId })
            .then((response) => {
              const { data: empresa } =
                formatEmpresaFromResponseObject(response);
              this.mxToggleToast(HTTP_RESPONSE.EMPRESA.SUCCESS.UPDATE);
              this.model = new DadosGeraisModel({
                ...empresa,
                endereco: new EnderecoModel(empresa.endereco),
              });
              this.mountRecentUpdatedFields(empresa.id);
            })
            .catch(this.mxHandleRequestError)
            .finally(() => this.toggleLoading(false));
        })
        .catch(this.mxHandleRequestError);
    },
    /* Overriding CRUDTemplate getByEmpresa function */
    getByEmpresa({ id }) {
      this.$parent.empresaId = id;
    },
    getEmpresaDataByCNPJ() {
      const {
        cnpj,
        tipo,
        setor,
        status,
        matriz,
        apelido,
        segmento,
        categorias,
        matrizCnpj,
        classeAgente,
        representante,
        regimeEspecial,
        inscricaoEstadual,
        inscricaoEstadualEstado,
        inscricaoEstadualTipo,
      } = this.model;

      this.toggleLoading(true);
      this.service
        .getEmpresaFromReceita({
          cnpj,
        })
        .then(({ data: empresa }) => {
          const { empresaId: id } = this.$parent;
          if (id) {
            this.model = new DadosGeraisModel({
              ...empresa,
              endereco: new EnderecoModel(empresa.endereco),
              id,
              tipo,
              setor,
              status,
              matriz,
              apelido,
              segmento,
              matrizCnpj,
              categorias,
              classeAgente,
              representante,
              regimeEspecial,
              inscricaoEstadual,
              inscricaoEstadualEstado,
              inscricaoEstadualTipo,
            });
          } else {
            this.model = new DadosGeraisModel({
              ...empresa,
              tipo: this.model.tipo,
              status: this.model.status,
              endereco: new EnderecoModel(empresa.endereco),
              categorias,
            });
          }
          this.mxResetWarningPendencies();
          this.mxResetSingleMessageAlert();
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.mxToggleWarningMessageAlert(
              "CNPJ não encontrado na base de dados da Receita Federal.",
              true
            );
          } else {
            this.mxHandleRequestError(error, "empresa");
          }
        })
        .finally(() => this.toggleLoading(null, true));
    },
    getCnaeValido() {
      return new Promise((resolve) => {
        const { cnae } = this.model;
        this.toggleLoading(true);
        this.service
          .validarCnae({ cnae })
          .then(({ data }) => {
            this.model.descricaoCnae = data.descricao;
            resolve(true);
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.mxToggleWarningMessageAlert(
                "CNAE não encontrado na base de dados da Receita Federal.",
                true
              );
            } else {
              this.mxHandleRequestError(error, "empresa");
            }
            resolve(false);
          })
          .finally(() => this.toggleLoading(null, true));
      });
    },
  },
  components: {
    Tooltip,
    CnpjField,
    CNAEField,
    CNAEDescricaoField,
    SetorField,
    ApelidoField,
    FormRowHeader,
    EnderecoFields,
    TipoEmpresaCard,
    RazaoSocialField,
    NomeFantasiaField,
    RegimeEspecialField,
    SegmentoEmpresaField,
    InscricaoEstadualField,
    InscricaoEstadualTypeField,
    InscricaoEstadualEstadoField,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
