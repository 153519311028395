<template>
  <div v-async="loading">
    <div class="mt-5">
      <div class="row mt-4">
        <checkbox-matriz-field
          :modelProp="model"
          ref="cnpjMatrizFieldCheckbox"
          :isEmpresaMatriz="IS_EMPRESA_MATRIZ"
          :invalidYellow="invalidYellow(model.matriz)"
          :recentUpdatedField="recentUpdatedField('matriz')"
          :disabled="
            !$can('CENTRAL_CADASTROS_EDITAR') &&
            !$can('CENTRAL_CADASTROS_APROVAR') &&
            !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
          "
        />
        <!-- <div
          v-if="IS_EMPRESA_MATRIZ"
          class="col-1 d-flex align-items-center justify-content-center"
        >
          <tooltip
            :width="350"
            boxAlign="right"
            arrowAlign="right"
            icon="icon-warning"
          >
            <template #tooltip-text>
              {{ EMPRESA_FILIAIS_MESSAGE }}
            </template>
          </tooltip>
        </div> -->
        <cnpj-matriz-field
          :modelProp="model"
          ref="cnpjMatrizField"
          :disabled="
            !$can('CENTRAL_CADASTROS_EDITAR') &&
            !$can('CENTRAL_CADASTROS_APROVAR') &&
            !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
          "
        />
        <div class="row mt-5 d-flex align-items-center justify-content-between">
          <div class="col col-lg-6 w-50 col-md-8"></div>
          <div
            class="
              col col-lg-4
              w-50
              d-flex
              align-items-center
              justify-content-end
            "
          >
            <button
              class="btn btn-secondary ms-3"
              @click="$router.push({ name: 'BuscarEmpresas' })"
            >
              Cancelar
            </button>
            <button @click="update" class="btn btn-primary ms-3">
              Atualizar
            </button>
          </div>
        </div>
      </div>
      <p class="title-filial">Relação Matriz e Filiais</p>
      <table-estrutura-rateio @emitEmpresaId="changeTab" :empresas="empresas" />
    </div>
  </div>
</template>
<script>
import { HTTP_RESPONSE } from "@/constants/strings";
import { CNPJ, EMPTY } from "@/filters/formatters";
import CRUDTemplate from "@/components/CRUDTemplate.vue";
import EmpresaService from "@/services/portal/empresa/EmpresaService.js";
import CheckboxMatrizField from "@/components/forms/fields/empresa/CheckboxMatrizField.vue";
import CnpjMatrizField from "@/components/forms/fields/empresa/CnpjMatrizField.vue";
import TableEstruturaRateio from "@/pages/empresas/components/tables/TableEstruturaRateio";
import DadosGeraisModel from "@/models/empresa/DadosGeraisModel";
import EnderecoModel from "@/models/empresa/EnderecoModel";
export default {
  extends: CRUDTemplate,
  data() {
    return {
      loading: undefined,
      empresas: new Array(),
      model: new DadosGeraisModel({ mounting: true }),
      recentUpdatedFields: new Object(),
      service: null,
    };
  },
  mounted() {
    this.requestEntity = "est_rateio";
    this.service = EmpresaService;
    this.mountEmpresas();
    this.mountEmpresaModel();
  },
  methods: {
    changeTab(empresaId) {
      this.$parent.changeTab("DADOS_GERAIS", empresaId);
    },
    mountEmpresas() {
      const { empresaId } = this.$parent;
      this.loading = EmpresaService.getById({ empresaId })
        .then(({ data }) => {
          this.empresas = data.filiais;
          if (data.matrizCnpj) {
            EmpresaService.getByCnpj({ cnpj: data.matrizCnpj })
              .then((response) => {
                if (response.data.filiais && response.data.filiais.length) {
                  this.empresas = response.data.filiais.filter(
                    (filial) => filial.cnpj == data.cnpj
                  );
                }
                if (this.empresas && this.empresas.length) {
                  this.empresas.unshift(response.data);
                }
              })
              .catch(this.mxHandleRequestError);
          } else {
            if (this.empresas && this.empresas.length) {
              this.empresas.unshift(data);
            }
          }
        })
        .catch(this.mxHandleRequestError);
    },
    mountEmpresaModel() {

      const { empresaId } = this.$parent;
      
      if (empresaId) {
        this.toggleLoading(true);
        this.service
          .getById({ empresaId })
          .then(({ data: empresa }) => {
            this.model = new DadosGeraisModel({
              ...empresa,
              endereco: new EnderecoModel(empresa.endereco),
            });
            this.model.filiais = empresa.filiais;
            this.mountRecentUpdatedFields(empresaId);
          })
          .catch(this.mxHandleRequestError);
      }
    },
    toggleLoading(show, forceHide) {
      if (forceHide) {
        this.loading = false;
        return;
      }
      if (typeof this.loading === "object") {
        if (show) return;
        else {
          if (this.cidadesIsLoaded) {
            this.loading = false;
          }
        }
      } else {
        if (show) {
          this.loading = new Promise((res, rej) => {});
        }
      }
    },
    mountRecentUpdatedFields(empresaId) {
      this.toggleLoading(true);
      this.service
        .getEmpresaRecentUpdates({ empresaId })
        .then(({ data }) => {
          this.recentUpdatedFields = data;

          if (this.recentUpdatedFields.campos.length > 0) {
            const fieldsHint = {
              tipo: "Tipo de Empresa",
              cnpj: "CNPJ",
              razaoSocial: "Razão Social",
              matriz: "Boleta Mãe",
              matrizCnpj: "CNPJ da Empresa Mãe",
              nomeFantasia: "Nome Fantasia",
              apelido: "Apelido",
              inscricaoEstadualTipo: "Tipo de Inscrição Estadual",
              inscricaoEstadualEstado: "U.F I.E",
              inscricaoEstadual: "Inscrição Estadual",
              cnae: "CNAE",
              descricaoCnae: "Descrição de CNAE",
              setor: "Setor",
              segmento: "Segmento",
              regimeEspecial: "Regime Especial",
              classeAgente: "Classe do Agente (tipo)",
              representante: "Representante",
              categorias: "Tipo de Empresa (WBC)",
              enderecoCep: "CEP",
              enderecoLogradouro: "Logradouro",
              enderecoNumero: "Número",
              enderecoComplemento: "Complemento",
              enderecoBairro: "Bairro",
              enderecoCidade: "Cidade",
              enderecoEstado: "U.F.",
              enderecoPais: "País",
            };

            const updatedFields = Object.keys(fieldsHint)
              .map((atributo) => {
                if (this.recentUpdatedFields.campos.includes(atributo)) {
                  return fieldsHint[atributo];
                }
              })
              .filter((contatoAlterado) => contatoAlterado != undefined);

            let updatedFieldsMessage = "";
            if (updatedFields.length > 0) {
              updatedFieldsMessage = `Campos alterados: ${updatedFields.reduce(
                (previous, next) => {
                  return previous + ", " + next;
                }
              )}`;
            }

            if (updatedFieldsMessage.trim() != "") {
              this.mxToggleWarningMessageAlert(
                updatedFieldsMessage,
                true,
                true
              );
            } else {
              this.mxClearYellowPendency(true, false);
            }
          } else {
            this.mxClearYellowPendency(true, false);
          }
          this.maintainValue = false;
        })
        .catch(this.mxHandleRequestError);
    },
    invalidYellow(field) {
      return [null, undefined, "", []].includes(field) && !!this.model.id;
    },
    recentUpdatedField(field) {
      return (
        this.recentUpdatedFields.campos &&
        this.recentUpdatedFields.campos.includes(field)
      );
    },
    update() {
      return new Promise((resolve, reject) => {
        this.toggleLoading(true);
        const { id: empresaId } = this.model;
        this.$parent.empresaId = empresaId;
        this.service
          .update(this.model.getData(), empresaId)
          .then(() => {
            this.mxToggleToast(
              HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.UPDATE
            );
            this.service
              .getById({ empresaId })
              .then(({ data: empresa }) => {
                this.model = new DadosGeraisModel({
                  ...empresa,
                  endereco: new EnderecoModel(empresa.endereco),
                });
                this.mountEmpresas();
                resolve();
              })
              .catch((error) => {
                this.mxHandleRequestError(error, this.requestEntity);
              });
          })
          .catch((error) => {
            this.toggleLoading(false);
            this.mxHandleRequestError(error, "EST_RATEIO");
          });
      });
    },
  },
  computed: {
    IS_EMPRESA_MATRIZ() {
      return this.model && this.model.filiais && this.model.filiais.length > 0;
    },
  },
  filters: {
    CNPJ,
    EMPTY,
  },
  components: {
    CheckboxMatrizField,
    CnpjMatrizField,
    TableEstruturaRateio,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
.title-filial {
  font-size: 16px;
  font-weight: 600;
}

.margin-filial {
  margin-top: 32px;
}

thead {
  font-size: 16px;
}

tbody {
  color: #505050;
  font-size: 12.8px;
  cursor: pointer;
}
</style>
