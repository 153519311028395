<template id="switch-button">
  <div class="switch-button-control col col-lg-auto">
    <div class="input-group">
      <label for="switchField">
        Cliente com Gestor
        <div
          class="switch-button"
          :class="{ enabled: model.content }"
          @click="!disabled && (model.content = !model.content)"
          :style="{ '--color': '#FFFFFF' }"
        >
          <div class="button"></div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
  },
  data() {
    return {
      loading: undefined,
      model: {
        name: "clienteGestor",
        content: false,
      },
    };
  },
  watch: {
    "model.content": {
      deep: true,
      handler() {
        if (!this.model.content) {
          this.$parent.resetGestorView(CONFIRMATION.GESTOR.UNBIND, true);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
.switch-button-control {
  .switch-button {
    margin-top: 10px !important;
    background: $primary--gray;
    $switch-button-height: 1em;
    $switch-button-color: transparent;
    $switch-button-border-thickness: 2px;
    $switch-transition: all 0.3s ease-in-out;
    $switch-is-rounded: true;

    height: $switch-button-height;
    width: calc(#{$switch-button-height} * 2);
    border: $switch-button-border-thickness solid $switch-button-color;
    box-shadow: inset 0px 0px $switch-button-border-thickness 0px
      rgba(0, 0, 0, 0.33);
    border-radius: if($switch-is-rounded, $switch-button-height, 0);

    transition: $switch-transition;

    $button-side-length: calc(
      #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
    );

    cursor: pointer;

    .button {
      height: $button-side-length;
      width: $button-side-length;
      border: $switch-button-border-thickness solid $switch-button-color;
      border-radius: if($switch-is-rounded, $button-side-length, 0);
      background: white;
      box-shadow: 0.5px 0.5px 2px $black-overlay;

      transition: $switch-transition;
    }

    &.enabled {
      background-color: $primary--orange;
      box-shadow: none;

      .button {
        background: white;
        transform: translateX(
          calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
        );
      }
    }
  }
}
</style>
