<template>
  <div class="col col-lg-5">
    <div class="input-group">
      <span>
        Tipo de Empresa (WBC)
        <span
          class="recent-updated-label"
          v-if="recentUpdatedField && !isDisabled"
        >
          <tooltip-informacao-alterada />
        </span>
      </span>
      <v-select
        multiple
        label="nome"
        class="w-100"
        :disabled="isDisabled"
        v-model="model.content"
        :options="nonSelectedItems"
      >
        <span slot="no-options"></span>
      </v-select>
    </div>
    <div class="form-text">Escolha o tipo de empresa.</div>
  </div>
</template>

<script>
import { Buffer } from "buffer";
import { userHasPermissions } from "@/utils/permissions";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      list: new Array(),
      model: {
        name: "categorias",
        content: new Array(),
      },
    };
  },
  methods: {
    buscarPermissoes() {
      let permissions = window.sessionStorage.getItem("p-keys");
      if (permissions) {
        permissions = JSON.parse(
          Buffer.from(permissions, "base64").toString("utf8")
        );
      }
      return permissions;
    },
    mountCategories() {
      return new Promise((resolve, reject) => {
        EmpresaService.listCategorias()
          .then(({ data }) => {
            const permissions = this.buscarPermissoes();
            this.list = data.filter((item) => {
              if (item.nome === "Administrador") {
                if (
                  userHasPermissions(permissions, [
                    "CENTRAL_CADASTROS_PARAMETRIZAR",
                  ])
                ) {
                  return item;
                }
              } else {
                return item;
              }
            });
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error);
            reject();
          });
      });
    },
  },
  computed: {
    nonSelectedItems() {
      if (this.model?.content?.length) {
        return this.list?.filter?.((item) => {
          return !this.model?.content
            ?.map?.((item) => item?.nome)
            .includes(item?.nome);
        });
      } else {
        return this.list;
      }
    },
    isDisabled() {
      if (!this.$parent.model.tipo) return false;
      if (
        this.$parent.model.tipo.restrictions.fields.includes(this.model.name)
      ) {
        this.model.content = new Array();
        return true;
      }
      if (this.disabled) return true;
      return false;
    },
  },
  watch: {
    modelProp: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.modelProp) return;
        if (
          JSON.stringify(this.model.content) ==
          JSON.stringify(this.modelProp[this.model.name])
        )
          return;
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style lang="scss">
@import "@/assets/style/style.scss";
@import "vue-select/src/scss/vue-select.scss";
label {
  width: 100% !important;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__selected-options {
  background-color: #e9ecef !important;
}

.vs__dropdown-toggle {
  border: 1px solid #ced4da;
  padding: 0 0 8px 0 !important;
  border-radius: 2px 0 0 2px !important;
}
</style>
