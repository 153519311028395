<template>
  <div>
    <div class="wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th
              v-for="(titleColuna, titleColunaColunaIndex) in config.colunas"
              :key="`titleColunaColunaIndex${titleColunaColunaIndex}`"
            >
              {{ titleColuna.description }}
            </th>
          </tr>
        </thead>
        <tbody v-if="config.conteudo.length > 0">
          <tr
            @click="click(linha)"
            v-for="(linha, linhaIndex) in config.conteudo"
            :key="`linhaIndex${linhaIndex}`"
          >
            <td
              v-for="(coluna, indexColuna) in config.colunas"
              :key="`indexColuna${indexColuna}`"
            >
              <span>{{ getData(linha, coluna.name) }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" :colspan="config.colunas.length">
              Nenhum registro encontrado
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  props: {
    config: { type: Object, required: true },
  },
  methods: {
    click(value) {
      this.$emit("click", value);
    },
    getData(linha, coluna) {
      return linha[coluna];
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.table {
  cursor: pointer;
}
</style>
