<template>
  <div class="mt-4 wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="text-center" scope="col"></th>
          <th
            scope="col"
            class="cursop"
            @click="$emit('sortBy', 'tipoEnergia.nome')"
          >
            Tipo de Energia
          </th>
          <th
            scope="col"
            class="cursop"
            @click="$emit('sortBy', 'codigoPerfilAgente')"
          >
            Código Perfil do Agente
          </th>
          <th
            scope="col"
            class="cursop"
            @click="$emit('sortBy', 'siglaPerfilAgente')"
          >
            Sigla Perfil do Agente
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <transition-group
        tag="tbody"
        name="list"
        mode="in-out"
        v-if="agentesList && agentesList.length > 0"
      >
        <tr
          v-for="(agente, agenteIndex) in agentesList"
          :key="`perfil_agente_${agenteIndex}`"
          :class="{
            'recent-updated-row': recentUpdatedField(agente.id),
          }"
          :style="disabled && { backgroundColor: '#e9ecef' }"
        >
          <td>
            <div class="pt-1 d-flex align-items-center justify-content-center">
              <tooltip
                v-if="recentUpdatedField(agente.id)"
                :width="450"
                boxAlign="right"
                arrowAlign="right"
                icon="icon-warning"
              >
                <template #tooltip-text>
                  Possui informações alteradas recentemente, pendente de
                  aprovação
                </template>
              </tooltip>
            </div>
          </td>
          <td>
            {{ agente.tipoEnergia.nome }}
          </td>
          <td>
            {{ agente.codigoPerfilAgente }}
          </td>
          <td>
            {{ agente.siglaPerfilAgente }}
          </td>

          <td>
            <i
              v-if="!disabled && !editDisabled"
              class="icon-lapis icon cursop"
              @click="$emit('editAgente', agente)"
            />
          </td>
          <td>
            <i
              v-if="!disabled"
              class="icon-lixeira icon ms-3 cursop"
              @click="$emit('deleteAgente', agente)"
            />
          </td>
        </tr>
      </transition-group>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="7">Nenhum agente adicionado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";
export default {
  props: {
    disabled: Boolean,
    editDisabled: Boolean,
    agentesList: {
      type: Array,
      required: false,
      default() {
        return new Array();
      },
    },
    recentUpdatedFields: {
      type: Array,
      required: false,
      default() {
        return new Array();
      },
    },
  },
  methods: {
    recentUpdatedField(agenteId) {
      return this.recentUpdatedFields.find(
        (agenteAlterado) => agenteId === agenteAlterado.id
      );
    },
  },
  components: {
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon {
  color: $primary--orange !important;
}

th {
  font-weight: 500;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
