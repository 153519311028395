<template>
  <div class="container-fluid" v-async="loading">
    <div class="row my-4">
      <strong>
        Adicione o E-mail e a Frequência para o recebimento do Extrato de Status
        de Cadastro
      </strong>
    </div>

    <div class="row">
      <div class="col col-lg-6">
        <div class="input-group">
          <label for="emailField">
            E-mail
            <input
              v-model="$v.model.content.email.$model"
              :class="{
                'is-invalid': $v.model.content.email.$error,
              }"
              maxlength="100"
              class="form-control"
              id="emailField"
              ref="emailField"
            />
            <div class="form-text">
              Informe o e-mail que receberá o extrato.
            </div>
          </label>
        </div>
      </div>

      <div class="col col-lg-4">
        <div class="input-group">
          <label for="frequenciaField">
            Frequência para o envio

            <input
              v-model="$v.model.content.frequencia.$model"
              :class="{
                'is-invalid': $v.model.content.frequencia.$error,
              }"
              maxlength="100"
              class="form-control"
              v-mask="'999'"
              id="frequenciaField"
              ref="frequenciaField"
            />

            <div class="form-text">
              Informe a frequência para o envio em dias.
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="ms-auto col-2">
        <button class="btn btn-secondary w-100" @click="cleanUpFields">
          Limpar
        </button>
      </div>
      <div class="col-2">
        <button v-if="editing" class="btn btn-primary w-100">Atualizar</button>
        <button v-else class="btn btn-primary w-100" @click="add">
          Adicionar
        </button>
      </div>
      <div class="col-2"></div>
      <!-- DIV FOR SPACING THE MS-AUTO! -->
    </div>

    <div class="row mt-4">
      <div class="col-10">
        <transition-group
          name="list"
          tag="ul"
          mode="in-out"
          class="list-params"
        >
          <li
            v-for="(parametro, indexParametro) in list"
            :key="`indexParametro${indexParametro}`"
          >
            <div>
              <span
                class="pe-3 small text-break"
                :class="{ 'text-secondary': !parametro.ativo }"
              >
                {{ parametro.email }}
              </span>

              <span class="pe-3 small text-break">
                {{
                  parametro.frequenciaEmDias
                    ? `${parametro.frequenciaEmDias} dias`
                    : "-"
                }}
              </span>

              <div
                style="width: 15%"
                class="d-flex align-items-center justify-content-between"
              >
                <i
                  @click="removeParametro(parametro.id)"
                  class="icon-lixeira cursop"
                ></i>
              </div>
            </div>
          </li>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { isValidEmail } from "@/filters/assertions";
import { required } from "vuelidate/lib/validators";
import TarefaService from "@/services/portal/empresa/TarefaService.js";
export default {
  data() {
    return {
      loading: undefined,
      editing: false,
      model: {
        name: "",
        content: {
          email: "",
          frequencia: "",
        },
      },
      list: new Array(),
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  mounted() {
    this.setBreadcrumbsParam("Status dos Cadastros");
    this.getTarefas();
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      let invalid = false;
      if (
        this.model.content.email == "" &&
        this.model.content.frequencia == ""
      ) {
        this.mxToggleErrorMessageAlert(
          "Preencha todos os campos para prosseguir!"
        );
        return true;
      }
      if (this.$v.model.content.email.$error) {
        this.mxToggleErrorMessageAlert("Campo Email inválido");
        invalid = true;
      }
      if (this.$v.model.content.frequencia.$error) {
        invalid = true;
      }
      return invalid;
    },
    add() {
      if (this.isInvalid()) return;
      const item = new Object({
        email: this.model.content.email,
        frequenciaEmDias: this.model.content.frequencia,
      });

      TarefaService.configureTarefa(item)
        .then(() => {
          this.cleanUpFields();
          this.$v.$reset();
          this.getTarefas();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "AGENDAMENTO_TAREFA");
        });
    },
    cleanUpFields() {
      this.model.content.email = "";
      this.model.content.frequencia = "";
    },
    changeTab(key) {
      this.activeTab = key;
    },
    removeParametro(id) {
      this.mxToggleWarningModal(
        "Você está prestes a excluir o parâmetro selecionado. Deseja prosseguir?"
      )
        .then(() => {
          this.loading = this.service;
          this.loading = TarefaService.deleteTarefa({ id })
            .then(() => {
              this.getTarefas();
            })
            .catch((error) => {
              this.mxToggleErrorMessageAlert(error);
            });
        })
        .catch(() => {});
    },
    getTarefas() {
      this.loading = TarefaService.listTarefas()
        .then(({ data }) => {
          this.list = data;
          this.mxResetAllPendencies();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "AGENDAMENTO_TAREFA");
        });
    },
  },
  computed: {
    splittedLocalPartFromEmail() {
      if (this.model.content.email) {
        if (this.model.content.email.includes("@")) {
          return this.model.content.email.split("@")[0];
        }
        return this.model.content.email;
      }
      return "";
    },
  },
  components: {},
  validations: {
    model: {
      content: {
        email: {
          required,
          isValidEmail,
          localPartContainsLessThan64Char() {
            if (this.splittedLocalPartFromEmail.length > 64) {
              return false;
            }
            return true;
          },
        },
        frequencia: {
          required,
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.container-fluid {
  padding: 12px !important;
  height: 100%;
}

.list-params {
  padding: 0px;
  margin-top: 16px;
  li {
    display: flex;
    justify-content: space-between;
    div {
      &:first-child {
        padding: 8px 0px 8px 0px;
        border-bottom: 1px solid #e8e8e8;
        width: 100%;
        display: flex;
      }
      span {
        &:first-child {
          width: 100%;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}
</style>
