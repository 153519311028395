<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="codigoUnidadeConsumidoraField">
        Código da Unidade Consumidora
        <span v-if="IS_REQUIRED" class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          maxlength="30"
          :disabled="disabled"
          class="form-control"
          @input="checkSpecialCharacters"
          id="codigoUnidadeConsumidoraField"
          v-model.trim="$v.model.content.$model"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
        />
      </label>
    </div>
  </div>
</template>

<script>
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import { maxLength, requiredIf } from "vuelidate/lib/validators";

export default {
  props: {
    modelProp: Object,
    disabled: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "codigoUnidadeConsumidora",
        content: "",
      },
    };
  },
  methods: {
    checkSpecialCharacters() {
      if (!this.model.content) {
        this.model.content = "";
      } else {
        const newValueWOSpecialCharacters = this.model.content
          .trim()
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, "");
        this.model.content = newValueWOSpecialCharacters;
      }
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert(
          "Campo Código da Unidade Consumidora inválido"
        );
        return true;
      }
      return false;
    },
  },
  watch: {
    "$parent.model.codigoUnidadeConsumidora": {
      deep: true,
      handler() {
        this.model.content = this.$parent.model[this.model.name];
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.$parent.model[this.model.name] = this.model.content;
      },
    },
  },
  validations: {
    model: {
      content: {
        maxLength: maxLength(255),
        required: requiredIf(function () {
          return this.IS_REQUIRED;
        }),
      },
    },
  },
  computed: {
    IS_REQUIRED() {
      return (
        !this.modelProp?.pontoMedicao ||
        this.modelProp?.pontoMedicao.length !== 13
      );
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
