import { render, staticRenderFns } from "./GruposAcesso.vue?vue&type=template&id=3864a63e&scoped=true&"
import script from "./GruposAcesso.vue?vue&type=script&lang=js&"
export * from "./GruposAcesso.vue?vue&type=script&lang=js&"
import style0 from "./GruposAcesso.vue?vue&type=style&index=0&id=3864a63e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3864a63e",
  null
  
)

export default component.exports