<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="apelidoField">
        Apelido <span class="required-markup">*</span>
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="150"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          :disabled="disabled"
          id="apelidoField"
          v-model.trim="model.content"
        />
        <div class="form-text">
          Utilizado como identificador da empresa no Portal.
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "apelido",
        content: "",
      },
    };
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Apelido inválido");
        return true;
      }
      return false;
    },
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required,
        minLength: minLength(1),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
