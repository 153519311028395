export default {
  CONTATO_DF_APROVA: {
    id: "CONTATO_DF_APROVA",
    nome: "Aprova",
  },
  CONTATO_DF_NOTIFICADO: {
    id: "CONTATO_DF_NOTIFICADO",
    nome: "Notificado",
  },
  CONTATO_DF_NA: {
    id: "CONTATO_DF_NA",
    nome: "Não se Aplica",
  },
};
