<template>
  <div :class="`col col-lg-${size}`">
    <label :for="parentModel.id">
      <div class="checkbox-container input-group">
        <input
          type="checkbox"
          v-model="selected"
          :disabled="disabled"
          :id="parentModel.id"
        />
        <span class="ms-2 text-contact">
          {{ fieldTitle }}
        </span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: ["parentModel", "fieldTitle", "name", "size", "disabled"],
  data() {
    return {
      selected: false,
    };
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        if (this.selected) {
          this.parentModel.id === "CONTATO_TIPO_REPRESENTANTE_LEGAL" &&
            this.$emit("addRepresentante", this.parentModel);
        } else {
          this.parentModel.id === "CONTATO_TIPO_REPRESENTANTE_LEGAL" &&
            this.$emit("removeRepresentante", this.parentModel);
        }
        if (this.selected) {
          this.parentModel.id === "CONTATO_TIPO_COMPRADOR" &&
            this.$emit("addComprador", this.parentModel);
        } else {
          this.parentModel.id === "CONTATO_TIPO_COMPRADOR" &&
            this.$emit("removeComprador", this.parentModel);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";
@import "@/assets/style/breakpoints.scss";

label {
  cursor: pointer !important;
  width: 100% !important;
}
.checkbox-container {
  display: flex;
  align-items: center;
}

.text-contact {
  @include tablet {
    font-size: 14px;
  }
}

.text-contact {
  @include tablet-small {
    font-size: 13px;
  }
}
</style>
