<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="telefoneCelularField">
        Telefone Celular 
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          :disabled="disabled"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          v-mask="'(99) 99999-9999'"
          id="telefoneCelularField"
          v-model.trim="$v.model.content.$model"
        />
        <div class="form-text">
          Formato (00) 0 0000-0000.
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { isCelPhone } from "@/filters/assertions";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "telefoneCelular",
        content: "",
      },
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Telefone Celular inválido");
        return true;
      }
      return false;
    },
  },
  watch: {
    "model.content": {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal) {
          if (oldVal.replace(/[^\d]/g, "") != newVal.replace(/[^\d]/g, "")) {
            this.modelProp[this.model.name] = this.model.content;
          }
        } else {
          this.modelProp[this.model.name] = this.model.content;
        }
      },
    },
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
  },
  validations: {
    model: {
      content: {
        isCelPhone,
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },  
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
