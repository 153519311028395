<template>
  <div v-async="loading">
    <div class="row d-flex align-items-center justify-content-between">
      <div class="col-md-6 col-lg-6">
        <div>
          <search-bar
            ref="searchBar"
            @doSearch="doSearch"
            title="Buscar Empresa"
            :cols="10"
          />
          <div class="form-text">
            Pesquise por Razão Social, CNPJ ou Apelido
          </div>
        </div>
      </div>
      <div
        v-if="
          $can('CENTRAL_CADASTROS_EDITAR') ||
          $can('CENTRAL_CADASTROS_APROVAR') ||
          $can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        class="col-md-6 d-flex justify-content-end"
        style="margin-top: 15px; gap: 8px"
      >
        <router-link to="/cadastros/empresas">
          <button class="btn btn-primary-blue">+ Adicionar Empresa</button>
        </router-link>
        <router-link to="/cadastros/empresas/lote">
          <button class="btn btn-secondary-blue">
            Adicionar Empresas em Lote
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table-empresas-cadastradas
          class="mt-4"
          @nextPage="nextPage"
          @setPage="setPage"
          :config="tableConfig"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import Pagination from "@/components/pagination/pagination";
import DataTable from "@/components/tables/Table.vue";
import TableEmpresasCadastradas from "@/components/tables/TableEmpresasCadastradas.vue";
import EmpresaService from "@/services/portal/empresa/EmpresaService";

export default {
  data() {
    return {
      loading: undefined,
      dataTableKey: 0,
      tableConfig: {
        conteudo: new Array(),
        pagination: undefined,
      },
      filter: {
        size: 10,
        page: 0,
      },
    };
  },
  mounted() {
    if (
      JSON.parse(window.sessionStorage.getItem("was_token_recently_refreshed"))
    ) {
      window.sessionStorage.setItem("was_token_recently_refreshed", false);
      this.mxToggleToast("Token de acesso atualizado com sucesso");
    }
    this.doSearch();
  },
  methods: {
    nextPage(empresaId) {
      this.$router.push({
        name: "CadastroEmpresas",
        params: { id: empresaId },
      });
    },
    setPage(page) {
      this.filter.page = page;
      this.doSearch();
    },
    doSearch(search = this.$refs.searchBar.content, resetPage = false) {
      const { size, page } = this.filter;
      this.loading = EmpresaService.list({
        filtro: search,
        size,
        page: resetPage ? 0 : page,
        desc: true,
      })
        .then((response) => {
          this.tableConfig.conteudo = response.data.content;
          this.tableConfig.pagination = response.data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  components: {
    SearchBar,
    DataTable,
    Pagination,
    FormRowHeader,
    TableEmpresasCadastradas,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.text {
  @include phablet {
    margin-top: 10px;
  }
}
</style>
