 <template>
  <div class="col col-lg-4">
    <div>
      <label for="enderecoCidadeField">
        Cidade
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          :disabled="disabled"
          id="enderecoCidadeField"
          v-model="model.content"
          class="form-select"
          :class="{
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField,
          }"
        >
          <option
            v-for="(cidade, cidadeIndex) in list"
            :key="`uf_option_${cidadeIndex}`"
            :value="cidade"
          >
            {{ cidade.nome }}
          </option>
        </select>
      </label>
      <div
        v-if="loading"
        style="margin-left: -50px"
        class="spinner-border spinner-border-sm"
      ></div>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    list: Array,
    loading: Boolean,
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "cidade",
        content: "",
      },
    };
  },
  methods: {
    setContent() {
      if (this.model.content && typeof this.model.content === "string") {
        const cidadeObjectFromString = this.createObjectByCidade(
          this.model.content
        );
        if (cidadeObjectFromString) {
          this.model.content = cidadeObjectFromString;
        }
      }
    },
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    createObjectByCidade(cidadeParam) {
      return this.list.find(
        (cidade) =>
          cidade.nome.toUpperCase() === cidadeParam && cidadeParam.toUpperCase()
      );
    },
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          if (this.invalidYellow) {
            return true;
          }
          return false;
        }),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

.align-spinner {
  display: flex;
  align-items: center;
}
</style>
