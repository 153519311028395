<template>
  <div class="mt-5" v-async="loading">
    <div class="row">
      <form-row-header text="Cadastrar Ponto de Medição" />
    </div>
    <div class="row mt-3">
      <switch-ativo-field
        ref="switchField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('ativo')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <distribuidora-field
        ref="distribuidoraField"
        :list="distribuidoraList"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('distribuidora')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <codigo-unidade-consumidora-field
        ref="codigoUnidadeConsumidoraField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('codigoUnidadeConsumidora')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-3">
      <subgrupo-field
        ref="subgrupoField"
        :list="subgrupoList"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('subGrupo')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <modalidade-field
        ref="modalidadeField"
        :modelProp="model"
        :list="modalidadeList"
        :recentUpdatedField="recentUpdatedField('modalidadeTarifaria')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-3">
      <demanda-contratada-ponta-field
        ref="demandaContratadaPontaField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('demandaContratadaPonta')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <demanda-contratada-fora-ponta-field
        ref="demandaContratadaForaPontaField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('demandaContratadaForaPonta')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-3">
      <ponto-medicao-field
        :modelProp="model"
        ref="pontosDeMedicaoField"
        :recentUpdatedField="recentUpdatedField('pontoMedicao')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <data-migracao-field
        :modelProp="model"
        ref="dataMigracaoField"
        :recentUpdatedField="recentUpdatedField('dataMigracao')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <id-carga-field
        ref="idCargaField"
        :modelProp="model"
        :recentUpdatedField="recentUpdatedField('idCarga')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <id-carga-CCEE
        ref="idCargaCCEE"
        :modelProp="model"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('identificacaoCargaCCEE')"
      />
    </div>
    <div class="row">
      <switch-ponto-retaguarda-field
        v-if="model"
        :modelProp="model"
        ref="switchPontoRetaguardaField"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <ponto-retaguarda-field
        v-if="model"
        :modelProp="model"
        ref="pontoRetaguardaField"
        :pontoMedicao="model.pontoMedicao"
        :tipoRetaguardaAtivo="model.pontoRetaguardaAtivo"
        :recentUpdatedField="recentUpdatedField('pontoRetaguarda')"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row">
      <form-row-header text="Endereço" />
    </div>
    <div class="row mt-4">
      <endereco-unidades-consumidoras-fields
        :modelProp="model"
        @updateEndereco="updateEndereco"
        ref="enderecoFields"
        @setCidadesToLoadedState="setCidadesToLoadedState"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-5">
      <div class="col col-lg-auto ms-auto">
        <button class="btn btn-secondary" v-if="editing" @click="resetFields">
          Cancelar
        </button>
        <button
          @click="add"
          class="btn btn-primary ms-3"
          v-if="editing && CAN_SHOW_BUTTON"
        >
          Atualizar
        </button>
        <button
          @click="add"
          v-else-if="CAN_SHOW_BUTTON"
          class="btn btn-primary ms-3"
        >
          Adicionar
        </button>
      </div>
    </div>
    <div class="row">
      <form-row-header class="ms-2 mt-2" text="Pontos de Medição Cadastrados" />
    </div>
    <table-medicao-cadastrada
      :distribuidoraList="distribuidoraList"
      @editPontoMedicao="edit"
      :pontosDeMedicaoList="list"
      @deletePontoMedicao="remove"
      :recentUpdatedFields="recentUpdatedFields"
      :recentCreatedPontosMedicao="recentCreatedPontosMedicao"
      :disabled="
        !this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
      "
    />
  </div>
</template>

<script>
import CRUDTemplate from "@/components/CRUDTemplate";
import EmpresaModel from "@/models/empresa/EmpresaModel";
import EnderecoModel from "@/models/empresa/EnderecoModel";
import SubgrupoService from "@/services/portal/SubgrupoService";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import { formatArrayDateToISODate } from "@/utils/functionUtils";
import ModalidadeService from "@/services/portal/ModalidadeService";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import PontoDeMedicaoModel from "@/models/empresa/PontoDeMedicaoModel";
import DistribuidorasService from "@/services/portal/DistribuidorasService";
import IdCargaCCEE from "@/components/forms/fields/empresa/IdCargaCCEE.vue";
import IdCargaField from "@/components/forms/fields/empresa/IdCargaField.vue";
import PontoMedicaoService from "@/services/portal/empresa/PontoMedicaoService";
import TableMedicaoCadastrada from "@/components/tables/TableMedicaoCadastrada.vue";
import SwitchAtivoField from "@/components/forms/fields/empresa/SwitchAtivoField.vue";
import PontoMedicaoField from "@/components/forms/fields/empresa/PontoMedicaoField.vue";
import DataMigracaoField from "@/components/forms/fields/empresa/DataMigracaoField.vue";
import SubgrupoField from "@/components/forms/fields/empresa/SubgrupoField.vue";
import ModalidadeField from "@/components/forms/fields/empresa/ModalidadeField.vue";
import PontoRetaguardaField from "@/components/forms/fields/empresa/PontoRetaguardaField.vue";
import EnderecoUnidadesConsumidorasFields from "../fields/EnderecoUnidadesConsumidorasFields.vue";
import DistribuidoraField from "@/components/forms/fields/empresa/DistribuidoraField.vue";
import SwitchPontoRetaguardaField from "@/components/forms/fields/empresa/SwitchPontoRetaguardaField.vue";
import DemandaContratadaPontaField from "@/components/forms/fields/empresa/DemandaContratadaPontaField.vue";
import DemandaContratadaForaPontaField from "@/components/forms/fields/empresa/DemandaContratadaForaPontaField.vue";
import CodigoUnidadeConsumidoraField from "@/components/forms/fields/empresa/CodigoUnidadeConsumidoraField.vue";

export default {
  extends: CRUDTemplate,
  data() {
    return {
      cidadesIsLoaded: false,
      subgrupoList: new Array(),
      shouldRepararString: false,
      modalidadeList: new Array(),
      distribuidoraList: new Array(),
      recentUpdatedFields: new Array(),
      recentCreatedPontosMedicao: new Array(),
    };
  },
  mounted() {
    const { empresaId } = this.$parent;
    this.toggleLoading(true);
    EmpresaService.getById({ empresaId })
      .then(({ data }) => {
        this.service = PontoMedicaoService;
        this.requestEntity = "ponto_medicao";
        this.model = new PontoDeMedicaoModel();
        this.empresa = new EmpresaModel(data);
        this.setHeaderText(this.empresa.razaoSocial);
        this.setBreadcrumbsParam(this.empresa.tipo.nome);
        this.getByEmpresa();
        this.mountRecentUpdatedFields(empresaId);
      })
      .catch((error) => this.mxHandleRequestError(error, "ponto_medicao"))
      .finally(() => {
        if (this.cidadesIsLoaded == true) {
          this.toggleLoading(false);
        }
      });

    this.toggleLoading(true);
    DistribuidorasService.getDistribuidoras()
      .then(({ data }) => {
        this.distribuidoraList = data;
      })
      .catch((error) => this.mxHandleRequestError(error))
      .finally(() => {
        if (this.cidadesIsLoaded == true) {
          this.toggleLoading(false);
        }
      });
    SubgrupoService.getSubgrupos()
      .then(({ data }) => {
        this.subgrupoList = data;
      })
      .catch((error) => this.mxHandleRequestError(error))
      .finally(() => {
        if (this.cidadesIsLoaded == true) {
          this.toggleLoading(false);
        }
      });

    ModalidadeService.getModalidades()
      .then(({ data }) => {
        this.modalidadeList = data;
      })
      .catch((error) => this.mxHandleRequestError(error))
      .finally(() => {
        if (this.cidadesIsLoaded == true) {
          this.toggleLoading(false);
        }
      });
  },
  methods: {
    toggleRepararString() {
      this.shouldRepararString = !this.shouldRepararString;
    },
    toggleLoading(show, forceHide) {
      if (forceHide) {
        this.loading = false;
        return;
      }
      if (typeof this.loading === "object") {
        if (show) return;
        else {
          if (this.cidadesIsLoaded) {
            this.loading = false;
          }
        }
      } else {
        if (show) {
          this.loading = new Promise((res, rej) => {});
        }
      }
    },
    setCidadesToLoadedState() {
      this.cidadesIsLoaded = true;
    },
    mountRecentUpdatedFields(empresaId) {
      this.toggleLoading(true);
      EmpresaService.getEmpresaPontosMedicaoRecentUpdates({
        empresaId,
      })
        .then(({ data }) => {
          this.recentUpdatedFields = data;

          this.recentCreatedPontosMedicao = this.recentUpdatedFields
            .map((pontoMedicaoAlterado) => {
              if (pontoMedicaoAlterado.campos.length == 0) {
                return pontoMedicaoAlterado.id;
              }
            })
            .filter(
              (pontoMedicaoAlterado) => pontoMedicaoAlterado != undefined
            );
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        })
        .finally(() => {
          this.toggleLoading(false, true);
        });
    },
    edit(entity) {
      this.editing = true;
      this.toggleRepararString();
      this.cidadesIsLoaded = false;
      entity.dataMigracao = formatArrayDateToISODate(entity.dataMigracao);
      this.model = new PontoDeMedicaoModel(entity);
      const { empresaId } = this.$parent;
      this.mountRecentUpdatedFields(empresaId);
    },
    updateEndereco(endereco) {
      this.model = new PontoDeMedicaoModel({
        ...this.model.getData(),
        endereco: new EnderecoModel(endereco),
      });
    },
    resetFields() {
      this.editing = false;
      this.mxResetErrorPendencies();
      this.model = new PontoDeMedicaoModel();
      this.resetVuelidateFields();
    },
    resetVuelidateFields() {
      Object.keys(this.$refs).forEach((ref) => {
        if (!this.$refs[ref]) return;
        if (!this.$refs[ref].hasOwnProperty("isInvalid")) return;
        this.$refs[ref].$v?.$reset();
      });
    },
    add() {
      (this.editing ? this.update(true) : this.save(true))
        .then(() => {
          const { empresaId } = this.$parent;
          this.mountRecentUpdatedFields(empresaId);
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            this.mxToggleErrorMessageAlert(error.response.data.message);
            return;
          }
          !!error && this.mxHandleRequestError(error);
        });
    },
    recentUpdatedField(field) {
      const updatedFieldsFiltered =
        this.recentUpdatedFields.find(
          (pontoMedicaoAlterado) => this.model.id === pontoMedicaoAlterado.id
        ) ?? false;

      if (updatedFieldsFiltered) {
        return (
          updatedFieldsFiltered.campos &&
          updatedFieldsFiltered.campos.includes(field)
        );
      }
      return false;
    },
  },
  watch: {
    cidadesIsLoaded: {
      handler() {
        if (this.cidadesIsLoaded == true) {
          this.toggleLoading(false);
        }
      },
    },
  },
  components: {
    IdCargaCCEE,
    IdCargaField,
    FormRowHeader,
    SubgrupoField,
    ModalidadeField,
    SwitchAtivoField,
    PontoMedicaoField,
    DataMigracaoField,
    DistribuidoraField,
    PontoRetaguardaField,
    TableMedicaoCadastrada,
    SwitchPontoRetaguardaField,
    DemandaContratadaPontaField,
    CodigoUnidadeConsumidoraField,
    DemandaContratadaForaPontaField,
    EnderecoUnidadesConsumidorasFields,
  },
  computed: {
    CAN_SHOW_BUTTON() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
