<template>
  <div class="col col-lg-3">
    <label for="idAnexo">
      Tipo do Anexo
      <span class="required-markup">*</span>
      <select
        :disabled="disabled"
        class="form-select"
        id="idAnexo"
        :class="{
          'is-invalid': $v.model.content.$error,
        }"
        v-model="model.content"
        placeholder="Selecione"
      >
        <option
          :value="tipoAnexo"
          v-for="(tipoAnexo, tipoAnexoIndex) in tipoAnexoList"
          :key="`anexo_type_${tipoAnexoIndex}`"
        >
          {{ tipoAnexo.nome }}
        </option>
      </select>
    </label>
    <div class="form-text">Escolha uma das opções.</div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ArquivoService from "@/services/portal/empresa/ArquivoService";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
  },
  data() {
    return {
      tipoAnexoList: new Array(),
      loadList: new Array(),
      model: {
        name: "tipo",
        content: "",
      },
    };
  },
  mounted() {
    this.loadList = ArquivoService.listTipoArquivo()
      .then((response) => {
        this.tipoAnexoList = response.data.filter(
          (tipoAnexo) => tipoAnexo.ativo
        );
      })
      .catch((error) => console.log(error));
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Selecione o tipo de anexo");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100%;
}
</style>
