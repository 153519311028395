<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="cnpjMatrizField">
        CNPJ da Empresa Mãe
        <span
          class="recent-updated-label"
          v-if="recentUpdatedField && !isDisabled"
        >
          <tooltip-informacao-alterada />
        </span>
        <input
          :disabled="isDisabled"
          ref="cnpjMatrizField"
          id="cnpjMatrizField"
          class="form-control"
          v-mask="'99.999.999/9999-99'"
          :class="{
            'is-invalid': $v.model.content.$error,
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField && !isDisabled,
          }"
          v-model.trim="$v.model.content.$model"
        />
        <div class="form-text">Somente números.</div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { CNPJ } from "@/filters/formatters";
import { isCnpj } from "@/filters/assertions";
import { validateCNPJ } from "@/utils/functionUtils";
import { requiredIf } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: ["modelProp", "invalidYellow", "recentUpdatedField", "disabled"],
  data() {
    return {
      model: {
        name: "matrizCnpj",
        content: "",
      },
    };
  },
  directives: {
    mask: AwesomeMask,
  },
  methods: {
    isInvalid() {
      if (this.model.content && this.contentsAreEqual()) {
        this.mxToggleErrorMessageAlert(
          "O campo de CNPJ da empresa não pode ser igual ao campo de CNPJ da Empresa Mãe"
        );
        return true;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo CNPJ da Empresa Mãe inválido");
        this.$refs.cnpjMatrizField.classList.remove("is-invalid-yellow");
        return true;
      }
      return false;
    },
    contentsAreEqual() {
      if (this.model.content && this.$parent.model.cnpj) {
        if (CNPJ(this.model.content) === CNPJ(this.$parent.model.cnpj)) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
    "model.content": {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          if (oldVal.replace(/[^\d]/g, "") != newVal.replace(/[^\d]/g, "")) {
            this.modelProp[this.model.name] = this.model.content;
          }
        } else {
          this.modelProp[this.model.name] = this.model.content;
        }
      },
    },
    modelProp: {
      deep: true,
      immediate: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
      },
    },
  },
  computed: {
    isDisabled() {
      return this.$parent.model.matriz || this.disabled;
    },
  },
  validations: {
    model: {
      content: {
        isCnpj,
        required: requiredIf(function () {
          return !this.$parent.model.matriz;
        }),
        validateCNPJ,
        contentIsNotDuplicated() {
          return !this.contentsAreEqual();
        },
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
