import EnderecoModel from "./EnderecoModel";
import EmpresaTipoENUM from "@/enums/EmpresaTipoENUM";
import RegimeEspecialENUM from "@/enums/RegimeEspecialENUM";
import InscricaoEstadualTypeENUM from "@/enums/InscricaoEstadualTypeENUM";

export default class DadosGeraisModel {
  constructor(data = new Object()) {
    this._id = data.id;
    this._tipo = data.tipo;
    this._cnpj = data.cnpj;
    this._matriz = data.matriz;
    this._integrado = data.integrado;
    this._dataIntegrado = data.dataIntegrado;
    this._razaoSocial = data.razaoSocial;
    this._apelido = data.apelido;
    this._nomeFantasia = data.nomeFantasia;
    this._setor = data.setor;
    this._inscricaoEstadual = data.inscricaoEstadual;
    this._inscricaoEstadualTipo = data.inscricaoEstadualTipo;
    this._inscricaoEstadualEstado = data.inscricaoEstadualEstado;
    this._regimeEspecial = data.regimeEspecial;
    this._cnae = data.cnae;
    this._descricaoCnae = data.descricaoCnae;
    this._segmento = data.segmento;
    this._matrizCnpj = data.matrizCnpj;
    this._representante = data.representante;
    this._categorias = data.categorias;
    this._classeAgente = data.classeAgente;
    this._endereco = data.endereco;
    this._status = data.status;
    this._mounting = data.mounting;
    this._isProvisorio = data.isProvisorio;
    this._agenteCcee = data.agenteCcee;
    this._empresaRepresentada = data.empresaRepresentada;
    this._isIntegradoSap = data.isIntegradoSap;
  }

  set mounting(mounting = false) {
    this._mounting = mounting;
  }

  get mounting() {
    return this._mounting;
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id || null;
  }

  set inscricaoEstadualTipo(inscricaoEstadualTipo) {
    this._inscricaoEstadualTipo = inscricaoEstadualTipo;
  }

  get inscricaoEstadualTipo() {
    return (
      this._inscricaoEstadualTipo || InscricaoEstadualTypeENUM.CONTRIBUINTE
    );
  }

  set cnpj(cnpj = null) {
    this._cnpj = cnpj;
  }

  get cnpj() {
    if (this._cnpj) {
      return this._cnpj.replace(/[^\d]/g, "");
    }
    return null;
  }

  set tipo(tipo = null) {
    this._tipo = tipo;
  }

  get tipo() {
    return this._tipo || EmpresaTipoENUM.CONSUMIDOR;
  }

  set matriz(matriz = false) {
    this._matriz = matriz;
    if (matriz) {
      this._matrizCnpj = "";
    }
  }

  get matriz() {
    return this._matriz ?? true;
  }

  set razaoSocial(razaoSocial = null) {
    this._razaoSocial = razaoSocial;
  }

  get razaoSocial() {
    return this._razaoSocial;
  }

  set integrado(integrado = null) {
    this._integrado = integrado;
  }

  get integrado() {
    return this._integrado;
  }

  set dataIntegrado(dataIntegrado = null) {
    this._dataIntegrado = dataIntegrado;
  }

  get dataIntegrado() {
    return this._dataIntegrado;
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get apelido() {
    return this._apelido || null;
  }

  set segmento(segmento = null) {
    this._segmento = segmento;
  }

  get segmento() {
    // return this._segmento || null;
    return null;
  }

  set regimeEspecial(regimeEspecial = null) {
    this._regimeEspecial = regimeEspecial;
  }

  get regimeEspecial() {
    return this._regimeEspecial || RegimeEspecialENUM.NAO_SE_APLICA;
  }

  set representante(representante = null) {
    this._representante = representante;
  }

  get representante() {
    return this._representante || null;
  }

  set nomeFantasia(nomeFantasia = null) {
    this._nomeFantasia = nomeFantasia;
  }

  get nomeFantasia() {
    return this._nomeFantasia || null;
  }

  set setor(setor = null) {
    this._setor = setor;
  }

  get setor() {
    return this._setor;
  }

  set cnae(cnae = null) {
    this._cnae = cnae;
  }

  get cnae() {
    if (this._cnae) {
      return this._cnae.replace(/[^\d]/g, "");
    }
    return null;
  }

  set descricaoCnae(descricaoCnae = null) {
    this._descricaoCnae = descricaoCnae;
  }

  get descricaoCnae() {
    return this._descricaoCnae;
  }

  set inscricaoEstadual(inscricaoEstadual = { tipo: null, numero: null }) {
    this._inscricaoEstadual = inscricaoEstadual;
  }

  get inscricaoEstadual() {
    if (this._inscricaoEstadual) {
      return this._inscricaoEstadual;
    }
    return null;
  }

  set inscricaoEstadualEstado(inscricaoEstadualEstado) {
    this._inscricaoEstadualEstado = inscricaoEstadualEstado;
  }

  get inscricaoEstadualEstado() {
    return this._inscricaoEstadualEstado;
  }

  set matrizCnpj(matrizCnpj = null) {
    this._matrizCnpj = matrizCnpj;
  }

  get matrizCnpj() {
    return this._matrizCnpj ? this._matrizCnpj.replace(/[^\d]/g, "") : null;
  }

  set categorias(categorias = null) {
    this._categorias = categorias;
  }

  get categorias() {
    return this._categorias && this._categorias.length > 0
      ? this._categorias
      : null;
  }

  set classeAgente(classeAgente = null) {
    this._classeAgente = classeAgente;
  }

  get classeAgente() {
    return this._classeAgente || null;
  }

  set endereco(endereco) {
    this._endereco = endereco;
  }

  get endereco() {
    return this._endereco || new EnderecoModel();
  }

  set status(status = null) {
    this._status = status;
  }

  get status() {
    return this._status || null;
  }

  set isProvisorio(isProvisorio = false) {
    this._isProvisorio = isProvisorio;
  }

  get isProvisorio() {
    return this._isProvisorio || false;
  }

  set agenteCcee(agenteCcee = false) {
    this._agenteCcee = agenteCcee;
  }

  get agenteCcee() {
    return this._agenteCcee || false;
  }

  set empresaRepresentada(empresaRepresentada = false) {
    this._empresaRepresentada = empresaRepresentada;
  }

  get empresaRepresentada() {
    return this._empresaRepresentada || false;
  }

  set isIntegradoSap(isIntegradoSap = false) {
    this._isIntegradoSap = isIntegradoSap;
  }

  get isIntegradoSap() {
    return this._isIntegradoSap || false;
  }

  getData() {
    const {
      id,
      tipo,
      cnae,
      descricaoCnae,
      cnpj,
      setor,
      matriz,
      apelido,
      segmento,
      integrado,
      dataIntegrado,
      razaoSocial,
      nomeFantasia,
      inscricaoEstadual,
      inscricaoEstadualEstado,
      inscricaoEstadualTipo,
      regimeEspecial,
      matrizCnpj,
      representante,
      endereco,
      categorias,
      classeAgente,
      status,
      isProvisorio,
      agenteCcee,
      empresaRepresentada,
      isIntegradoSap,
    } = this;
    return {
      id,
      tipo,
      cnpj,
      matriz,
      integrado,
      dataIntegrado,
      razaoSocial,
      apelido,
      nomeFantasia,
      setor,
      inscricaoEstadual,
      inscricaoEstadualEstado,
      inscricaoEstadualTipo,
      regimeEspecial,
      cnae,
      descricaoCnae,
      segmento,
      matrizCnpj,
      representante,
      endereco: endereco.getData(),
      categorias,
      classeAgente,
      status,
      isProvisorio,
      agenteCcee,
      empresaRepresentada,
      isIntegradoSap,
    };
  }
}
