<template>
  <div class="col col-lg-3 tipo-contato-container shadow p-4 mb-5 rounded">
    <span class="fw-bold mb-3">Nota Fiscal</span>
    <label for="recebeNotaFiscalField">
      <span class="checkbox-container input-group">
        <input
          type="checkbox"
          v-model="selected"
          :disabled="disabled"
          id="recebeNotaFiscalField"
        />
        <span class="ms-2"> Recebe Nota Fiscal </span>
      </span>
    </label>
  </div>
</template>

<script>
import RecebeNotaFiscalENUM from "@/enums/RecebeNotaFiscalENUM";
export default {
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      model: {
        name: "recebeNotaFiscal",
        content: null,
      },
      selected: false,
    };
  },
  methods: {
    cleanUpFields() {
      this.selected = false;
    },
  },
  computed: {
    parentModel() {
      return this.$parent.model;
    },
    RecebeNotaFiscalENUM: () => RecebeNotaFiscalENUM,
  },
  watch: {
    selected() {
      if (this.selected) {
        this.model.content = RecebeNotaFiscalENUM.CONTATO_NF_RECEBE;
        this.$parent.model.permissoes[this.model.name] = this.model.content;
      } else {
        this.model.content = null;
        this.$parent.model.permissoes[this.model.name] = this.model.content;
      }
    },
    "$parent.model.permissoes": {
      deep: true,
      handler() {
        if (
          this.$parent.model.permissoes.recebeNotaFiscal &&
          this.$parent.model.permissoes.recebeNotaFiscal.id ===
            RecebeNotaFiscalENUM.CONTATO_NF_RECEBE.id
        ) {
          this.selected = true;
          this.model.content = this.$parent.model.permissoes[this.model.name];
        } else {
          this.selected = false;
          this.model.content = null;
        }
      },
    },
  },
  validations: {
    model: {
      content: {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";

label {
  width: 100% !important;
}
.checkbox-container {
  display: flex;
  align-items: center;
}

.tipo-contato-container {
  display: flex;
  flex-direction: column;
}

input,
label {
  cursor: pointer !important;
}
</style>
