<template>
  <div class="col col-lg-2 d-flex align-items-center justify-content-center">
    <div class="input-group checkbox-tooltip-container">
      <label for="checkboxMatrizField">
        <input
          type="checkbox"
          id="checkboxMatrizField"
          v-model.trim="$v.model.content.$model"
          :disabled="isEmpresaMatriz || disabled"
        />
        Boleta Mãe WBC
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada box="left" />
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  extends: FieldTemplate,
  props: {
    modelProp: Object,
    disabled: Boolean,
    isEmpresaMatriz: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "matriz",
        content: false,
      },
    };
  },
  mounted() {
    if (this.modelProp && !this.modelProp.matrizCnpj) {
      this.modelProp[this.model.name] = true;
      this.model.content = true;
    }
  },
  validations: {
    model: {
      content: {},
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";

label {
  width: auto !important;
}

.checkbox-tooltip-container {
  display: flex;
  flex-direction: row;
}
</style>
