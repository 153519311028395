<template>
  <div class="col col-lg-5">
    <div class="input-group">
      <label for="inscricaoEstadualField">
        Inscrição Estadual
        <span
          class="recent-updated-label"
          v-if="recentUpdatedField && !isDisabled"
        >
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="20"
          class="form-control"
          ref="inscricaoEstadualField"
          id="inscricaoEstadualField"
          :class="{
            'is-invalid': $v.model.content.$error,
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField && !isDisabled,
          }"
          v-model.trim="$v.model.content.$model"
          :disabled="isDisabled"
        />
        <div class="form-text">
          Somente números.
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import InscricaoEstadualTypeENUM from "@/enums/InscricaoEstadualTypeENUM";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "inscricaoEstadual",
        content: "",
      },
    };
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
    "$parent.model.inscricaoEstadualTipo": {
      immediate: true,
      deep: true,
      handler() {
        if (!!this.modelProp.inscricaoEstadualTipo) {
          if (
            this.$parent.model.inscricaoEstadualTipo.id ===
              InscricaoEstadualTypeENUM.ISENTO.id ||
            this.$parent.model.inscricaoEstadualTipo.id ===
              InscricaoEstadualTypeENUM.VERIFICAR.id ||
            this.$parent.model.inscricaoEstadualTipo.id ===
              InscricaoEstadualTypeENUM.NAO_LOCALIZADO.id
          ) {
            this.model.content = null;
          }
        }
      },
    },
  },
  computed: {
    InscricaoEstadualTypeENUM: () => InscricaoEstadualTypeENUM,
    isDisabled() {
      return (
        this.$parent.model.inscricaoEstadualTipo.id ===
          InscricaoEstadualTypeENUM.ISENTO.id ||
        this.$parent.model.inscricaoEstadualTipo.id ===
          InscricaoEstadualTypeENUM.VERIFICAR.id ||
        this.$parent.model.inscricaoEstadualTipo.id ===
          InscricaoEstadualTypeENUM.NAO_LOCALIZADO.id ||
        this.disabled
      );
    },
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^0-9]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Inscrição Estadual inválido");
        this.$refs.inscricaoEstadualField.classList.remove("is-invalid-yellow");
        return true;
      }
      return false;
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
  validations: {
    model: {
      content: {},
    },
  },
};
</script>

<style scoped lang="scss">
label {
  width: 100% !important;
}
</style>
