<template>
  <div>
    <div class="wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">CNPJ</th>
            <th class="text-center" scope="col">Razão Social</th>
            <th class="text-center" scope="col">Cadastrado na CC</th>
            <th class="text-center" scope="col">Integrado no WBC</th>
            <th scope="col">Descrição do Status</th>
            <th class="text-center" scope="col">Data de Finalização</th>
          </tr>
        </thead>
        <transition-group
          name="list"
          mode="in-out"
          tag="tbody"
          v-if="config.list && config.list.length"
        >
          <tr
            v-for="(registro, registroIndex) in config.list"
            :key="`registro_${registroIndex}`"
          >
            <td>{{ registro.cnpj }}</td>
            <td class="text-center">{{ registro.nome }}</td>
            <td class="text-center">
              <i v-if="registro.cadastroRealizado" class="icon-check small" />
              <i v-else class="icon-xis large" />
            </td>
            <td class="text-center">
              <i v-if="registro.integradoWbc" class="icon-check small" />
              <i v-else class="icon-xis large" />
            </td>
            <td>
              <template
                v-if="
                  !registro.detalhesProcessamento ||
                  registro.detalhesProcessamento.length <= 1
                "
              >
                {{ registro.detalheProcessamento || "--" }}
              </template>
              <ul v-else>
                <li
                  v-for="(detalhe, i) in registro.detalhesProcessamento"
                  :key="i"
                >
                  {{ detalhe }}
                </li>
              </ul>
            </td>
            <td class="text-center">
              {{ DATETIME_ptBR(registro.dataFinalizacao) || "--" }}
            </td>
          </tr>
        </transition-group>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="6">Nenhum registro encontrado</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination";
import { DATETIME_ptBR } from "@/filters/formatters";

export default {
  props: { config: Object },
  components: {
    Pagination,
  },
  methods: {
    DATETIME_ptBR,
    setPage(page) {
      this.config.filter.page = page;
      this.$parent.getHistoricoByEmpresa();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

th {
  font-weight: 500;
}
td {
  > ul {
    line-height: 16px;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
