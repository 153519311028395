<template>
  <div v-async="loading">
    <div class="mb-3 row d-flex justify-content-between align-items-center">
      <div
        v-if="!isNotCreated"
        class="d-flex align-items-end justify-content-between"
      >
        <nome-grupo-field
          :modelProp="model"
          :isCreating="false"
          @updateNomeGrupoEconomico="updateNomeGrupoEconomico"
          ref="nomeGrupoEconomicoField"
        />
        <div
          v-if="
            !isNotCreated &&
            ($can('CENTRAL_CADASTROS_APROVAR') || $can('CENTRAL_CADASTROS_PARAMETRIZAR'))
          "
        >
          <button @click="deleteGrupoEconomico" class="btn btn-primary-red">
            Apagar Grupo
          </button>
        </div>
      </div>
      <div v-else>
        <nome-grupo-field
          :modelProp="model"
          :isCreating="true"
          ref="nomeGrupoEconomicoField"
        />
      </div>
    </div>
    <div class="d-flex flex-column">
      <div :class="['col', 'col-6', isNotCreated ? 'order-1' : 'order-2']">
        <search-bar
          ref="searchBar"
          @doSearch="doSearch"
          placeholder="Busque por Nome, CNPJ..."
          title="Buscar Empresas"
          :cols="10"
        />
      </div>
      <div :class="['row mt-5', isNotCreated ? 'order-2' : 'order-3']">
        <form-row-header text="Empresas Encontradas" />
        <div class="col-12">
          <table-empresa
            class="mt-3"
            ref="tableEmpresa"
            @setPage="setPage"
            :empresasAdded="model.empresas"
            :empresas="tableConfig.conteudo"
            :pagination="tableConfig.pagination"
          />
          <div class="col col-12 d-flex justify-content-end">
            <button
              :disabled="!HAS_SOME_EMPRESA_SELECTED"
              v-if="isNotCreated && CAN_EDITAR"
              @click="addEmpresaChecked"
              class="btn btn-primary"
            >
              Adicionar ao Grupo
            </button>
          </div>
        </div>
      </div>
      <div :class="[isNotCreated ? 'order-3' : 'order-1']" class="row mt-6">
        <form-row-header text="Empresas do Grupo Econômico" />
        <div class="col-md-12">
          <table-empresa-selecionadas
            @deleteEmpresa="deleteEmpresa"
            :config="tableConfigAssociadas"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 d-flex justify-content-end">
        <button
          class="btn btn-secondary"
          :class="{ 'me-3': CAN_EDITAR }"
          @click="$router.push({ name: 'BuscarGrupoEconomico' })"
        >
          Cancelar
        </button>
        <button
          v-if="isNotCreated && CAN_EDITAR"
          @click="createGrupo"
          :disabled="model.empresas.length < 2"
          class="btn btn-primary"
        >
          Salvar
        </button>
        <button
          v-else-if="CAN_EDITAR"
          :disabled="!HAS_SOME_EMPRESA_SELECTED"
          @click="addEmpresaChecked"
          class="btn btn-primary"
        >
          Adicionar ao Grupo
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { formIsValid } from "@/utils/validators";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/pagination/pagination";
import TableEmpresa from "@/components/tables/TableEmpresa.vue";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import { HTTP_RESPONSE, CONFIRMATION } from "@/constants/strings";
import NomeGrupoField from "./components/fields/NomeGrupoField.vue";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import GrupoEconomicoModel from "@/models/empresa/GrupoEconomicoModel";
import GrupoEconomicoService from "@/services/portal/empresa/GrupoEconomicoService";
import TableEmpresaSelecionadas from "./components/tables/TableEmpresaSelecionadas.vue";

export default {
  data() {
    return {
      loading: undefined,
      isNotCreated: true,
      componenteLoading: undefined,
      editingComponent: false,
      model: new GrupoEconomicoModel(),
      tableConfig: {
        conteudo: new Array(),
        pagination: undefined,
      },
      tableConfigAssociadas: {
        colunas: [
          { name: "razaoSocial", description: "Razão Social" },
          { name: "cnpj", description: "CNPJ" },
          { name: "apelido", description: "Apelido" },
        ],
        conteudo: new Array(),
        pagination: undefined,
      },
      filter: {
        size: 10,
        page: 0,
      },
    };
  },
  mounted() {
    this.getGroupById();
    this.doSearch();
  },
  watch: {
    "model.empresas": {
      deep: true,
      handler() {
        this.tableConfigAssociadas.conteudo = this.model.empresas;
      },
    },
  },
  computed: {
    CAN_EDITAR() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
    HAS_SOME_EMPRESA_SELECTED() {
      return !!this.tableConfig.conteudo.find(
        (empresa) => empresa.selectedForGroup
      );
    },
  },
  methods: {
    updateNomeGrupoEconomico() {
      if (!this.$refs.nomeGrupoEconomicoField.isInvalid()) {
        this.editingComponent = false;
        this.componenteLoading = GrupoEconomicoService.update(
          this.model.getData()
        )
          .then(() => {
            this.mxToggleToast(HTTP_RESPONSE.GRUPO_ECONOMICO.SUCCESS.UPDATE);
          })
          .catch((error) =>
            this.mxHandleRequestError(error, "nome_grupo_economico")
          )
          .finally(() => (this.componenteLoading = undefined));
      }
    },
    deleteGrupoEconomico() {
      this.mxToggleWarningModal(CONFIRMATION.GRUPO_ECONOMICO.DELETE)
        .then(() => {
          const { id } = this.$router.history.current.params;
          const empresaIds = this.model.empresas.map(({ id }) => id);
          this.loading = GrupoEconomicoService.removeEmpresaFromGrupo(
            empresaIds,
            id
          )
            .then(() => {
              GrupoEconomicoService.remove({ id })
                .then(() => {
                  this.mxToggleToast(
                    HTTP_RESPONSE.GRUPO_ECONOMICO.SUCCESS.DELETE
                  );
                  this.$router.push({ name: "BuscarGrupoEconomico" });
                })
                .catch((error) =>
                  this.mxHandleRequestError(error, "GRUPO_ECONOMICO")
                );
            })
            .catch((error) =>
              this.mxHandleRequestError(error, "GRUPO_ECONOMICO")
            );
        })
        .catch(() => {
          return false;
        });
    },
    doSearch(search = this.$refs.searchBar.content, resetPage = false) {
      const { size, page } = this.filter;
      this.loading = EmpresaService.list({
        filtro: search,
        size,
        nulo: "grupo",
        page: resetPage ? 0 : page,
      })
        .then((response) => {
          this.tableConfig.conteudo = response.data.content;
          this.tableConfig.pagination = response.data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    setPage(page) {
      this.filter.page = page;
      this.doSearch();
    },
    deleteEmpresa({ id: empresaId }) {
      const { id } = this.$router.history.current.params;
      if (!id) {
        const empresaToDelete = this.model.empresas.findIndex(
          (empresa) => empresa.id === empresaId
        );
        this.model.empresas.splice(empresaToDelete, 1);
        return;
      }
      this.mxToggleWarningModal(CONFIRMATION.GRUPO_ECONOMICO.DISASSOCIATE)
        .then(() => {
          this.loading = GrupoEconomicoService.removeEmpresaFromGrupo(
            [empresaId],
            id
          )
            .then(() => {
              this.mxToggleToast(
                HTTP_RESPONSE.GRUPO_ECONOMICO.SUCCESS.DISASSOCIATE
              );
              this.getGroupById();
              this.doSearch();
            })
            .catch((error) =>
              this.mxHandleRequestError(error, "GRUPO_ECONOMICO")
            );
        })
        .catch(() => {
          return false;
        });
    },
    getGroupById() {
      const { id } = this.$router.history.current.params;
      if (id) {
        this.isNotCreated = false;
        this.loading = GrupoEconomicoService.getById({ id })
          .then(({ data }) => {
            this.model = new GrupoEconomicoModel(data);
            this.tableConfigAssociadas.conteudo = this.model.empresas;
          })
          .catch((error) => {
            this.mxHandleRequestError(error, "GRUPO_ECONOMICO");
          });
      }
    },
    createGrupo() {
      if (formIsValid(this)) {
        this.mxResetErrorPendencies();
        if (this.isNotCreated) {
          this.loading = GrupoEconomicoService.create(this.model.getData())
            .then(() => {
              this.mxToggleToast(HTTP_RESPONSE.GRUPO_ECONOMICO.SUCCESS.CREATE);
              this.$router.push({
                name: "BuscarGrupoEconomico",
              });
            })
            .catch((error) => {
              this.mxHandleRequestError(error, "nome_grupo_economico");
            });
        }
      }
    },
    addEmpresa() {
      return this.tableConfig.conteudo
        .filter((empresa) => empresa.selectedForGroup)
        .map((empresa) => {
          empresa.selectedForGroup = false;
          this.model.addEmpresa(empresa);
        });
    },
    addEmpresaChecked() {
      if (this.isNotCreated) {
        this.addEmpresa();
      } else {
        const { id } = this.$router.history.current.params;
        this.model.id = id;
        if (id) {
          this.mxToggleWarningModal(CONFIRMATION.GRUPO_ECONOMICO.UPDATE)
            .then(() => {
              this.addEmpresa();
              this.loading = GrupoEconomicoService.update(this.model.getData())
                .then(() => {
                  this.mxToggleToast(
                    HTTP_RESPONSE.GRUPO_ECONOMICO.SUCCESS.UPDATE
                  );
                  this.$router.push({
                    name: "BuscarGrupoEconomico",
                  });
                })
                .catch((error) => {
                  this.mxHandleRequestError(error, "GRUPO_ECONOMICO");
                });
            })
            .catch(() => {
              return false;
            });
        }
      }
    },
  },
  components: {
    SearchBar,
    Pagination,
    TableEmpresa,
    FormRowHeader,
    NomeGrupoField,
    TableEmpresaSelecionadas,
    FormRowHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.font-w {
  font-weight: 400;
}
</style>
