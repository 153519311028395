export const CNPJ = function (value) {
  if (!value) return "";
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};

export const CURRENCY = function (value, thousandSeparator = ".") {
  if (!value) return "N/A";
  return Number(value).toLocaleString("pt-BR", {
    type: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const LIMITADORES = function (value) {
  if (!value || Number(value) === 0) return "N/A";
  if (value.toString().indexOf("%") > -1) return value;
  return CURRENCY(value) + "%";
};

export const VOLUME = function (value) {
  if (!value) return "N/A";
  return Number(value).toLocaleString("pt-BR", {
    type: "currency",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
};

export const PERCENTUAL = function (value) {
  if (!value) return "-";
  if (value.toString().indexOf("%") > -1) return value;
  return (CURRENCY(value) + "%").replace(".", ",");
};

export const CAPITALIZE = function (value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const REMOVE_DOTS = function (value) {
  if (!value) return "";
  return value.toString().replace(/([^\d])+/gim, "");
};

export const PHONE = (value) => {
  if (!value) return "-";
  return value.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
};

export const CELPHONE = (value) => {
  if (!value) return "-";
  return value.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
};

export const CPF = (value) => {
  if (!value) return "-";
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};

export const INSCRICAO_ESTADUAL = (value) => {
  if (!value) return "-";
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/g, "$1.$2.$3.$4");
};

export const CEP = (value) => {
  if (!value) return "-";
  return value.replace(/(\d{5})(\d{3})/g, "$1-$2");
};

export const DATE_ptBR = (value) => {
  if (!value) return "";
  try {
    if (value.length === 10) value += "T12:00:00";
    const date = new Date(value);
    if (isNaN(date)) return "";

    return date.toLocaleDateString("pt-br");
  } catch (error) {
    return "";
  }
};

export const DATETIME_ptBR = (value) => {
  if (!value) return "";
  try {
    const date = new Date(value);
    if (isNaN(date)) return "";

    return date
      .toLocaleDateString("pt-br", {
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", " às");
  } catch (error) {
    return "";
  }
};

export const PROPOSTA_STATUS = (value) => {
  if (!value) return "-";
  return {
    PENDENTE: "Em Preenchimento",
    EM_ANALISE: "Proposta Enviada",
    CANCELADO: "Proposta Cancelada",
    APROVADO: "Proposta Aprovada",
    REPROVADO: "Proposta Reprovada",
    EXPIRADO: "Proposta Expirada",
  }[value];
};

export const EMPRESA_STATUS = (value) => {
  if (!value) return "Rascunho";
  if (value.nome) {
    return value.nome;
  }
  return value;
};

export const EMPTY = (value) => {
  if (!value) return "-";
  return value;
};
