<template>
  <div :class="['col', `col-lg-${colLG}`]">
    <div class="input-group">
      <label for="cargoField">
        Cargo
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          :disabled="disabled"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="cargoField"
          v-model.trim="$v.model.content.$model"
          maxLength="30"
        />
        <div class="form-text">Preencha com o cargo.</div>
      </label>
    </div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { maxLength } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
    colLG: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  data() {
    return {
      model: {
        name: "cargo",
        content: "",
      },
    };
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
  },
  directives: {
    mask: AwesomeMask,
  },
  validations: {
    model: {
      content: {
        maxLength: maxLength(30),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
