<template>
  <div>
    <div class="row">
      <endereco-cep-field
        :isFieldRequired="false"
        :disabled="disabled"
        ref="enderecoCep"
        @updateEnderecoFields="updateEnderecoFields"
        :modelProp="model.content"
      />
      <endereco-logradouro-field
        :disabled="disabled"
        ref="enderecoLogradouro"
        :modelProp="model.content"
      />
      <endereco-numero-field
        :disabled="disabled"
        ref="enderecoNumero"
        :modelProp="model.content"
      />
      <endereco-complemento-field
        :disabled="disabled"
        ref="enderecoComplemento"
        :modelProp="model.content"
      />
    </div>
    <div class="row mt-3">
      <endereco-bairro-field
        :disabled="disabled"
        ref="enderecoBairro"
        :modelProp="model.content"
      />
      <endereco-cidade-field
        :list="cidadesList"
        ref="enderecoCidade"
        :loading="cidadeFieldLoading"
        :modelProp="model.content"
        :disabled="disabled"
      />
      <endereco-estado-field
        :disabled="disabled"
        ref="enderecoEstado"
        :modelProp="model.content"
        @estadoUpdated="findCidadesFromEstado"
      />
      <endereco-pais-field
        ref="enderecoPais"
        :disabled="disabled"
        :modelProp="model.content"
      />
    </div>
    <div
      style="
        right: 0px;
        bottom: 50px;
        position: absolute;
        z-index: 999 !important;
      "
    ></div>
  </div>
</template>

<script>
import { formIsValid } from "@/utils/validators";
import EnderecoModel from "@/models/empresa/EnderecoModel";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import EnderecoCepField from "@/components/forms/fields/empresa/endereco/EnderecoCepField.vue";
import EnderecoPaisField from "@/components/forms/fields/empresa/endereco/EnderecoPaisField.vue";
import EnderecoNumeroField from "@/components/forms/fields/empresa/endereco/EnderecoNumeroField.vue";
import EnderecoBairroField from "@/components/forms/fields/empresa/endereco/EnderecoBairroField.vue";
import EnderecoCidadeField from "@/components/forms/fields/empresa/endereco/EnderecoCidadeField.vue";
import EnderecoEstadoField from "@/components/forms/fields/empresa/endereco/EnderecoEstadoField.vue";
import EnderecoLogradouroField from "@/components/forms/fields/empresa/endereco/EnderecoLogradouroField.vue";
import EnderecoComplementoField from "@/components/forms/fields/empresa/endereco/EnderecoComplementoField.vue";
export default {
  data() {
    return {
      cidadesList: new Array(),
      cidadeFieldLoading: false,
      model: {
        name: "endereco",
        content: new EnderecoModel(),
      },
    };
  },
  props: {
    disabled: Boolean,
    modelProp: Object,
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        // if (this.modelProp.mounting == true) return;
        this.model.content = this.modelProp[this.model.name];
      },
    },
    "modelProp.endereco.estado": {
      deep: true,
      immediate: true,
      handler() {
        if (this.modelProp) {
          const { endereco } = this.modelProp;
          if (endereco) {
            const { estado } = this.modelProp.endereco;
            if (estado) {
              const { id } = this.modelProp.endereco.estado;
              if (id) {
                this.cidadeFieldDisabled = false;
                this.findCidadesFromEstado(this.modelProp.endereco.estado.id);
              }
            }
          }
        }
      },
    },
  },
  methods: {
    async findCidadesFromEstado(estado) {
      try {
        this.cidadeFieldLoading = true;
        const { data } = await EmpresaService.listCidades({ estado });
        this.cidadesList = data;
        await this.$nextTick();
        if (this.$refs.enderecoCidade) {
          this.$refs.enderecoCidade.setContent();
        }
      } catch (error) {
        this.mxHandleRequestError(error);
      } finally {
        this.cidadeFieldLoading = false;
        this.$emit("setCidadesToLoadedState");
      }
    },
    disableLoading() {
      this.$emit("setCidadesToLoadedState");
    },
    updateEnderecoFields(Endereco) {
      this.$emit("updateEndereco", Endereco);
    },
    isInvalid() {
      return !formIsValid(this);
    },
  },
  components: {
    EnderecoCepField,
    EnderecoPaisField,
    EnderecoNumeroField,
    EnderecoBairroField,
    EnderecoCidadeField,
    EnderecoEstadoField,
    EnderecoLogradouroField,
    EnderecoComplementoField,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
