<template>
  <div class="mt-5" v-async="loading">
    <div class="row mt-5 d-flex align-items-center">
      <switch-agente-CCEE-field
        :modelProp="dadosGeraisModel"
        @clearRepresentanteFields="clearRepresentanteFields"
        @handleSwitchChange="handleSwitchChange"
        ref="agenteCcee"
        :disabled="
          (PERSISTING_ALREADY_HAS_AGENTE && IS_AGENTE_CCEE) ||
          IS_EMPRESA_REPRESENTADA ||
          !USER_HAS_GENERAL_PERMISSION
        "
      />
      <codigo-agente-field
        :modelProp="empresa"
        :disabled="!IS_AGENTE_CCEE || !USER_HAS_GENERAL_PERMISSION"
        ref="codigoAgenteField"
        :recentUpdatedField="recentUpdatedField('codigoAgente')"
      />
      <switch-empresa-representada-field
        :modelProp="dadosGeraisModel"
        @clearRepresentante="clearRepresentante"
        @handleSwitchChange="handleSwitchChange"
        ref="empresaRepresentada"
        :disabled="IS_AGENTE_CCEE || !USER_HAS_GENERAL_PERMISSION"
      />
      <representante-empresa-field
        :modelProp="dadosGeraisModel"
        ref="representanteField"
        :disabled="!IS_EMPRESA_REPRESENTADA || !USER_HAS_GENERAL_PERMISSION"
        :invalidYellow="
          invalidYellow(empresa ? empresa.representante : undefined)
        "
        :recentUpdatedField="recentUpdatedField('representante')"
      />
    </div>
    <div class="row mt-5 d-flex align-items-center">
      <categoria-empresa-field
        :modelProp="dadosGeraisModel"
        ref="categoriaField"
        :disabled="!USER_HAS_GENERAL_PERMISSION"
      />
      <classe-agente-field
        ref="classeAgenteField"
        :modelProp="dadosGeraisModel"
        :disabled="!USER_HAS_GENERAL_PERMISSION"
        :recentUpdatedField="recentUpdatedField('classeAgente')"
      />
    </div>
    <div class="row mt-5 d-flex align-items-center justify-content-between">
      <div class="col col-lg-6 w-50 col-md-8" />
      <div class="col col-lg-4 w-50">
        <div
          v-if="!editing && showSavePendencyMessage"
          class="mb-2 d-flex align-items-center justify-content-end"
        >
          <span class="save-message">
            É necessário salvar as alterações para atualizar os dados.
          </span>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <button
            class="btn btn-secondary ms-3"
            @click="$router.push({ name: 'BuscarEmpresas' })"
          >
            Cancelar
          </button>
          <button
            @click="
              updateDadosGerais();
              updateCodigoAgente();
            "
            class="btn btn-primary ms-3"
          >
            Atualizar
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <form-row-header text="Cadastrar Perfil de Agente" />
    </div>
    <div class="row mt-3">
      <switch-dados-provisorios-field
        :modelProp="model"
        ref="isProvisorioField"
        :empresa="empresa"
        :disabled="
          !PERSISTING_IS_AGENTE_CCEE ||
          ALREADY_HAS_AGENTE ||
          !USER_HAS_GENERAL_PERMISSION
        "
      />
      <tipo-energia-field
        ref="tipoEnergiaField"
        :modelProp="model"
        :disabled="
          !PERSISTING_IS_AGENTE_CCEE ||
          IS_SIGLA_PROVISORIA ||
          !USER_HAS_GENERAL_PERMISSION
        "
        :recentUpdatedField="recentUpdatedField('tipoEnergia')"
      />
      <codigo-perfil-agente-field
        :disabled="
          !PERSISTING_IS_AGENTE_CCEE ||
          IS_SIGLA_PROVISORIA ||
          !USER_HAS_GENERAL_PERMISSION
        "
        :modelProp="model"
        ref="codigoPerfilAgenteField"
        :recentUpdatedField="recentUpdatedField('perfilAgente')"
      />
      <sigla-perfil-agente-field
        :disabled="
          !PERSISTING_IS_AGENTE_CCEE ||
          IS_SIGLA_PROVISORIA ||
          !USER_HAS_GENERAL_PERMISSION
        "
        :modelProp="model"
        ref="siglaPerfilAgenteField"
        :recentUpdatedField="recentUpdatedField('sigla')"
      />
    </div>
    <div class="row mt-5">
      <div class="col col-lg-auto ms-auto">
        <button v-if="editing" class="btn btn-secondary" @click="resetFields">
          Cancelar
        </button>
        <button
          v-else
          @click="clearFields"
          class="btn btn-secondary"
          :disabled="IS_SIGLA_PROVISORIA"
        >
          Limpar
        </button>
        <template v-if="USER_HAS_GENERAL_PERMISSION">
          <button
            @click="add"
            class="btn btn-primary ms-3"
            :disabled="
              (!editing && IS_SIGLA_PROVISORIA) || !PERSISTING_IS_AGENTE_CCEE
            "
            v-if="editing"
          >
            Atualizar
          </button>
          <button
            @click="add"
            class="btn btn-primary ms-3"
            :disabled="IS_SIGLA_PROVISORIA || !PERSISTING_IS_AGENTE_CCEE"
            v-else
          >
            Adicionar perfil de agente
          </button>
        </template>
      </div>
    </div>
    <div class="row mt-3">
      <table-agentes-cadastrados
        @sortBy="sortBy"
        @editAgente="edit"
        :agentesList="agentes"
        @deleteAgente="remove"
        :editDisabled="ALREADY_HAS_AGENTE && IS_SIGLA_PROVISORIA"
        :disabled="!IS_AGENTE_CCEE || !USER_HAS_GENERAL_PERMISSION"
        :recentUpdatedFields="recentUpdatedFields"
        :recentCreatedDadosBancarios="recentCreatedDadosBancarios"
      />
    </div>
    <div class="row mt-3">
      <div class="col-12 d-flex justify-content-end">
        <button
          :disabled="!IS_AGENTE_CCEE || !USER_HAS_GENERAL_PERMISSION"
          @click="sincronizarWbc()"
          class="btn btn-secondary-blue ms-3"
        >
          <strong>Importar</strong> dados do <strong>WBC</strong>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CRUDTemplate from "@/components/CRUDTemplate";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import CategoriaEmpresaField from "@/components/forms/fields/empresa/CategoriaEmpresaField.vue";
import ClasseAgenteField from "@/components/forms/fields/empresa/ClasseAgenteField.vue";
import CodigoAgenteField from "@/components/forms/fields/empresa/CodigoAgenteField.vue";
import CodigoPerfilAgenteField from "@/components/forms/fields/empresa/CodigoPerfilAgenteField.vue";
import RepresentanteEmpresaField from "@/components/forms/fields/empresa/RepresentanteEmpresaField.vue";
import SiglaPerfilAgenteField from "@/components/forms/fields/empresa/SiglaPerfilAgenteField.vue";
import SwitchAgenteCCEEField from "@/components/forms/fields/empresa/SwitchAgenteCCEEField.vue";
import SwitchDadosProvisoriosField from "@/components/forms/fields/empresa/SwitchDadosProvisoriosField.vue";
import SwitchEmpresaRepresentadaField from "@/components/forms/fields/empresa/SwitchEmpresaRepresentadaField.vue";
import TipoEnergiaField from "@/components/forms/fields/empresa/TipoEnergiaField.vue";
import TableAgentesCadastrados from "@/components/tables/TableAgentesCadastrados.vue";
import { CONFIRMATION, HTTP_RESPONSE } from "@/constants/strings";
import AgentePerfilModel from "@/models/empresa/AgentePerfilModel";
import DadosGeraisModel from "@/models/empresa/DadosGeraisModel";
import EmpresaModel from "@/models/empresa/EmpresaModel";
import EnderecoModel from "@/models/empresa/EnderecoModel";
import CCEEService from "@/services/portal/empresa/CCEEService";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { cleanUpFields, setFieldsData } from "@/utils/functionUtils";
import { formIsValid } from "@/utils/validators";

export default {
  extends: CRUDTemplate,
  data() {
    return {
      agentes: new Array(),
      agentesMirror: new Array(),
      codigoAgenteValue: null,
      empresaService: null,
      showSavePendencyMessage: false,
      // Model: Agente Atual
      model: new AgentePerfilModel(),
      modelMirror: new AgentePerfilModel(),
      dadosGeraisModel: new DadosGeraisModel({ mounting: true }),
      dadosGeraisModelMirror: new DadosGeraisModel({ mounting: true }),
      empresaMirror: null,
      recentUpdatedFields: new Array(),
      recentCreatedDadosBancarios: new Array(),
      filter: {
        sort: ["tipoEnergia.nome", "codigoPerfilAgente", "siglaPerfilAgente"],
      },
      classeAgenteBeforeReq: null,
    };
  },
  mounted() {
    this.mountCCEE();
  },
  methods: {
    handleSwitchChange() {
      this.showSavePendencyMessage = true;
    },
    invalidYellow(field) {
      return [null, undefined, "", []].includes(field) && !!this.model.id;
    },
    mountCCEE() {
      this.toggleLoading(true);
      const { empresaId } = this.$parent;
      EmpresaService.getById({ empresaId })
        .then(({ data: empresa }) => {
          this.service = CCEEService;
          this.empresaService = EmpresaService;
          this.requestEntity = "ccee";
          this.model = new AgentePerfilModel();
          this.modelMirror = new AgentePerfilModel();
          this.empresa = new EmpresaModel({
            ...empresa,
            endereco: new EnderecoModel(empresa.endereco),
          });
          this.empresaMirror = new EmpresaModel({
            ...empresa,
            endereco: new EnderecoModel(empresa.endereco),
          });
          this.dadosGeraisModel = new DadosGeraisModel({
            ...empresa,
            endereco: new EnderecoModel(empresa.endereco),
          });
          this.dadosGeraisModelMirror = new DadosGeraisModel({
            ...empresa,
            endereco: new EnderecoModel(empresa.endereco),
          });
          this.classeAgenteBeforeReq = this.dadosGeraisModel.classeAgente;
          this.codigoAgenteValue = this.empresa.codigoAgente;
          if (this.dadosGeraisModel.agenteCcee) {
            this.clearRepresentanteFields();
          } else {
            this.clearCodAgente();
          }
          if (!this.dadosGeraisModel.empresaRepresentada) {
            this.clearRepresentante();
          }
          this.setHeaderText(this.empresa.razaoSocial);
          this.setBreadcrumbsParam(this.empresa.tipo.nome);
          this.getByEmpresa();
          this.mountRecentUpdatedFields(empresaId);
          this.$refs.categoriaField.mountCategories();
        })
        .catch((error) => this.mxHandleRequestError(error, "ccee"))
        .finally(() => {
          this.toggleLoading(false, true);
        });
    },
    sortBy(emitted) {
      this.filter.sort = emitted;
      this.getByEmpresa();
    },
    /* Overriding CRUDTemplate getByEmpresa function */
    getByEmpresa() {
      const { empresaId } = this.$parent;
      const { sort } = this.filter;
      this.service
        .getByEmpresa({
          empresaId,
          sort,
        })
        .then(({ data }) => {
          this.agentes = data.map(
            (perfilAgente) => new AgentePerfilModel(perfilAgente)
          );
          this.agentesMirror = this.agentes.map(
            (perfilAgente) => new AgentePerfilModel(perfilAgente)
          );

          if (this.empresa && this.IS_SIGLA_PROVISORIA) {
            this.model.isProvisorio = true;
          }
        })
        .catch((error) => {
          this.mxHandleRequestError(error, this.requestEntity);
        });
    },
    remove({ id }) {
      this.mxToggleWarningModal(
        CONFIRMATION[this.requestEntity.toUpperCase()].DELETE
      ).then(() => {
        const { empresaId } = this.$parent;
        this.toggleLoading(true);
        this.service
          .remove({ id }, empresaId)
          .then(() => {
            this.mxToggleToast(
              HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.DELETE
            );
            this.resetFields();
            this.getByEmpresa();
            this.mountCCEE();
          })
          .catch((error) =>
            this.mxHandleRequestError(error, this.requestEntity)
          )
          .finally(() => {
            this.toggleLoading(false, true);
          });
      });
    },
    edit(perfil) {
      this.editing = true;
      this.model.id = perfil.id;
      this.model.wbcSiglaCcee = perfil.wbcSiglaCcee;
      setFieldsData(this, perfil);
      this.modelMirror = this.model;
    },
    sincronizarWbc() {
      this.loading = this.service
        .sincronizarCCEEComWBC({
          empresaId: this.$parent.empresaId,
        })
        .then(({ data }) => {
          this.agentes = data || new Array();
          this.agentesMirror = data || new Array();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, this.requestEntity);
        });
    },
    mountRecentUpdatedFields(empresaId) {
      EmpresaService.getEmpresaCCEERecentUpdates({
        empresaId,
      })
        .then(({ data }) => {
          this.recentUpdatedFields = data;

          this.recentCreatedDadosBancarios = this.recentUpdatedFields
            .map((perfilAlterado) => {
              if (perfilAlterado.campos.length == 0) {
                return perfilAlterado.id;
              }
            })
            .filter((perfilAlterado) => perfilAlterado != undefined);

          if (this.recentUpdatedFields.length > 0) {
            this.mxToggleWarningMessageAlert(
              "Campos alterados: na aba CCEE",
              true,
              true
            );
          } else {
            this.mxClearYellowPendency(true);
          }
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    add() {
      (this.editing ? this.update() : this.save())
        .then(() => this.mountCCEE())
        .catch((error) => this.mxHandleRequestError(error));
    },
    updateCodigoAgente() {
      if (this.codigoAgenteValue != this.empresa.codigoAgente) {
        if (!this.$refs.codigoAgenteField.isInvalid()) {
          const { empresaId } = this.$parent;
          this.componenteLoading = CCEEService.updateCodigoAgente(
            { codigo: this.empresa.codigoAgente },
            empresaId
          )
            .then(() => {
              this.mxToggleToast(HTTP_RESPONSE.CCEE.SUCCESS.UPDATE);
              this.codigoAgenteValue = this.empresa.codigoAgente;
              this.mxResetErrorPendencies();
            })
            .catch((error) => {
              this.mxHandleRequestError(error, "ccee");
              if (this.$refs.codigoAgenteField) {
                this.$refs.codigoAgenteField.focusField();
              }
            })
            .finally(() => (this.componenteLoading = undefined));
        }
      }
    },
    async updateDadosGerais() {
      const {
        codigoAgenteField,
        categoriaField,
        classeAgenteField,
        representanteField,
      } = this.$refs;
      if (
        !formIsValid({
          $refs: {
            codigoAgenteField,
            categoriaField,
            classeAgenteField,
            representanteField,
          },
        })
      ) {
        return;
      }

      this.toggleLoading(true);
      const { id: empresaId } = this.dadosGeraisModel;
      this.$parent.empresaId = empresaId;

      try {
        await this.empresaService.update(
          this.dadosGeraisModel.getData(),
          empresaId
        );
      } catch (error) {
        this.dadosGeraisModel.classeAgente = this.classeAgenteBeforeReq;
        error.response.status === 422
          ? this.mxToggleErrorMessageAlert(error.response.data.message)
          : this.mxHandleRequestError(error, this.requestEntity);
        this.toggleLoading(false, true);
        return;
      }

      this.mxToggleToast(
        HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.UPDATE
      );
      this.showSavePendencyMessage = false;
      try {
        const { data: empresa } = await this.empresaService.getById({
          empresaId,
        });
        this.dadosGeraisModel = new DadosGeraisModel({
          ...empresa,
          endereco: new EnderecoModel(empresa.endereco),
        });
        this.dadosGeraisModelMirror = new DadosGeraisModel({
          ...empresa,
          endereco: new EnderecoModel(empresa.endereco),
        });
        this.classeAgenteBeforeReq = this.dadosGeraisModel.classeAgente;

        if (this.dadosGeraisModel?.classeAgente) {
          this.$parent.inactiveTabs(this.dadosGeraisModel);
        }
        this.showSavePendencyMessage = false;
      } catch (error) {
        this.mxHandleRequestError(error, this.requestEntity);
      }
      this.toggleLoading(false, true);
    },
    toggleLoading(show, forceHide) {
      if (forceHide) {
        this.loading = false;
        return;
      }
      if (typeof this.loading === "object" && !show && this.cidadesIsLoaded) {
        this.loading = false;
        return;
      }
      if (show) {
        this.loading = new Promise(() => {});
      }
    },
    clearFields() {
      const {
        codigoPerfilAgenteField,
        tipoEnergiaField,
        siglaPerfilAgenteField,
      } = this.$refs;
      cleanUpFields({
        $refs: {
          codigoPerfilAgenteField,
          tipoEnergiaField,
          siglaPerfilAgenteField,
        },
      });
    },
    clearRepresentanteFields() {
      this.dadosGeraisModel.empresaRepresentada = false;
      this.dadosGeraisModel.representante = "";
      this.$refs.representanteField.$v.search.$model = "";
    },
    clearRepresentante() {
      this.dadosGeraisModel.representante = "";
      this.$refs.representanteField.$v.search.$model = "";
    },
    clearCodAgente() {
      this.empresa.codigoAgente = "";
    },
    recentUpdatedField(field) {
      const updatedFieldsFiltered =
        this.recentUpdatedFields.find(
          (perfilAlterado) => this.model.id === perfilAlterado.id
        ) ?? false;

      if (updatedFieldsFiltered) {
        return (
          updatedFieldsFiltered.campos &&
          updatedFieldsFiltered.campos.includes(field)
        );
      }
      return false;
    },
  },
  computed: {
    IS_SIGLA_PROVISORIA() {
      return this.agentes?.some?.((agente) => agente.isProvisorio);
    },
    PERSISTING_IS_SIGLA_PROVISORIA() {
      return this.agentesMirror?.some?.((agente) => agente.isProvisorio);
    },
    ALREADY_HAS_AGENTE() {
      return !!this.agentes?.length;
    },
    PERSISTING_ALREADY_HAS_AGENTE() {
      return !!this.agentesMirror?.length;
    },
    IS_AGENTE_CCEE() {
      return !!this.dadosGeraisModel?.agenteCcee;
    },
    PERSISTING_IS_AGENTE_CCEE() {
      return !!this.dadosGeraisModelMirror?.agenteCcee;
    },
    IS_EMPRESA_REPRESENTADA() {
      return !!this.dadosGeraisModel?.empresaRepresentada;
    },
    PERSISTING_IS_EMPRESA_REPRESENTADA() {
      return !!this.dadosGeraisModelMirror?.empresaRepresentada;
    },
    USER_HAS_GENERAL_PERMISSION() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
  },
  watch: {
    "model.isProvisorio": {
      deep: true,
      async handler() {
        // Ou seja, se o campo acabou de ser acionado mas ainda não existe nenhum perfil de agente com sigla provisória a validação falha e o código segue, para cadastrar o agente;
        if (!this.model.isProvisorio || this.IS_SIGLA_PROVISORIA) return;

        this.toggleLoading(true);

        let empresa;
        try {
          const empresaId = this.empresa.id;
          await EmpresaService.updateIsProvisorio({ empresaId });
          ({ data: empresa } = await EmpresaService.getById({ empresaId }));
        } catch (error) {
          this.mxHandleRequestError(error, this.requestEntity);
        }

        this.empresa = new EmpresaModel({
          ...empresa,
          endereco: new EnderecoModel(empresa.endereco),
        });
        if (this.empresa.id) {
          this.mountRecentUpdatedFields(this.empresa.id);
          this.mountCCEE();
        }

        this.toggleLoading(false, true);
      },
    },
  },
  components: {
    FormRowHeader,
    TipoEnergiaField,
    CodigoAgenteField,
    TableAgentesCadastrados,
    CategoriaEmpresaField,
    ClasseAgenteField,
    SwitchAgenteCCEEField,
    SwitchEmpresaRepresentadaField,
    RepresentanteEmpresaField,
    SiglaPerfilAgenteField,
    CodigoPerfilAgenteField,
    SwitchDadosProvisoriosField,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.save-message {
  color: $primary--orange;
}
</style>
