import HeaderBoxInfo from "@/components/HeaderBoxInfo.vue";
import Vue from "vue";

Vue.mixin({
  methods: {
    setHeaderText(text) {
      HeaderBoxInfo.setHeaderText(text.toUpperCase());
    },
    setHeaderStatusText(text) {
      HeaderBoxInfo.setHeaderStatusText(text);
    },
  },
});
