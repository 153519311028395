import ServicesPortal from "./ServicesPortal";

class DistribuidorasService extends ServicesPortal {
    constructor(endpoint) {
        super(endpoint);
    }

    getDistribuidoras(request) {
        this._url = "";
        return this.get(request);
    }
}

export default new DistribuidorasService("empresas/distribuidoras");