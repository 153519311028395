import AccessDenied from "@/pages/AccessDenied.vue";
import Home from "@/pages/Home.vue";
import LoginCallback from "@/pages/LoginCallback.vue";
import LoginSignout from "@/pages/LoginSignout.vue";
import PageNotFound from "@/pages/PageNotFound.vue";
import acessoPortalRoutes from "@/router/acesso-portal";
import ajudaRoutes from "@/router/ajuda";
import beforeEach from "@/router/before-each";
import cadastrosRoutes from "@/router/cadastros";
import parametrosRoutes from "@/router/parametros";
import perfilRoutes from "@/router/perfil";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    showInSidebar: true,
    meta: {
      auth: true,
      permissions: new Array(),
    },
    children: [
      ...cadastrosRoutes,
      ...parametrosRoutes,
      ...perfilRoutes,
      ...acessoPortalRoutes,
      ...ajudaRoutes,
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      auth: true,
      permissions: new Array(),
    },
  },
  {
    path: "/login/callback",
    name: "LoginCallback",
    component: LoginCallback,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: AccessDenied,
    meta: {
      permissions: new Array(),
    },
  },
  {
    path: "/login/signout",
    name: "LoginSignout",
    component: LoginSignout,
    meta: {
      permissions: new Array(),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

beforeEach(router);

export default router;
