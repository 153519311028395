import ServicesPortal from "../ServicesPortal";
import GestorModel from "@/models/empresa/GestorModel";
import GestorContatoModel from "@/models/empresa/GestorContatoModel";
import RegistroHistoricoGestorModel from "@/models/empresa/RegistroHistoricoGestorModel";

class GestorService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  list(request) {
    this._url = "/gestor";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data.content.length > 0) {
          response.data.content = response.data.content.map((gestor) => {
            gestor.contatos = gestor.contatos.map((gestorContato) => {
              return new GestorContatoModel({
                contato: gestorContato,
                permissoes: gestorContato.permissoes,
              });
            });
            return new GestorModel(gestor);
          });
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  getHistoricoByGestor(request) {
    this._url = `/${request.id}/contatos/gestor/historico`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data.content.length > 0) {
          response.data.content = response.data.content.map(
            (registroHistoricoGestor) =>
              new RegistroHistoricoGestorModel({
                id: registroHistoricoGestor.id,
                dataInicio: registroHistoricoGestor.inicioRelacionamento,
                dataFim: registroHistoricoGestor.terminoRelacionamento,
                gestor: registroHistoricoGestor.empresa,
                nomeGestor: registroHistoricoGestor.contato,
                atual: false,
              })
          );
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  associateGestor(request) {
    const { empresaId, gestorId } = request;
    this._url = `/${empresaId}/contatos/gestor/${gestorId}`;
    return this.put(request);
  }

  getContatosByGestor(request) {
    const { empresaId, filter } = request;

    this._url = `/${empresaId}/contatos/gestor`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(filter, true);

        if (response.data && response.data.length > 0) {
          response.data = response.data.map((contatoGestor) => {
            return new GestorContatoModel(contatoGestor);
          });
        }

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  update(request, empresaId) {
    this._url = `/${empresaId}/contatos/gestor`;
    return this.patch(request);
  }
}

export default new GestorService("empresas");
