import DataTableEmpresaModel from "@/models/empresa/DataTableEmpresaModel";
import EnderecoModel from "@/models/empresa/EnderecoModel";
import ServicesPortal from "@/services/portal/ServicesPortal";
import { formatEmpresaFromResponseObject } from "@/utils/functionUtils";

class EmpresaService extends ServicesPortal {
  constructor(endpoint) {
    super(endpoint);
  }

  list(request) {
    this._url = "";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data.content.length > 0) {
          response.data.content = response.data.content.map(
            (empresa) => new DataTableEmpresaModel(empresa)
          );
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @summary Gets an Empresa entity;
   * @param {Object} request.id
   * @endpoint /api/empresas/{request.id}
   */
  getById(request) {
    this._url = `/${request.empresaId}`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        resolve(formatEmpresaFromResponseObject(response));
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @summary Gets an Empresa entity;
   * @param {Object} request.cnpj
   * @endpoint /api/empresas/{request.cnpj}
   */
  getByCnpj(request) {
    this._url = `/buscar-cnpj/${request.cnpj}`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request);
        resolve(formatEmpresaFromResponseObject(response));
      } catch (error) {
        reject(error);
      }
    });
  }

  getEmpresaFromReceita(request) {
    this._url = `/receita`;
    return this.get(request, true);
  }

  getEmpresaEnderecoFromCorreios(request) {
    this._url = `/correios`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(request, true);
        if (response.data) {
          response.data = new EnderecoModel(response.data);
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  getEmpresaRecentUpdates(request) {
    this._url = `/${request.empresaId}/alteracoes`;
    return this.get(request);
  }

  getEmpresaContatosRecentUpdates(request) {
    this._url = `/${request.empresaId}/contatos/alteracoes`;
    return this.get(request);
  }

  getEmpresaDadosBancariosRecentUpdates(request) {
    this._url = `/${request.empresaId}/bancos/alteracoes`;
    return this.get(request);
  }

  getEmpresaPontosMedicaoRecentUpdates(request) {
    this._url = `/${request.empresaId}/pontos/alteracoes`;
    return this.get(request);
  }

  getEmpresaCCEERecentUpdates(request) {
    this._url = `/${request.empresaId}/ccee/perfis/alteracoes`;
    return this.get(request);
  }

  getEmpresaAnexosRecentUpdates(request) {
    this._url = `/${request.empresaId}/anexos/alteracoes`;
    return this.get(request);
  }

  integrarWBC(request) {
    this._url = `/${request.empresaId}/integrar-wbc`;
    return this.post(request);
  }

  validarCadastro(request) {
    this._url = `/${request.empresaId}/validar-cadastro`;
    return this.post(request);
  }

  validarCnae(request) {
    this._url = `/receita/cnae`;
    return this.get(request, true);
  }

  /**
   * @summary Creates an Empresa entity;
   * @param {Object} request
   * @endpoint /api/empresas
   */
  create(request) {
    this._url = "";
    return this.post(request);
  }

  update(request, empresaId) {
    this._url = `/${empresaId}/dadosgerais`;
    return this.patch(request);
  }

  updateIsProvisorio(request) {
    const { empresaId } = request;
    this._url = `/${empresaId}/provisorio`;
    return this.patch(request);
  }

  approveEmpresa(request) {
    this._url = `/${request.empresaId}/aprovar`;
    return this.patch(request);
  }

  listRepresentantes(request) {
    this._url = "/representante";
    return this.get(request, true);
  }

  listCidades(request) {
    this._url = "/cidades";
    return this.get(request, true);
  }

  listEstados(request) {
    this._url = "/estados";
    return this.get(request);
  }

  createSegmento(request) {
    this._url = "/segmentos";
    return this.post(request);
  }

  listSegmento(request) {
    this._url = "/segmentos";
    return this.get(request);
  }

  removeSegmento(request) {
    this._url = `/segmentos/${request.id}`;
    return this.delete(request);
  }

  updateSegmento(request) {
    this._url = `/segmentos/${request.id}`;
    return this.put(request);
  }

  createSetor(request) {
    this._url = "/setores";
    return this.post(request);
  }

  removeSetor(request) {
    this._url = `/setores/${request.id}`;
    return this.delete(request);
  }

  listSetor(request) {
    this._url = "/setores";
    return this.get(request);
  }

  updateSetor(request) {
    this._url = `/setores/${request.id}`;
    return this.put(request);
  }

  getPaisList(request) {
    this._url = `/paises`;
    return this.get(request);
  }

  getParametrosFieldsList(request) {
    this._url = `/parametros`;
    return this.get(request);
  }

  getEmpresaCSV(request) {
    this._url = "/exportar";
    return this.get(request, true);
  }

  getEmpresaExcel(paramKey, request) {
    this._url = "/relatorios";
    return this.getExcel(paramKey, request);
  }

  listProspects(request) {
    this._url = "/prospects";
    return this.get(request, true);
  }

  listCategorias(request) {
    this._url = "/tipos";
    return this.get(request);
  }

  findAllPosVenda(request) {
    this._url = "/pos-venda";
    return this.get(request);
  }

  savePosVenda(request) {
    this._url = "/pos-venda";
    return this.post(request);
  }

  removePosVenda(request) {
    this._url = `/pos-venda/${request.id}`;
    return this.delete(request);
  }

  updatePosVenda(request) {
    this._url = `/pos-venda/${request.id}`;
    return this.put(request);
  }

  cadastrarEmpresasLote(request) {
    this._url = "/cadastro-lote/upload";
    return this.post(request, false, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  getStatusCadastroLote(request) {
    this._url = "/cadastro-lote/status";
    return this.get(request);
  }

  getTemplateExcel(request) {
    this._url = "/cadastro-lote/template-excel";
    return this.getAttachment(request);
  }
}

export default new EmpresaService("empresas");
