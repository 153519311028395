export default class EnderecoModel {
  constructor(data = new Object()) {
    this._cep = data.cep;
    this._pais = data.pais;
    this._bairro = data.bairro;
    this._cidade = data.cidade;
    this._estado = data.estado;
    this._numero = data.numero;
    this._logradouro = data.logradouro;
    this._complemento = data.complemento;
  }

  set bairro(bairro = null) {
    this._bairro = bairro;
  }

  get bairro() {
    return this._bairro || null;
  }

  set cep(cep = null) {
    this._cep = cep;
  }

  get cep() {
    if (this._cep) {
      return this._cep.replace(/[^\d]/g, "");
    }
    return null;
  }

  set complemento(complemento = null) {
    this._complemento = complemento;
  }

  get complemento() {
    return this._complemento || null;
  }

  set cidade(cidade = null) {
    this._cidade = cidade;
  }

  get cidade() {
    return this._cidade || null;
  }

  set estado(estado = null) {
    this._estado = estado;
  }

  get estado() {
    if (!this._estado) return null;
    return this._estado;
  }

  set logradouro(logradouro = null) {
    this._logradouro = logradouro;
  }

  get logradouro() {
    return this._logradouro || null;
  }

  set pais(pais = null) {
    this._pais = pais;
  }

  get pais() {
    return this._pais || { id: "BR", nome: "Brasil" };
  }

  set numero(numero = null) {
    this._numero = numero;
  }

  get numero() {
    return this._numero;
  }

  getData() {
    const {
      bairro,
      cep,
      complemento,
      cidade,
      logradouro,
      estado,
      pais,
      numero,
    } = this;
    return {
      bairro,
      cep,
      complemento,
      cidade: typeof cidade === "string" ? cidade : cidade?.nome,
      logradouro,
      estado,
      pais,
      numero,
    };
  }
}
