export default {
  CONSUMIDOR: {
    id: "CONSUMIDOR",
    nome: "Consumidor",
    restrictions: {
      tabs: new Array(),
      fields: new Array(),
    },
  },
  GERADOR: {
    id: "GERADOR",
    nome: "Gerador",
    restrictions: {
      tabs: new Array(),
      fields: new Array(),
    },
  },
  TRADING: {
    id: "TRADING",
    nome: "Trading",
    restrictions: {
      tabs: new Array("GESTOR", "PONTOS_DE_MEDICAO", "ESTRUTURA_RATEIO_WBC"),
      fields: new Array(),
    },
  },
  GESTOR: {
    id: "GESTOR",
    nome: "Gestor",
    restrictions: {
      tabs: new Array("GESTOR", "PONTOS_DE_MEDICAO", "ESTRUTURA_RATEIO_WBC"),
      fields: new Array(
        "segmento",
        "representante",
      ),
    },
  },
  PARCEIRO_COMISSIONADO: {
    id: "PARCEIRO_COMISSIONADO",
    nome: "Parceiro Comissionado",
    restrictions: {
      tabs: new Array(
        "CCEE",
        "GESTOR",
        "POS_VENDAS",
        "PONTOS_DE_MEDICAO",
        "ESTRUTURA_RATEIO_WBC"
      ),
      fields: new Array(
        "segmento",
        "classeAgente",
        "representante",
        "categorias"
      ),
    },
  },
  OUTROS_FORNECEDORES: {
    id: "OUTROS_FORNECEDORES",
    nome: "Outros Fornecedores",
    restrictions: {
      tabs: new Array(
        "CCEE",
        "GESTOR",
        "POS_VENDAS",
        "PONTOS_DE_MEDICAO",
        "ESTRUTURA_RATEIO_WBC"
      ),
      fields: new Array(
        "segmento",
        "classeAgente",
        "representante",
        "categorias"
      ),
    },
  },
};
