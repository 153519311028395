<template>
  <div class="col col-6 d-flex align-items-end">
    <div class="input-group">
      <label for="nomeField">
        Nome do Grupo Econômico <span class="required-markup">*</span>
        <input
          :disabled="!$parent.editingComponent && !isCreating"
          @blur="updateNomeGrupoEconomico"
          @keyup.enter="updateNomeGrupoEconomico"
          maxlength="100"
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
          }"
          id="nomeField"
          v-model.trim="$v.model.content.$model"
        />
      </label>
    </div>
    <div v-if="!$parent.isNotCreated" class="mb-2 ms-3">
      <i
        v-if="!$parent.componenteLoading && CAN_EDITAR"
        class="icon-lapis icon cursop col-lg-1"
        @click="$parent.editingComponent = true"
      ></i>
      <div
        v-else-if="$parent.componenteLoading"
        class="small spinner-border spinner-orange-default col-lg-1"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
export default {
  extends: FieldTemplate,
  props: { modelProp: Object, isCreating: Boolean },
  data() {
    return {
      model: {
        name: "nome",
        content: "",
      },
    };
  },
  methods: {
    isInvalid() {
      if (!this.$parent.editingComponent) return false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert(
          "Campo Nome do Grupo Econômico inválido"
        );
        return true;
      }
      return false;
    },
    updateNomeGrupoEconomico() {
      if (!this.isCreating) {
        this.$emit("updateNomeGrupoEconomico");
      }
    },
  },
  computed: {
    CAN_EDITAR() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
label {
  width: 100% !important;
}
</style>
