<template>
  <div class="mt-5" v-async="loading">
    <div class="row mt-3">
      <form-row-header text="Cadastrar conta" />
    </div>
    <div class="row mt-3">
      <banco-field
        ref="bancoField"
        :modelProp="model"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('nomeBanco')"
      />
    </div>
    <div class="row mt-3">
      <switch-conta-ativa-field
        :modelProp="model"
        ref="contaAtivaField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('contaPrincipal')"
      />
      <agencia-field
        ref="agenciaField"
        :modelProp="model"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('agencia')"
      />
      <numero-conta-field
        :modelProp="model"
        ref="numeroContaField"
        :recentUpdatedField="recentUpdatedField('numero')"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
      <tipo-conta-field
        :modelProp="model"
        ref="tipoContaField"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentUpdatedField="recentUpdatedField('tipo')"
      />
    </div>
    <div class="row mt-5">
      <div class="col col-lg-auto ms-auto">
        <button v-if="editing" @click="resetFields" class="btn btn-secondary">
          Cancelar
        </button>
        <button
          class="btn btn-primary ms-3"
          @click="add"
          v-if="CAN_SHOW_BUTTON"
        >
          {{ editing ? "Atualizar" : "Adicionar" }}
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <form-row-header text="Contas Cadastradas" />
      <table-contas-cadastradas
        :contasList="list"
        @editConta="edit"
        @deleteConta="remove"
        :recentUpdatedFields="recentUpdatedFields"
        :disabled="
          !$can('CENTRAL_CADASTROS_EDITAR') &&
          !$can('CENTRAL_CADASTROS_APROVAR') &&
          !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        :recentCreatedDadosBancarios="recentCreatedDadosBancarios"
      />
    </div>
    <div class="col-12 d-flex justify-content-end">
      <button
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
        @click="sincronizarWbc()"
        class="btn btn-secondary-blue ms-3"
      >
        <strong>Importar</strong> dados do <strong>WBC</strong>
      </button>
    </div>
  </div>
</template>

<script>
import CRUDTemplate from "@/components/CRUDTemplate";
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
import AgenciaField from "@/components/forms/fields/empresa/AgenciaField.vue";
import BancoField from "@/components/forms/fields/empresa/BancoField.vue";
import NumeroContaField from "@/components/forms/fields/empresa/NumeroContaField.vue";
import SwitchContaAtivaField from "@/components/forms/fields/empresa/SwitchContaAtivaField.vue";
import TipoContaField from "@/components/forms/fields/empresa/TipoContaField.vue";
import TableContasCadastradas from "@/components/tables/TableContasCadastradas.vue";
import { CONFIRMATION } from "@/constants/strings";
import DadosBancariosModel from "@/models/empresa/DadosBancariosModel";
import EmpresaModel from "@/models/empresa/EmpresaModel";
import DadosBancariosService from "@/services/portal/empresa/DadosBancariosService";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { formIsValid } from "@/utils/validators";

export default {
  extends: CRUDTemplate,
  data() {
    return {
      recentUpdatedFields: new Array(),
      recentCreatedDadosBancarios: new Array(),
    };
  },
  mounted() {
    const { empresaId } = this.$parent;
    this.loading = EmpresaService.getById({ empresaId })
      .then(({ data }) => {
        this.service = DadosBancariosService;
        this.requestEntity = "dado_bancario";
        this.model = new DadosBancariosModel();
        this.empresa = new EmpresaModel(data);
        this.setHeaderText(this.empresa.razaoSocial);
        this.setBreadcrumbsParam(this.empresa.tipo.nome);
        this.getByEmpresa();
        this.mountRecentUpdatedFields(empresaId);
      })
      .catch((error) => this.mxHandleRequestError(error, "dado_bancario"));
  },
  methods: {
    mountRecentUpdatedFields(empresaId) {
      this.loading = EmpresaService.getEmpresaDadosBancariosRecentUpdates({
        empresaId,
      })
        .then(({ data }) => {
          this.recentUpdatedFields = data;
          this.recentCreatedDadosBancarios = this.recentUpdatedFields
            .map((dadoBancarioAlterado) => {
              if (dadoBancarioAlterado.campos.length == 0) {
                return dadoBancarioAlterado.id;
              }
            })
            .filter(
              (dadoBancarioAlterado) => dadoBancarioAlterado != undefined
            );

          if (this.recentUpdatedFields.length > 0) {
            this.mxToggleWarningMessageAlert(
              "Campos alterados: na aba Dados Bancários",
              true,
              true
            );
          } else {
            this.mxClearYellowPendency(true);
          }
        })
        .catch((error) => {
          this.mxHandleRequestError(error);
        });
    },
    async disableEmpresaConta(conta) {
      conta.contaPrincipal = false;
      conta.codigoBanco = this.model.nomeBanco.substring(0, 3);
      return DadosBancariosService.update(conta, this.empresa.id);
    },
    getEmpresaContaAtiva() {
      if (this.list.length === 0) return;
      return this.list.find((conta) => conta.contaPrincipal);
    },
    persistConta() {
      this.model.codigoBanco = this.model.nomeBanco.substring(0, 3);
      this.model.numero = this.model.numero.replace(/[^\d]/g, "");

      (this.editing ? this.update() : this.save())
        .then(() => {
          const { empresaId } = this.$parent;
          this.mountRecentUpdatedFields(empresaId);
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    async add() {
      if (!formIsValid(this)) return;
      if (this.model.contaPrincipal) {
        const contaAtiva = this.getEmpresaContaAtiva();
        if (contaAtiva && contaAtiva.id !== this.model.id) {
          try {
            await this.mxToggleWarningModal(
              CONFIRMATION.DADO_BANCARIO.INACTIVE_PRINCIPAL
            );
            await this.disableEmpresaConta(contaAtiva);
          } catch (error) {}
        }
      }
      this.persistConta();
    },
    edit(entity) {
      this.editing = true;
      this.model = new DadosBancariosModel(entity);
    },
    resetFields() {
      this.editing = false;
      this.mxResetErrorPendencies();
      this.model = new DadosBancariosModel();
      this.resetVuelidateFields();
    },
    resetVuelidateFields() {
      Object.keys(this.$refs).forEach((ref) => {
        if (!this.$refs[ref]) return;
        if (!this.$refs[ref].hasOwnProperty("isInvalid")) return;
        this.$refs[ref].$v.$reset();
      });
    },
    recentUpdatedField(field) {
      const updatedFieldsFiltered =
        this.recentUpdatedFields.find(
          (dadoBancarioAlterado) => this.model.id === dadoBancarioAlterado.id
        ) ?? false;

      if (updatedFieldsFiltered) {
        return (
          updatedFieldsFiltered.campos &&
          updatedFieldsFiltered.campos.includes(field)
        );
      }
      return false;
    },
    sincronizarWbc() {
      const { empresaId } = this.$parent;
      this.loading = this.service
        .sincronizarDadosBancariosComWBC({
          empresaId,
        })
        .then(({ data }) => {
          this.list = data || new Array();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, this.requestEntity);
        });
    },
  },
  computed: {
    CAN_SHOW_BUTTON() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
  },
  components: {
    BancoField,
    AgenciaField,
    FormRowHeader,
    TipoContaField,
    NumeroContaField,
    SwitchContaAtivaField,
    TableContasCadastradas,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
