<template>
  <div class="container-about" v-async="loading">
    <div class="row">
      <h3>Dúvidas Sobre a Central de Cadastros?</h3>
    </div>
    <div class="mt-3 text-dark">
      Entre em contato através do
      <a href="https://matrix.movidesk.com/Account/Login" target="blank">Ed Ajuda </a> ou fale
      com a equipe responsável através do e-mail ou telefone.
    </div>
    <div class="row mt-5">
      <div class="col-1">
        <form-row-header text="E-mail:" />
      </div>
      <div class="col">ajudaportal@matrixenergia.com.br</div>
    </div>
    <div class="row mt-3">
      <div class="col-1">
        <form-row-header text="Telefone:" />
      </div>
      <div class="col">(11) 3344-5566</div>
    </div>
    <div class="row">
      <img
        src="@/assets/svg/heliceFundo.svg"
        class="img-fluid"
        alt="Imagem de um parque"
      />
    </div>
  </div>
</template>

<script>
import FormRowHeader from "@/components/forms/FormRowHeader.vue";
export default {
  data() {
    return {
      loading: undefined,
    };
  },
  components: {
    FormRowHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
.container-about {
  padding: 12px !important;

  h1 {
    font-weight: 400;
  }
}

img {
  margin-bottom: -32px;
  margin-left: 24px;
}

.text-dark {
  font-weight: 200;
}
</style>
