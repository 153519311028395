export default [
  "ACESSAR_CENTRAL_CADASTROS",
  "CENTRAL_CADASTROS_DESABILITADO",
  "CENTRAL_CADASTROS_CONSULTAR",
  "CENTRAL_CADASTROS_EDITAR",
  "CENTRAL_CADASTROS_APROVAR",
  "CENTRAL_CADASTROS_PARAMETRIZAR",
  "CENTRAL_CADASTROS_USUARIO_DESABILITADO",
  "CENTRAL_CADASTROS_USUARIO_VISUALIZAR",
  "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR",
  "CENTRAL_CADASTROS_GRUPO_ACESSO_DESABILITADO",
  "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR",
  "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR",
];
