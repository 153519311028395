<template>
  <div class="mt-4 wrapper-table table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="text-center" scope="col"></th>
          <th scope="col">Banco</th>
          <th scope="col">Agência</th>
          <th scope="col">Num. Conta</th>
          <th scope="col">Tipo de Conta</th>
          <th class="text-center" scope="col">Conta Principal</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <transition-group
        name="list"
        tag="tbody"
        mode="in-out"
        v-if="contasList.length > 0"
      >
        <tr
          v-for="(conta, contaIndex) in contasList"
          :key="`conta_${contaIndex}`"
          :class="{
            'recent-updated-row': recentUpdatedField(conta.id),
          }"
        >
          <td>
            <div class="pt-1 d-flex align-items-center justify-content-center">
              <tooltip
                v-if="recentUpdatedField(conta.id)"
                :width="450"
                boxAlign="right"
                arrowAlign="right"
                icon="icon-warning"
              >
                <template #tooltip-text>
                  Possui informações alteradas recentemente, pendente de
                  aprovação
                </template>
              </tooltip>
            </div>
          </td>
          <td>{{ conta.nomeBanco }}</td>
          <td>{{ conta.agencia }}</td>
          <td class="text-break">{{ setNumMask(conta.numero)}}</td>
          <td>{{ conta.tipo.nome }}</td>
          <td class="text-center">
            <i v-if="conta.contaPrincipal" class="icon-check icon small" />
            <span v-else></span>
          </td>
          <td>
            <i
              v-if="!disabled"
              class="icon-lapis cursop"
              @click="$emit('editConta', conta)"
            ></i>
          </td>
          <td>
            <i
              v-if="!disabled"
              class="icon-lixeira ms-2 cursop"
              @click="$emit('deleteConta', conta)"
            ></i>
          </td>
        </tr>
      </transition-group>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="7">Nenhuma conta cadastrada</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";
export default {
  props: {
    disabled: Boolean,
    contasList: {
      type: Array,
      required: true,
      default() {
        return new Array();
      },
    },
    recentUpdatedFields: {
      type: Array,
      required: false,
      default() {
        return new Array();
      },
    },
  },
  methods: {
    setNumMask(value){
      if (value) {
      const newValue = value.replace(/[^0-9]/g, "");
        if (newValue.length > 1) {
          return newValue.replace(/.$/, "-$&");
        } 
        return newValue;
      }
      return value;
    },
    recentUpdatedField(contaId) {
      return this.recentUpdatedFields.find(
        (contaAlterada) => contaId === contaAlterada.id
      );
    },
  },
  components: {
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon {
  color: $primary--orange !important;
}

th {
  font-weight: 500;
}

i {
  color: $primary--orange;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
