<template>
  <div>
    <div class="mt-4 wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Data da Criação</th>
            <th scope="col">Acesso</th>
          </tr>
        </thead>
        <tbody v-if="config.conteudo && config.conteudo.length > 0">
          <tr
            @click="click(usuario.id)"
            v-for="(usuario, usuarioIndex) in config.conteudo"
            :key="`usuario_${usuarioIndex}`"
          >
            <td>
              {{ usuario.nome }}
            </td>
            <td>{{ usuario.dataCriacao | DATE_ptBR }}</td>
            <td v-if="usuario.ativo === true">
              <i class="icon icon-padunlocked"></i>
              {{ "Ativo" || "-" }}
            </td>
            <td class="ft" v-else>
              <i class="icon icon-padlock"></i>
              {{ "Bloqueado" || "-" }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="4">Nenhum usuário adicionado</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { DATE_ptBR } from "@/filters/formatters";
import Pagination from "@/components/pagination/pagination";
export default {
  props: { config: Object },
  components: {
    Pagination,
  },
  filters: {
    DATE_ptBR,
  },
  methods: {
    click(value) {
      this.$emit("selectUser", value);
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon {
  color: $primary--orange !important;
}

td {
  cursor: pointer;
}

.icon-padlock:before {
  color: $primary--orange;
}

.icon-key:before {
  color: $primary--orange;
}

.ft {
  font-weight: 600;
}
</style>
