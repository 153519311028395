<template>
  <div>
    <div class="wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Razão social</th>
            <th>CNPJ</th>
            <th>Apelido</th>
            <th v-if="CAN_APROVAR">
              <div class="d-flex align-items-center justify-content-center">
                Excluir
                <help v-if="config.conteudo.length <= 2" class="ms-2">
                  O Grupo Econômico deve ter, ao menos, 2 empresas
                </help>
              </div>
            </th>
          </tr>
        </thead>
        <transition-group
          tag="tbody"
          name="list"
          mode="in-out"
          v-if="config.conteudo.length > 0"
        >
          <tr
            @click="click(empresa)"
            v-for="(empresa, empresaIndex) in config.conteudo"
            :key="`empresaIndex${empresaIndex}`"
          >
            <td>{{ empresa.razaoSocial }}</td>
            <td>{{ empresa.cnpj | CNPJ }}</td>
            <td>{{ empresa.apelido }}</td>
            <td
              v-if="config.conteudo.length > 2 && CAN_APROVAR"
              @click.stop="$emit('deleteEmpresa', empresa)"
            >
              <div class="text-center">
                <i class="icon-lixeira" />
              </div>
            </td>
          </tr>
        </transition-group>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="4">Nenhuma empresa cadastrada</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { CNPJ } from "@/filters/formatters";
import Pagination from "@/components/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  filters: {
    CNPJ,
  },
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {};
  },
  computed: {
    CAN_APROVAR() {
      return (
        this.$can("CENTRAL_CADASTROS_APROVAR") || this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
  },
  methods: {
    click(value) {
      this.$emit("click", value);
    },
    getData(linha, coluna) {
      if (coluna === "tipo") {
        return linha[coluna].nome;
      }
      return linha[coluna];
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.table {
  cursor: pointer;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
