<template>
  <div class="container-fluid">
    <page-tabs :tabs="tabs" />
    <dados-pessoais v-if="activeTab === 'DADOS_PESSOAIS'" />
    <seguranca v-else-if="activeTab === 'SEGURANCA'" />
  </div>
</template>
<script>
import Seguranca from "./forms/Seguranca.vue";
import DadosPessoais from "./forms/DadosPessoais.vue";
import PageTabs from "@/components/navigation/PageTabs.vue";
export default {
  data() {
    return {
      activeTab: "DADOS_PESSOAIS",
      tabs: new Array(
        { title: "Dados pessoais", key: "DADOS_PESSOAIS", active: true },
        { title: "Segurança", key: "SEGURANCA", active: true }
      ),
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
  components: {
    PageTabs,
    DadosPessoais,
    Seguranca,
  },
};
</script>
<style scoped lang="scss">
.container-fluid {
  padding: 12px !important;
  height: 100%;
}
</style>
