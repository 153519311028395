import WarningModal from "@/components/modais/WarningModal.vue";

export default {
  methods: {
    mxToggleWarningModal(
      message = "Deseja prosseguir ?",
      btnTrue = "Sim, prosseguir",
      btnFalse = "Não",
      title = "ATENÇÃO",
      showBtnFalse = true,
      rejectOnCloseModal = false
    ) {
      return WarningModal.show({
        message,
        title,
        btnTrue,
        btnFalse,
        showBtnFalse,
        rejectOnCloseModal,
      });
    },
  },
};
