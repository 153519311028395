import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    usuario: state.usuario,
  }),
  storage: window.sessionStorage,
});

import { pages } from "./pages";
import { usuario } from "./usuario";
import { empresa } from "./empresa";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    usuario,
    pages,
    empresa,
  },
  plugins: [vuexLocal.plugin],
});
