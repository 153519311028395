<template>
  <div>
    <div class="box-breadcrumbs mb-3" v-if="routeFormated">
      <div v-html="routeFormated" class="mtx-breadcrumbs" />
    </div>
  </div>
</template>
<script>
const store = {
  dialog: {},
};
export default {
  data() {
    return {
      routeFormated: "",
      param: "",
    };
  },
  mounted() {
    store.dialog = this;
    this.getRoute();
  },
  methods: {
    getRoute() {
      this.routeFormated = this.format(this.$route.meta.breadcrumb);
    },
    format(routes = new Array()) {
      let format = "";
      if (routes.length > 0) {
        routes.forEach((route, index) => {
          if (!this.param) {
            if (index < routes.length - 1) {
              format += `${route} > `;
            } else {
              format += route;
            }
          } else {
            format += `${route} > `;
          }
        });
      }
      if(!format) return null;
      format += `<span style="color: #418db7">${this.param}</span>`;
      return format;
    },
  },
  setParam(param) {
    store.dialog.param = param;
  },
  watch: {
    $route: function () {
      this.getRoute();
    },
    param: function () {
      this.getRoute();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

.mtx-breadcrumbs,
.last-route {
  font-size: 16px;
  border-radius: 5px;
  background: white;
  color: $secondary--gray;
  padding: 12px 16px;
}

.last-route {
  color: $secondary--blue !important;
}
</style>
