export default class DadosPessoaisModel {
  constructor(
    {
      id,
      cpf,
      role,
      nome,
      email,
      ativo,
      cargo,
      senha,
      documento,
      estadoCivil,
      telefoneFixo,
      telefoneCelular,
      numeroDocumento,
      dashboardsPermitidos,
    } = new Object()
  ) {
    this._id = id;
    this._cpf = cpf;
    this._role = role;
    this._nome = nome;
    this._email = email;
    this._ativo = ativo;
    this._cargo = cargo;
    this._senha = senha;
    this._estadoCivil = estadoCivil;
    this._telefoneFixo = telefoneFixo;
    this._tipoDocumento = documento;
    this._telefoneCelular = telefoneCelular;
    this._numeroDocumento = numeroDocumento;
    this._dashboardsPermitidos = dashboardsPermitidos;
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  set cpf(cpf = null) {
    this._cpf = cpf;
  }

  get cpf() {
    return this._cpf;
  }

  set role(role = null) {
    this._role = role;
  }

  get role() {
    return this._role;
  }

  set nome(nome = null) {
    this._nome = nome;
  }

  get nome() {
    return this._nome;
  }

  set email(email = null) {
    this._email = email;
  }

  get email() {
    return this._email;
  }

  set ativo(ativo = null) {
    this._ativo = ativo;
  }

  get ativo() {
    return this._ativo ?? true;
  }

  set cargo(cargo = null) {
    this._cargo = cargo;
  }

  get cargo() {
    return this._cargo;
  }

  set senha(senha = null) {
    this._senha = senha;
  }

  get senha() {
    return this._senha;
  }

  set estadoCivil(estadoCivil = null) {
    this._estadoCivil = estadoCivil;
  }

  get estadoCivil() {
    return this._estadoCivil;
  }

  set telefoneFixo(telefoneFixo = null) {
    this._telefoneFixo = telefoneFixo;
  }

  get telefoneFixo() {
    if (this._telefoneFixo) {
      return this._telefoneFixo.replace(/[^\d]/g, "");
    }
    return null;
  }

  set documento(documento = null) {
    this._tipoDocumento = documento;
  }

  get documento() {
    return this._tipoDocumento;
  }

  set telefoneCelular(telefoneCelular = null) {
    this._telefoneCelular = telefoneCelular;
  }

  get telefoneCelular() {
    if (this._telefoneCelular) {
      return this._telefoneCelular.replace(/[^\d]/g, "");
    }
    return null;
  }

  set numeroDocumento(numeroDocumento = null) {
    this._numeroDocumento = numeroDocumento;
  }

  get numeroDocumento() {
    return this._numeroDocumento;
  }

  set dashboardsPermitidos(dashboardsPermitidos = new Array()) {
    this._dashboardsPermitidos = dashboardsPermitidos;
  }

  get dashboardsPermitidos() {
    return this._dashboardsPermitidos || new Array();
  }

  getData() {
    const {
      id,
      cpf,
      role,
      nome,
      email,
      ativo,
      cargo,
      senha,
      documento,
      estadoCivil,
      telefoneFixo,
      telefoneCelular,
      numeroDocumento,
      dashboardsPermitidos,
    } = this;
    return {
      id,
      cpf,
      role,
      nome,
      email,
      ativo,
      cargo,
      senha,
      documento,
      estadoCivil,
      telefoneFixo,
      telefoneCelular,
      numeroDocumento,
      dashboardsPermitidos,
    };
  }
}
