<template>
  <div class="cc-param-anexos">
    <section class="cc-param-anexos__content">
      <div class="row mt-5">
        <div class="col-md-6">
          <div class="text">Buscar Empresa Consumidora</div>
          <search-bar :cols="10" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <div class="text">Gestores encontrados</div>
          <table-selecionar-empresa class="mt-3" :config="tableSelectConfig" />
          <div class="box-buttom">
            <button class="btn btn-secondary">Selecionar Empresa</button>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <div class="text">Contatos de <b>Empresa Gestora ABC</b>:</div>
          <table-acesso :config="tableAcessoConfig" />
        </div>
      </div>
    </section>
    <footer>
      <cc-barra-salvar-cancelar @save="save" @cancel="cancel" />
    </footer>
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";
import TableAcesso from "@/components/tables/TableAcesso.vue";
import TableSelecionarEmpresa from "@/components/tables/TableSelecionarEmpresa.vue";
export default {
  data() {
    return {
      tableSelectConfig: {
        colunas: [
          { name: "cnpj", description: "CNPJ" },
          { name: "razao", description: "Razão Social" },
          { name: "apelido", description: "Apelido" },
          { name: "nome", description: "Nome Fantasia" },
        ],
        conteudo: [
          {
            cnpj: "00.000.000/000-00",
            razao: "Empresa Gestora ABC",
            apelido: "Gestora ABC",
            nome: "Gestora ABC",
          },
          {
            cnpj: "00.000.000/000-00",
            razao: "Empresa Gestora DEF",
            apelido: "Gestora DEF",
            nome: "Gestora DEF",
          },
        ],
      },
      tableAcessoConfig: {
        colunas: [
          { name: "nome", description: "Nome" },
          { name: "email", description: "E-mail" },
          { name: "acesso", description: "Acesso" },
          { name: "senha", description: "Senha" },
        ],
        conteudo: [
          { nome: "Ana", email: "ana@teste.com", acesso: "Criar", senha: "" },
          {
            nome: "Ana",
            email: "ana@teste.com",
            acesso: "Desbloquear",
            senha: "Bloqueada",
          },
          {
            nome: "Ana",
            email: "ana@teste.com",
            acesso: "Bloquear",
            senha: "Reenviar",
          },
        ],
      },
    };
  },
  methods: {
    save() {
      console.log("salvar");
    },
    cancel() {
      console.log("cancelar");
    },
  },
  components: {
    SearchBar,
    TableSelecionarEmpresa,
    TableAcesso,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/mixins.scss";
.cc-param-anexos {
  display: flex;
  flex-direction: column;
  .cc-param-anexos__content {
    flex: 1;
  }
  height: calc(100% - 30px);
}
.section-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.text {
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #222222;
}
.box-buttom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
