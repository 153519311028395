<template>
  <div class="cc-param-anexos">
    <section class="cc-param-anexos__content">
      <div class="row mt-5">
        <div class="col-lg-7">
          <crud-parametros :parametro="ParametrosENUM.TIPO_ANEXO" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CrudParametros from "../CRUDParametros";
import ParametrosENUM from "@/enums/ParametrosENUM";
export default {
  computed: {
    ParametrosENUM: () => ParametrosENUM,
  },
  components: {
    CrudParametros,
  },
};
</script>
<style lang="scss" scoped>
.cc-param-anexos {
  display: flex;
  flex-direction: column;
  .cc-param-anexos__content {
    flex: 1;
  }
  height: calc(100% - 30px);
}
.section-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
</style>
