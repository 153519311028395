<template>
  <div class="col col-lg-1">
    <div class="input-group">
      <label for="inscricaoEstadualEstadoField">
        U.F I.E
        <span
          class="recent-updated-label"
          v-if="recentUpdatedField && !isDisabled"
        >
          <tooltip-informacao-alterada />
        </span>
        <select
          ref="inscricaoEstadualEstadoField"
          id="inscricaoEstadualEstadoField"
          v-model="model.content"
          class="form-select break-text"
          :class="{
            'is-invalid': $v.model.content.$error,
            'is-invalid-yellow': $v.model.content.$error && invalidYellow,
            'recent-updated': recentUpdatedField && !isDisabled,
          }"
          :disabled="isDisabled"
        >
          <option
            v-for="(estado, estadoIndex) in list"
            :key="`ufie_option_${estadoIndex}`"
            :value="estado"
          >
            {{ estado.id }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import InscricaoEstadualTypeENUM from "@/enums/InscricaoEstadualTypeENUM";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    invalidYellow: Boolean,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      loadList: undefined,
      list: new Array(),
      model: {
        name: "inscricaoEstadualEstado",
        content: "",
      },
    };
  },
  watch: {
    invalidYellow: {
      deep: true,
      handler() {
        if (this.invalidYellow) {
          this.$v.$touch();
        } else {
          this.$v.$reset();
        }
      },
    },
    "$parent.model.inscricaoEstadualTipo": {
      immediate: true,
      deep: true,
      handler() {
        if (!!this.$parent.model.inscricaoEstadualTipo) {
          if (
            this.$parent.model.inscricaoEstadualTipo.id ===
              InscricaoEstadualTypeENUM.ISENTO.id ||
            this.$parent.model.inscricaoEstadualTipo.id ===
              InscricaoEstadualTypeENUM.VERIFICAR.id ||
            this.$parent.model.inscricaoEstadualTipo.id ===
              InscricaoEstadualTypeENUM.NAO_LOCALIZADO.id
          ) {
            this.model.content = null;
          }
        }
      },
    },
  },
  computed: {
    InscricaoEstadualTypeENUM: () => InscricaoEstadualTypeENUM,
    isDisabled() {
      return (
        this.$parent.model.inscricaoEstadualTipo.id ===
          InscricaoEstadualTypeENUM.ISENTO.id ||
        this.$parent.model.inscricaoEstadualTipo.id ===
          InscricaoEstadualTypeENUM.VERIFICAR.id ||
        this.$parent.model.inscricaoEstadualTipo.id ===
          InscricaoEstadualTypeENUM.NAO_LOCALIZADO.id ||
        this.disabled
      );
    },
  },
  mounted() {
    this.loadList = EmpresaService.listEstados()
      .then(({ data }) => (this.list = data))
      .catch((error) => console.log(error));
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo UF Inscrição Estadual inválido");
        this.$refs.inscricaoEstadualEstadoField.classList.remove(
          "is-invalid-yellow"
        );
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {},
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}

.break-text {
  padding: 0.375rem 1.25rem 0.375rem 0.75rem !important;
  background-position: right 0.3rem center !important;
}
</style>
