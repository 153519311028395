<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="acessoField">
        Grupo de Acesso <span class="required-markup">*</span>
        <select
          id="acessoField"
          class="form-select"
          :disabled="disabled"
          :class="{
            'is-invalid': $v.model.content.$error,
          }"
          v-model.trim="$v.model.content.$model"
        >
          <option
            :value="role"
            v-for="(role, roleIndex) in list"
            :key="`role_${roleIndex}`"
          >
            {{ role.nome }}
          </option>
        </select>
        <div class="form-text">Escolha uma das opções.</div>
      </label>
    </div>
  </div>
</template>

<script>
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import { required } from "vuelidate/lib/validators";
import RolesService from "@/services/portal/RolesService";
export default {
  extends: FieldTemplate,
  props: {
    modelProp: Object,
    disabled: Boolean,
  },
  data() {
    return {
      loadList: undefined,
      list: new Array(),
      model: {
        name: "role",
        content: "",
      },
    };
  },
  mounted() {
    this.loadList = RolesService.getRolesList()
      .then(({ data }) => {
        this.list = data.filter((grupoAcesso) => grupoAcesso.ativo);
      })
      .catch((error) => console.log(error));
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Grupo de Acesso inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
