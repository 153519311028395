<template>
  <div class="col col-lg-5 mt-4">
    <div class="input-group">
      <label for="pontoRetaguardaField">
        Pto Retaguarda
        <span
          class="recent-updated-label"
          v-if="recentUpdatedField && tipoRetaguardaAtivo"
        >
          <tooltip-informacao-alterada />
        </span>
        <input
          @input="checkSpecialCharacters"
          maxlength="13"
          class="form-control mt-2"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField && tipoRetaguardaAtivo,
          }"
          :disabled="!tipoRetaguardaAtivo || disabled"
          v-model.trim="$v.model.content.$model"
        />
      </label>
    </div>
    <div class="form-text">Insira os 13 caracteres.</div>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import {
  requiredIf,
  minLength,
  maxLength,
  not,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    pontoMedicao: String,
    recentUpdatedField: Boolean,
    tipoRetaguardaAtivo: Boolean,
  },
  data() {
    return {
      model: {
        name: "pontoRetaguarda",
        content: "",
      },
    };
  },
  watch: {
    tipoRetaguardaAtivo: {
      handler() {
        if (!this.tipoRetaguardaAtivo) {
          this.model.content = null;
        }
      },
    },
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Ponto de Retaguarda inválido");
        return true;
      }
      return false;
    },
  },
  directives: {
    mask: AwesomeMask,
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          return this.tipoRetaguardaAtivo;
        }),
        maxLength: maxLength(13),
        minLength: minLength(13),
        isValid: not(
          sameAs(function () {
            return this.pontoMedicao;
          })
        ),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}

.form-control {
  text-transform: uppercase;
}
</style>
