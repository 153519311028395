import { mapActions } from "vuex";
import { Toast } from "bootstrap";

export default {
    methods: {
        ...mapActions(["ActionToggleToast", "ActionResetToast"]),
        mxToggleToast(message) {
            this.ActionToggleToast({ title: "Sucesso", message });
            const myToastEl = document.getElementById('toast-component');
            return new Toast(myToastEl).show();
        },
        mxResetToast() {
            this.ActionResetToast();
        }
    }
}