<template>
  <div class="col col-lg-3">
    <label for="tipoContaField">
      Tipo de Conta <span class="required-markup">*</span>
      <span class="recent-updated-label" v-if="recentUpdatedField">
        <tooltip-informacao-alterada />
      </span>
      <div class="input-group">
        <select
          class="form-select"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="tipoContaField"
          :disabled="disabled"
          placeholder="Selecione"
          v-model.trim="$v.model.content.$model"
        >
          <option
            :value="contaType"
            v-for="(contaType, contaTypeIndex) in list"
            :key="`anexo_type_${contaTypeIndex}`"
          >
            {{ contaType.nome }}
          </option>
        </select>
      </div>
      <div class="form-text">Escolha uma das opções.</div>
    </label>
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { required } from "vuelidate/lib/validators";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
import DadosBancariosService from "@/services/portal/empresa/DadosBancariosService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "tipo",
        content: "",
      },
      loadList: new Array(),
      list: new Array(),
    };
  },
  mounted() {
    this.loadList = DadosBancariosService.listTipoConta()
      .then(
        ({ data }) => (this.list = data.filter((tipoConta) => tipoConta.ativo))
      )
      .catch((error) => console.log(error));
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Tipo de Conta inválido");
        return true;
      }
      return false;
    },
  },
  directives: {
    mask: AwesomeMask,
  },
  validations: {
    model: {
      content: {
        required,
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
