<template>
  <div class="ms-2 d-flex align-items-center justify-content-center">
    <tooltip :width="200" :boxAlign="box" arrowAlign="right" icon="icon-info">
      <template #tooltip-text>
        Campo com destaque porque sofreu alterações desde a última aprovação.
      </template>
    </tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";
export default {
  props: {
    box: {
      type: String,
      default: "right",
    },
  },
  data() {
    return {};
  },
  components: {
    Tooltip,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
</style>
