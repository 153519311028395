export default class DataTableEmpresaModel {
  constructor(
    {
      id,
      razaoSocial,
      nome,
      cnpj,
      apelido,
      tipo,
      grupo,
      status,
      dataStatus,
      selectedForGroup,
    } = new Object()
  ) {
    this._id = id;
    this._razaoSocial = razaoSocial;
    this._dataStatus = dataStatus;
    this._nome = nome;
    this._cnpj = cnpj;
    this._apelido = apelido;
    this._tipo = tipo;
    this._grupo = grupo;
    this._status = status;
    this._selectedForGroup = selectedForGroup;
  }

  get nome() {
    return this._nome;
  }

  set nome(nome = null) {
    this._nome = nome;
  }

  get selectedForGroup() {
    return this._selectedForGroup;
  }

  set selectedForGroup(selectedForGroup = null) {
    this._selectedForGroup = selectedForGroup;
  }

  get dataStatus() {
    return this._dataStatus;
  }

  set dataStatus(dataStatus = null) {
    this._dataStatus = dataStatus;
  }

  get id() {
    return this._id;
  }

  set id(id = null) {
    this._id = id;
  }

  get razaoSocial() {
    return this._razaoSocial;
  }

  set razaoSocial(razaoSocial = null) {
    this._razaoSocial = razaoSocial;
  }

  get cnpj() {
    if (this._cnpj) {
      return this._cnpj.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    }
    return "-";
  }

  set cnpj(cnpj = null) {
    this._cnpj = cnpj;
  }

  get apelido() {
    return this._apelido || "-";
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get tipo() {
    return this._tipo.nome;
  }

  set tipo(tipo = null) {
    this._tipo = tipo;
  }

  get grupo() {
    if (this._grupo) return this._grupo.nome;
    return "-";
  }

  set grupo(grupo = null) {
    this._grupo = grupo;
  }

  get status() {
    return this._status;
  }

  set status(status = null) {
    this._status = status;
  }

  getData() {
    const {
      id,
      nome,
      razaoSocial,
      cnpj,
      apelido,
      tipo,
      grupo,
      dataStatus,
      status,
    } = this;
    return {
      id,
      nome,
      razaoSocial,
      dataStatus,
      cnpj,
      apelido,
      tipo,
      grupo,
      status,
    };
  }

}