import Vue from 'vue';
import vuescroll from 'vuescroll/dist/vuescroll-native';
Vue.use(vuescroll, {
    ops: {},
    name: 'mtx-scroll'
});

Vue.prototype.$vuescrollConfig = {
    bar: {
        background: '#000'
    }
};