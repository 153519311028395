export default {
  inserted(el, binding) {},
  update(el, binding) {
    if (binding.value === binding.oldValue) return;
    if (document.querySelector(".modalSpinner")) return;
    const modalSpinner = document.createElement("div");
    modalSpinner.setAttribute("class", "modalSpinner");
    const spinner = document.createElement("div");
    spinner.setAttribute("class", "spinner");
    modalSpinner.appendChild(spinner);
    el.setAttribute("style", "position:relative");
    el.appendChild(modalSpinner);
    const modal = document.querySelector(".modalSpinner");
    binding.value.catch(() => el.remove(modal));
    binding.value.then(() => modal.remove());
  },
};
