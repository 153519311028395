<template>
  <div class="col col-lg-4">
    <label for="numeroContaField">
      Número da Conta (com dígito) <span class="required-markup">*</span>
      <span class="recent-updated-label" v-if="recentUpdatedField">
        <tooltip-informacao-alterada />
      </span>
      <div class="input-group">
        <input
          class="form-control"
          :class="{
            'is-invalid': $v.model.content.$error,
            'recent-updated': recentUpdatedField,
          }"
          id="numeroContaField"
          v-model.trim="$v.model.content.$model"
          maxLength="255"
          @input="setNumContaMask()"
          :disabled="disabled"
        />
      </div>
    </label>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      model: {
        name: "numero",
        content: "",
      },
    };
  },
  watch: {
    modelProp: {
      deep: true,
      handler: "modelPropHandler"
    },
    "model.content": {
      deep: true,
      handler() {
        const replacedStr = this.model.content?.replace(/[^0-9]/g, "")

        if(this.modelProp[this.model.name] == replacedStr) this.modelPropHandler()
        this.modelProp[this.model.name] = replacedStr
      },
    },
  },
  methods: {
    modelPropHandler() {
        if (this.modelProp && this.modelProp[this.model.name]) {
          if (this.modelProp[this.model.name].length > 1) {
            this.model.content = this.modelProp[this.model.name].replace(
              /.$/,
              "-$&"
            );
          } else {
            this.model.content = this.modelProp[this.model.name];
          }
        } else {
          this.model.content = "";
        }
    },
    setNumContaMask() {
      this.model.content = this.model.content.replace(/[^0-9]/g, "");

      if (this.model.content.length > 1) {
        this.model.content = this.model.content.replace(/.$/, "-$&");
      }
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Campo Número da Conta inválido");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
