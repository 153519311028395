import EnderecoModel from "./EnderecoModel";
import AgentePosVendaModel from "./AgentePosVendaModel";
import InscricaoEstadualTypeENUM from "@/enums/InscricaoEstadualTypeENUM";

export default class EmpresaModel {
  constructor(data = new Object()) {
    this._id = data.id;
    this._cnae = data.cnae;
    this._cnpj = data.cnpj;
    this._tipo = data.tipo;
    this._ccee = data.ccee;
    this._setor = data.setor;
    this._gestor = data.gestor;
    this._matriz = data.matriz;
    this._status = data.status;
    this._apelido = data.apelido;
    this._arquivos = data.arquivos;
    this._contatos = data.contatos;
    this._endereco = data.endereco;
    this._segmento = data.segmento;
    this._categorias = data.categorias;
    this._matrizCnpj = data.matrizCnpj;
    this._pendencies = new Array();
    this._razaoSocial = data.razaoSocial;
    this._nomeFantasia = data.nomeFantasia;
    this._classeAgente = data.classeAgente;
    this._isProvisorio = data.isProvisorio;
    this._configuracao = data.configuracao;
    this._codigoAgente = data.codigoAgente;
    this._representante = data.representante;
    this._contatosGestor = data.contatosGestor;
    this._dadosBancarios = data.dadosBancarios;
    this._usuarioPosVenda = data.usuarioPosVenda;
    this._descricaoStatus = data.descricaoStatus;
    this._perfisDeAgentes = data.perfisDeAgentes;
    this._pontosDeMedicao = data.pontosDeMedicao;
    this._inscricaoEstadual = data.inscricaoEstadual;
    this._habilitadoDemonstrativo = data.habilitadoDemonstrativo;
    this._inscricaoEstadualTipo = data.inscricaoEstadualTipo;
    this._inscricaoEstadualEstado = data.inscricaoEstadualEstado;
    this._pendencies = new Array();
  }

  get id() {
    return this._id || null;
  }

  set id(id = null) {
    this._id = id;
  }

  get cnae() {
    return this._cnae || null;
  }

  set cnae(cnae = null) {
    this._cnae = cnae;
  }

  get cnpj() {
    return this._cnpj.replace(/[^\d]/g, "");
  }

  set cnpj(cnpj = null) {
    this._cnpj = cnpj;
  }

  get tipo() {
    return this._tipo || null;
  }

  set tipo(tipo = null) {
    this._tipo = tipo;
  }

  get ccee() {
    return this._ccee || null;
  }

  set ccee(ccee = null) {
    this._ccee = ccee;
  }

  get setor() {
    return this._setor || null;
  }

  set setor(setor = null) {
    this._setor = setor;
  }

  get gestor() {
    return this._gestor || null;
  }

  set gestor(gestor = null) {
    this._gestor = gestor;
  }

  get matriz() {
    return this._matriz || false;
  }

  set matriz(matriz = false) {
    this._matriz = matriz;
  }

  get status() {
    return this._status || null;
  }

  set status(status = null) {
    this._status = status;
  }

  get apelido() {
    return this._apelido || null;
  }

  set apelido(apelido = null) {
    this._apelido = apelido;
  }

  get arquivos() {
    if (this._arquivos) {
      if (this._arquivos.length > 0) {
        return this._arquivos;
      }
    }
    return new Array();
  }

  set arquivos(arquivos = null) {
    this._arquivos = arquivos;
  }

  get contatos() {
    if (this._contatos) {
      if (this._contatos.length > 0) {
        return this._contatos;
      }
    }
    return new Array();
  }

  addContato(contato) {
    this._contatos.push(contato);
  }

  set contatos(contatos = null) {
    this._contatos = contatos;
  }

  get endereco() {
    return this._endereco || new EnderecoModel();
  }

  set endereco(endereco = null) {
    this._endereco = endereco;
  }

  get segmento() {
    return this._segmento || null;
  }

  set segmento(segmento = null) {
    this._segmento = segmento;
  }

  get categorias() {
    if (this._categorias) {
      if (this._categorias.length > 0) {
        return this._categorias;
      }
    }
    return new Array();
  }

  set categorias(categorias = null) {
    this._categorias = categorias;
  }

  get matrizCnpj() {
    if (this._matrizCnpj) {
      return this._matrizCnpj.replace(/[^\d]/g, "");
    }
    return null;
  }

  set matrizCnpj(matrizCnpj = null) {
    this._matrizCnpj = matrizCnpj;
  }

  get razaoSocial() {
    return this._razaoSocial || null;
  }

  set razaoSocial(razaoSocial = null) {
    this._razaoSocial = razaoSocial;
  }

  get nomeFantasia() {
    return this._nomeFantasia || null;
  }

  set nomeFantasia(nomeFantasia = null) {
    this._nomeFantasia = nomeFantasia;
  }

  get classeAgente() {
    return this._classeAgente || null;
  }

  set classeAgente(classeAgente = null) {
    this._classeAgente = classeAgente;
  }

  get isProvisorio() {
    return this._isProvisorio || false;
  }

  set isProvisorio(isProvisorio = false) {
    this._isProvisorio = isProvisorio;
  }

  get configuracao() {
    return this._configuracao || null;
  }

  set configuracao(configuracao = null) {
    this._configuracao = configuracao;
  }

  get codigoAgente() {
    return this._codigoAgente || null;
  }

  set codigoAgente(codigoAgente = null) {
    this._codigoAgente = codigoAgente;
  }

  get representante() {
    return this._representante || null;
  }

  set representante(representante = null) {
    this._representante = representante;
  }

  get contatosGestor() {
    if (this._contatosGestor) {
      if (this._contatosGestor.length > 0) {
        return this._contatosGestor;
      }
    }
    return new Array();
  }

  set contatosGestor(contatosGestor = new Array()) {
    this._contatosGestor = contatosGestor;
  }

  addDadosBancarios(dadosBancarios) {
    this._dadosBancarios.push(dadosBancarios);
  }

  get dadosBancarios() {
    if (this._dadosBancarios) {
      if (this._dadosBancarios.length > 0) {
        return this._dadosBancarios;
      }
    }
    return new Array();
  }

  set dadosBancarios(dadosBancarios = new Array()) {
    this._dadosBancarios = dadosBancarios;
  }

  get usuarioPosVenda() {
    return this._usuarioPosVenda || new AgentePosVendaModel();
  }

  set usuarioPosVenda(usuarioPosVenda = null) {
    this._usuarioPosVenda = usuarioPosVenda;
  }

  get descricaoStatus() {
    return this._descricaoStatus || null;
  }

  set descricaoStatus(descricaoStatus = null) {
    this._descricaoStatus = descricaoStatus;
  }

  get perfisDeAgentes() {
    return this._perfisDeAgentes || new Array();
  }

  set perfisDeAgentes(perfisDeAgentes = new Array()) {
    this._perfisDeAgentes = perfisDeAgentes;
  }

  addPontoDeMedicao(pontoDeMedicao) {
    this._pontosDeMedicao.push(pontoDeMedicao);
  }

  get pontosDeMedicao() {
    if (this._pontosDeMedicao) {
      if (this._pontosDeMedicao.length > 0) {
        return this._pontosDeMedicao;
      }
    }
    return new Array();
  }

  set pontosDeMedicao(pontosDeMedicao) {
    this._pontosDeMedicao = pontosDeMedicao;
  }

  get inscricaoEstadual() {
    if (this._inscricaoEstadual) {
      return this._inscricaoEstadual;
    }
    return null;
  }

  set inscricaoEstadual(inscricaoEstadual = { tipo: null, numero: null }) {
    this._inscricaoEstadual = inscricaoEstadual;
  }

  get inscricaoEstadualTipo() {
    return (
      this._inscricaoEstadualTipo || InscricaoEstadualTypeENUM.CONTRIBUINTE
    );
  }

  set inscricaoEstadualTipo(inscricaoEstadualTipo) {
    this._inscricaoEstadualTipo = inscricaoEstadualTipo;
  }

  set inscricaoEstadualEstado(
    inscricaoEstadualEstado = {
      uf: null,
      nome: null,
    }
  ) {
    this._inscricaoEstadualEstado = inscricaoEstadualEstado;
  }

  get inscricaoEstadualEstado() {
    return this._inscricaoEstadualEstado;
  }

  get pendencies() {
    return this._pendencies || new Array();
  }

  set pendencies(pendencies = new Array()) {
    this._pendencies = pendencies;
  }

  get habilitadoDemonstrativo() {
    return this._habilitadoDemonstrativo || false;
  }

  set habilitadoDemonstrativo(habilitadoDemonstrativo = false) {
    this._habilitadoDemonstrativo = habilitadoDemonstrativo;
  }

  getData() {
    const {
      id,
      cnae,
      cnpj,
      tipo,
      setor,
      gestor,
      matriz,
      status,
      apelido,
      arquivos,
      contatos,
      endereco,
      segmento,
      categorias,
      matrizCnpj,
      razaoSocial,
      nomeFantasia,
      classeAgente,
      isProvisorio,
      configuracao,
      codigoAgente,
      representante,
      contatosGestor,
      dadosBancarios,
      usuarioPosVenda,
      descricaoStatus,
      perfisDeAgentes,
      pontosDeMedicao,
      inscricaoEstadual,
      inscricaoEstadualTipo,
      habilitadoDemonstrativo,
      inscricaoEstadualEstado,
    } = this;
    return {
      id,
      cnae,
      cnpj,
      tipo,
      setor,
      gestor,
      matriz,
      status,
      apelido,
      arquivos: arquivos.length === 0 ? null : arquivos,
      contatos:
        contatos.length === 0
          ? null
          : contatos.map((contato) => contato.getData(tipo.id)),
      endereco: endereco.getData(),
      segmento,
      categorias: categorias.length === 0 ? null : categorias,
      matrizCnpj,
      razaoSocial,
      nomeFantasia,
      classeAgente,
      isProvisorio,
      configuracao,
      codigoAgente,
      representante,
      contatosGestor: contatosGestor.length === 0 ? null : contatosGestor,
      dadosBancarios: dadosBancarios.length === 0 ? null : dadosBancarios,
      usuarioPosVenda,
      descricaoStatus,
      perfisDeAgentes: perfisDeAgentes.length === 0 ? null : perfisDeAgentes,
      pontosDeMedicao: pontosDeMedicao.length === 0 ? null : pontosDeMedicao,
      inscricaoEstadual,
      inscricaoEstadualTipo,
      habilitadoDemonstrativo,
      inscricaoEstadualEstado,
    };
  }
}
