<template>
  <div class="container" v-async="loading">
    <div class="row">
      <div class="col col-md-6">
        <form-row-header text="Pesquisa" />
      </div>
    </div>
    <div class="row my-5">
      <div class="col col-auto">
        <label>
          <input
            value="DADOS_PREENCHIDOS"
            v-model="selectedDataType"
            name="tipo_dados"
            type="radio"
          />
          Dados Preenchidos
        </label>
      </div>
      <div class="col col-auto">
        <label>
          <input
            value="DADOS_FALTANTES"
            v-model="selectedDataType"
            name="tipo_dados"
            type="radio"
          />
          Dados Faltantes
        </label>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-5 input-group">
        <label for="input_select_fields">
          Selecione os Campos do Cadastro
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="d-flex flex-row">
          <v-select
            multiple
            class="col-9"
            label="nome"
            v-model="selectedFields"
            :options="fields"
          >
            <span slot="no-options"></span>
          </v-select>

          <button
            @click="doSearch"
            :disabled="!(selectedFields.length > 0)"
            class="col-3 btn btn-primary rounded-0 rounded-end"
            type="button"
          >
            Gerar Lista
          </button>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <button
          type="button"
          @click="generateReport"
          class="col-auto btn btn-primary-blue rounded"
          :disabled="
            [selectedFields.length, tableConfig.conteudo.length].includes(0)
          "
        >
          Exportar XLS
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <data-table
          class="mt-4"
          @click="nextPage"
          @setPage="setPage"
          :config="tableConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/tables/Table.vue";
import FormRowHeader from "@/components/forms/FormRowHeader";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { downloadFileFromResponseObject } from "@/utils/functionUtils";
export default {
  data() {
    return {
      selectedDataType: "DADOS_PREENCHIDOS",
      fields: new Array(),
      loading: undefined,
      selectedField: null,
      selectedFields: new Array(),
      tableConfig: {
        colunas: [
          { name: "razaoSocial", description: "Razão Social" },
          { name: "cnpj", description: "CNPJ" },
          { name: "apelido", description: "Apelido" },
          { name: "tipo", description: "Tipo" },
        ],
        conteudo: new Array(),
        pagination: undefined,
      },
      filter: {
        size: 10,
        page: 0,
      },
    };
  },
  methods: {
    nextPage({ id }) {
      this.$router.push({
        name: "CadastroEmpresas",
        params: { id },
      });
    },
    generateReport() {
      const request = this.selectedFields.map((item) => item.caminho);
      this.loading = EmpresaService.getEmpresaExcel(
        this.selectedDataType === "DADOS_PREENCHIDOS"
          ? "camposPreenchidos"
          : "camposNulos",
        request
      )
        .then(({ data }) => {
          downloadFileFromResponseObject(
            data,
            `Dados ${
              this.selectedDataType === "DADOS_PREENCHIDOS"
                ? "Preenchidos"
                : "Faltantes"
            } por Empresa - ${new Date().toLocaleDateString()}.xlsx`
          );
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    setPage(page) {
      this.filter.page = page;
      this.doSearch();
    },
    doSearch(resetPage = false) {
      const { size, page } = this.filter;
      let nulo,
        preenchido = undefined;
      if (this.selectedDataType === "DADOS_PREENCHIDOS") {
        preenchido = this.selectedFields
          .map((item) => item.caminho)
          .join("&preenchido=");
      } else {
        nulo = this.selectedFields.map((item) => item.caminho).join("&nulo=");
      }
      this.loading = EmpresaService.list({
        size,
        nulo,
        preenchido,
        page: resetPage ? 0 : page,
      })
        .then((response) => {
          this.tableConfig.conteudo = response.data.content;
          this.tableConfig.pagination = response.data;
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  watch: {
    selectedDataType: {
      handler() {
        this.tableConfig.conteudo = new Array();
        this.tableConfig.pagination = undefined;
      },
    },
  },
  mounted() {
    this.setBreadcrumbsParam("Pesquisa");
    this.loading = EmpresaService.getParametrosFieldsList()
      .then(({ data }) => (this.fields = data))
      .catch((error) => this.mxHandleRequestError(error));
  },
  components: {
    DataTable,
    FormRowHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
