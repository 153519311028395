export const empresa = {
    state: {
        data: new Object(),
    },
    getters: {
        empresaEditing(state) {
            return state.data;
        }
    },
    mutations: {
        resetEmpresaEditing(state) {
            state.data = new Object();
        },
        setEmpresaEditing(state, data) {
            state.data = data;
        },
    },
    actions: {
        ActionResetEmpresaEditing(context, data) {
            context.commit("resetEmpresaEditing", data);
        },
        ActionSetEmpresaEditing(context, data) {
            context.commit("setEmpresaEditing", data);
        },
    }
}