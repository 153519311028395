<template>
  <div class="wrapper-paginacao">
    <div v-if="info" class="box">
      <ul class="pagination">
        <li v-if="info && !info.first">
          <a class="icon-change-page cursop" @click="previousPage()">
            <img src="./left.svg" />
          </a>
        </li>
        <template v-for="(v, i) in pages">
          <li
            v-if="showPage(v.page)"
            :class="[
              activePage == v.page ? (i <= 99 ? 'active' : 'active-lg') : null,
              i <= 99 ? 'item' : 'item-lg',
            ]"
            :key="`page_${i}`"
            @click="setPage(v.page)"
          >
            {{ v.label }}
          </li>
        </template>
        <li v-if="info && !info.last">
          <a class="icon-change-page cursop" @click="nextPage()">
            <img src="./right.svg" />
          </a>
        </li>
      </ul>
      <div class="legenda">
        {{ showInformation() }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["info"],
  data() {
    return {
      activePage: 1,
      lastPage: undefined,
      gap: 0,
      pages: new Array(),
    };
  },
  methods: {
    showPage(page) {
      const max = this.info.number + 10;
      const min = this.info.number - 10;
      return page >= min && page <= max;
    },
    setPage(number) {
      this.activePage = number;
      this.$emit("setPage", number);
    },
    previousPage() {
      this.setPage(this.activePage - 1);
    },
    nextPage() {
      this.setPage(this.activePage + 1);
    },
    showInformation() {
      const init = this.info.number * this.info.size + 1;
      const end = this.info.last
        ? this.info.totalElements
        : init + this.info.size - 1;
      return `Mostrando resultados de ${init} a ${end} de um total de ${this.info.totalElements} encontrados.`;
    },
  },
  watch: {
    info(newValue) {
      if (!newValue) return;
      this.pages = new Array();
      for (let i = 1; i <= newValue.totalPages; i++) {
        this.pages.push({ page: i - 1, label: i });
      }
      this.lastPage = newValue.totalPages;
      this.activePage = newValue.number;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.icon-change-page img {
  width: 10px;
  margin: 8px;
  margin-top: 3px;
  &:hover {
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }
}

.box {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.legenda {
  font-size: 11px;
  font-weight: 400;
  color: #707683;
  font-style: normal;
  margin-bottom: 5px;
  letter-spacing: 0.05em;
}

.item,
.item-lg {
  min-width: 25px;
  cursor: pointer;
  color: $secondary--gray;
  &:hover {
    transition: 0.2s ease-in-out;
    color: rgba($secondary--gray, $alpha: 0.5) !important;
  }
}

.item-lg {
  margin: 0px 3px !important;
}

.active,
.active-lg {
  color: #fff;
  border-radius: 3px;
  background: $primary--orange;
  &:hover {
    color: #fff !important;
  }
}

.active-lg {
  padding: 0px 4px !important;
}

.link {
  padding: 0 8px 0 8px;
  min-width: 25px !important;
}
</style>
