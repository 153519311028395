<template>
  <div class="modal" id="errorModal" tabindex="-1">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Atenção</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
const store = {
  dialog: {},
};
export default {
  data() {
    return {
      message: "Ocorreu um erro ao realizar esta operação",
    };
  },
  mounted() {
    store.dialog = this;
  },
  show(message) {
    store.dialog.message = message;
    const modal = new Modal(document.getElementById("errorModal"), {});
    modal.show();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";

.modal-footer,
.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header h5 {
  width: 100%;
  float: left;
  text-align: center;
}
</style>
