<template>
  <div class="main-content notFound-children-scroll">
    <div class="container-about">
      <div class="row">
        <div class="mt-5">
          <h2>
            Você não possui permissão para acessar a Central de Cadastros.
          </h2>
          <h2>Entre em contato com algum administrador.</h2>
          <button class="btn btn-primary" @click="logout">Fazer Logout</button>
        </div>
      </div>
      <div class="container-image">
        <img
          src="@/assets/svg/heliceFundo.svg"
          alt="Imagem de um Parque Eólico"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import { logoutFromSSO } from "@/utils/authentication";
export default {
  mounted() {},
  methods: {
    logout() {
      this.mxToggleWarningModal(CONFIRMATION.APP.LOGOUT, "Sim")
        .then(() => {
          logoutFromSSO();
          this.ActionSetToken(null);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
// @import "@/assets/style/mixins.scss";
// @import "@/assets/style/breakpoints.scss";
.notFound-page {
  display: flex;
  padding: 0 !important;
  background-color: #e8e8e8;
}
.notFound-content {
  width: 100%;
}
.notFound-children-scroll {
  text-align: center;
  padding: 0 !important;
  height: calc(100vh - 63px - 1.5rem);
}
h1 {
  font-weight: 400;
}
.main-content {
  height: 100vh;
  background-color: #ffffff;
}
.container-about {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  justify-content: space-between;
  .container-image {
    width: 100%;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
