<template>
  <div>
    <div class="wrapper-table table-responsive">
      <table class="cursop table table-hover">
        <thead>
          <tr>
            <th>Razão Social</th>
            <th>CNPJ</th>
            <th>Apelido</th>
            <th>Tipo</th>
            <th v-if="CAN_EDITAR">
              <div class="d-flex align-items-center justify-content-center">
                Selecionar
              </div>
            </th>
          </tr>
        </thead>
        <transition-group
          tag="tbody"
          name="list"
          mode="in-out"
          v-if="empresas.length > 0"
        >
          <tr
            @click.prevent="selectGroup(empresa)"
            v-for="(empresa, empresaIndex) in empresas"
            :key="`empresa_${empresaIndex}`"
            :class="{ 'text-secondary disable-event': isSelected(empresa) }"
            :disabled="isSelected(empresa)"
          >
            <td>{{ empresa.razaoSocial }}</td>
            <td>{{ empresa.cnpj }}</td>
            <td>{{ empresa.apelido }}</td>
            <td>{{ empresa.tipo }}</td>
            <td class="text-center" v-if="!isSelected(empresa) && CAN_EDITAR">
              <input
                @click.stop="selectGroup(empresa)"
                :checked="empresa.selectedForGroup"
                type="checkBox"
                id="flexcheckBoxDefault1"
              />
            </td>
          </tr>
        </transition-group>
        <tbody v-else>
          <tr>
            <td class="text-center" :colspan="5">Nenhuma empresa encontrada</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="pagination"></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  props: {
    empresasAdded: { type: Array },
    pagination: { type: Object },
    empresas: { type: Array },
  },
  computed: {
    CAN_EDITAR() {
      return (
        this.$can("CENTRAL_CADASTROS_EDITAR") ||
        this.$can("CENTRAL_CADASTROS_APROVAR") ||
        this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
  },
  methods: {
    selectGroup(empresa) {
      if (!this.isSelected(empresa)) {
        empresa.selectedForGroup = !empresa.selectedForGroup;
      }
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
    isSelected(empresa) {
      return !!this.empresasAdded.find((item) => item.id === empresa.id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
@import "@/assets/style/breakpoints.scss";
.disable-event {
  cursor: initial;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
