<template>
  <div class="col col-lg-6">
    <div class="input-group">
      <label for="descricaoField">
        Descrição ou Comentário
        <span v-if="isRequired" class="required-markup">*</span>
        <input
          @input="checkSpecialCharacters"
          :class="{
            'is-invalid': $v.model.content.$error,
          }"
          maxlength="100"
          :disabled="disabled"
          id="descricaoField"
          class="form-control"
          v-model.trim="model.content"
        />
      </label>
      <div class="form-text">
        Caso selecionado o tipo do anexo Outros, esse campo será obrigatório.
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import FieldTemplate from "@/components/forms/fields/empresa/FieldTemplate.vue";
export default {
  extends: FieldTemplate,
  props: {
    disabled: Boolean,
    modelProp: Object,
    isRequired: Boolean,
  },
  data() {
    return {
      model: {
        name: "descricao",
        content: "",
      },
    };
  },
  methods: {
    checkSpecialCharacters() {
      const newValueWOSpecialCharacters = this.$v.model.content.$model.replace(
        /[^a-zA-Z0-9\s-./]/g,
        ""
      );
      this.$v.model.content.$model = newValueWOSpecialCharacters;
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.mxToggleErrorMessageAlert("Preencha a descrição do arquivo");
        return true;
      }
      return false;
    },
  },
  validations: {
    model: {
      content: {
        required: requiredIf(function () {
          return this.isRequired;
        }),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
label {
  width: 100% !important;
}
</style>
