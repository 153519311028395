<template>
  <div>
    <div class="mt-4 wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">CNPJ</th>
            <th scope="col">Razão Social</th>
            <th scope="col">Apelido</th>
            <th scope="col">Nome fantasia</th>
            <th class="text-center" scope="col">Selecionar</th>
          </tr>
        </thead>
        <tbody v-if="config.conteudo.length > 0">
          <tr
            :class="
              gestor.id !== $parent.gestorSelected.id ? 'text-secondary' : null
            "
            v-for="(gestor, gestorIndex) in config.conteudo"
            :key="`gestor_${gestorIndex}`"
          >
            <td>{{ gestor.cnpj | CNPJ }}</td>
            <td>{{ gestor.razaoSocial }}</td>
            <td>{{ gestor.apelido | EMPTY }}</td>
            <td>{{ gestor.nomeFantasia | EMPTY }}</td>
            <td class="text-center">
              <input
                name="#"
                type="radio"
                @click="selectGestor(gestor)"
                :disabled="isAssociate || disabled"
                :class="isAssociate ? 'text-secondary' : null"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="5">Nenhum gestor encontrado</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { CNPJ, EMPTY } from "@/filters/formatters";
import Pagination from "@/components/pagination/pagination";
export default {
  props: {
    config: Object,
    disabled: Boolean,
    isAssociate: Boolean,
  },
  methods: {
    selectGestor(gestor) {
      this.$parent.gestorSelected = gestor;
    },
    setPage(page) {
      this.config.filter.page = page;
      this.$parent.doSearch();
    },
  },
  components: {
    Pagination,
  },
  filters: {
    CNPJ,
    EMPTY,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
th {
  font-weight: 500;
}
</style>
