<template>
  <div class="pre-input-group">
    <div
      class="input-group"
      :class="{
        'is-invalid': $v.model.content.$error,
        'file-attached': IS_FILE_ATTACHED && !$v.model.content.$error,
        'is-dragging': isDragging,
        disabled,
      }"
    >
      <span>
        <template v-if="model.content">
          <i class="icon-file" />
          {{ model.content.name }}
        </template>
        <template v-else>
          <i class="icon-download" />
          Arraste e solte arquivos aqui ou clique para enviar
        </template>
      </span>
      <input
        type="file"
        :disabled="disabled"
        @change="changeFile"
        ref="inputUploadField"
        @dragenter="isDragging = true"
        @dragleave="isDragging = false"
        @dragover.prevent
        @drop.prevent="drop"
      />
    </div>
    <button
      @click="downloadLayoutFile"
      v-if="getLayoutFile !== null"
      :disabled="loadingLayout"
      :class="{ disabled: loadingLayout }"
    >
      <div>
        <overlay-loader
          v-if="loadingLayout"
          :styles="{
            spinnerWrapper: {
              width: '30px',
              height: '30px',
            },
          }"
        />
        <i class="icon-descarregar small" />
        <span>Baixar template de Excel</span>
      </div>
    </button>
  </div>
</template>

<script>
import { ERROR, MAX_FILE_SIZE } from "@/constants/strings";
import { downloadFileFromResponseObject } from "@/utils/functionUtils";

export default {
  props: {
    disabled: Boolean,
    modelProp: Object,
    fileType: {
      type: Array,
      required: false,
    },
    getLayoutFile: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      model: {
        name: "arquivo",
        content: null,
      },
      isDragging: false,
      loadingLayout: false,
    };
  },
  watch: {
    modelProp: {
      deep: true,
      handler() {
        this.model.content = this.modelProp[this.model.name];
        if (!this.model.content && this.$refs.inputUploadField.files) {
          this.$refs.inputUploadField.files = new DataTransfer().files;
        }
        this.$v.$touch();
      },
    },
    "model.content": {
      deep: true,
      handler() {
        this.modelProp[this.model.name] = this.model.content;
        this.$v.$touch();
      },
    },
  },
  methods: {
    changeFile(event) {
      this.model.content = event.target.files[0];
    },
    drop(event) {
      this.isDragging = false;
      if (this.disabled) return;
      this.model.content = event.dataTransfer.files[0];
    },
    isInvalid() {
      this.$v.$touch();
      if (!this.$v.model.content.hasRightExtension) {
        this.mxToggleErrorMessageAlert(ERROR.WRONG_FILE_EXTENSION);
      }
      if (!this.$v.model.content.inMaxNameSize) {
        this.mxToggleErrorMessageAlert(ERROR.MAX_FILE_SIZE_NAME);
      }
      if (!this.$v.model.content.inMaxContentSize) {
        this.mxToggleErrorMessageAlert(ERROR.MAX_FILE_SIZE_MB);
      }
      return !!this.$v.$invalid;
    },
    async downloadLayoutFile() {
      if (this.loadingLayout) return;

      this.loadingLayout = true;

      try {
        const data = await this.getLayoutFile();
        downloadFileFromResponseObject(
          data,
          "Template Cadastro de Empresas.xlsx"
        );
      } catch (error) {
        this.mxHandleRequestError(error);
      } finally {
        this.loadingLayout = false;
      }
    },
  },
  computed: {
    IS_FILE_ATTACHED() {
      return this.model.content !== null;
    },
  },
  validations: {
    model: {
      content: {
        hasRightExtension(value) {
          if (!this.fileType) return true;
          if (!value) return true;
          const filename = value.name;
          for (let i = 0; i < this.fileType.length; i++) {
            const ext = this.fileType[i];
            if (
              filename.indexOf(ext) &&
              filename.length - ext.length === filename.indexOf(ext)
            ) {
              return true;
            }
          }
          return false;
        },
        inMaxNameSize(value) {
          if (!value) return true;
          return value.name.length < 100;
        },
        inMaxContentSize(value) {
          if (!value) return true;
          return value.size < MAX_FILE_SIZE;
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.pre-input-group {
  position: relative;
  > button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $primary--white;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    padding: 5px 10px;
    &:not(.disabled) {
      transition: background-color 200ms ease-in-out;
      cursor: pointer;
      border: 1px solid #505050;
      &:hover {
        background-color: #f3f2f2;
      }
    }
    > div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      > i {
        font-size: 1em;
        &::before {
          color: #505050;
        }
      }
    }
  }
}

.input-group {
  height: 30vh;
  min-height: 150px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px dashed $primary--black;
  transition: opacity 200ms ease-in-out;
  &.disabled {
    > span {
      opacity: 0.5;
    }
  }
  &:not(.disabled) {
    &:hover {
      opacity: 0.5;
    }
    &.is-invalid {
      border: 1px solid $red--primary-error;
    }
    &.file-attached {
      border: 2px solid $status--green;
    }
    &.is-dragging {
      border: 1px solid $status--yellow;
    }
    > input {
      cursor: pointer;
    }
  }
  > span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    > i {
      font-size: 3em;
      &::before {
        color: #505050;
      }
    }
  }
  > input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }
}
</style>
