class AgentePosVendaModel {
  constructor({ id, nome, ativo, email } = new Object()) {
    this._id = id;
    this._nome = nome;
    this._ativo = ativo;
    this._email = email;
  }

  get id() {
    return this._id || null;
  }

  set id(id = null) {
    this._id = id;
  }

  get nome() {
    return this._nome || null;
  }

  set nome(nome = null) {
    this._nome = nome;
  }

  get ativo() {
    return this._ativo || false;
  }

  set ativo(ativo = null) {
    this._ativo = ativo;
  }

  get email() {
    return this._email || null;
  }

  set email(email = null) {
    this._email = email;
  }

  getData() {
    const { nome, ativo, email } = this;
    return {
      id: null,
      nome,
      ativo,
      email,
    };
  }
}

export default AgentePosVendaModel;
