export default class ContatoModel {
  constructor(
    {
      id,
      cpf,
      nome,
      cargo,
      email,
      documento,
      comprador,
      wbcContato,
      estadoCivil,
      departamento,
      telefoneFixo,
      telefoneCelular,
      numeroDocumento,
      representanteLegal,
      permissoes = new Object(),
    } = new Object()
  ) {
    this._id = id;
    this._cpf = cpf;
    this._nome = nome;
    this._email = email;
    this._cargo = cargo;
    this._comprador = comprador;
    this._wbcContato = wbcContato;
    this._permissoes = permissoes;
    this._estadoCivil = estadoCivil;
    this._departamento = departamento;
    this._telefoneFixo = telefoneFixo;
    this._tipoDocumento = documento;
    this._telefoneCelular = telefoneCelular;
    this._numeroDocumento = numeroDocumento;
    this._representanteLegal = representanteLegal;
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id || null;
  }

  set nome(nome = null) {
    this._nome = nome;
  }

  get nome() {
    return this._nome || null;
  }

  set email(email = null) {
    this._email = email;
  }

  get email() {
    return this._email || null;
  }

  set telefoneFixo(telefoneFixo = null) {
    this._telefoneFixo = telefoneFixo;
  }

  get telefoneFixo() {
    return this._telefoneFixo || null;
  }

  set telefoneCelular(telefoneCelular = null) {
    this._telefoneCelular = telefoneCelular;
  }

  get telefoneCelular() {
    return this._telefoneCelular || null;
  }

  set cargo(cargo = null) {
    this._cargo = cargo;
  }

  get cargo() {
    return this._cargo || null;
  }

  set cpf(cpf = null) {
    this._cpf = cpf;
  }

  get cpf() {
    return this._cpf;
  }

  set estadoCivil(estadoCivil = null) {
    this._estadoCivil = estadoCivil;
  }

  get estadoCivil() {
    return this._estadoCivil || null;
  }

  set documento(documento = null) {
    this._tipoDocumento = documento;
  }

  get documento() {
    return this._tipoDocumento || null;
  }

  set numeroDocumento(numeroDocumento = null) {
    this._numeroDocumento = numeroDocumento;
  }

  get numeroDocumento() {
    return this._numeroDocumento || null;
  }
  
  set departamento(departamento = null) {
    this._departamento = departamento;
  }

  get departamento() {
    return this._departamento || null;
  }

  set permissoes(permissoes = new Object()) {
    this._permissoes = permissoes;
  }

  addPermissao(permissao) {
    this._permissoes.push(permissao);
  }

  clearPermissoes() {
    this._permissoes = new Array();
  }

  get permissoes() {
    return this._permissoes ?? new Object();
  }

  set representanteLegal(representanteLegal = null) {
    this._representanteLegal = representanteLegal;
  }

  get representanteLegal() {
    return this._representanteLegal || null;
  }

  set comprador(comprador = null) {
    this._comprador = comprador;
  }

  get comprador() {
    return this._comprador || null;
  }

  set wbcContato(wbcContato = null) {
    this._wbcContato = wbcContato;
  }

  get wbcContato() {
    return this._wbcContato;
  }

  getData(empresaTipo) {
    const {
      id,
      cpf,
      nome,
      email,
      cargo,
      documento,
      comprador,
      wbcContato,
      departamento,
      permissoes,
      estadoCivil,
      telefoneFixo,
      numeroDocumento,
      telefoneCelular,
      representanteLegal,
    } = this;

    return {
      id,
      cpf: cpf && cpf.replace(/[^\d]/g, ""),
      nome,
      email,
      cargo,
      wbcContato,
      comprador: !!comprador || null,
      departamento,
      permissoes: {
        demonstrativo:
          empresaTipo && empresaTipo === "GESTOR"
            ? false
            : permissoes.demonstrativo,
        recebeNotaFiscal:
          empresaTipo && empresaTipo === "GESTOR"
            ? false
            : !!permissoes.recebeNotaFiscal || false,
      },
      representanteLegal: !!representanteLegal || null,
      estadoCivil,
      telefoneFixo: telefoneFixo && telefoneFixo.replace(/[^\d]/g, ""),
      documento,
      numeroDocumento: numeroDocumento && numeroDocumento.replace(/[^\d]/g, ""),
      telefoneCelular: telefoneCelular && telefoneCelular.replace(/[^\d]/g, ""),
    };
  }
}
