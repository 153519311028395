export default class ArquivoModel {
  constructor(
    { id, arquivo, tipo, dataCriacao, dataValidade, descricao } = new Object()
  ) {
    this._id = id;
    this._arquivo = arquivo;
    this._tipo = tipo;
    this._dataCriacao = dataCriacao;
    this._dataValidade = dataValidade;
    this._descricao = descricao;
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id || null;
  }

  set arquivo(arquivo = null) {
    this._arquivo = arquivo;
  }

  get arquivo() {
    return this._arquivo;
  }

  set tipo(tipo = { id: null, nome: null }) {
    this._tipo = tipo;
  }

  get tipo() {
    return this._tipo || null;
  }

  set descricao(descricao = null) {
    this._descricao = descricao;
  }

  get descricao() {
    return this._descricao || null;
  }

  set dataValidade(dataValidade = null) {
    this._dataValidade = dataValidade;
  }

  get dataValidade() {
    return this._dataValidade || null;
  }

  set dataCriacao(dataCriacao = null) {
    this._dataCriacao = dataCriacao;
  }

  get dataCriacao() {
    return this._dataCriacao || null;
  }

  getFileName() {
    return this._arquivo;
  }

  getData() {
    const { arquivo, tipo, dataCriacao, descricao, dataValidade } = this;
    const data = {
      arquivo: new Array(arquivo, arquivo.nome),
      tipoAnexo: tipo.id,
      descricao,
      dataCriacao,
      dataValidade
    };
    if (!descricao) delete data.descricao;
    if (!dataValidade) delete data.dataValidade;
    return data;
  }
}
