<template>
    <div>
        <table  class="table table-hover">
        <tbody>
            <tr>
                <td style="width:80%" >
                    {{name}}
                </td>
                <td >
                    <select @change="select" v-model="selected">
                        <option value="no">Não se aplica</option>
                        <option :value="option.permissao" v-for="(option,index) in options" :key="index" >{{option.nome}}</option>
                    </select>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
</template>
<script>
import GrupoAcessoModel from "@/models/grupo-acesso/GrupoAcessoModel";
export default {
    props:['combo','name'],
    data(){
        return{
            model: GrupoAcessoModel,
            selected : 'no'
        }
    },
    mounted(){
        if(this.model.getOptions().find(g=>g.combo == this.combo && g.enabled == true )){
             this.selected = this.model.getOptions().find(g=>g.combo == this.combo && g.enabled == true ).permissao
        }
    },
    computed: {
    options: function () {
      return this.model.getOptions().filter(g=>g.combo == this.combo);
    },
  },
  methods:{
      select(){
          this.model.comboChange(this.combo,this.selected)
      }
  }
}
</script>
<style lang="scss" scoped>
    @import "@/assets/style/colors.scss";
    @import "@/assets/style/style.scss";
    select{
        background: #E8E8E8;
        border:none;
        font-size: 12.8px;
        line-height: 24px;
        color: #6C757D;
        border: 1px solid #CED4DA;
        border-radius: 4px;
    }
    .table {
  cursor: pointer;
  margin-bottom: 0px !important;
}
</style>