<template>
  <div class="col col-lg-3">
    <div class="input-group">
      <label for="departamentoField">
        Departamento
        <span class="recent-updated-label" v-if="recentUpdatedField">
          <tooltip-informacao-alterada />
        </span>
        <select
          :disabled="disabled"
          id="departamentoField"
          v-model="model.content"
          class="form-select"
          :class="{
            'recent-updated': recentUpdatedField,
          }"
        >
          <option
            v-for="(departamento, departamentoIndex) in list"
            :key="`tipo_departamento_${departamentoIndex}`"
            :value="departamento"
          >
            {{ departamento.nome }}
          </option>
        </select>
        <div class="form-text">Escolha uma das opções.</div>
      </label>
    </div>
  </div>
</template>

<script>
import ContatoService from "@/services/portal/empresa/ContatoService";
import TooltipInformacaoAlterada from "@/components/forms/TooltipInformacaoAlterada.vue";
import FieldTemplateUpperCase from "@/components/forms/fields/empresa/FieldTemplateUpperCase.vue";
export default {
  extends: FieldTemplateUpperCase,
  props: {
    disabled: Boolean,
    modelProp: Object,
    recentUpdatedField: Boolean,
  },
  data() {
    return {
      list: new Array(),
      loadList: undefined,
      model: {
        name: "departamento",
        content: "",
      },
    };
  },
  mounted() {
    this.loadList = ContatoService.listDepartamentosAtivos()
      .then(
        ({ data }) => (this.list = data.filter((departamento) => departamento.ativo))
      )
      .catch((error) => console.log(error));
  },
  components: {
    TooltipInformacaoAlterada,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/style.scss";
label {
  width: 100% !important;
}
</style>
