<template>
  <div>
    <div class="wrapper-table table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nome do Grupo</th>
            <th>Status</th>
            <th>Data Criação</th>
          </tr>
        </thead>
        <tbody v-if="config.conteudo.length > 0">
          <tr
            @click="click(grupoAcesso)"
            v-for="(grupoAcesso, grupoAcessoIndex) in config.conteudo"
            :key="`grupo_acesso_${grupoAcessoIndex}`"
          >
            <td>
              <tooltip
                :width="700"
                :renderMessage="grupoAcesso.nome.length > 90"
                :text="`${formattedText(grupoAcesso.nome)}`"
              >
                <template #tooltip-text>
                  {{ grupoAcesso.nome }}
                </template>
              </tooltip>
            </td>
            <td>
              {{ grupoAcesso.ativo ? "Ativo" : "Inativo" }}
            </td>
            <td>
              {{ grupoAcesso.dataCriacao | DATE_ptBR }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="3">Nenhum registro encontrado</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col col-lg-12">
        <pagination @setPage="setPage" :info="config.pagination"></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Tooltip from "@/components/Tooltip.vue";
import { DATE_ptBR } from "@/filters/formatters";
import Pagination from "@/components/pagination/pagination";
export default {
  props: {
    config: { type: Object, required: true },
  },
  filters: {
    DATE_ptBR,
  },
  methods: {
    formattedText(value) {
      if (!value) return "";

      if (value.length <= 90) {
        return value;
      }
      return `${value.substring(0, 90)}...`;
    },
    click(value) {
      this.$emit("click", value);
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
  components: {
    Tooltip,
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";

.table {
  cursor: pointer;
}
</style>
