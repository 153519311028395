<script>
import { formIsValid } from "@/utils/validators";
import { CONFIRMATION, HTTP_RESPONSE } from "@/constants/strings";
import { cleanUpFields, setFieldsData } from "@/utils/functionUtils";
export default {
  data() {
    return {
      loading: undefined,
      editing: false,
      editingComponent: false,
      componenteLoading: undefined,
      list: new Array(),
      service: null,
      requestEntity: "",
      model: null,
      empresa: null,
    };
  },
  destroyed() {
    this.setHeaderText("");
  },
  methods: {
    edit(entity) {
      this.editing = true;
      this.model.id = entity.id;
      setFieldsData(this, entity);
    },
    resetFields() {
      this.editing = false;
      this.model.id = null;
      cleanUpFields(this);
      this.mxResetErrorPendencies();
    },
    getByEmpresa() {
      const { empresaId } = this.$parent;
      this.loading = this.service
        .getByEmpresa({
          empresaId,
        })
        .then(({ data }) => {
          this.list = data || new Array();
        })
        .catch((error) => {
          this.mxHandleRequestError(error, this.requestEntity);
        });
    },
    remove({ id }) {
      this.mxToggleWarningModal(
        CONFIRMATION[this.requestEntity.toUpperCase()].DELETE
      ).then(() => {
        const { empresaId } = this.$parent;
        this.loading = this.service
          .remove({ id }, empresaId)
          .then(({ data }) => {
            this.mxToggleToast(
              HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.DELETE
            );
            this.resetFields();
            this.getByEmpresa(data);
          })
          .catch((error) =>
            this.mxHandleRequestError(error, this.requestEntity)
          );
      });
    },
    update() {
      return new Promise((resolve, reject) => {
        if (!formIsValid(this)) {
          reject();
        } else {
          const { empresaId } = this.$parent;
          this.loading = this.service
            .update(this.model.getData(), empresaId)
            .then(({ data }) => {
              this.mxToggleToast(
                HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.UPDATE
              );
              this.resetFields();
              if (this.service.getByEmpresa) {
                this.getByEmpresa(data);
              }
              resolve();
            })
            .catch((error) => {
              this.mxHandleRequestError(error, this.requestEntity);
              reject(error);
            });
        }
      });
    },
    save(throwError = false) {
      return new Promise((resolve, reject) => {
        if (!formIsValid(this)) {
          throwError && reject();
        } else {
          const { empresaId } = this.$parent;
          this.loading = this.service
            .create(this.model.getData(), empresaId)
            .then(({ data }) => {
              this.mxToggleToast(
                HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.CREATE
              );
              this.resetFields();
              this.getByEmpresa(data);
              resolve();
            })
            .catch((error) => {
              this.mxHandleRequestError(error, this.requestEntity);
              throwError && reject(error);
            });
        }
      });
    },
  },
};
</script>
