<template>
  <div class="navbar-style">
    <div class="row align-items-center">
      <div @click.stop="ActionToggleStatusMenu(!menuIsOpened)" class="col col-1" style="cursor: pointer">
        <i class="icon-menu"></i>
      </div>
      <slot name="navbar-items" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MxUserUtils from "@/mixins/mxUserUtils";
export default {
  computed: {
    ...mapGetters(["menuIsOpened"]),
  },
  methods: {
    ...mapActions(["ActionToggleStatusMenu"]),
  },
  updated() {
    if (!this.mxCheckIsLogged()) {
      this.$store.dispatch('ActionSetUsuario');
    }
  },
  mixins: [MxUserUtils],
};

</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
@import "@/assets/style/style.scss";

.row {
  height: 50px;
}

.navbar-item {
  margin: 0px 4px;
}

.navbar-item:last-child {
  margin-right: 0px;
}

.navbar-style {
  padding-left: 40px;
  padding-right: 40px;
  background-color: $primary--white;
  border-bottom: 3px solid $primary--orange;
}

.icon {
  width: 20px !important;
  color: $primary--black;
  font-size: 1.1em !important;
}

.icon-logout {
  height: 14px !important;
  font-size: 0.97em !important;
}

.icon-avatar {
  height: 19px !important;
  padding-right: 25px;
  font-size: 1.2em !important;
}

.icon-sino {
  height: 19px !important;
  font-size: 1.2em !important;
}

.local-environment,
.homologacao-environment,
.desenvolvimento-environment {
  height: 50%;
  width: auto;
  display: flex;
  color: #ffffff;
  align-items: center;
  border-radius: 20px;
}

.local-environment {
  background: #ec6623;
}

.homologacao-environment {
  background: #d40d00;
}

.desenvolvimento-environment {
  background: #7700d4;
}
</style>
