var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",class:{ 'justify-content-between': _vm.tabs.length > 3 }},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('span',{key:("tab_" + tabIndex),class:[
      { 'selected-tab': _vm.$parent.activeTab === tab.key },
      { 'me-3': _vm.tabs.length <= 3 },
      tab.active ? 'tab-active' : 'tab-inactive',
      'tab',
      'mb-3',
      'col',
      'col-auto' ],on:{"click":function($event){return _vm.changeTab(tab)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }