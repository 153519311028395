<template>
  <div v-async="loading">
    <div class="row mt-3">
      <nome-field :modelProp="model" ref="nomeField" />
      <email-field :modelProp="model" :disabled="true" ref="emailField" />
    </div>
    <div class="row mt-3">
      <telefone-fixo-field :modelProp="model" ref="telefoneFixoField" />
      <telefone-celular-field :modelProp="model" ref="telefoneCelularField" />
      <cargo-field :colLG="3" :modelProp="model" ref="cargoField" />
    </div>
    <div class="row mt-3">
      <grupo-de-acesso-field
        :modelProp="model"
        :disabled="true"
        ref="grupoAcessoField"
      />
    </div>
    <div class="row mt-5">
      <div class="col col-lg-auto ms-auto">
        <button class="btn btn-primary ms-3" @click="edit">Atualizar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MxUserUtils from "@/mixins/mxUserUtils";
import { formIsValid } from "@/utils/validators";
import { HTTP_RESPONSE } from "@/constants/strings";
import { setFieldsData } from "@/utils/functionUtils";
import UsuarioService from "@/services/portal/UsuarioService";
import DadosPessoaisModel from "@/models/perfil/DadosPessoaisModel";
import NomeField from "@/components/forms/fields/empresa/NomeField.vue";
import CnpjField from "@/components/forms/fields/empresa/CnpjField.vue";
import EmailField from "@/components/forms/fields/empresa/EmailField.vue";
import CargoField from "@/components/forms/fields/empresa/CargoField.vue";
import TelefoneFixoField from "@/components/forms/fields/empresa/TelefoneFixoField.vue";
import GrupoDeAcessoField from "@/components/forms/fields/empresa/GrupoDeAcessoField.vue";
import TelefoneCelularField from "@/components/forms/fields/empresa/TelefoneCelularField.vue";

export default {
  data() {
    return {
      loading: undefined,
      model: new DadosPessoaisModel()
    };
  },
  computed: {
    ...mapGetters(["getUsuario"]),
  },
  async mounted() {
    this.model = new DadosPessoaisModel(this.getUsuario);
    await this.$refs.grupoAcessoField.loadList;
    setFieldsData(this, this.model);
  },
  mixins: [MxUserUtils],
  methods: {
    edit() {
      if (formIsValid(this)) {
        this.loading = UsuarioService.update(this.model.getData())
          .then(() => {
            this.mxToggleToast(HTTP_RESPONSE.USUARIO.SUCCESS.UPDATE);
            this.mxGetUserDetails(this.model.id, false);
          })
          .catch((error) => this.mxHandleRequestError(error, "USUARIO"));
      }
    },
  },
  components: {
    CnpjField,
    NomeField,
    EmailField,
    CargoField,
    TelefoneFixoField,
    GrupoDeAcessoField,
    TelefoneCelularField,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
@import "@/assets/style/colors.scss";
</style>
