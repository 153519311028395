var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 wrapper-table table-responsive"},[_c('table',{staticClass:"table table-hover"},[_c('thead',[(_vm.isGestor)?_c('tr',[_c('th',{staticClass:"cursop",on:{"click":function($event){return _vm.$emit('sortBy', 'nome')}}},[_vm._v("Nome")]),_c('th',{staticClass:"cursop",attrs:{"scope":"col"},on:{"click":function($event){return _vm.$emit('sortBy', 'email')}}},[_vm._v(" E-mail ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Departamento")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Nota Fiscal")]),_c('th'),_c('th')]):_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}}),_c('th',{staticClass:"cursop",attrs:{"scope":"col"},on:{"click":function($event){return _vm.$emit('sortBy', 'nome')}}},[_vm._v(" Nome ")]),_c('th',{staticClass:"cursop",attrs:{"scope":"col"},on:{"click":function($event){return _vm.$emit('sortBy', 'email')}}},[_vm._v(" E-mail ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Departamento")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Rep. Legal")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Comprador")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Nota Fiscal")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Aprova Dem.")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Notificado Dem.")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}}),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}})])]),(!_vm.contatosList || _vm.contatosList.length === 0)?_c('tbody',[_vm._m(0)]):(_vm.isGestor)?_c('transition-group',{attrs:{"tag":"tbody","name":"list","mode":"in-out"}},_vm._l((_vm.contatosList),function(contato,contatoIndex){return _c('tr',{key:("contato_" + contatoIndex),class:{
          'recent-updated-row': _vm.recentUpdatedField(contato.id),
        }},[_c('td',[_vm._v(_vm._s(contato.nome))]),_c('td',[_vm._v(_vm._s(contato.email))]),_c('td',[_vm._v(_vm._s(contato.departamento ? contato.departamento.nome : "-"))]),_c('td',{staticClass:"text-center"},[(contato.permissoes && contato.permissoes.recebeNotaFiscal)?_c('i',{staticClass:"icon-check icon small"}):_vm._e()]),_c('td',{staticClass:"text-end"},[_c('i',{staticClass:"icon-lapis icon cursop",on:{"click":function($event){return _vm.$emit('editContato', contato)}}})]),_c('td',[_c('i',{staticClass:"icon-lixeira icon ms-3 cursop",on:{"click":function($event){return _vm.$emit('deleteContato', contato)}}})])])}),0):_c('transition-group',{attrs:{"name":"list","mode":"in-out","tag":"tbody"}},_vm._l((_vm.contatosList),function(contato,contatoIndex){return _c('tr',{key:("contato_" + contatoIndex),class:{
          'recent-updated-row': _vm.recentUpdatedField(contato.id),
        }},[_c('td',[_c('div',{staticClass:"pt-1 d-flex align-items-center justify-content-center"},[(_vm.recentUpdatedField(contato.id))?_c('tooltip',{attrs:{"width":450,"boxAlign":"right","arrowAlign":"right","icon":"icon-warning"},scopedSlots:_vm._u([{key:"tooltip-text",fn:function(){return [_vm._v(" Possui informações alteradas recentemente, pendente de aprovação ")]},proxy:true}],null,true)}):_vm._e()],1)]),_c('td',[_vm._v(_vm._s(contato.nome))]),_c('td',[_vm._v(_vm._s(contato.email))]),_c('td',[_vm._v(_vm._s(contato.departamento ? contato.departamento.nome : "-"))]),_c('td',{staticClass:"text-center"},[(contato.representanteLegal)?_c('i',{staticClass:"icon-check icon small"}):_vm._e()]),_c('td',{staticClass:"text-center"},[(contato.comprador)?_c('i',{staticClass:"icon-check icon small"}):_vm._e()]),_c('td',{staticClass:"text-center"},[(contato.permissoes && contato.permissoes.recebeNotaFiscal)?_c('i',{staticClass:"icon-check icon small"}):_vm._e()]),_c('td',{staticClass:"text-center"},[(
              contato.permissoes.demonstrativo &&
              contato.permissoes.demonstrativo.id &&
              contato.permissoes.demonstrativo.id === 'CONTATO_DF_APROVA'
            )?_c('i',{staticClass:"icon-check icon small"}):_vm._e()]),_c('td',{staticClass:"text-center"},[(
              contato.permissoes.demonstrativo &&
              contato.permissoes.demonstrativo.id &&
              contato.permissoes.demonstrativo.id === 'CONTATO_DF_NOTIFICADO'
            )?_c('i',{staticClass:"icon-check icon small"}):_vm._e()]),_c('td',[(!_vm.disabled)?_c('i',{staticClass:"icon-lapis icon cursop",on:{"click":function($event){return _vm.$emit('editContato', contato)}}}):_vm._e()]),_c('td',[(!_vm.disabled)?_c('i',{staticClass:"icon-lixeira icon ms-3 cursop",on:{"click":function($event){return _vm.$emit('deleteContato', contato)}}}):_vm._e()])])}),0)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"8"}},[_vm._v("Nenhum contato adicionado")])])}]

export { render, staticRenderFns }